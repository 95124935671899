import { useState, useContext, useEffect } from "react";
import clsx from "clsx";
import RichTextContent from "contentful";
import { useRouter } from "next/router";

import Grid from "@components/Grid/Grid";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import IconArrowSmall from "../../public/assets2019/img/svg/arrow.svg";
import { LOGIN_URL } from "../../constants";
import styles from "@components/HowToApplyPage/HowToApplyPage.module.scss";
import { UserContext } from "../../context/user";
import ClipLoader from "react-spinners/ClipLoader";
import { useDevice } from "@utilities/react/get-device/get-device";

export interface HowToApplyPageProps {
  howToApplyHeadingTitle: string;
  descriptionSecondaryTitleText: string;
  createProfileText: RichTextContent;
  createProfileTinyMceText: TinyMceRichText;
  createProfileLinkTitleText: string;
  createProfileUrlLink: string;
  loginTitleText: string;
  loginButtonTitleText: string;
  mobileBannerText?: string;
}

const HowToApplyPage = (props: { content: HowToApplyPageProps }) => {
  const { content } = props;
  const {
    howToApplyHeadingTitle,
    descriptionSecondaryTitleText,
    createProfileText,
    createProfileTinyMceText,
    createProfileLinkTitleText,
    createProfileUrlLink,
    loginTitleText,
    loginButtonTitleText,
    mobileBannerText,
  } = content;
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const isWindow = typeof window === "object";
  const urlParams = isWindow
    ? new URLSearchParams(window.location.search)
    : null;
  const applyID = urlParams?.get("applyID") || "";

  const redirectUrl = `${LOGIN_URL}?returnUrl=${encodeURIComponent(
    `${process.env.NEXT_PUBLIC_URL}/interact?applyID=${applyID}`
  )}`;
  const device = useDevice();

  const isMobile = device === "mobile";
  const { user } = useContext(UserContext);

  useEffect(() => {
    if (user && device && !isMobile) {
      window.location.href = `${process.env.NEXT_PUBLIC_URL}/interact?applyID=${applyID}`;
    }
  }, [user, device, isMobile]);

  if (user && !isMobile) return <p>Loading...</p>;

  const handleLoginClick = () => {
    setLoading(true);
  };

  const renderMobileBanner = () => {
    return (
      <div className={clsx(styles.mobile, styles["mobile-section"])}>
        <h1 className={clsx(styles["section-title"], styles.h2)}>
          {howToApplyHeadingTitle}
        </h1>
        <p>{mobileBannerText}</p>
      </div>
    );
  };

  return (
    <div className={clsx("wrapper")}>
      <Grid row>
        <Grid column sm={12}>
          {!!mobileBannerText && renderMobileBanner()}

          <section
            className={clsx(
              "component",
              styles["apply-page"],
              !!mobileBannerText && styles.desktop
            )}
          >
            <h1 className={clsx(styles["section-title"], styles.h2)}>
              {howToApplyHeadingTitle}
            </h1>
            <Grid row>
              <Grid column sm={12} md={6}>
                <h3 className={styles["sub-title"]}>
                  {descriptionSecondaryTitleText}
                </h3>
                <div className={styles["create-profile"]}>
                  <RichTextDisplay
                    richText={createProfileText}
                    tinyMceRichText={createProfileTinyMceText}
                  />
                  <p>
                    <button
                      className="tertiary"
                      onClick={() =>
                        router.push({
                          pathname: createProfileUrlLink,
                          query: {
                            programme: router?.query?.programmeTypeCode,
                            applyID: router?.query?.applyID,
                          },
                        })
                      }
                    >
                      <IconArrowSmall className="tertiary-arrow" />
                      {createProfileLinkTitleText}
                    </button>
                  </p>
                </div>
              </Grid>

              <Grid column sm={12} md={6}>
                <h3 className={styles["sub-title"]}>{loginTitleText}</h3>
                <div className="btn-and-text-wrapper">
                  <div
                    className={clsx(
                      styles["button-wrapper"],
                      styles["form-row"]
                    )}
                  >
                    <a
                      href={redirectUrl}
                      onClick={handleLoginClick}
                      className={`cta ${loading ? "loading" : ""}`}
                    >
                      {loading && (
                        <ClipLoader
                          color="#001e62"
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                      {loginButtonTitleText}
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default HowToApplyPage;
