import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { ProgrammeType } from "@customTypes/ProgrammeType";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import { ReactNode } from "react";
import clsx from "clsx";
import Accordion from "./Accordion";
import styles from "./C11_VerticalAccordion.module.scss";

export interface AccordionItemData {
  title: any;
  content?: any;
  contentTinyMce?: TinyMceRichText;
  childContent?: ReactNode;
}

export interface Fields {
  accordionItems: {
    fields?: AccordionItemData;
  }[];
  singleDisplay?: boolean;
  defaultOpen: boolean;
  title?: string;
}

export interface AccordionData {
  metadata?: { tags: string[] };
  fields: Fields;
}

interface AccordionContainerProps {
  accordionFields: Fields;
  noHeading?: boolean;
  isDropdown?: boolean;
  isProgramme?: boolean;
  isCompare?: boolean;
  programme?: ProgrammeDetails;
  customClass?: string;
}

export const AccordionContainer = (props: AccordionContainerProps) => {
  const {
    noHeading,
    accordionFields,
    isProgramme,
    isCompare,
    isDropdown,
    customClass,
    programme,
  } = props;
  const { title } = accordionFields;
  const careerStageType =
    programme?.programmeType === ProgrammeType.EE
      ? programme?.programmeCareerStagesEeList
      : programme?.programmeCareerStagesDeList;
  const dataAttribute = isProgramme ? { "data-type": careerStageType } : null;
  const accordionProps = {
    ...accordionFields,
    isDropdown,
    isProgramme,
    isCompare,
    programme,
  };

  return (
    <div
      {...dataAttribute}
      className={clsx(
        "component",
        styles["vertical-accordion"],
        customClass && customClass,
        isDropdown && styles["dropdown"],
        isProgramme && styles["programme"]
      )}
    >
      {noHeading ? (
        <Accordion {...accordionProps} />
      ) : (
        <div className="wrapper">
          {title && <h4>{title}</h4>}
          <Accordion {...accordionProps} />
        </div>
      )}
    </div>
  );
};

export default AccordionContainer;
