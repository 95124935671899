import Cta from "@components/Cta/Cta";
import { GeneralLink } from "@customTypes/generalLink";
import styles from "./C270_CtaContainer.module.scss";

interface CtaLink {
  fields: {
    description?: string;
    cta: GeneralLink;
    isSecondaryCta: boolean;
  };
}

interface Cta {
  description?: string;
  cta: GeneralLink;
  isSecondaryCta: boolean;
}

export interface CtaContainerLinkProps {
  content: Cta;
  isBlue: boolean;
  ctaContent: CtaLink;
}

export const CtaContainerLink = (props: CtaContainerLinkProps) => {
  const { content, ctaContent, isBlue } = props;

  let ctaData = content || ctaContent?.fields;

  return (
    <div className={styles["cta-content"]}>
      {ctaData?.description && <p>{ctaData?.description}</p>}
      <div className={styles["button-wrapper"]}>
        <Cta
          light={isBlue}
          type={ctaData?.isSecondaryCta ? "secondary" : "primary"}
          {...ctaData?.cta?.fields}
        />
      </div>
    </div>
  );
};

export default CtaContainerLink;
