import { useEffect } from "react";
import coveoStyling from "../coveo/js/c243_coveo-styling";

import styles from "./C225_LatestBlogPosts.module.scss";

export interface LatestBlogsProps {
  tagFilter: string[];
  categoryFilter?: string[];
  viewMoreLink?: string;
  blogSource: string;
  secondaryTitle: string;
  primaryTitle: string;
}

declare global {
  interface Window {
    CoveoForSitecore: any;
    Coveo: any;
  }
}

export const LatestBlogs = (props: {
  content: LatestBlogsProps;
  index: number;
}) => {
  const { content, index } = props;
  const {
    tagFilter,
    categoryFilter,
    viewMoreLink,
    blogSource,
    secondaryTitle,
    primaryTitle,
  } = content;

  const initCoveo = () => {
    var endpointConfiguration = {
      itemUri: "",
      siteName: "website",
      restEndpointUri: "/api",
    };
    if (typeof window.CoveoForSitecore !== "undefined") {
      window.CoveoForSitecore.SearchEndpoint.configureSitecoreEndpoint(
        endpointConfiguration
      );
      var context = document.getElementById(
        `s595e8a124c0146fa9ab29ee42e7fe312_${index}`
      );
      if (!!context) {
        window.CoveoForSitecore.Context.configureContext(context);
      }
    }
    document.addEventListener("CoveoSearchEndpointInitialized", function () {
      var root = document.querySelector(
        `#s595e8a124c0146fa9ab29ee42e7fe312_${index}`
      );
      window.Coveo.init(root);
    });
  };

  useEffect(() => {
    if (window) {
      coveoStyling.init(".CoveoSearchInterface");
      const script = document.createElement("script");
      script.src =
        "https://static.cloud.coveo.com/searchui/v2.10083/js/CoveoJsSearch.Lazy.min.js";
      script.classList.add("coveo-script");
      script.onload = () => {
        const script2 = document.createElement("script");
        script2.src =
          "https://static.cloud.coveo.com/coveoforsitecore/ui/v0.54.6/js/CoveoForSitecore.Lazy.min.js";
        script2.classList.add("coveo-for-sitecore-script");
        script2.onload = () => {
          const script3 = document.createElement("script");
          script3.src =
            "https://static.cloud.coveo.com/searchui/v2.10083/js/cultures/en.js";
          script3.onload = () => {
            initCoveo();
          };
          document.body.appendChild(script3);
        };
        document.body.appendChild(script2);
      };
      document.body.appendChild(script);
    }
  }, []);

  var coveoMarkup = `
  <div
  class="wrapper CoveoSearchInterface"
  data-results-per-page="3"
  id="s595e8a124c0146fa9ab29ee42e7fe312_${index}"
  >
  <div class="coveo-main-section">
    <div class="coveo-results-column">
      <div class="CoveoForSitecoreAnalytics"></div>
      <div>
      </div>
      <div
        id="genericListingFilterExpression"
        class="CoveoForSitecoreFilterExpression"
        data-sc-advanced-filter='${
          blogSource && blogSource !== "all" && `(@syssource=="${blogSource}")`
        } ${tagFilter?.length ? `(metatags:"${tagFilter[0]}")` : ""} ${
          categoryFilter?.length
            ? `(metacategories:"${categoryFilter[0]}")`
            : ""
        }'
      ></div>
      <span
        class="CoveoSort coveo-accessible-button coveo-selected coveo-sort-hidden"
        style="display: none"
        data-caption="Date"
        data-prebind-sort-criteria="sortCriteriaResolver"
        data-sort-criteria="@metapublishdate descending"
        data-sc-enable-direction-toggle="false"
        data-applied-prebind="true"
        role="button"
        aria-label="Date"
        title="Date"
        tabindex="0"
        >Date</span
      >
      <div
        class="CoveoResultList coveo-result-list-container"
        data-layout="list"
        data-wait-animation="fade"
      >
        <script type="text/underscore" class="result-template">
          <% var dateFieldName = coveoFieldName('metapublishdate'); %>
          <% var dateFormat = "DD MMMM YYYY"; %>
          <% var date = dateTime(raw[dateFieldName], {predefinedFormat: dateFormat}); %>

          <% var source = encodeURIComponent(window.location.hostname); %>
          <% var url = encodeURIComponent(clickUri); %>
          <% var title = encodeURIComponent(coveoFieldValue('metatitle')); %>
          <% var summary = encodeURIComponent(coveoFieldValue('metadescription')); %>
          <% var twitterText = encodeURIComponent(coveoFieldValue('metatitle') + ": " + clickUri); %>

          <div class="component ${styles["blog-post-tile"]} promo-article-news">
              <article>

                  <% if (coveoFieldValue('metaimage')) { %>
                  <div class="wrapper-image">
                      <a class="CoveoResultLink">
                          <img data-src="<%= coveoFieldValue('metaimage') %>" src="<%= coveoFieldValue('metaimage') %>" alt="<%= coveoFieldValue('metatitle') %>">
                      </a>
                  </div>
                  <% } %>
                  <div class="text">
                      <p class="primary-tag"><%= date %></p>
                      <h2 class="heading-rhs text-decoration-border">
                          <a class="CoveoResultLink"><%= coveoFieldValue('metatitle') %></a>
                      </h2>
                      <p class="${
                        styles.copy
                      } copy"><%= coveoFieldValue('metadescription') %></p>
                      <footer>

                          <div class="component tag cf">
                              <% _.each(_.take(coveoFieldValue('metatags'), 3), function(value){ %>
                              <span><%= value %></span>
                              <% }); %>
                          </div>
                          <div class="component social-share">
                              <a href="" class="share-toggle" aria-label="<%= translateLabel('Share content') %>">
                                  <i class="icon">
                                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><title><%= translateLabel('Share') %></title><path d="M18 16.12a2.91 2.91 0 00-2 .77l-7.09-4.15A3.27 3.27 0 009 12a3.27 3.27 0 00-.09-.7L16 7.23A3 3 0 1015 5a3.27 3.27 0 00.09.7L8 9.85a3 3 0 100 4.38l7.12 4.16a2.82 2.82 0 00-.08.65A2.92 2.92 0 1018 16.12z"></path></svg>
                                  </i>
                              </a>
                              <ul class="share-links">
                                  <li>
                                      <a href="https://www.facebook.com/sharer/sharer.php?u=<%=url%>&quote=<%=url%>" target="_blank" aria-label="<%= translateLabel('Share to Facebook') %>">
                                          <i class="icon">
                                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" focusable="false"><title><%= translateLabel('Facebook') %></title><path d="M22 12.06a10 10 0 10-11.56 9.88V15H7.9v-2.94h2.54v-2.2A3.52 3.52 0 0114.21 6a15.72 15.72 0 012.24.19v2.43h-1.26a1.45 1.45 0 00-1.63 1.56v1.88h2.78L15.89 15h-2.33v7A10 10 0 0022 12.06"></path></svg>
                                          </i>
                                      </a>
                                  </li>
                                  <li>
                                      <a href="http://www.linkedin.com/shareArticle?mini=true&url=<%=url%>&title=<%=title%>&summary=<%=summary%>&source=<%=source%>" target="_blank" aria-label="<%= translateLabel('Share to LinkedIn') %>">
                                          <i class="icon">
                                              <svg height="20" width="20" viewBox="0 0 43 43" version="1.1" xmlns="http://www.w3.org/2000/svg" focusable="false"><title><%= translateLabel('LinkedIn') %></title><path d="M21.503 0C9.627 0 0 9.627 0 21.503 0 33.373 9.627 43 21.503 43 33.373 43 43 33.373 43 21.503 43 9.627 33.373 0 21.503 0zm-9.361 17.048h3.964v13.025h-3.964V17.048zm1.75-1.132h-.029c-1.478 0-2.43-1.127-2.43-2.379 0-1.274.98-2.31 2.487-2.31 1.5 0 2.424 1.07 2.458 2.344 0 1.252-.957 2.345-2.486 2.345zm18.637 14.157h-5.097v-6.597c0-1.75-.583-2.94-2.175-2.94-1.211 0-1.914.805-2.23 1.58-.12.278-.125.663-.125 1.054v6.903h-4.384s.057-11.892 0-13.025h4.384v2.226c.566-.884 1.925-2.146 4.258-2.146 2.894 0 5.369 1.851 5.369 5.85v7.095z" stroke="none" stroke-width="1" fill-rule="evenodd"></path></svg>
                                          </i>
                                      </a>
                                  </li>
                                  <li>
                                      <a href="https://twitter.com/intent/tweet?source=<%=source%>&text=<%=twitterText%>&via=LBS" target="_blank" aria-label="<%= translateLabel('Share to Twitter') %>">
                                          <i class="icon">
                                              <svg height="20" width="20" viewBox="0 0 43 43" version="1.1" xmlns="http://www.w3.org/2000/svg" focusable="false"><title><%= translateLabel('Twitter') %></title><path d="M21.503 0C9.627 0 0 9.627 0 21.503 0 33.373 9.627 43 21.503 43 33.373 43 43 33.373 43 21.503 43 9.627 33.373 0 21.503 0zm9.172 17.944c.005.193.011.385.011.578 0 5.895-4.378 12.696-12.38 12.696-2.457 0-4.745-.742-6.67-2.004a8.615 8.615 0 006.445-1.846c-1.91-.04-3.512-1.326-4.067-3.098.266.05.538.079.815.079.402 0 .833-.057 1.195-.159-1.993-.407-3.449-2.214-3.449-4.377v-.051a4.083 4.083 0 001.932.555 4.502 4.502 0 01-1.96-3.715c0-.816.204-1.586.578-2.243 2.146 2.702 5.346 4.474 8.964 4.661a4.315 4.315 0 01-.116-1.014c0-2.463 1.947-4.462 4.348-4.462 1.252 0 2.384.538 3.176 1.404a8.478 8.478 0 002.764-1.081 4.433 4.433 0 01-1.915 2.469 8.39 8.39 0 002.498-.703 8.873 8.873 0 01-2.17 2.311z" stroke="none" stroke-width="1" fill-rule="evenodd"></path></svg>
                                          </i>
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </footer>
                  </div>
              </article>
          </div><!-- .latest blog post tile -->
        </script>
      </div>
    </div>
  </div>
</div>
<div class="${styles["latest-blogs-actions"]}">
  <a href="${viewMoreLink}" target="_blank" class="${styles["external-link"]}"
    ><i class="icon-external" style="background-image: none"
      ><svg
        height="20"
        width="20"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        focusable="false"
      >
        <path d="M0 0h24v24H0z" fill="none"></path>
        <path
          d="M19 19H5V5h7V3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
        ></path></svg></i
    >View more</a
  >
</div>
<!-- Resources -->
`;

  return (
    <>
      <div className="wrapper">
        <div className="row">
          <div className="base12">
            <div className={styles["latest-blog-posts"]}>
              {secondaryTitle && (
                <p className={styles.label}>{secondaryTitle}</p>
              )}
              {primaryTitle && <h2>{primaryTitle}</h2>}

              <div
                className="Custom_injection"
                dangerouslySetInnerHTML={{ __html: coveoMarkup }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestBlogs;
