import { ImageLoaderProps } from "next/image";
import { NextContentfulImageLoaderProps } from "./NextContentfulImage.interfaces";

export function contentfulLoader(config: NextContentfulImageLoaderProps) {
  const { f, fm, fit, h, w } = config || {};

  return ({ src, width, quality }: ImageLoaderProps) => {
    const url = new URL(src, process.env.NEXT_PUBLIC_IMAGE_BASE);

    url.searchParams.set("fm", fm || "avif");
    url.searchParams.set("q", (quality || 75).toString());
    url.searchParams.set("w", width.toString());

    if (fit) {
      url.searchParams.set("fit", fit);
    }

    if (f) {
      url.searchParams.set("f", f);
    }

    if (h && w) {
      url.searchParams.set("h", h.toString());
      url.searchParams.set("w", w.toString());
    }

    return url.href;
  };
}

export function getImagePath(src: string) {
  if (src.startsWith("//")) {
    src = `https:${src}`;
  }
  return src;
}
