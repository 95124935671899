import React, { useContext } from "react";
import Link from "@components/Link/Link";
import styles from "./MegaNav.module.scss";
import { NextContentfulImage } from "@components/Image";
import Cta from "@components/Cta/Cta";
import { navigationGAClick } from "@utilities/navigationGAClick";
import { useRouter } from "next/router";
import { NavigationStateContext } from "../../context/navigationContext";

const PromoItemAside = ({ mainMenuLink }) => {
  const router = useRouter();
  const { handleReset } = useContext(NavigationStateContext);

  return (
    <aside>
      {mainMenuLink.promoItems &&
        mainMenuLink.promoItems.slice(0, 2).map((promoItem, index) => {
          const promoImageFile =
            promoItem?.fields?.image?.fields?.image?.fields?.file;
          return (
            <div
              key={`${
                promoItem?.fields?.link?.fields?.linkText || "#"
              }${index}`}
              className={styles.item}
            >
              <Link
                key={promoItem?.fields?.link?.fields?.linkText || "#"}
                href={promoItem?.fields?.link?.fields?.url}
              >
                <a
                  onClick={(e) => {
                    navigationGAClick(e);
                    handleReset();
                  }}
                  className={styles.imageWrapper}
                >
                  <NextContentfulImage
                    src={promoImageFile?.url}
                    alt={promoItem?.fields?.image?.fields?.altText || ""}
                    fill
                  />
                </a>
              </Link>
              <Cta
                icon="icon-arrow"
                linkText={promoItem?.fields?.link?.fields?.linkText}
                type="tertiary"
                url={promoItem?.fields?.link?.fields?.url}
                onClick={(e) => {
                  navigationGAClick(e);
                  router.push(promoItem?.fields?.link?.fields?.url);
                  handleReset();
                }}
              />
            </div>
          );
        })}
    </aside>
  );
};

export default PromoItemAside;
