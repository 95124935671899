import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { useState } from "react";
import { Fields } from "./AccordionContainer";
import AccordionItem from "./AccordionItem";
import AccordionItemProgramme from "./AccordionItemProgramme";
import styles from "./C11_VerticalAccordion.module.scss";
import clsx from "clsx";

interface AccordionProps extends Fields {
  isDropdown?: boolean;
  isProgramme?: boolean;
  isCompare?: boolean;
  programme?: ProgrammeDetails;
  accordionItems: any;
}

export interface ItemState {
  isOpen: boolean;
  lock: boolean;
  contentHeight: number | string;
}

export const Accordion = (props: AccordionProps) => {
  const {
    accordionItems,
    singleDisplay,
    defaultOpen,
    title,
    isDropdown,
    isProgramme,
    isCompare,
    programme,
  } = props;
  const [itemState, setItemState] = useState<ItemState[]>(
    accordionItems.map((_, i) => ({
      isOpen: defaultOpen && i === 0,
      lock: false,
      contentHeight: 0,
    }))
  );

  // Toggle Accordion
  const toggleAccordion = (e, index) => {
    if (e.currentTarget.classList.contains("is-external")) {
      return;
    }

    e.preventDefault();

    // If accordion is not locked
    if (!itemState[index].lock) {
      const content = e?.currentTarget?.nextElementSibling?.children[0];
      const contentOffsetHeight = content?.offsetHeight;

      // Update state
      const newState = [...itemState].map((item, i) => {
        // if selected item
        if (i === index) {
          return {
            // if singleDisplay on then open, otherwise toggle open state
            isOpen: singleDisplay ? true : !item.isOpen,
            contentHeight: `${contentOffsetHeight}px`,
            lock: true,
          };

          // if not the selected item
        } else {
          return {
            // if singleDisplay on then close, otherwise keep open state
            isOpen: singleDisplay ? false : item.isOpen,
            contentHeight: item.contentHeight,
            lock: true,
          };
        }
      });

      setItemState(newState);

      const links = content.querySelectorAll("a");

      [...links].forEach((a) => {
        a.setAttribute("tabindex", "-1");
      });

      setTimeout(() => {
        // Unlocks accordions
        const unlockedState = [...newState].map((item) => ({
          ...item,
          lock: false,
        }));

        setItemState(unlockedState);

        [...links].forEach((a) => {
          a.setAttribute("tabindex", "0");
        });
      }, 500);
    }
  };

  return (
    <ul className={clsx(styles["accordion"], isDropdown && styles["dropdown"])}>
      {accordionItems.map((item, i) => {
        return isProgramme ? (
          <AccordionItemProgramme
            isCompare={isCompare}
            accordionState={itemState[i]}
            item={i}
            toggleAccordion={toggleAccordion}
            programme={programme!}
            key={`${title}-${i}`}
          />
        ) : (
          <AccordionItem
            title={item.fields.title}
            content={item.fields.content}
            contentTinyMce={item.fields.contentTinyMce}
            childContent={item.fields.childContent}
            accordionState={itemState[i]}
            item={i}
            toggleAccordion={toggleAccordion}
            key={`${title}-${i}`}
          />
        );
      })}
    </ul>
  );
};

export default Accordion;
