import Grid from "@components/Grid/Grid";
import styles from "./PublicationDetail.module.scss";
import clsx from "clsx";
import { useSettings } from "@utilities/context/settings";
import Cta from "@components/Cta/Cta";
import Link from "@components/Link/Link";
import Head from "next/head";
import formatCoveoMetadata, {
  formatPublicationAuthors,
} from "@utilities/formatCoveoMetadata";

export interface PublicationDetailProps {
  publicationTitle: string;
  publicationJournalName?: string;
  publicationSubjectAreasList?: string;
  publicationPublishingDetails?: string;
  publicationPublishUrl?: string;
  publicationAuthors?: any[];
  publicationExternalAuthors?: string;
  publicationYear?: number;
  publicationKeywords?: string;
  publicationTopics?: string;
  publicationIndustry?: string;
  publicationAwardType?: string;
  publicationCaseSubject?: string;
  publicationContact?: string;
  publicationEmail?: string;
  publicationEventDate?: string;
  publicationLbsCaseNumber?: string;
  publicationLocation?: string;
  publicationOrganisationSize?: string;
  publicationProjectFunder?: string;
  publicationProjectInfo?: string;
  publicationProjectLength?: string;
  publicationProjectUrl?: string;
  publicationResearchers?: string;
  publicationSeriesNumber?: string;
  publicationSeries?: string;
  publicationProjectStartDate?: string;
  publicationProjectEndDate?: string;
  publicationPublisherWebsiteUrl?: string;
  publicationWebsiteUrl?: string;
  publicationWebsiteName?: string;
  publicationAbstract?: string;
  publicationPublisher?: string;
  publicationSupervisors?: string;
  publicationDownloadableFileUrl?: string;
  publicationType?: string;
  publicationDownloadUrl?: string;
  publicationNotes?: string;
  publicationResearchCenterNameList?: string;
  publicationAvailableOnEcch?: Boolean;
}

export const PublicationDetail = (props: {
  content: PublicationDetailProps;
}) => {
  const { content } = props;
  const {
    publicationTitle,
    publicationJournalName,
    publicationSubjectAreasList,
    publicationPublishingDetails,
    publicationPublishUrl,
    publicationAuthors,
    publicationExternalAuthors,
    publicationYear,
    publicationKeywords,
    publicationTopics,
    publicationIndustry,
    publicationAwardType,
    publicationCaseSubject,
    publicationContact,
    publicationEmail,
    publicationEventDate,
    publicationLbsCaseNumber,
    publicationLocation,
    publicationOrganisationSize,
    publicationProjectFunder,
    publicationProjectInfo,
    publicationProjectLength,
    publicationProjectUrl,
    publicationResearchers,
    publicationSeriesNumber,
    publicationSeries,
    publicationProjectStartDate,
    publicationProjectEndDate,
    publicationPublisherWebsiteUrl,
    publicationWebsiteUrl,
    publicationWebsiteName,
    publicationAbstract,
    publicationPublisher,
    publicationSupervisors,
    publicationDownloadableFileUrl,
    publicationType,
    publicationDownloadUrl,
    publicationResearchCenterNameList,
    publicationNotes,
    publicationAvailableOnEcch,
  } = content;
  /*
    TODO:
      - ViewBag.AuthorsNamesWithLinks integration
  */

  const { publicationSettings } = useSettings();

  const publicationNotesUrl = publicationNotes
    ?.replace("url=[", "")
    .replace(/]+$/, "");

  return (
    <>
      <Head>
        <meta
          name="publicationauthorslist"
          content={formatPublicationAuthors(publicationAuthors)}
        />
        <meta
          name="publicationpublishingdetails"
          content={formatCoveoMetadata(publicationPublishingDetails)}
        />
        <meta
          name="publicationsubjectareaslist"
          content={formatCoveoMetadata(publicationSubjectAreasList)}
        />
        <meta
          name="publicationyear"
          content={
            publicationYear !== undefined ? String(publicationYear) : "0"
          }
        />
        <meta
          name="publicationresearchcenternamelist"
          content={formatCoveoMetadata(publicationResearchCenterNameList)}
        />
      </Head>
      <div className="wrapper">
        <Grid row>
          <Grid column sm={12}>
            <div
              className={clsx(
                "component",
                "publication-individual-page",
                styles["publication-individual-page"]
              )}
            >
              <div className="wrapper">
                <Grid row>
                  <Grid column sm={12} md={8}>
                    <h1>{publicationTitle}</h1>
                  </Grid>
                </Grid>

                <div className="component rich-text">
                  {publicationJournalName && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Journal</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationJournalName}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationSubjectAreasList && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Subject</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>
                          {publicationSubjectAreasList
                            ?.replace(/\|/g, ", ")
                            .trim()}
                        </p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationPublishingDetails && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Publishing details</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        {publicationPublishUrl ? (
                          <p>
                            <Link href={publicationPublishUrl}>
                              <a target="_blank">
                                {publicationPublishingDetails}
                              </a>
                            </Link>
                          </p>
                        ) : (
                          <p>{publicationPublishingDetails}</p>
                        )}
                      </Grid>
                    </Grid>
                  )}

                  {publicationAuthors && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Authors / Editors</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationExternalAuthors}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationAuthors &&
                    publicationAuthors.find(
                      (e) => e.fields.publicationAuthorFullName
                    ) && (
                      <Grid row>
                        <Grid column sm={12} md={2}>
                          <p>
                            <strong>Biographies</strong>
                          </p>
                        </Grid>
                        <Grid column sm={12} md={6}>
                          <p>
                            {publicationAuthors
                              .map(({ fields }) => {
                                if (fields?.publicationAuthorFullName) {
                                  const { slug, facultyProfileData } =
                                    fields?.publicationAuthorFullName?.fields ||
                                    {};
                                  const { profileName } =
                                    facultyProfileData?.fields || {};
                                  return (
                                    profileName && (
                                      <Link href={slug}>
                                        <a>{profileName}</a>
                                      </Link>
                                    )
                                  );
                                }
                              }) //@ts-ignore
                              .reduce((prev, curr) => [prev, " ", curr])}
                          </p>
                        </Grid>
                      </Grid>
                    )}

                  {publicationPublisherWebsiteUrl && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Publisher</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p className="bold">
                          <Link href={publicationPublisherWebsiteUrl}>
                            <a target="_blank">{publicationPublisher}</a>
                          </Link>
                        </p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationYear && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Publication Year</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationYear}</p>
                      </Grid>
                    </Grid>
                  )}
                  {publicationWebsiteName && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Website</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>
                          {publicationWebsiteUrl ? (
                            <Link href={publicationWebsiteUrl}>
                              <a target="_blank">{publicationWebsiteName}</a>
                            </Link>
                          ) : (
                            publicationWebsiteName
                          )}
                        </p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationAbstract && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Abstract</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationAbstract}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationKeywords &&
                    publicationKeywords.trim() !== "" &&
                    publicationKeywords !== "-" && (
                      <Grid row>
                        <Grid column sm={12} md={2}>
                          <p>
                            <strong>Keywords</strong>
                          </p>
                        </Grid>
                        <Grid column sm={12} md={6}>
                          <p>{publicationKeywords}</p>
                        </Grid>
                      </Grid>
                    )}

                  {publicationTopics && publicationTopics !== "-" && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Topic List</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationTopics}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationIndustry && publicationIndustry !== "-" && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Industry</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationIndustry}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationAwardType && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Award Type</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationAwardType}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationCaseSubject && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Case Subject</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationCaseSubject}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationContact && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Publication Contact</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationContact}</p>
                      </Grid>
                    </Grid>
                  )}
                  {publicationEmail && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Publication Email</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationEmail}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationEventDate && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Publication Event Date</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationEventDate}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationLbsCaseNumber && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>LBS Case Number</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationLbsCaseNumber}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationLocation && publicationLocation !== "-" && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Location</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationLocation}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationNotes && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Publication Notes</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>
                          {publicationNotes.startsWith("url=") ? (
                            <Link href={publicationNotesUrl || ""}>
                              <a target="_blank">{publicationNotesUrl}</a>
                            </Link>
                          ) : (
                            publicationNotes
                          )}
                        </p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationOrganisationSize && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Publication Organisation Size</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationOrganisationSize}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationContact && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Publication Contact</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationContact}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationProjectFunder && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Project Funder</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationProjectFunder}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationProjectInfo && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Project Info</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationProjectInfo}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationProjectLength &&
                    Number(publicationProjectLength) > 0 && (
                      <Grid row>
                        <Grid column sm={12} md={2}>
                          <p>
                            <strong>Project Length</strong>
                          </p>
                        </Grid>
                        <Grid column sm={12} md={6}>
                          <p>{publicationProjectLength}</p>
                        </Grid>
                      </Grid>
                    )}

                  {publicationProjectUrl && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Project URL</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>
                          <Link href={publicationProjectUrl}>
                            <a target="_blank">{publicationProjectUrl}</a>
                          </Link>
                        </p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationResearchCenterNameList && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Publication Research Centre</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>
                          {publicationResearchCenterNameList
                            ?.replace(/\|/g, ", ")
                            .trim()}
                        </p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationResearchers && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Researchers</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationResearchers}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationSeriesNumber && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Series Number</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationSeriesNumber}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationSeries && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Series</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationSeries}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationSupervisors && publicationSupervisors !== "-" && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Supervisor</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationSupervisors}</p>
                      </Grid>
                    </Grid>
                  )}

                  {typeof publicationAvailableOnEcch !== "undefined" && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Available on ECCH</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>{publicationAvailableOnEcch ? "Yes" : "No"}</p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationProjectStartDate && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Start Date</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>
                          {new Date(
                            publicationProjectStartDate
                          ).toLocaleDateString("en-GB")}
                        </p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationProjectEndDate && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>End Date</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>
                          {new Date(
                            publicationProjectEndDate
                          ).toLocaleDateString("en-GB")}
                        </p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationDownloadableFileUrl && (
                    <Grid row>
                      <Grid column sm={12} md={2}>
                        <p>
                          <strong>Download</strong>
                        </p>
                      </Grid>
                      <Grid column sm={12} md={6}>
                        <p>
                          <Link
                            href={`${publicationSettings?.publicationsDownloadBaseUrl}${publicationDownloadableFileUrl}`}
                          >
                            <a target="_blank">Download document</a>
                          </Link>
                        </p>
                      </Grid>
                    </Grid>
                  )}

                  {publicationDownloadUrl && (
                    <Grid row>
                      <Grid column sm={12} md={2}></Grid>
                      <Grid column sm={12} md={6}>
                        <p>
                          <Cta
                            key={publicationDownloadUrl}
                            type="tertiary"
                            customClass={styles.tertiary}
                            url={publicationDownloadUrl}
                            icon="icon-external"
                            linkText="View document"
                            openInNewTab
                          />
                        </p>
                      </Grid>
                    </Grid>
                  )}
                </div>
              </div>
            </div>
            <br />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PublicationDetail;
