import styles from "./C207_PopularAuthors.module.scss";
import clsx from "clsx";
import { Author } from "@customTypes/Author";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import Cta from "@components/Cta/Cta";
import {
  NextContentfulImageProps,
  NextContentfulImage,
} from "@components/Image";
import Link from "@components/Link/Link";
import { useSettings } from "@utilities/context/settings";
import { useBSRContentPages } from "../../context/bsrContentPage";
import { SearchLandingType } from "@customTypes/SearchLandingType";

export interface PopularAuthorsProps {
  authorsHeading?: string;
  authorsList?: Author[];
}

export const PopularAuthors = (props: { content: PopularAuthorsProps }) => {
  const { content } = props;
  const { authorsHeading, authorsList } = content;

  const templateAuthorsHeading = useBSRContentPages().authorsHeading;
  const templateAuthorsList = useBSRContentPages().authorsList;
  const { siteSettings } = useSettings();

  const heading = authorsHeading || templateAuthorsHeading;
  const authors = authorsList || templateAuthorsList;

  const thinkAuthorsPage =
    siteSettings?.searchResultsPagesCollection?.items?.filter(
      ({ type }) => type === SearchLandingType.ThinkAuthors
    )[0];

  return (
    <section className={clsx("component", styles["popular-authors"])}>
      <div className="wrapper">
        {heading && <h2>{heading}</h2>}
        <div className={styles["authors-container"]}>
          {authors &&
            authors.map((author, i) => {
              const {
                articleAuthorName,
                articleAuthorProfileLink,
                articleAuthorDescription,
                articleAuthorImage,
                articleAuthorFreeHtmlSection,
                articleAuthorViewArticlesCtaText,
                articleAuthorFacultyProfile,
              } = author?.fields;

              const profileLink = articleAuthorProfileLink
                ? articleAuthorProfileLink?.fields?.url
                : articleAuthorFacultyProfile?.fields?.slug;

              const title =
                articleAuthorFacultyProfile?.fields?.facultyProfileData?.fields
                  ?.profilePositionTitle ||
                articleAuthorFacultyProfile?.fields.profilePositionTitle;

              const imageProps: NextContentfulImageProps = {
                src: articleAuthorImage
                  ? articleAuthorImage.fields.image.fields.file.url
                  : "",
                fill: true,
                alt: articleAuthorImage
                  ? articleAuthorImage.fields.image.fields.file.title
                  : "",
                sizes: "(max-width: 768px) 100vw, 20vw",
              };
              const imageAlt = articleAuthorName;
              return (
                <article className={styles.author} key={`popular-author-${i}`}>
                  <div className={styles["wrapper-image"]}>
                    {articleAuthorImage && profileLink ? (
                      <a href={profileLink}>
                        <NextContentfulImage alt={imageAlt} {...imageProps} />
                      </a>
                    ) : (
                      articleAuthorImage && (
                        <NextContentfulImage alt={imageAlt} {...imageProps} />
                      )
                    )}
                  </div>
                  <h3>
                    {profileLink ? (
                      <Link
                        key={`link-${articleAuthorName}`}
                        href={profileLink}
                      >
                        {articleAuthorName}
                      </Link>
                    ) : (
                      articleAuthorName
                    )}
                  </h3>
                  <p className={styles["description-copy"]}>
                    {title ? title : articleAuthorDescription}
                  </p>
                  <div className={clsx("rich-text", styles["text-with-icon"])}>
                    <RichTextDisplay
                      richText={articleAuthorFreeHtmlSection?.fields?.content}
                      tinyMceRichText={
                        articleAuthorFreeHtmlSection?.fields?.contentTinyMce
                      }
                    />
                  </div>
                  <p className={styles["author-footer"]}>
                    <Cta
                      type="tertiary"
                      customClass={clsx(styles.tertiary, styles.cta)}
                      url={`/${thinkAuthorsPage?.url?.slug}?${
                        thinkAuthorsPage?.queryParams
                      }=${author.fields.articleAuthorName.replaceAll(" ", "")}`}
                      icon="icon-arrow"
                      linkText={
                        articleAuthorViewArticlesCtaText || "View articles"
                      }
                    />
                  </p>
                </article>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default PopularAuthors;
