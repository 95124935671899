import { useRouter } from "next/router";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";

export interface ServerlessFunctionErrorProps {
  content: {
    name?: string;
    errorMessage?: string;
  };
}

const ServerlessFunctionError = (props: ServerlessFunctionErrorProps) => {
  const appInsights = useAppInsightsContext();
  const router = useRouter();
  const { content } = props;
  const { errorMessage, name } = content;

  useEffect(() => {
    logToAppInsight();
    resetUrl();
  }, [router]);

  const logToAppInsight = () => {
    let error = router.query?.error;
    let errorDescription = router.query?.error_description ?? "";
    if (error) {
      appInsights.trackEvent({
        name: name ?? "Netlify Function Error Message",
        properties: {
          error,
          errorDescription,
        },
      });
    }
  };

  const resetUrl = () => {
    if (typeof window !== "undefined") {
      let uri = window.location.toString();
      if (uri.indexOf("?") > 0) {
        let clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
      }
    }
  };

  return (
    <div className="wrapper">
      <h1>{errorMessage}</h1>
    </div>
  );
};

export default ServerlessFunctionError;
