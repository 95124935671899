import C375_EventDetail from "@components/C37.5_EventDetail/C37.5_EventDetail";
import C46_HeaderBanner from "@components/C46_HeaderBanner/C46_HeaderBanner";
import { GeneralLink } from "@customTypes/generalLink";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import { format, parseISO } from "date-fns";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

export interface Speaker {
  fields: {
    name: string;
    link?: GeneralLink;
  };
}

export interface Contact {
  fields: {
    email?: string;
    eventContactId: string;
    fullName?: string;
    phoneNumber?: string;
  };
}

export interface EventsDetailProps {
  slug: string;
  eventTitle: string;
  eventDetails: {
    content: [];
  };
  eventDetailsTinyMce?: TinyMceRichText;
  eventBackgroundImage?: ImageWrapperType;
  eventTopImage?: ImageWrapperType;
  eventUseCustomHeaderBanner?: boolean;
  eventClosedMessage?: string;
  eventShortDescription?: string;
  eventLongDescription?: string;
  eventDressCode?: string;
  eventOrganisedByList?: string[];
  eventWhoIsAimedAtList?: string[];
  eventDepartment?: string;
  eventProgrammeTypesList?: string[];
  eventDuration?: string;
  eventStarts?: string;
  eventEnds?: string;
  eventTimingList?: string;
  eventLocationGoogleMapsLink?: string;
  eventLocationName?: string;
  eventCity?: string;
  eventRegion?: string;
  eventHowToFindUs?: string;
  eventClosed?: boolean;
  eventCode?: string;
  eventStatus?: string;
  eventInvitationType?: string;
  eventPriceList?: string;
  eventSignupMethod?: string;
  eventSignupUrl?: string;
  eventType?: string;
  externalDataId?: string;
  eventMainSpeaker?: Speaker;
  eventSpeakers?: Speaker[];
  eventPrimaryContact?: Contact;
  eventContacts?: Contact[];
}

const EventsDetail = (props: { content: EventsDetailProps }) => {
  const { content } = props;

  const {
    eventTitle,
    eventLongDescription,
    eventStarts,
    eventEnds,
    eventLocationName,
    eventCity,
    eventPriceList,
    eventSignupUrl,
  } = content;
  const { route } = useRouter();
  const [url, setUrl] = useState("");

  useEffect(() => {
    const url = document.URL;
    setUrl(url);
  }, [route]);

  const schemaData = {
    "@@context": "http://schema.org",
    "@@type": "Event",
    name: eventTitle,
    url: url,
    startDate: eventStarts ? format(parseISO(eventStarts), "yyyy-MM-dd") : "",
    location: {
      "@@type": "Place",
      name: eventLocationName ?? "",
      address: {
        "@@type": "PostalAddress",
        addressLocality: eventCity ?? eventLocationName ?? "",
      },
    },
    description: eventLongDescription || "",
    endDate: eventEnds ? format(parseISO(eventEnds), "yyyy-MM-dd") : "",
    offers: {
      "@@type": "Offer",
      url: eventSignupUrl ?? "",
      price: eventPriceList
        ? eventPriceList
            .split("|")
            .map((str) => str.trim())
            .filter(Boolean)
            .join(", ")
        : "",
      priceCurrency: "GBP",
    },
  };

  function getAddress(s) {
    let address = s.replace(/\/\s*/g, "");
    return address.trim();
  }

  return (
    <>
      <Head>
        <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
        {content.eventTitle && (
          <meta name="eventtitle" content={content.eventTitle} />
        )}
        {content.eventStarts && (
          <meta name="eventstarttime" content={content.eventStarts} />
        )}
        {content.eventOrganisedByList && (
          <meta
            name="eventorganisedbylist"
            content={content.eventOrganisedByList
              .map((organizer) => {
                return organizer;
              })
              .join(";")}
          />
        )}
        {content.eventLocationName && (
          <meta
            name="eventlocationname"
            content={getAddress(content.eventLocationName)}
          />
        )}
        {content.eventRegion && (
          <meta name="eventregion" content={content.eventRegion} />
        )}
        {content.eventType && (
          <meta name="eventtype" content={content.eventType} />
        )}
        {content.eventProgrammeTypesList && (
          <meta
            name="eventprogrammetype"
            content={content.eventProgrammeTypesList
              .map((programme) => {
                return programme;
              })
              .join(";")}
          />
        )}
        {content.eventWhoIsAimedAtList && (
          <meta
            name="eventwhoisaimedat"
            content={content.eventWhoIsAimedAtList
              .map((item) => {
                return item;
              })
              .join(";")}
          />
        )}
        {content.eventEnds && (
          <meta name="eventendtime" content={content.eventEnds} />
        )}
        {content.eventDepartment && (
          <meta name="eventdepartment" content={content.eventDepartment} />
        )}
        {content.eventCity && (
          <meta name="eventcity" content={content.eventCity} />
        )}
        {content.eventStarts && (
          <meta
            name="eventsyear"
            content={format(parseISO(content.eventStarts), "yyyy")}
          />
        )}
        {content.eventStarts && (
          <meta
            name="eventmonth"
            content={format(parseISO(content.eventStarts), "MMMM")}
          />
        )}
      </Head>
      <C46_HeaderBanner
        content={{
          title: eventTitle,
          isLight: true,
        }}
      />
      <C375_EventDetail content={content} />
    </>
  );
};

export default EventsDetail;
