import NextContentfulImage from "@components/Image/NextContentfulImage";
import React, { memo, useCallback, useState } from "react";
import clsx from "clsx";
import dynamic from "next/dynamic";
import videoIcon from "../../public/image/vidicon.png";
import { HeaderVideoPlayerProps } from "./C46_HeaderVideoPlayer.interfaces";
import styles from "./C46_HeaderBanner.module.scss";

const VideoPlayer = dynamic(() => import("@components/VideoPlayer"), {
  ssr: false,
});

function C46_HeaderVideoPlayer({
  url,
  onVideoReady,
  onVideoPlay,
  onVideoEnded,
}: HeaderVideoPlayerProps) {
  const [isPlaying, setIsPlaying] = useState(true);

  const handleVideoPlay = useCallback(() => {
    setIsPlaying(true);
    setTimeout(onVideoPlay, 300);
  }, [onVideoPlay]);

  const handleVideoReady = useCallback(() => {
    setTimeout(onVideoReady, 300);
  }, [onVideoReady]);

  const handleVideoEnded = useCallback(() => {
    setIsPlaying(false);
    onVideoEnded();
  }, [onVideoEnded]);

  return (
    <>
      {isPlaying && (
        <VideoPlayer
          className={styles["video-player"]}
          url={url}
          playing
          muted
          onReady={handleVideoReady}
          onEnded={handleVideoEnded}
        />
      )}
      <div
        onClick={handleVideoPlay}
        className={clsx(
          styles["play-icon"],
          !isPlaying && styles["play-icon--show"]
        )}
        aria-label="play icon that starts video"
      >
        <NextContentfulImage
          src={videoIcon}
          alt="play icon"
          width={54}
          height={54}
        />
      </div>
    </>
  );
}

export default memo(C46_HeaderVideoPlayer);
