import Grid from "@components/Grid/Grid";
import Link from "@components/Link/Link";
import { GeneralLink } from "../../types";
import { GeneralLinkNornalized } from "@customTypes/generalLink";
import Cta from "../Cta/Cta";
import clsx from "clsx";
import { NextContentfulImage } from "@components/Image";
import styles from "./C02_Footer.module.scss";
import { useRouter } from "next/router";
import setGADataLayer, { dataLayerEventKey } from "@utilities/setGADataLayer";

export interface FooterProps {
  primaryLinksColumn1: GeneralLink[];
  primaryLinksColumn2: GeneralLink[];
  primaryLinksColumn3: GeneralLink[];
  secondaryLinks: GeneralLinkNornalized[];
  ctaHeading: string;
  ctaText: string;
  ctaLink: string;
  socialHeading: string;
  socialLinks: any;
  copyrightText: string;
  displayFooter: boolean;
}

export const Footer = ({ content }: { content: FooterProps }) => {
  const {
    primaryLinksColumn1,
    primaryLinksColumn2,
    primaryLinksColumn3,
    secondaryLinks,
    ctaHeading,
    ctaText,
    ctaLink,
    socialHeading,
    socialLinks,
    copyrightText,
  } = content;

  const router = useRouter();

  let pageURL = router?.asPath;
  pageURL = pageURL === "/" ? pageURL.replace("/", "") : pageURL;

  const setGAClick = (name: string, destination: string) => {
    setGADataLayer({
      data: {
        name: name,
        referring_page:
          typeof window !== "undefined" ? window.location.href : "",
        destination_page: destination,
      },
      _clear: true,
      event: dataLayerEventKey.FOOTER,
    });
  };

  const NavLink = ({ link }) => (
    <li>
      <Link href={link.fields ? link.fields.url : link.url}>
        <a
          onClick={() =>
            setGAClick(
              link.fields ? link.fields.linkText : link.linkText,
              link.fields ? link.fields.url : link.url
            )
          }
        >
          {link.fields ? link.fields.linkText : link.linkText}
        </a>
      </Link>
    </li>
  );

  return (
    <footer className={styles["site-footer"]}>
      <div className="wrapper">
        {content?.displayFooter && (
          <div className={styles.top}>
            <Grid row>
              <Grid column sm={12} md={3}>
                <ul>
                  {primaryLinksColumn1?.map((link, i) => (
                    <NavLink link={link} key={`${i}${link.fields?.linkText}`} />
                  ))}
                </ul>
              </Grid>
              <Grid column sm={12} md={3}>
                <ul>
                  {primaryLinksColumn2?.map((link, i) => (
                    <NavLink link={link} key={`${i}${link.fields?.linkText}`} />
                  ))}
                </ul>
              </Grid>
              <Grid column sm={12} md={3}>
                <ul>
                  {primaryLinksColumn3?.map((link, i) => (
                    <NavLink link={link} key={`${i}${link.fields?.linkText}`} />
                  ))}
                </ul>
              </Grid>
              <Grid column sm={12} md={3}>
                <div className={styles.ctas}>
                  <p>{ctaHeading}</p>
                  <Cta
                    type="secondary"
                    light={true}
                    url={pageURL?.split("?")[0].split("#")[0] + ctaLink}
                    linkText={ctaText}
                    preventDefault={false}
                    onClick={() =>
                      setGAClick(
                        ctaText,
                        pageURL?.split("?")[0].split("#")[0] + ctaLink
                      )
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        {content?.displayFooter && (
          <div className={styles.social}>
            <Grid row>
              <Grid column sm={12}>
                <p className={clsx(styles.large, "large")}>{socialHeading}</p>
              </Grid>
              <Grid column sm={12}>
                <ul>
                  {socialLinks.map(({ fields }, i) => {
                    return (
                      <li key={`${i}${fields.title}`}>
                        <a
                          href={fields.url}
                          aria-label={fields.title}
                          target="_blank"
                          rel="noreferrer"
                          onClick={() =>
                            setGADataLayer({
                              data: {
                                platform: fields.title,
                              },
                              _clear: true,
                              event: dataLayerEventKey.SOCIAL_CLICKOUT,
                            })
                          }
                        >
                          <div
                            className={clsx(styles.socialIcon, "image-hover")}
                          >
                            <NextContentfulImage
                              src={fields.icon.fields.file.url}
                              alt={fields.title}
                              width={40}
                              height={40}
                            />
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </Grid>
            </Grid>
          </div>
        )}
        <div className={styles.bottom}>
          <Grid row>
            <Grid column md={9} sm={12}>
              <ul>
                {secondaryLinks.map((link, i) => (
                  <NavLink link={link} key={`${i}${link?.linkText}`} />
                ))}
              </ul>
            </Grid>
            <Grid column md={3} sm={12}>
              <div className={styles.copyright}>
                <p>{copyrightText}</p>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
