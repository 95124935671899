import Accordion from "@components/C11_VerticalAccordion/Accordion";
import Cta from "@components/Cta/Cta";
import { Image as ImageProps } from "@customTypes/image";
import { Link as LinkType, Link } from "@customTypes/link";
import clsx from "clsx";
import { NextContentfulImage } from "@components/Image";
import styles from "./C25_NavigationManual.module.scss";

export interface Fields {
  title: string;
  titleUrl?: LinkType;
  description: string;
  learnMoreLink: Link;
  accordionTitle: string;
  expandLinks: boolean;
  flip: boolean;
  accordionOpen: boolean;
  programmeBlockLinks: Link[];
  image: ImageProps;
}

interface ProgrammeBlockProps extends Fields {
  fullWidth?: boolean;
}

export const ProgrammeBlock = (props: ProgrammeBlockProps) => {
  const {
    title,
    titleUrl,
    description,
    learnMoreLink,
    accordionTitle,
    accordionOpen,
    expandLinks,
    flip,
    programmeBlockLinks,
    image,
    fullWidth = false,
  } = props;

  const getLinks = () =>
    expandLinks
      ? programmeBlockLinks.slice(0, 15)
      : programmeBlockLinks.slice(0, 20);

  const programmeBlock = (
    <>
      {titleUrl?.fields?.url ? (
        <h4>
          <a href={titleUrl?.fields?.url}>{titleUrl?.fields?.linkText}</a>
        </h4>
      ) : (
        <h4>{title}</h4>
      )}
      <p>{description}</p>
      {learnMoreLink && (
        <Cta {...learnMoreLink.fields} type="tertiary" icon="icon-arrow" />
      )}
      {programmeBlockLinks?.length > 0 && (
        <Accordion
          accordionItems={[
            {
              fields: {
                title: (
                  <>
                    <span className="chevron"></span>
                    <span>{accordionTitle}</span>
                  </>
                ),
                content: getLinks().map((link) => ({
                  ...link.fields,
                })),
              },
            },
          ]}
          singleDisplay={false}
          defaultOpen={accordionOpen}
          title={accordionTitle}
        />
      )}
    </>
  );

  return (
    <div
      className={clsx(
        styles["programme-block"],
        programmeBlockLinks?.length > 0 && styles["accordion-block"],
        !image && styles["no-img"],
        flip && styles["flip"]
      )}
    >
      {image && (
        <div className={styles["image-wrapper"]}>
          <div className={styles["inner-wrap"]}>
            <NextContentfulImage
              src={image.fields.file.url}
              alt={image.fields.title}
              width={fullWidth ? 590 : 354}
              height={fullWidth ? 590 : 354}
              sizes={`(max-width: 1024px) 100vw, ${fullWidth ? "590px" : "354px"}`}
            />
          </div>
        </div>
      )}
      <div className={styles["programme-info"]}>
        {image && fullWidth ? (
          <div className={styles["inner-wrapper"]}>{programmeBlock}</div>
        ) : (
          programmeBlock
        )}
      </div>
    </div>
  );
};

export default ProgrammeBlock;
