import Image from "next/image";
import { memo } from "react";
import {
  contentfulLoader,
  getImagePath,
} from "./NextContentfulImage.functions";
import { NextContentfulImageProps } from "./NextContentfulImage.interfaces";

const NextContentfulImage = ({
  src,
  height,
  width,
  quality = 75,
  sizes = "100vw",
  loaderConfig = {},
  ...others
}: NextContentfulImageProps) => {
  if (typeof src === "string") {
    return (
      <Image
        src={getImagePath(src)}
        loader={contentfulLoader({ ...loaderConfig, w: width })}
        quality={quality}
        unoptimized={src.includes(".svg")}
        width={width}
        height={height}
        sizes={sizes}
        {...others}
      />
    );
  }

  return <Image src={src} {...others} />;
};

export default memo(NextContentfulImage);
