export const DefaultResultsTemplateStyles = `
.coveo-result {
  border-bottom: 1px solid #7f8eb0;
  padding: 2.4rem 0;
}

.lbs-result-list-wrapper .field {
  display: inline-flex;
  align-items: center;
}

.lbs-result-list-wrapper .field-label {
  font-weight: bold;
  margin-right: 0.25rem;
}

.coveo-link {
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 100% 0.1rem;
  padding-bottom: 0.4rem;
  display: inline;
}

.coveo-link:hover {
  text-decoration: none;
  background-image: linear-gradient(90deg, #001e62, #001e62);
  background-image: linear-gradient(90deg, #001e62, #001e62);
}

.lbs-result-list-wrapper .h3 {
  display: inherit;
  color: #001e62;
  font-weight: 400;
  line-height: 1.26667;
  font-family: var(--lbs-heading-font-family), serif;
  font-size: 2.8rem;
  margin-bottom: 0.5rem;
}

.lbs-result-list-wrapper .page-description {
  color: #001e62;
  line-height: 1.5;
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.lbs-result-list-wrapper .tag a {
  padding: 0.5rem 0.6rem 0.2rem;
  color: #0059b3;
  border: 0.1rem solid #d7d2cb;
  display: inline-block;
  font-size: 1rem;
  text-decoration: none;
  margin: 0 1rem 1rem 0;
  font-weight: 700;
}

.lbs-result-list-wrapper .tag a:hover {
  background: #001e62;
  border: 0.1rem solid #001e62;
  color: #fff;
}

`;
