import React, { useEffect, useRef, useState } from "react";
import styles from "./C72.2_ProgrammeDetailsBarEMbaMerge.module.scss";
import Cta from "@components/Cta/Cta";
import Grid from "@components/Grid/Grid";
import IconChevron from "../../public/image/svg/chevron.svg";
import { fetchApi } from "@utilities/fetchApi";
import { ProgrammeImportData } from "@customTypes/programmeImportData";
import { ProgrammeDetailsBarSettings } from "@customTypes/programmeDetailsBarSettings";
import clsx from "clsx";
import { GeneralLink } from "@customTypes/generalLink";
import { useProgramme } from "@components/__templates__/ProgrammeDetailsPage/ProgrammeDetailsProvider";
import { useSettings } from "@utilities/context/settings";
import { useProgrammeDetailsPage } from "../../context/programmeDetailsPage";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import DownloadTypes from "../../types/DownloadTypes";
import { useRouter } from "next/router";
import IconDownload from "../../public/image/svg/download.svg";
import { format, parseISO } from "date-fns";

interface ProgrammeDetailPage {
  fields: {
    programmeTypeCode: string;
    programmeTypeGroup: string;
  };
}

interface PDBarContentProps {
  ctaButton1: any;
  ctaLink1: GeneralLink;
  helpText1: string;
  showTwoIterations: boolean;
  programmeDetailPage?: ProgrammeDetailPage;
  programmeDetailPages: any;
}

interface PDBarContentFieldProps {
  fields: PDBarContentProps;
}

export interface ProgrammeDetailsBarDEProps {
  content: PDBarContentFieldProps | PDBarContentProps;
  isSticky?: boolean;
  programmeId: string;
}

const ProgrammeDetailsBarDE = (props: ProgrammeDetailsBarDEProps) => {
  const { content, isSticky, programmeId } = props;

  const structuredContent = (content as PDBarContentFieldProps).fields
    ? ((content as PDBarContentFieldProps).fields as PDBarContentProps)
    : (content as PDBarContentProps);

  const {
    programmeTypeCode: programmeTypeCodeFromProvider,
    programmeTypeGroup: programmeTypeGroupFromProvider,
  } = useProgramme();

  let typeCodes = structuredContent?.programmeDetailPages
    .map((obj) => obj["fields"].programmeTypeCode)
    .join(",")
    .trim();

  let programmeTypeCode: string =
    programmeTypeCodeFromProvider ??
    structuredContent?.programmeDetailPage?.fields?.programmeTypeCode;

  let programmeTypeGroup: string =
    programmeTypeGroupFromProvider ??
    structuredContent?.programmeDetailPage?.fields?.programmeTypeGroup;

  const { ctaButton1, ctaLink1, helpText1, showTwoIterations } =
    structuredContent;

  const getFormattedDateFromISO = (datetime) => {
    const date = new Date(datetime);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
    };
    return date.toLocaleDateString(undefined, options);
  };

  const detailsBarRef: any = useRef();
  const optionWrapperRef: any = useRef();
  const optionsRef: any = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const [showSessions, setShowSessions] = useState(false);
  const router = useRouter();

  const [programmeImportData, setProgrammeImportData] = useState<
    ProgrammeImportData[]
  >([]);

  const { siteSettings } = useSettings();
  const { programmeApplyOnlineParameterValue } = useProgrammeDetailsPage();

  const apply = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.Apply
  )[0];

  const getProgrammesNextIterations = (data) => {
    const result: ProgrammeImportData[] = Object.values(
      data.reduce((acc, obj) => {
        const key = obj.importDataProgrammeTypeCode; // Use the property to determine distinctiveness
        if (
          !acc[key] ||
          new Date(obj.programmeStartDate) <
            new Date(acc[key].programmeStartDate)
        ) {
          acc[key] = obj; // Keep the earliest date object for each key
        }
        return acc;
      }, {})
    );

    return result;
  };

  const [programmeDetailsBarSettings, setProgrammeDetailsBarSettings] =
    useState<ProgrammeDetailsBarSettings>();

  useEffect(() => {
    const getData = async () => {
      const programmeBarDEData = await fetchApi(
        "/api/getProgrammeDetailsBarData",
        {
          typeCodes: typeCodes,
        }
      );
      setProgrammeImportData(programmeBarDEData.programmeImportDataCollection);

      setProgrammeImportData(
        getProgrammesNextIterations(
          programmeBarDEData.programmeImportDataCollection
        )
      );

      setProgrammeDetailsBarSettings(
        programmeBarDEData.programmeDetailsBarSettings
      );
    };
    getData();
  }, [typeCodes]);

  useEffect(() => {
    if (isSticky) {
      setShowOptions(false);
    }

    if (showOptions) {
      let height = optionWrapperRef.current.offsetHeight;
      if (height > 400) {
        height = 400;
      }
      optionsRef.current.style.height = height + "px";
    } else {
      optionsRef.current.style.height = "0px";
    }
  }, [showOptions, isSticky]);

  useEffect(() => {
    if (isSticky) {
      setShowOptions(false);
      setShowSessions(false);
    }
  }, [isSticky]);

  const optionsVisibility = () => {
    if (isSticky) {
      if (showSessions) {
        return showSessions;
      }
      return false;
    }
    return true;
  };

  const downloadBrochureSlug =
    siteSettings?.programmeCtaPagesCollection?.items.find(
      (item) =>
        item.type.toLowerCase() ===
        DownloadTypes.DOWNLOAD_BROCHURE.toLowerCase()
    );

  const onSelectContainerClick = () => {
    if (programmeImportData.length <= 1) {
      return;
    }
    setShowOptions(!showOptions);
  };

  const onToggleSessionsClick = (e) => {
    e.preventDefault();
    setShowSessions(!showSessions);

    setTimeout(() => {
      setShowOptions(!showOptions);
    }, 100);
  };

  const onSelectContainerKeyPress = (e) => {
    if (programmeImportData.length <= 1) {
      return;
    }
    if ((e.keyCode = 13)) {
      setShowOptions(!showOptions);
    }
  };
  const [referPage, setReferPage] = useState("");
  useEffect(() => {
    setReferPage(router.asPath);
  }, [router]);
  const getCtaButton1Url = () => {
    if (
      ctaButton1.toLowerCase() === DownloadTypes.APPLY_NOW_CTA.toLowerCase()
    ) {
      return `${apply?.url.slug}?programmeTypeCode=${programmeTypeCode}&applyID=${programmeApplyOnlineParameterValue}`;
    }
    if (
      ctaButton1.toLowerCase() === DownloadTypes.DOWNLOAD_BROCHURE.toLowerCase()
    ) {
      return `${downloadBrochureSlug?.url.slug}?programmeTypeCode=${programmeId}&programmeTypeGroup=${programmeTypeGroup}&programmeType=DE&brochureType=Download%20a%20brochure&referPage=${referPage}`;
    }

    return ctaButton1.url;
  };

  const ctasJSX = () => (
    <>
      {ctaButton1 && (
        <>
          {ctaButton1.toLowerCase() ===
          DownloadTypes.USE_CTA_LINK_1.toLowerCase() ? (
            <Cta {...ctaLink1.fields} type="tertiary" icon="icon-arrow" light />
          ) : (
            <Cta
              linkText={
                ctaButton1.toLowerCase() ===
                DownloadTypes.APPLY_NOW_CTA.toLowerCase()
                  ? "Apply now"
                  : ctaButton1
              }
              type="secondary"
              // use CTAUrl1 for the url
              // CTAUrl1 is used as the url for the cta, this will be covered in a different ticket
              url={getCtaButton1Url()}
              light
            />
          )}
        </>
      )}
      {helpText1 && (
        <Cta linkText=" " type="tertiary" url="#" light helpText={helpText1} />
      )}
    </>
  );

  const showAccordionOpener = programmeImportData
    .slice(1)
    .some((item) => item.programmeInclusiveDates !== null);

  return (
    <div
      ref={detailsBarRef}
      className={clsx(
        styles.component,
        styles["programme-details-bar-sticky"],
        styles.two,
        showOptions && styles.show,
        programmeImportData.length === 1 && styles["single-option"],
        isSticky ? styles.stick : ""
      )}
    >
      <div className={styles["sticky-wrap"]}>
        {/* add is sticky here to the wrapperto ensure when sticky 
        only teh available more appears */}
        <div
          className={clsx([
            "wrapper",
            styles.wrapper,
            isSticky && styles.stick,
          ])}
        >
          <div
            onClick={onToggleSessionsClick}
            className={clsx(
              styles["toggle_available_sessions"],
              showSessions && styles.sessionsOpen
            )}
            id="toggle_available_sessions"
          >
            <a
              className={styles["toggle-link-for-available-sessions"]}
              href=""
              aria-expanded={showSessions ? "true" : "false"}
            >
              {programmeDetailsBarSettings?.stickyToggleTextEe}
            </a>

            {isSticky && (
              <div
                className={clsx(
                  styles.buttons,
                  styles["desktop-only"],
                  styles["sticky-download-brochure-button"]
                )}
              >
                {ctasJSX()}
              </div>
            )}
          </div>

          {optionsVisibility() && (
            <Grid row>
              <Grid column sm={12} md={9}>
                <div
                  className={clsx(
                    styles["select-container"],
                    styles["desktop-only"]
                  )}
                >
                  <div
                    onClick={onSelectContainerClick}
                    onKeyDown={onSelectContainerKeyPress}
                    className={styles.select}
                    tabIndex={0}
                    role="combobox"
                    aria-expanded={showOptions ? "true" : "false"}
                    aria-owns="details-select-box"
                    aria-haspopup="listbox"
                  >
                    <div className={styles.selection} tabIndex={-1}>
                      <div>
                        <span>Dates</span>
                        {programmeImportData &&
                          programmeImportData.map((programmeIteration) => (
                            <p>
                              {programmeIteration &&
                                (programmeIteration.programmeInclusiveDates ||
                                  getFormattedDateFromISO(
                                    programmeIteration.programmeStartDate
                                  ))}
                            </p>
                          ))}
                      </div>
                      <div>
                        <span>Duration</span>
                        {programmeImportData &&
                          programmeImportData.map((programmeIteration) => (
                            <p>{programmeIteration?.programmeDuration}</p>
                          ))}
                      </div>
                      <div>
                        <span>Location/Format</span>
                        {programmeImportData &&
                          programmeImportData.map((programmeIteration) => (
                            <p>{programmeIteration?.programmeLocation}</p>
                          ))}

                        <p></p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Mobile version */}
                {programmeImportData.map((programmeIteration) => (
                  <>
                    <div
                      className={clsx(
                        styles["select-container"],
                        styles["mobile-only"]
                      )}
                    >
                      <div
                        onClick={onSelectContainerClick}
                        onKeyDown={onSelectContainerKeyPress}
                        className={styles.select}
                        tabIndex={0}
                        role="combobox"
                        aria-expanded={showOptions ? "true" : "false"}
                        aria-owns="details-select-box"
                        aria-haspopup="listbox"
                      >
                        <div className={styles.selection} tabIndex={-1}>
                          <div>
                            <span>Dates</span>
                            <p>
                              {programmeIteration.programmeInclusiveDates ||
                                getFormattedDateFromISO(
                                  programmeIteration.programmeStartDate
                                )}
                            </p>
                          </div>
                          <div>
                            <span>Duration</span>
                            <p>{programmeIteration?.programmeDuration}</p>
                          </div>
                          <div>
                            <span>Location/Format</span>
                            <p>{programmeIteration?.programmeLocation}</p>
                            <p></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}

                {/* End of mObile version */}
              </Grid>
              {!isSticky && (
                <Grid column sm={12} md={3}>
                  <div className={clsx(styles.buttons, styles["desktop-only"])}>
                    {ctasJSX()}
                  </div>
                </Grid>
              )}
            </Grid>
          )}
        </div>
        <div className={styles.options} ref={optionsRef}>
          <div
            ref={optionWrapperRef}
            className={clsx(styles.wrapper, "wrapper")}
          >
            <Grid row>
              <Grid column sm={showTwoIterations ? 6 : 12}>
                <div
                  className={clsx(styles.container, "container")}
                  role="listbox"
                  id="details-select-box"
                ></div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={clsx(styles.buttons, styles["mobile-only"])}>
          <IconDownload className={styles["download-icon"]} />
          {ctasJSX()}
        </div>
      </div>
    </div>
  );
};

export default ProgrammeDetailsBarDE;
