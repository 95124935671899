import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import clsx from "clsx";
import { useFacultyDetails } from "../../context/facultyDetails";
import styles from "./C50.2_FacultyDetail.module.scss";

const FacultyDetail = () => {
  const {
    facultyProfileData,
    profileDetailsText,
    profileDetailsTextTinyMce,
    profilePositionTitle,
  } = useFacultyDetails();

  const title =
    facultyProfileData?.fields.profilePositionTitle || profilePositionTitle;

  return (
    <div className={clsx("component", styles["faculty-details"], "cf")}>
      <h1 className={styles.h2}>
        {facultyProfileData?.fields.profileFirstname}{" "}
        {facultyProfileData?.fields.profileSurname}
      </h1>
      <div className={styles.intro}>
        <p>{title}</p>
        <p className={styles.qualifications}>
          {facultyProfileData?.fields.profileQualification && (
            <>
              {facultyProfileData?.fields.profileQualification}
              <br />
            </>
          )}
          {facultyProfileData?.fields.profileProfessionalQualification && (
            <>
              {facultyProfileData.fields.profileProfessionalQualification}
              <br />
            </>
          )}
        </p>
      </div>
      <div className="cont rich-text limited-height">
        <RichTextDisplay
          richText={profileDetailsText}
          tinyMceRichText={profileDetailsTextTinyMce}
        />
      </div>
    </div>
  );
};

export default FacultyDetail;
