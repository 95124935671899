import clsx from "clsx";

import Grid from "@components/Grid/Grid";
import { GeneralLink } from "@customTypes/generalLink";
import C270_CtaContainerLink from "./C270_CtaContainerLink";
import C270_GatedCtaContainerLink from "./C270_GatedCtaContainerLink";

import styles from "./C270_CtaContainer.module.scss";

export interface CtaContainerProps {
  ctaContent: [
    {
      fields: {
        cta: GeneralLink;
        description: string;
      };
      sys: {
        contentType: {
          sys;
        };
      };
    },
  ];
  title: string;
  isBlue: boolean;
}

export const CtaContainer = (props: { content: CtaContainerProps }) => {
  const { content } = props;

  const { ctaContent, title, isBlue } = content;

  return (
    <div
      className={clsx(
        styles.component,
        styles["cta-container"],
        styles.flush,
        isBlue && styles.blue
      )}
    >
      <div className={styles["cta-section"]}>
        <div className="wrapper">
          <h3>{title}</h3>
          <Grid row>
            {ctaContent.map((ctaData, index) => (
              <Grid
                key={`${ctaData.fields?.cta?.fields?.linkText}${index}`}
                column
                md={12 / ctaContent.length}
                sm={12}
              >
                {ctaData?.sys?.contentType?.sys?.id === "gatedCtaContent" ? (
                  <C270_GatedCtaContainerLink
                    ctaContent={ctaData}
                    isBlue={isBlue}
                  />
                ) : (
                  <C270_CtaContainerLink ctaContent={ctaData} isBlue={isBlue} />
                )}
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default CtaContainer;
