import { useForm } from "react-hook-form";
import React, { useState, useEffect } from "react";
import { format, parseISO } from "date-fns";
import clsx from "clsx";

import Grid from "@components/Grid/Grid";
import FormField from "@components/Form/FormFields/FormFields";
import RichTextFullWidth from "@components/C16_RichTextFullWidth/C16_RichTextFullWidth";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import setGADataLayer from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";

export interface GiftAidForAdvancementFormProps {
  formTitle: string;
  emailLabelText: string;
  titleLabelText: string;
  firstNameLabelText: string;
  lastNameLabelText: string;
  fullHomeAddressLabelText: string;
  postCodeLabelText: string;
  dateLabelText: string;
  signatureLabelText: string;
  successText: any;
  errorText: any;
}

let firstInteract = 0;

export const GiftAidForAdvancementForm = (props: {
  content: GiftAidForAdvancementFormProps;
}) => {
  const { content } = props;
  const {
    formTitle,
    emailLabelText,
    titleLabelText,
    firstNameLabelText,
    lastNameLabelText,
    fullHomeAddressLabelText,
    postCodeLabelText,
    dateLabelText,
    signatureLabelText,
    successText,
    errorText,
  } = content;
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { register, handleSubmit, reset, control, formState, watch } = useForm({
    mode: "onBlur",
  });

  const errors = formState?.errors;

  const FormFields = [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
    },
    {
      propertyName: "title",
      validation: {
        isRequired: true,
      },
      options: [
        { value: "Mr", id: "mr" },
        { value: "Mrs", id: "mrs" },
        { value: "Ms", id: "ms" },
        { value: "Dr", id: "dr" },
        { value: "Professor", id: "professor" },
        { value: "Other", id: "other" },
      ],
      formType: "select",
      labelText: titleLabelText,
    },

    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabelText,
    },
    {
      propertyName: "fullHomeAddress",
      validation: {
        isRequired: true,
        maxLength: 30,
        fullHomeAddress: true,
      },
      labelText: fullHomeAddressLabelText,
    },
    {
      propertyName: "postCode",
      validation: {
        isRequired: true,
        maxLength: 10,
        postCode: true,
      },
      labelText: postCodeLabelText,
    },
    {
      propertyName: "date",
      validation: {
        isRequired: true,
      },
      labelText: dateLabelText,
      type: "date",
    },
    {
      propertyName: "signature",
      validation: {
        isRequired: true,
        maxLength: 30,
        signature: true,
      },
      labelText: signatureLabelText,
    },
  ];

  const sendGAData = () => {
    setGADataLayer({
      event: "formComplete",
      formName: "giftAidForAdvancementForm",
      businessUnit: getBusinessUnit(),
      programmeCode: "",
      eventType: "",
    });
  };

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "giftAidForAdvancementForm",
        });
        firstInteract++;
        return () => subscription.unsubscribe();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    setLoading(true);
    // call to the Netlify Function
    try {
      const response = await fetch(
        "/.netlify/functions/triggerGiftAidForAdvancementEmail",
        {
          method: "POST",
          body: JSON.stringify({
            subscriberName: data.subscriberName,
            subscriberEmail: data.subscriberEmail,
            ukTaxPayer: data.ukTaxPayer ? "Yes" : "No",
            emailAddress: data.emailAddress,
            title: data.title,
            firstName: data.firstName,
            lastName: data.lastName,
            fullHomeAddress: data.fullHomeAddress,
            postCode: data.postCode,
            date: format(parseISO(data.date), "MMMM d, yyyy"),
            signature: data.signature,
          }),
        }
      );

      setLoading(false);

      if (response.ok) {
        // Submit email
        setHasSubmitted(true);
        setSubmitSuccess(true);
        sendGAData();
      } else {
        setHasSubmitted(true);
        setSubmitSuccess(false);
        const error = await response.json();
        console.log(error);
      }
    } catch (e) {
      setHasSubmitted(true);
      setSubmitSuccess(false);
    }
  };

  return (
    <>
      {!hasSubmitted && (
        <div className="wrapper">
          <Grid row>
            <Grid column sm={12} md={6}>
              <section className="component form">
                <form
                  className="form gift-aid"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <h1 className="h2">{formTitle}</h1>
                  <p>
                    Boost your donation to London Business School by 25p for
                    every £1 you donate.
                  </p>
                  <div className="form-field form-row field-row form-row--checkbox">
                    <div className="checkbox">
                      <input
                        id="ukTaxPayer"
                        type="checkbox"
                        {...register("ukTaxPayer", {
                          required: {
                            value: true,
                            message: "You have to be a UK Tax Payer",
                          },
                        })}
                      />
                      <span className="tick"></span>
                      <label htmlFor="ukTaxPayer">
                        I am a UK taxpayer and I want to Gift Aid my donation to
                        London Business School and any donations that I make in
                        the future or have made in the past four years.
                      </label>
                    </div>
                  </div>
                  {errors && errors["ukTaxPayer"] && (
                    <div className="form-row error">
                      <div className="form-field base6">
                        <div
                          className={clsx(
                            "field",
                            "message-wrapper",
                            "inline-message",
                            errors["ukTaxPayer"]?.message && "field_error_msg"
                          )}
                          tabIndex={0}
                        >
                          <p
                            className={clsx(
                              "message",
                              errors["ukTaxPayer"]?.message && "active"
                            )}
                          >
                            {errors["ukTaxPayer"]?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  <p>
                    I am a UK taxpayer and understand that if I pay less Income
                    Tax and/or Capital Gains Tax than the amount of Gift Aid
                    claimed on all my donations in that tax year it is my
                    responsibility to pay any difference.
                  </p>
                  {FormFields.map((field: any, index) => (
                    <FormField
                      formType={field?.formType}
                      watch={watch}
                      validation={field?.validation}
                      type={field.type}
                      register={register}
                      key={`${field.labelText}${index}`}
                      placeholder={field.labelText}
                      options={field.options}
                      name={field?.propertyName}
                      errors={errors}
                      control={control}
                    />
                  ))}
                  <div className="btn-and-text-wrapper">
                    <SubmitButton
                      loading={loading}
                      id="submitButton"
                      text="Submit"
                    />
                  </div>
                </form>
              </section>
            </Grid>
          </Grid>
        </div>
      )}
      {hasSubmitted && (
        <RichTextFullWidth
          content={
            submitSuccess
              ? successText.fields.content
              : errorText.fields.content
          }
          addBorder={false}
        />
      )}
    </>
  );
};

export default GiftAidForAdvancementForm;
