import { useRouter } from "next/router";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";

export interface Auth0ErrorProps {
  name?: string;
  errorMessage?: string;
}

const Auth0Error = (props: { content: Auth0ErrorProps }) => {
  const appInsights = useAppInsightsContext();
  const router = useRouter();
  const { content } = props;
  const errorMessage = content.errorMessage;

  function handleAuth0Error() {
    logToAppInsight();
    resetUrl();
  }

  function logToAppInsight() {
    var error = router.query?.error;
    var errorDescription = router.query?.error_description;
    if (error) {
      appInsights.trackEvent({
        name: "Auth0 Error Message",
        properties: {
          error,
          errorDescription,
        },
      });
    }
  }

  function resetUrl() {
    if (typeof window !== "undefined") {
      var uri = window.location.toString();
      if (uri.indexOf("?") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
      }
    }
  }

  handleAuth0Error();

  return (
    <div className="wrapper">
      <h1>{errorMessage}</h1>
    </div>
  );
};

export default Auth0Error;
