import Grid from "@components/Grid/Grid";
import Cta from "@components/Cta/Cta";
import Link from "@components/Link/Link";
import {
  NextContentfulImage,
  NextContentfulLoaderProps,
} from "@components/Image";
import C09_VideoVimeoAndYoutube from "@components/C09_VideoVimeoAndYoutube/C09_VideoVimeoAndYoutube";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { Author } from "@customTypes/Author";
import { SearchLandingType } from "@customTypes/SearchLandingType";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { Video } from "@customTypes/Video";
import RenderComponents from "@utilities/renderComponents";
import clsx from "clsx";
import { format, parseISO } from "date-fns";
import Head from "next/head";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import styles from "./ResearchDetailsPage.module.scss";
import { ctaLink } from "@customTypes/generalLink";

export interface ResearchDetailsPageProps {
  researchTitle: string;
  longDescription?: any;
  longDescriptionPartTwo?: any;
  date: string;
  bodyContent?: any;
  thumbnailImage?: ImageWrapperType;
  articleImage?: ImageWrapperType;
  authorsList?: Author[];
  subjectArea?: string;
  //newsTopic?: string[];
  basePageTemplate: {
    fields: {
      pageDescription: string;
    };
  };
  video?: Video;
  cta?: {
    fields: ctaLink;
  };
}

const loaderConfig: NextContentfulLoaderProps = { fit: "fill", f: "faces" };

export const ResearchDetailsPage = (props: {
  content: ResearchDetailsPageProps;
}) => {
  const { content } = props;
  const {
    longDescription,
    longDescriptionPartTwo,
    researchTitle,
    date,
    bodyContent,
    thumbnailImage,
    authorsList,
    articleImage,
    basePageTemplate: {
      fields: { pageDescription },
    },
    subjectArea,
    video,
    cta,
  } = content;

  //const [url, setUrl] = useState("");
  //const { route } = useRouter();

  //const { siteSettings } = useSettings();

  /*const searchPage = siteSettings?.searchResultsPagesCollection?.items?.filter(
    ({ type }) => type === SearchLandingType.NewsTopics
  )[0];*/

  /*useEffect(() => {
    const url = document.URL;
    setUrl(url);
  }, [route]);*/

  return (
    <>
      <Head>
        <meta name="articledateyear" content={format(parseISO(date), "yyyy")} />
        <meta
          name="articledate"
          content={format(parseISO(date), "yyyy/MM/dd")}
        />
        <meta
          name="articledatetext"
          content={format(parseISO(date), "dd MMMM yyyy")}
        />
        <meta
          name="articledatemonth"
          content={format(parseISO(date), "MMMM")}
        />
        <meta name="researchTitle" content={researchTitle} />
        <meta name="subjectArea" content={subjectArea} />
        <meta name="pagedescription" content={pageDescription} />
        {!!thumbnailImage && (
          <>
            <meta
              key="articleimagealt"
              name="articleimagealt"
              content={thumbnailImage?.fields?.altText}
            />
            <meta
              key="articleimageurl"
              name="articleimageurl"
              content={thumbnailImage?.fields?.image?.fields.file?.url}
            />
            <meta
              key="twitter:image"
              name="twitter:image"
              content={`${thumbnailImage?.fields?.image?.fields.file?.url}?w=1200&h=1200`}
            />
            <meta
              key="og:image"
              property="og:image"
              content={`${thumbnailImage?.fields?.image?.fields.file?.url}?w=1200&h=600`}
            />
          </>
        )}
      </Head>
      <div className="wrapper">
        <Grid row>
          <Grid column sm={12}>
            <section
              className={clsx("component", styles["article-header"], "cf")}
            >
              <div className="wrapper">
                <Grid row>
                  <Grid column sm={12}>
                    <h1>{researchTitle}</h1>
                  </Grid>
                </Grid>
                <Grid row>
                  <Grid column sm={12}>
                    <div className={styles["article-details-wrapper"]}>
                      <Grid row>
                        <Grid column sm={12} md={8}>
                          <div className={styles["article-details"]}>
                            {authorsList && (
                              <div className={styles.author}>
                                {authorsList.map((author, index) => {
                                  const i = index + 1;
                                  const facultyPage =
                                    author.fields?.articleAuthorFacultyProfile
                                      ?.fields.slug;

                                  const contributorPage =
                                    author.fields
                                      ?.articleAuthorContributorProfile?.fields
                                      .slug;

                                  const authorshipLink =
                                    facultyPage || contributorPage
                                      ? `/${facultyPage || contributorPage}`
                                      : "#"; /*`/${thinkAuthorsPage?.url?.slug}?${
                                          thinkAuthorsPage?.queryParams
                                        }=${author.fields.articleAuthorName.replaceAll(
                                          " ",
                                          ""
                                        )}`*/
                                  return (
                                    <Fragment
                                      key={author.fields.articleAuthorName}
                                    >
                                      <Link
                                        key={`author-${author.fields.articleAuthorName}`}
                                        href={authorshipLink}
                                      >
                                        {author.fields.articleAuthorName}
                                      </Link>
                                      {i < authorsList.length - 1 && ", "}
                                      {i === authorsList.length - 1 && " and "}
                                    </Fragment>
                                  );
                                })}
                              </div>
                            )}
                            <div className={styles["date-read-time"]}>
                              <span className={styles.date}>
                                {format(parseISO(date), "dd MMMM yyyy")}
                              </span>
                            </div>
                          </div>
                        </Grid>
                        <Grid column sm={12} md={4}></Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </section>

            <section>
              <Grid row>
                <Grid column md={5}>
                  <div className={styles["hide-on-mobile"]}>
                    <RichTextDisplay
                      richText={longDescription}
                      tinyMceRichText=""
                    />
                  </div>
                </Grid>
                <Grid column md={7} sm={12}>
                  {video && <C09_VideoVimeoAndYoutube content={video.fields} />}
                  {articleImage && (
                    <div className={styles.image}>
                      <NextContentfulImage
                        src={articleImage.fields.image.fields.file.url}
                        alt={articleImage?.fields?.altText || ""}
                        width={640}
                        height={360}
                        loaderConfig={loaderConfig}
                      />
                    </div>
                  )}
                </Grid>
                <Grid column sm={12}>
                  <div className={styles["hide-on-desktop"]}>
                    <p></p>
                    <Cta
                      type="tertiary"
                      light={false}
                      {...cta.fields}
                      icon="icon-arrow"
                    />
                    <p></p>
                    <p></p>
                  </div>
                </Grid>
                <Grid column sm={12}>
                  <div className={styles["hide-on-desktop"]}>
                    <RichTextDisplay
                      richText={longDescription}
                      tinyMceRichText=""
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid row customClass={styles["parttwo"]}>
                <Grid column md={8} sm={12}>
                  <RichTextDisplay
                    richText={longDescriptionPartTwo}
                    tinyMceRichText=""
                  />
                </Grid>
                <Grid column md={4}>
                  <div className={styles["hide-on-mobile"]}>
                    <Cta
                      type="tertiary"
                      light={false}
                      {...cta.fields}
                      icon="icon-arrow"
                    />
                  </div>
                </Grid>
              </Grid>
            </section>
          </Grid>
        </Grid>
      </div>
      {RenderComponents(bodyContent)}
    </>
  );
};

export default ResearchDetailsPage;
