import C502_FacultyDetail from "@components/C50.2_FacultyDetail/C50.2_FacultyDetail";
import C54_ProfilePhotoLinkBox from "@components/C54_ProfilePhotoLinkBox/C54_ProfilePhotoLinkBox";
import Grid from "@components/Grid/Grid";
import RenderComponents from "@utilities/renderComponents";
import Head from "next/head";
import {
  FacultyDetailContextProps,
  FacultyDetailProvider,
} from "../../../context/facultyDetails";
import styles from "./FacultyProfileDetail.module.scss";

export interface FacultyProfileDetailProps extends FacultyDetailContextProps {
  bodyContent?: any;
  detailContent?: any;
  profileBoxContent?: any;
}

export const FacultyProfileDetail = (props: {
  content: FacultyProfileDetailProps;
}) => {
  const { content } = props;

  const { bodyContent, detailContent, profileBoxContent, ...details } = content;
  const { profilePositionTitle, profilePhoto, facultyProfileData } = details;

  const profileSurname = facultyProfileData?.fields?.profileSurname;
  const profileFirstName = facultyProfileData?.fields?.profileFirstname;
  const profileSubjectArea = facultyProfileData?.fields?.profileSubjectArea;
  const title =
    content?.facultyProfileData?.fields?.profilePositionTitle ||
    profilePositionTitle;
  const profileImage = profilePhoto?.fields?.file?.url;
  const url = `${process.env.NEXT_PUBLIC_URL}/${content?.slug}`;

  const schemaData = {
    "@context": "http://schema.org",
    "@type": "Person",
    name: `${profileFirstName} ${profileSurname}`,
    jobTitle: `${title}`,
    worksFor: {
      "@type": "Organization",
      name: "London Business School",
    },
    url: `${url}`,
    image: `${profileImage}`,
  };

  return (
    <FacultyDetailProvider details={details}>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
        <meta name="profilesubjectarea" content={profileSubjectArea || ""} />
        <meta
          name="profilephotosmallurl"
          content={profilePhoto?.fields?.file?.url || ""}
        />
        <meta
          name="profilephotosmallalt"
          content={
            profilePhoto?.fields?.description ||
            profilePhoto?.fields?.title ||
            ""
          }
        />
        <meta name="profilepositiontitle" content={title || ""} />
        <meta name="profilesurname" content={profileSurname || ""} />
      </Head>
      <div className="outer-wrap">
        <div className="wrapper">
          <Grid row justify="space-between">
            <Grid column sm={12} md={8}>
              <C502_FacultyDetail />
              {RenderComponents(detailContent)}
            </Grid>
            <Grid column sm={12} md={4} customClass="tp4">
              <C54_ProfilePhotoLinkBox
                content={{ ...details, ...details.facultyProfileData }}
              />
              {RenderComponents(profileBoxContent)}
            </Grid>
          </Grid>
        </div>
      </div>
      {RenderComponents(bodyContent)}
    </FacultyDetailProvider>
  );
};

export default FacultyProfileDetail;
