import { fetchApi } from "@utilities/fetchApi";
import { useEffect, useState } from "react";
import Grid from "@components/Grid/Grid";
import { EventTile } from "@components/C19_20_Tiles/Tiles";

export interface PromotionalEventsProps {
  displayOnlyFirstEvent: boolean;
  programmeTypes: string[];
}

const cleanTag = (tag) => {
  return tag.split(":").pop();
};

const PromotionalEvents = ({
  content,
}: {
  content: PromotionalEventsProps;
}) => {
  const { displayOnlyFirstEvent, programmeTypes } = content;
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    const payload: any = {
      limit: displayOnlyFirstEvent ? 1 : 2,
      tags: programmeTypes?.map(cleanTag).join(",") || "",
    };

    const getData = async () => {
      const eventItems = await fetchApi("/api/getEvents", payload);
      setItems(eventItems);
    };

    getData();
  }, []);

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12}>
          <Grid row>
            {items?.map((item, i) => {
              return (
                <Grid key={`${item.eventTitle}${i}`} column sm={12} md={6}>
                  <EventTile
                    model={item}
                    withImage={true}
                    reducedContent={false}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default PromotionalEvents;
