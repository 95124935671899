import React, { useContext, useEffect } from "react";
import clsx from "clsx";
import styles from "./C01_SiteHeader.module.scss";
import { NavigationStateContext } from "../../context/navigationContext";
import { GeneralLink } from "@customTypes/generalLink";
import { NavLinkList } from "@customTypes/megaNav";
import { useDevice } from "@utilities/react/get-device/get-device";
import { useRouter } from "next/router";
import { navigationGAClick } from "@utilities/navigationGAClick";

const MainNav = ({ megaNav }: { megaNav: NavLinkList[] }) => {
  const {
    showDesktop,
    showMobile,
    handleEnter,
    handleLeave,
    mainNavSelectedIndex,
    setTabSelected,
    handleReset,
  } = useContext(NavigationStateContext);

  const router = useRouter();
  const device = useDevice();
  const isMobile = device === "mobile";

  useEffect(() => {
    handleReset();
  }, [isMobile]);

  const handleNavigationDesktop = (index: string | number, url: string) => {
    if (showDesktop && mainNavSelectedIndex === index) {
      handleLeave();
    } else {
      handleEnter(index);
    }
    setTabSelected(true);
    router.push(url);
  };

  return (
    <nav className={clsx(showMobile && styles.open)}>
      <ul>
        {megaNav?.map((linkList, index) => {
          const ownLink = linkList?.fields?.ownLink?.fields;
          return ownLink ? (
            <li
              key={`${ownLink.linkText}-${linkList?.sys?.id}`}
              onMouseEnter={() => {
                handleEnter(index);
              }}
              onMouseLeave={() => handleLeave()}
            >
              <a
                href={ownLink?.url || "#"}
                onClick={(e) => {
                  e.preventDefault();
                  handleNavigationDesktop(index, ownLink.url);
                  navigationGAClick(e);
                }}
              >
                {ownLink.linkText}
              </a>
            </li>
          ) : null;
        })}
      </ul>
    </nav>
  );
};

export default MainNav;
