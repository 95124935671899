import { useState, useEffect } from "react";
import clsx from "clsx";
import { useForm } from "react-hook-form";

import Grid from "@components/Grid/Grid";
import FormField from "@components/Form/FormFields/FormFields";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import { getCountries, getCountriesIso } from "../../services/forms";

import styles from "./DeccPayment.module.scss";
import setGADataLayer from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";
import {
  getPaymentErrors,
  getCountryCode,
  hasCountryMatch,
} from "@utilities/payment";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import SelectAutocomplete from "@components/SelectAutocomplete/SelectAutocomplete";

export interface DeccFormDetailsProps {
  emailLabelText: string;
  firstNameLabelText: string;
  lastNameLabelText: string;
  invoiceNumberLabelText: string;
  currencyLabelText: string;
  studentIdcustomerCodeLabelText: string;
  addressLabelText: string;
  amountLabelText: number;
  companyNameLabelText: string;
  cityLabelText: string;
  postcodeLabelText: string;
  countyLabelText: string;
  mobileNumberLabelText: string;
  submitButtonLabelText: string;
  formDescription: string;
  pageHeaderTitle: string;
  amountLabelField: string;
  emailHintText: string;
  invoiceNumberHintText: string;
  studentIdcustomerCodeHintText: string;
  amountHintText: string;
}

export interface CountriesIsoProps {
  countryName: string;
  countryAlpha2Code?: string;
}

let firstInteract = 0;

export const DeccPayment = (props: { content: DeccFormDetailsProps }) => {
  const { content } = props;
  const {
    pageHeaderTitle,
    emailLabelText,
    firstNameLabelText,
    lastNameLabelText,
    invoiceNumberLabelText,
    currencyLabelText,
    studentIdcustomerCodeLabelText,
    addressLabelText,
    companyNameLabelText,
    cityLabelText,
    postcodeLabelText,
    countyLabelText,
    mobileNumberLabelText,
    submitButtonLabelText,
    formDescription,
    emailHintText,
    invoiceNumberHintText,
    studentIdcustomerCodeHintText,
    amountHintText,
    amountLabelText,
  } = content;

  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [countries, setCountries] = useState([]);
  const [countriesIso, setCountriesIso] = useState<CountriesIsoProps[]>([]);

  const {
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    trigger,
    clearErrors,
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    const getCountryIsos = async () => {
      const countries = await getCountriesIso();
      setCountriesIso(countries);
    };
    getCountryIsos();
  }, []);

  const sendGAEvent = (event) => {
    setGADataLayer({
      event,
      formName: "DECC Payment",
      programmeCode: "",
      businessUnit: getBusinessUnit(),
    });
  };

  const fetchCountryData = async (term: string, fieldName: string) => {
    try {
      if (!term) {
        setCountries([]);
        return;
      }
      const countriesResponse = await getCountries(term);
      setCountries(countriesResponse);
    } catch (e) {
      setErrors(["Fetching countries data is failed"]);
      setCountries([]);
    }
  };

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "DECC Payment",
        });
        firstInteract++;
        return () => subscription.unsubscribe();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const formFields: any = [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 255,
      },
      labelText: emailLabelText,
      hintText: emailHintText,
    },
    {
      propertyName: "PayReference",
      validation: {
        isRequired: true,
        maxLength: 30,
        alphaNumeric: true,
      },
      labelText: studentIdcustomerCodeLabelText,
      hintText: studentIdcustomerCodeHintText,
      additionalErrorMessage: true,
    },
    {
      propertyName: "invoiceNumber",
      validation: {
        isRequired: true,
        maxLength: 30,
        alphaNumeric: true,
      },

      labelText: invoiceNumberLabelText,
      hintText: invoiceNumberHintText,
    },
    {
      propertyName: "currencyCode",
      formType: "select",
      options: [{ value: "GBP" }, { value: "USD" }],
      validation: {
        isRequired: true,
      },
      labelText: currencyLabelText,
    },
    {
      propertyName: "payAmount",
      validation: {
        isRequired: true,
        range: { min: 10, max: 120000 },
        decimalValidator: true,
      },
      labelText: amountLabelText,
      hintText: amountHintText,
    },
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
        alphaNumeric: true,
      },
      labelText: firstNameLabelText,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabelText,
    },
    {
      propertyName: "companyName",
      validation: {
        maxLength: 30,
      },
      labelText: companyNameLabelText,
    },
    {
      propertyName: "addressLine1",
      validation: {
        isRequired: true,
        maxLength: 30,
      },
      labelText: addressLabelText,
    },
    {
      propertyName: "city",
      validation: {
        isRequired: true,
        maxLength: 30,
        alphaNumeric: true,
      },
      labelText: cityLabelText,
    },
    {
      propertyName: "country",
      fieldType: "selectAutocomplete",
      validation: {
        required: "Country of residence is required",
        maxLength: 30,
        validate: (value) =>
          !!hasCountryMatch(value, countries) || "Unknown country entered",
      },
      labelText: countyLabelText,
    },
    {
      propertyName: "postCode",
      validation: {
        isRequired: true,
        maxLength: 30,
      },
      labelText: postcodeLabelText,
    },
    {
      propertyName: "phoneNumber",
      validation: {
        isRequired: true,
        maxLength: 30,
        telephone: true,
      },
      labelText: mobileNumberLabelText,
    },
  ];

  const onSubmit = async (formData) => {
    setErrors([]);
    setLoading(true);

    const data = {
      ...formData,
      internalReference: "DECC",
      country: getCountryCode(formData.country, countriesIso),
    };

    localStorage.setItem("paymentFormData", JSON.stringify(data));

    const response = await fetch("/api/postPayment", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    const postPaymentResponseData = await response.json();

    setLoading(false);

    if (!response.ok) {
      setErrors(getPaymentErrors(postPaymentResponseData?.response));
      window.scrollTo(0, 0);
    }

    if (response.ok) {
      sendGAEvent("formComplete");
      window.location.href = postPaymentResponseData?.response?.checkOutUrl;
    }
  };

  return (
    <div className={clsx(styles["decc-form"], "wrapper")}>
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className={clsx(styles.component, "component", styles.form)}>
            <h1 className="h2">{pageHeaderTitle}</h1>
            <p>{formDescription}</p>
          </div>
          <section
            className={clsx(
              styles.component,
              styles["form"],
              "form",
              styles.cf
            )}
          >
            {errors &&
              errors.map((error, index) => (
                <FormErrorMessage key={index} text={error} />
              ))}
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <section className={styles["form-group-wrapper"]}>
                <div className={styles["form-row"]} />
                {formFields.map((field, index) => {
                  if (field.fieldType === "selectAutocomplete") {
                    return (
                      <SelectAutocomplete
                        key={`${field.labelText}${index}`}
                        placeholder={field.labelText}
                        name={field.propertyName}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        errors={formState.errors}
                        value={watch(field.propertyName)}
                        onBlur={() => {
                          trigger(field.propertyName);
                        }}
                        getOptions={(term) =>
                          fetchCountryData(term, field.propertyName)
                        }
                        options={countries}
                        validation={field.validation}
                      />
                    );
                  }

                  return (
                    <FormField
                      key={`${field.labelText}${index}`}
                      formType={field?.formType}
                      watch={watch}
                      validation={field?.validation}
                      type={field.type === "select" ? "select" : "text"}
                      isEmptyFirstOption={field.propertyName !== "currencyCode"}
                      register={register}
                      property={field.dataText}
                      hintText={field?.hintText}
                      placeholder={field.labelText}
                      options={field.options}
                      optionalText={field?.optionalText}
                      name={field?.propertyName}
                      errors={formState.errors}
                    />
                  );
                })}
              </section>
              <div className="btn-and-text-wrapper">
                <SubmitButton
                  loading={loading}
                  id="submitButton"
                  text={submitButtonLabelText || "Submit"}
                />
              </div>
            </form>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeccPayment;
