import Lightbox from "@components/Lightbox/Lightbox";
import SwiperNavigation from "@components/Swiper/SwiperNavigation";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { NextContentfulImage } from "@components/Image";
import { useState } from "react";
import { useModal } from "react-modal-hook";
import { Pagination } from "swiper/modules";
import { Swiper as SwiperProps } from "swiper/types";
import { Swiper as SwiperCarousel, SwiperSlide } from "swiper/react";
import styles from "./C24_NavigationalCarousel.module.scss";

export interface NavigationalCarouselProps {
  carouselMainTitle: string;
  carouselItem1: ImageWrapperType;
  carouselItem2: ImageWrapperType;
  carouselItem3: ImageWrapperType;
  carouselItem4: ImageWrapperType;
}

export const NavigationalCarousel = (props: {
  content: NavigationalCarouselProps;
}) => {
  const { content } = props;
  const {
    carouselMainTitle,
    carouselItem1,
    carouselItem2,
    carouselItem3,
    carouselItem4,
  } = content;

  const carouselItems = [
    carouselItem1,
    carouselItem2,
    carouselItem3,
    carouselItem4,
  ].filter(Boolean);

  const [swiper, setSwiper] = useState<SwiperProps>();
  const [lightboxSwiper, setLightboxSwiper] = useState<SwiperProps>();
  const [activeSlide, setActiveSlide] = useState(0);

  const [showModal, hideModal] = useModal(
    () => (
      <Lightbox onCloseFunction={hideModal} customInnerClass={styles.lightbox}>
        {carouselItems.length === 1 ? (
          <NextContentfulImage
            src={carouselItems[0].fields?.image?.fields?.file?.url}
            alt={carouselItems[0].fields?.image?.fields?.description}
            fill
            loading="eager"
            priority
          />
        ) : (
          <>
            <SwiperCarousel
              className={styles["lightbox-swiper"]}
              initialSlide={activeSlide}
              spaceBetween={50}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              onSwiper={(swiper) => {
                setLightboxSwiper(swiper);
              }}
              onSlideChange={(s) => {
                if (swiper) swiper.slideTo(s.activeIndex);
              }}
            >
              {carouselItems.map((item, index) => (
                <SwiperSlide
                  key={`${item.fields?.image?.fields?.description}${index}`}
                >
                  <NextContentfulImage
                    src={item.fields?.image?.fields?.file?.url}
                    alt={item.fields?.image?.fields?.description}
                    width={
                      item.fields?.image?.fields?.file?.details?.image?.width
                    }
                    height={
                      item.fields?.image?.fields?.file?.details?.image?.height
                    }
                    loading="eager"
                    priority
                  />
                </SwiperSlide>
              ))}
            </SwiperCarousel>

            <SwiperNavigation
              className={styles.prev}
              swiper={lightboxSwiper}
              direction="prev"
              perPage={1}
              variation="arrow"
            />
            <SwiperNavigation
              className={styles.next}
              swiper={lightboxSwiper}
              direction="next"
              perPage={1}
              variation="arrow"
            />
          </>
        )}
      </Lightbox>
    ),
    [activeSlide, lightboxSwiper, setLightboxSwiper]
  );

  return (
    <div className="wrapper">
      <div className={styles["nav-carousel"]}>
        {!carouselMainTitle && !carouselItems && (
          <h2>This component has no data</h2>
        )}
        {carouselMainTitle && <h2>{carouselMainTitle}</h2>}
        {carouselItems?.length && (
          <SwiperCarousel
            className={styles.swiper}
            spaceBetween={50}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            onSwiper={(swiper) => {
              setSwiper(swiper);
            }}
            onClick={(swiper) => {
              setActiveSlide(swiper.clickedIndex);
              showModal();
            }}
          >
            {carouselItems.map((item, index) => (
              <SwiperSlide
                key={`${item.fields?.image?.fields?.description}${index}`}
              >
                <NextContentfulImage
                  src={item.fields?.image?.fields?.file?.url}
                  alt={item.fields?.altText}
                  width={
                    item.fields?.image?.fields?.file?.details?.image?.width
                  }
                  height={
                    item.fields?.image?.fields?.file?.details?.image?.height
                  }
                />
              </SwiperSlide>
            ))}
          </SwiperCarousel>
        )}
      </div>
    </div>
  );
};

export default NavigationalCarousel;
