import { NextContentfulImage } from "@components/Image";
import { GeneralLink } from "@customTypes/generalLink";
import { ImageWrapperType } from "@customTypes/imageWrapper";

import styles from "./C253_StoriesIconLink.module.scss";

export interface StoriesIconLinkProps {
  iconImage: ImageWrapperType;
  iconLink: GeneralLink;
}

const StoriesIconLink = (props: StoriesIconLinkProps) => {
  const { iconLink, iconImage } = props;

  return (
    <ul className={styles.component}>
      <li>
        <a href={iconLink.fields.url}>
          <NextContentfulImage
            alt={iconImage.fields.altText}
            src={iconImage.fields.image.fields.file.url}
            width={60}
            height={60}
          />
          <p>{iconLink.fields.linkText}</p>
        </a>
      </li>
    </ul>
  );
};

export default StoriesIconLink;
