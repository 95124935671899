import C15_SocialSharing from "@components/C15_SocialSharing/C15_SocialSharing";
import Cta from "@components/Cta/Cta";
import { GeneralLink } from "@customTypes/generalLink";
import { SocialLink } from "@customTypes/SocialLink";
import clsx from "clsx";
import { useContext, useEffect } from "react";
import { useInView } from "react-hook-inview";
import { StoryContext } from "../C253_StoriesProvider";

import styles from "./C253_StoriesCallToAction.module.scss";

export interface StoriesCallToActionProps {
  h3Text: string;
  descriptionLarge: string;
  ctaLink1: GeneralLink;
  ctaLink2: GeneralLink;
  enableSaveToProfileLink: boolean;
  socialMediaIconsForPage: SocialLink[];
}

const StoriesCallToAction = (props: {
  content: StoriesCallToActionProps;
  index?: number;
}) => {
  const { content, index } = props;
  const {
    h3Text,
    descriptionLarge,
    ctaLink1,
    ctaLink2,
    enableSaveToProfileLink,
    socialMediaIconsForPage = [],
  } = content;

  const isFirst = index === 0;

  const [ref, isVisible] = useInView({
    threshold: 0.6,
  });

  const { updateNavigation } = useContext(StoryContext);

  useEffect(() => {
    if (isVisible) {
      updateNavigation(index, false);
    }
  }, [isVisible]);

  return (
    <section
      className={clsx(styles["stories-call-to-action"], "end")}
      ref={ref}
      data-bg="dark"
    >
      <div className="wrapper">
        <div className={clsx("content", styles.content)}>
          {h3Text && <h3 className={clsx("title", styles.title)}>{h3Text}</h3>}
          {isFirst && <div className="initial line"></div>}
          {descriptionLarge && (
            <p className={clsx("large", styles.large)}>{descriptionLarge}</p>
          )}

          <div className={clsx("bottom", styles.bottom)}>
            <div>
              {ctaLink1 && (
                <Cta {...ctaLink1.fields} type="primary" light={true} />
              )}
              {ctaLink2 && (
                <Cta
                  {...ctaLink2.fields}
                  type="tertiary"
                  light={true}
                  icon="icon-arrow"
                />
              )}
            </div>

            {socialMediaIconsForPage.length > 0 && (
              <div className={styles["social-sharing"]}>
                <C15_SocialSharing
                  socialLinks={socialMediaIconsForPage}
                  enableSaveToProfileLink={enableSaveToProfileLink}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default StoriesCallToAction;
