import styles from "./C64_MyProfilePreferences.module.scss";
import {
  getProfileContactPreferences,
  setProfileContactPreferences,
} from "../../services/forms";
import { useEffect, useState, useContext } from "react";
import Cta from "../Cta/Cta";
import Grid from "@components/Grid/Grid";
import ApiError from "@components/GenericApiError/Error";
import { UserContext } from "../../context/user";
import getCookie from "@utilities/getCookie";
import clsx from "clsx";

export interface PreferencesProps {
  canContactThankYouMessage: string;
  canContactPreferencesHeaderText: string;
  canContactPreferencesKeepInformedText: string;
  canPhoneText: string;
  canMailText: string;
  canSmsText: string;
  canBulkEmailText: string;
  signUpLbsrTitle: string;
  signUpToLbsrInformationText: string;
  signUpLbsrLabel: string;
  submitText: string;
}

export const MyPreferences = (props: { content: PreferencesProps }) => {
  const { content } = props;
  const {
    canContactThankYouMessage,
    canContactPreferencesHeaderText,
    canContactPreferencesKeepInformedText,
    canPhoneText,
    canMailText,
    canSmsText,
    canBulkEmailText,
    signUpLbsrTitle,
    signUpToLbsrInformationText,
    signUpLbsrLabel,
    submitText,
  } = content;

  const [preferences, setPreferences] = useState<any>({
    canContactByPhone: false,
    canContactByMail: false,
    canContactBySms: false,
    canContactByEmail: false,
    signUpToLBSThink: false,
  });
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    const getPreferences = async () => {
      if (user) {
        try {
          const contactPreferences = await getProfileContactPreferences(
            getCookie("access_token")
          );
          setPreferences(contactPreferences?.result);
        } catch (e) {
          console.log(e);
          setError(true);
        }
      }
    };

    getPreferences();
  }, [user]);

  const changePref = (name, value) => {
    setPreferences({
      ...preferences,
      [name]: value,
    });
  };

  const confirmPreferences = async () => {
    await setProfileContactPreferences(getCookie("access_token"), preferences);
    setHasSubmitted(true);
  };

  if (!user) return null;

  if (error) {
    return <ApiError />;
  }

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12}>
          <section
            className={clsx("component", "form", styles["profile-preferences"])}
          >
            <Grid row>
              <Grid column md={7} sm={12}>
                <h2 className={styles["section-title"]}>
                  {canContactPreferencesHeaderText}
                </h2>
                <p>{canContactPreferencesKeepInformedText}</p>
                <section>
                  <div className="form-row">
                    <div className="form-field">
                      <div className="checkbox">
                        <input
                          checked={preferences?.canContactByPhone}
                          id="CanContactByPhone"
                          name="CanContactByPhone"
                          type="checkbox"
                          value="true"
                          onChange={(e) =>
                            changePref("canContactByPhone", e.target.checked)
                          }
                        />
                        <span className="tick"></span>
                        <label htmlFor="CanContactByPhone">
                          {canPhoneText}
                        </label>
                      </div>
                    </div>

                    <div className="form-field">
                      <div className="checkbox">
                        <input
                          checked={preferences?.canContactByMail}
                          id="CanContactByMail"
                          name="CanContactByMail"
                          type="checkbox"
                          value="true"
                          onChange={(e) =>
                            changePref("canContactByMail", e.target.checked)
                          }
                        />
                        <span className="tick"></span>
                        <label htmlFor="CanContactByMail">{canMailText}</label>
                      </div>
                    </div>

                    <div className="form-field">
                      <div className="checkbox">
                        <input
                          checked={preferences?.canContactBySms}
                          id="CanContactBySms"
                          name="canContactBySms"
                          type="checkbox"
                          value="true"
                          onChange={(e) =>
                            changePref("canContactBySms", e.target.checked)
                          }
                        />
                        <span className="tick"></span>
                        <label htmlFor="CanContactBySms">{canSmsText}</label>
                      </div>
                    </div>

                    <div className="form-field">
                      <div className="checkbox">
                        <input
                          checked={preferences?.canContactByEmail}
                          id="CanContactByEmail"
                          name="canContactByEmail"
                          type="checkbox"
                          value="true"
                          onChange={(e) =>
                            changePref("canContactByEmail", e.target.checked)
                          }
                        />
                        <span className="tick"></span>
                        <label htmlFor="CanContactByEmail">
                          {canBulkEmailText}
                        </label>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <h2 className={styles["section-title"]}>{signUpLbsrTitle}</h2>
                  <p>{signUpToLbsrInformationText}</p>
                  <div className="form-row">
                    <div className="form-field">
                      <div className="checkbox">
                        <input
                          checked={preferences?.signUpToLBSThink}
                          id="IsSubscribedToThinkEmail"
                          name="IsSubscribedToThinkEmail"
                          type="checkbox"
                          value="true"
                          onChange={(e) =>
                            changePref("signUpToLBSThink", e.target.checked)
                          }
                        />
                        <span className="tick"></span>
                        <label htmlFor="IsSubscribedToThinkEmail">
                          {signUpLbsrLabel}
                        </label>
                      </div>
                    </div>
                  </div>
                </section>

                <div className={styles["btn-and-text-wrapper"]}>
                  <div className={styles["button-wrapper"]}>
                    <Cta
                      onClick={() => {
                        confirmPreferences();
                      }}
                      linkText={submitText}
                      type="primary"
                      url="#"
                    />
                  </div>
                </div>
                {hasSubmitted && (
                  <div className="form-row">
                    <h3 id="ThankYouLabel">{canContactThankYouMessage}</h3>
                  </div>
                )}
              </Grid>
            </Grid>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default MyPreferences;
