import { commaSeparated } from "@utilities/commaSeparated";
import { EventDetailProps } from "../C37.5_EventDetail";
import styles from "../C37.5_EventDetail.module.scss";

const EssentialInformationSection = (
  props: Pick<
    EventDetailProps,
    | "eventWhoIsAimedAtList"
    | "eventOrganisedByList"
    | "eventInvitationType"
    | "eventPriceList"
    | "eventDressCode"
  >
) => {
  const {
    eventWhoIsAimedAtList,
    eventOrganisedByList,
    eventInvitationType,
    eventPriceList,
    eventDressCode,
  } = props;

  return (
    <dl className={styles["essential-info"]}>
      {eventWhoIsAimedAtList && eventWhoIsAimedAtList.length > 0 && (
        <>
          <dt>Event for:</dt>
          <dd>
            {eventWhoIsAimedAtList
              .map((str) => str.trim())
              .filter(Boolean)
              .join(", ")}
          </dd>
        </>
      )}
      {eventOrganisedByList && eventOrganisedByList.length > 0 && (
        <>
          <dt>Organised by:</dt>
          <dd>
            {eventOrganisedByList
              .map((str) => str.trim())
              .filter(Boolean)
              .join(", ")}
          </dd>
        </>
      )}
      {eventInvitationType && (
        <>
          <dt>Event type:</dt>
          <dd>{eventInvitationType}</dd>
        </>
      )}
      {eventPriceList && eventPriceList.length > 0 && (
        <>
          <dt>Price list:</dt>
          <dd>{commaSeparated(eventPriceList)}</dd>
        </>
      )}
      {eventDressCode && (
        <>
          <dt>Dress code:</dt>
          <dd>{eventDressCode}</dd>
        </>
      )}
    </dl>
  );
};

export default EssentialInformationSection;
