import { useCallback } from "react";
import { useInView } from "react-hook-inview";

import theme from "../ResearchAndFacultyResultsTemplate/ResearchAndFacultyProfile.module.scss";
import clsx from "clsx";
import { NextContentfulImage } from "@components/Image";

import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Cta from "../../Cta/Cta";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import VideoVimeoAndYoutube from "../../C09_VideoVimeoAndYoutube/C09_VideoVimeoAndYoutube";

export interface VideoVimeoAndYoutubeProps {
  inSublayout?: boolean;
  videoCode: string;
  isVimeoCode?: boolean;
  overlayImage: ImageWrapperType;
  isAutoPlay?: boolean;
}

export interface ReserachFacultyProfileSoloProps {
  imageData: any;
  researchTitle: string;
  authors: any;
  videoData: VideoVimeoAndYoutubeProps;
  relatedResearchLink: any;
  longDescription: any;
  longDescriptionPartTwo: any;
}

const FacultyProfileSolo = (props: {
  content: ReserachFacultyProfileSoloProps;
  //theme?: any;
  lightbox?: boolean;
  onCloseFunction: Function;
}) => {
  const { content, lightbox, onCloseFunction } = props;

  const handleResearchLink = useCallback((element: HTMLAnchorElement) => {
    if (element) {
      element.addEventListener("click", () => onCloseFunction(), {
        once: true,
      });
    }
  }, []);

  const {
    imageData,
    researchTitle,
    authors,
    videoData,
    relatedResearchLink,
    longDescription,
    longDescriptionPartTwo,
  } = content;

  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });

  return (
    <div
      className={clsx(
        "component",
        theme["faculty-profile-carousel"],
        "faculty-profile-carousel",
        lightbox && theme["full-profile-container"]
      )}
    >
      <div className={theme.wrapper}>
        <div className={theme["profile-wrapper"]}>
          <div className={theme["pw-inner-wrapper"]}>
            <div
              className={clsx(
                theme["image-and-text-wrapper"],
                videoData && theme["video"]
              )}
            >
              <div
                className={clsx(
                  theme["image-wrapper"],
                  videoData && theme["video"]
                )}
                ref={ref}
              >
                <div className={theme.image}>
                  {videoData != null ? (
                    <VideoVimeoAndYoutube content={videoData} />
                  ) : (
                    imageData && (
                      <NextContentfulImage
                        src={imageData.image.url}
                        alt={imageData.altText || ""}
                        loaderConfig={{ f: "top", fit: "crop" }}
                        fill
                        style={{ objectFit: "cover" }}
                      />
                    )
                  )}
                </div>
              </div>
              <div
                className={clsx(
                  theme["text-wrapper"],
                  videoData && theme["video"]
                )}
              >
                {researchTitle && (
                  <h5 className={theme["profile-name"]}>{researchTitle}</h5>
                )}

                {authors && (
                  <>
                    <p
                      className={`${theme["programme-type-label"]} ${theme["sub-heading"]}`}
                    >
                      {authors.length > 1 ? "Authors" : "Author"}
                    </p>

                    <div>
                      {authors.map((author, index) => (
                        <p key={index} className={theme["programme-type"]}>
                          {author.articleAuthorName}
                        </p>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={theme["profile-details"]}>
              <div className={theme["button-wrapper"]}>
                {/* reserarch related area */}
                {relatedResearchLink && (
                  <Cta
                    type="tertiary"
                    url={relatedResearchLink.url}
                    linkText={relatedResearchLink.linkText}
                    icon="icon-arrow"
                    onClick={onCloseFunction}
                    id="researchlink"
                    ref={handleResearchLink}
                  />
                )}
                {/* End of reserarch related area */}
              </div>

              <PerfectScrollbar className={theme["pd-inner-wrapper"]}>
                <div className={theme["overflow-container"]}>
                  <span className={theme["dividing-line"]}></span>

                  {longDescription && (
                    <>
                      <div className="rich-text">
                        <RichTextDisplay
                          richText={longDescription}
                          tinyMceRichText={null}
                        />
                      </div>
                    </>
                  )}

                  {longDescriptionPartTwo && (
                    <>
                      <div className="rich-text">
                        <RichTextDisplay
                          richText={longDescriptionPartTwo}
                          tinyMceRichText={null}
                        />
                      </div>
                    </>
                  )}
                </div>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacultyProfileSolo;
