import Cta from "@components/Cta/Cta";
import { NextContentfulImage } from "@components/Image";
import { GeneralLink } from "@customTypes/generalLink";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import clsx from "clsx";
import Link from "@components/Link/Link";
import styles from "./C20_PromotionalArticles.module.scss";

export interface PromotionalArticleSingleProps {
  label: string;
  title: string;
  description: string;
  link: GeneralLink;
  articleImage: ImageWrapperType;
  mobileImage: ImageWrapperType;
}

export const PromotionalArticleSingle = (props: {
  content: PromotionalArticleSingleProps;
}) => {
  const { content } = props;
  const { label, title, description, link, articleImage, mobileImage } =
    content;
  const image = articleImage || mobileImage;

  return (
    <div className={clsx("component", styles["promotional-articles"])}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <article>
            {link && (
              <>
                {image && (
                  <Link passHref={true} href={link.fields.url || "#"}>
                    <a>
                      <NextContentfulImage
                        alt={image.fields.altText}
                        src={image.fields.image.fields.file.url}
                        height={
                          image.fields.image.fields.file.details.image.height
                        }
                        width={
                          image.fields.image.fields.file.details.image.width
                        }
                      />
                    </a>
                  </Link>
                )}
                <div className={styles.text}>
                  {label && (
                    <p
                      className={styles.label}
                      dangerouslySetInnerHTML={{ __html: label }}
                    />
                  )}
                  {title && link?.fields?.url && (
                    <h4>
                      <Link href={link?.fields?.url}>{title}</Link>
                    </h4>
                  )}
                  {description && <p>{description}</p>}
                  {link && (
                    <p>
                      <Cta
                        {...link.fields}
                        linkText={link.fields.linkText}
                        type="tertiary"
                        icon="icon-arrow"
                        openInNewTab={link.fields.openInNewTab}
                      />
                    </p>
                  )}
                </div>
              </>
            )}
          </article>
        </div>
      </div>
    </div>
  );
};

export default PromotionalArticleSingle;
