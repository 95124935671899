import React, { useEffect, useRef, useState } from "react";
import styles from "./C72.1_ProgrammeDetailsBarDE.module.scss";
import Cta from "@components/Cta/Cta";
import Grid from "@components/Grid/Grid";
import IconChevron from "../../public/image/svg/chevron.svg";
import { fetchApi } from "@utilities/fetchApi";
import { ProgrammeImportData } from "@customTypes/programmeImportData";
import { ProgrammeDetailsBarSettings } from "@customTypes/programmeDetailsBarSettings";
import clsx from "clsx";
import { GeneralLink } from "@customTypes/generalLink";
import { useProgramme } from "@components/__templates__/ProgrammeDetailsPage/ProgrammeDetailsProvider";
import { useSettings } from "@utilities/context/settings";
import { useProgrammeDetailsPage } from "../../context/programmeDetailsPage";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import DownloadTypes from "../../types/DownloadTypes";
import { useRouter } from "next/router";
import IconDownload from "../../public/image/svg/download.svg";
import { format, parseISO } from "date-fns";

interface ProgrammeDetailPage {
  fields: {
    programmeTypeCode: string;
    programmeTypeGroup: string;
  };
}

interface PDBarContentProps {
  ctaButton1: any;
  ctaLink1: GeneralLink;
  helpText1: string;
  showTwoIterations: boolean;
  programmeDetailPage?: ProgrammeDetailPage;
}

interface PDBarContentFieldProps {
  fields: PDBarContentProps;
}

export interface ProgrammeDetailsBarDEProps {
  content: PDBarContentFieldProps | PDBarContentProps;
  isSticky?: boolean;
  programmeId: string;
}

const ProgrammeDetailsBarDE = (props: ProgrammeDetailsBarDEProps) => {
  const { content, isSticky, programmeId } = props;
  const structuredContent = (content as PDBarContentFieldProps).fields
    ? ((content as PDBarContentFieldProps).fields as PDBarContentProps)
    : (content as PDBarContentProps);

  const {
    programmeTypeCode: programmeTypeCodeFromProvider,
    programmeTypeGroup: programmeTypeGroupFromProvider,
  } = useProgramme();

  let programmeTypeCode: string =
    programmeTypeCodeFromProvider ??
    structuredContent?.programmeDetailPage?.fields?.programmeTypeCode;

  let programmeTypeGroup: string =
    programmeTypeGroupFromProvider ??
    structuredContent?.programmeDetailPage?.fields?.programmeTypeGroup;

  const { ctaButton1, ctaLink1, helpText1, showTwoIterations } =
    structuredContent;

  const getFormattedDateFromISO = (datetime) => {
    const date = new Date(datetime);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
    };
    return date.toLocaleDateString(undefined, options);
  };

  const detailsBarRef: any = useRef();
  const optionWrapperRef: any = useRef();
  const optionsRef: any = useRef();
  const [showOptions, setShowOptions] = useState(false);
  const router = useRouter();

  const [programmeImportData, setProgrammeImportData] = useState<
    ProgrammeImportData[]
  >([]);

  const { siteSettings } = useSettings();
  const { programmeApplyOnlineParameterValue } = useProgrammeDetailsPage();

  const apply = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.Apply
  )[0];

  const [programmeDetailsBarSettings, setProgrammeDetailsBarSettings] =
    useState<ProgrammeDetailsBarSettings>();

  useEffect(() => {
    const getData = async () => {
      const programmeBarDEData = await fetchApi(
        "/api/getProgrammeDetailsBarData",
        {
          typeCodes: programmeTypeCode,
        }
      );
      setProgrammeImportData(programmeBarDEData.programmeImportDataCollection);
      setProgrammeDetailsBarSettings(
        programmeBarDEData.programmeDetailsBarSettings
      );
    };
    getData();
  }, [programmeTypeCode]);

  useEffect(() => {
    if (isSticky) {
      setShowOptions(false);
    }

    if (showOptions) {
      let height = optionWrapperRef.current.offsetHeight;
      if (height > 400) {
        height = 400;
      }
      optionsRef.current.style.height = height + "px";
    } else {
      optionsRef.current.style.height = "0px";
    }
  }, [showOptions, isSticky]);

  const downloadBrochureSlug =
    siteSettings?.programmeCtaPagesCollection?.items.find(
      (item) =>
        item.type.toLowerCase() ===
        DownloadTypes.DOWNLOAD_BROCHURE.toLowerCase()
    );

  const onSelectContainerClick = () => {
    if (programmeImportData.length <= 1) {
      return;
    }
    setShowOptions(!showOptions);
  };

  const onSelectContainerKeyPress = (e) => {
    if (programmeImportData.length <= 1) {
      return;
    }
    if ((e.keyCode = 13)) {
      setShowOptions(!showOptions);
    }
  };
  const [referPage, setReferPage] = useState("");
  useEffect(() => {
    setReferPage(router.asPath);
  }, [router]);
  const getCtaButton1Url = () => {
    if (
      ctaButton1.toLowerCase() === DownloadTypes.APPLY_NOW_CTA.toLowerCase()
    ) {
      return `${apply?.url.slug}?programmeTypeCode=${programmeTypeCode}&applyID=${programmeApplyOnlineParameterValue}`;
    }
    if (
      ctaButton1.toLowerCase() === DownloadTypes.DOWNLOAD_BROCHURE.toLowerCase()
    ) {
      return `${downloadBrochureSlug?.url.slug}?programmeTypeCode=${programmeId}&programmeTypeGroup=${programmeTypeGroup}&programmeType=DE&brochureType=Download%20a%20brochure&referPage=${referPage}`;
    }

    return ctaButton1.url;
  };

  const ctasJSX = () => (
    <>
      {ctaButton1 && (
        <>
          {ctaButton1.toLowerCase() ===
          DownloadTypes.USE_CTA_LINK_1.toLowerCase() ? (
            <Cta {...ctaLink1.fields} type="tertiary" icon="icon-arrow" light />
          ) : (
            <Cta
              linkText={
                ctaButton1.toLowerCase() ===
                DownloadTypes.APPLY_NOW_CTA.toLowerCase()
                  ? "Apply now"
                  : ctaButton1
              }
              type="secondary"
              // use CTAUrl1 for the url
              // CTAUrl1 is used as the url for the cta, this will be covered in a different ticket
              url={getCtaButton1Url()}
              light
            />
          )}
        </>
      )}
      {helpText1 && (
        <Cta linkText=" " type="tertiary" url="#" light helpText={helpText1} />
      )}
    </>
  );

  const showAccordionOpener = programmeImportData
    .slice(1)
    .some((item) => item.programmeInclusiveDates !== null);

  return (
    <div
      ref={detailsBarRef}
      className={clsx(
        styles.component,
        styles["programme-details-bar-sticky"],
        styles.two,
        showOptions && styles.show,
        programmeImportData.length === 1 && styles["single-option"],
        isSticky ? styles.stick : ""
      )}
    >
      <div className={styles["sticky-wrap"]}>
        <div className={clsx(["wrapper", styles.wrapper])}>
          <Grid row>
            <Grid column sm={12} md={9}>
              <div className={styles["select-container"]}>
                <div
                  onClick={onSelectContainerClick}
                  onKeyDown={onSelectContainerKeyPress}
                  className={styles.select}
                  tabIndex={0}
                  role="combobox"
                  aria-expanded={showOptions ? "true" : "false"}
                  aria-owns="details-select-box"
                  aria-haspopup="listbox"
                >
                  <div className={styles.selection} tabIndex={-1}>
                    <div>
                      <span>Dates</span>
                      <p>
                        {programmeImportData &&
                          programmeImportData[0] &&
                          (programmeImportData[0].programmeInclusiveDates ||
                            getFormattedDateFromISO(
                              programmeImportData[0].programmeStartDate
                            ))}
                      </p>
                    </div>
                    <div>
                      <span>Duration</span>
                      <p>{programmeImportData[0]?.programmeDuration}</p>
                    </div>
                    <div>
                      <span>Location</span>
                      <p>{programmeImportData[0]?.programmeLocation}</p>
                      <p></p>
                    </div>
                  </div>
                  {showAccordionOpener && (
                    <div className={styles["accordiondecc"]}>
                      <p
                        id="toggle_more_sessions"
                        className={styles["toggle-link"]}
                        aria-expanded="false"
                      >
                        {!showOptions ? (
                          <span className={styles.more}>View More Dates</span>
                        ) : (
                          <span className={styles.fever}>View Fewer Dates</span>
                        )}
                      </p>
                      <p className="toggle-link-plus-text">
                        {programmeDetailsBarSettings?.toggleLinkTextPlusDe}
                      </p>
                      <p className="toggle-link-minus-text">
                        {programmeDetailsBarSettings?.toggleLinkTextMinusDe}
                      </p>
                      <p className="sticky-toggle-text">
                        {programmeDetailsBarSettings?.stickyToggleTextDe}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid column sm={12} md={3}>
              <div className={clsx(styles.buttons, styles["desktop-only"])}>
                {ctasJSX()}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={styles.options} ref={optionsRef}>
          <div
            ref={optionWrapperRef}
            className={clsx(styles.wrapper, "wrapper")}
          >
            <Grid row>
              <Grid column sm={showTwoIterations ? 6 : 12}>
                <div
                  className={clsx(styles.container, "container")}
                  role="listbox"
                  id="details-select-box"
                >
                  {programmeImportData.slice(1).map((item, index) => (
                    <div
                      key={`${item.programmeStartDate}${index}`}
                      className={styles.option}
                      data-href={`?id=${1}`}
                      tabIndex={0}
                    >
                      <Grid row>
                        <Grid column sm={12} md={showTwoIterations ? 5 : 9}>
                          <div className={styles["option-inner"]}>
                            {programmeImportData && (
                              <div className={styles.data}>
                                <p>
                                  <span className={styles.title}>Dates</span>
                                  <span className={styles.value}>
                                    {item?.programmeInclusiveDates
                                      ? item?.programmeInclusiveDates
                                      : "-"}
                                  </span>
                                </p>
                                {(showTwoIterations && index === 0) ||
                                  (!showTwoIterations && (
                                    <>
                                      <p>
                                        <span className={styles.title}>
                                          Duration
                                        </span>
                                        <span className={styles.value}>
                                          {item?.programmeDuration
                                            ? item?.programmeDuration
                                            : "-"}
                                        </span>
                                      </p>
                                      <p>
                                        <span className={styles.title}>
                                          Location
                                        </span>
                                        <span className={styles.value}>
                                          {item?.programmeLocation
                                            ? item?.programmeLocation
                                            : "-"}
                                        </span>
                                      </p>
                                    </>
                                  ))}
                              </div>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={clsx(styles.buttons, styles["mobile-only"])}>
          <IconDownload className={styles["download-icon"]} />
          {ctasJSX()}
        </div>
      </div>
    </div>
  );
};

export default ProgrammeDetailsBarDE;
