import Grid from "@components/Grid/Grid";
import styles from "@components/InteractApplicationConfirmation/InteractApplicationConfirmation.module.scss";
import richTextify from "@utilities/richtext";
import clsx from "clsx";
import { useEffect, useState } from "react";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";

export interface InteractApplicationProps {
  successTransactionMessage: string;
  failureTransactionMessage: string;
  failureTransactionMessageTinyMce: TinyMceRichText;
  pendingTransactionMessageTinyMce: TinyMceRichText;
  successTransactionMessageTinyMce: TinyMceRichText;
}

export interface VerifoneResponseData {
  amountReceived?: number;
  amountRequired?: number;
  appId?: number;
  createdAt?: string;
  customerEmailAddress?: string;
  customerId?: string;
  programmeCode?: string;
  reference?: string;
  status?: string;
  transactionId?: string;
  updatedAt?: string;
}

export const InteractApplicationConfirmation = (props: {
  content: InteractApplicationProps;
}) => {
  const { content } = props;

  const {
    failureTransactionMessageTinyMce,
    pendingTransactionMessageTinyMce,
    successTransactionMessageTinyMce,
  } = content;

  const [responseMessage, setResponseMessage] = useState<TinyMceRichText>();

  const [isSuccessResponse, setSucessResponse] = useState<boolean>(false);

  const [responseData, setResponseData] = useState<VerifoneResponseData>();

  const isWindow = typeof window === "object";
  const urlParams = isWindow
    ? new URLSearchParams(window.location.search)
    : null;
  const transactionReference = urlParams?.get("reference") || "";

  const getFeesInPounds = (fee: number) => {
    return fee / 100;
  };

  useEffect(() => {
    const fetchTransactionStatus = async () => {
      try {
        const response = await fetch(
          "/.netlify/functions/userTransactionStatus",
          {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },

            body: transactionReference,
          }
        );
        const responseJsonData = await response.json();

        if (response.ok && responseJsonData.status === "CHECKOUT") {
          setResponseMessage(pendingTransactionMessageTinyMce);
        } else if (
          (response.ok && responseJsonData.status === "SETTLEMENT_REQUESTED") ||
          (response.ok && responseJsonData.status === "SETTLEMENT_COMPLETED")
        ) {
          setResponseMessage(successTransactionMessageTinyMce);
          setSucessResponse(true);
          setResponseData(responseJsonData);
        } else {
          setResponseMessage(failureTransactionMessageTinyMce);
          const error = await response.json();
        }
      } catch (e) {
        console.error(e);
      }
    };

    fetchTransactionStatus();
  }, []);

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12} md={12}>
          <div
            className={clsx(
              styles.component,
              "component",
              styles["interact-confirmation"]
            )}
          >
            {isSuccessResponse ? (
              <div>
                <div className="rich-text success-header">
                  <RichTextDisplay
                    richText={null}
                    tinyMceRichText={responseMessage}
                  />
                </div>

                <div className={styles.paymentDetails}>
                  <p>
                    <span>Merchant reference:</span>
                    {responseData?.reference}
                  </p>

                  <p>
                    <span>Transaction id:</span> {responseData?.transactionId}
                  </p>

                  <p>
                    <span>Email address:</span>{" "}
                    {responseData?.customerEmailAddress}
                  </p>

                  <p>
                    <span>Invoice currency:</span>GBP
                  </p>

                  <p>
                    <span>Amount:</span>
                    {getFeesInPounds(responseData?.amountRequired)}
                  </p>
                </div>
              </div>
            ) : (
              <div className="rich-text">
                <RichTextDisplay
                  richText={null}
                  tinyMceRichText={responseMessage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default InteractApplicationConfirmation;
