//prefixing all styles with lbs-result-list-wrapper so they don't bleed out
export const BsrTemplateStyles = (isMobile) => `
.lbs-result-list-wrapper .result-root {
  ${isMobile ? "display: block" : "display: flex"}; 
  height: 100%;
}

.lbs-result-list-wrapper .result-root>div {
  display: flex;
  flex-direction: column;
}

.lbs-result-list-wrapper .tag a {
  padding: 0.5rem 0.6rem 0.2rem;
  color: #001e62;
  border: 0.1rem solid #d7d2cb;
  display: inline-block;
  font-size: 1rem;
  text-decoration: none;
  margin: 0 1rem 1rem 0;
  font-weight: 700;
  font-family: var(--lbs-base-font-family), sans-serif;
}

.lbs-result-list-wrapper .social-share {
  align-self: flex-end;
  margin-left: auto;
}

.lbs-result-list-wrapper .tag a:hover {
  background: #001e62;
  border: 0.1rem solid #001e62;
  color: #fff;
}

.lbs-result-list-wrapper .text1 {
  padding: 2.6rem 2.6rem 3rem;
  background-color: #fff;
  color: #001e62;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 2;
  flex-grow: 2;
}

.lbs-result-list-wrapper .primary-tag {
  line-height: 1.57143;
  letter-spacing: .088rem;
  font-size: 1.4rem;
  font-weight: 400;
  font-family: var(--lbs-base-font-family), sans-serif;
  color: #6e6259;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.lbs-result-list-wrapper .h3 {
  line-height: 1.26667;
  font-size: 3rem;
  margin-bottom: 1.7rem;
}

.lbs-result-list-wrapper .sub-heading {
  color: #001e62;
  line-height: 1.5;
  font-size: 1.6rem;
  margin-bottom: 1rem;
  font-family: var(--lbs-base-font-family), sans-serif;
}

.lbs-result-list-wrapper .copy {
  line-height: 1.5;
  color: #001440;
  margin-bottom: 1.5rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  font-size: 1.6rem;
  font-family: var(--lbs-base-font-family), sans-serif;
}

.lbs-result-list-wrapper .article-panel .text .info-field {
  line-height: 1.71429;
  color: #6e6259;
  font-size: 1.4rem;
}

.lbs-result-list-wrapper .info-field {
  line-height: 1.71429;
  color: #6e6259;
  font-family: var(--lbs-base-font-family), sans-serif;
  font-size: 1.4rem;
  font-weight: 500;
}

.lbs-result-list-wrapper .authors {
  margin-left: 4px;
}

.lbs-result-list-wrapper .footer {
  height: 150px;
  border-top: 0.1rem solid #d8d8d8;
  display: flex;
  flex-wrap: wrap;
  padding-top: 3rem;
  margin-top: auto;
}

.lbs-result-list-wrapper svg {
  height: 40px;
}

.lbs-result-list-wrapper footer {
  border-top: 0.1rem solid #d8d8d8;
  display: flex;
  flex-wrap: wrap;
  padding-top: 3rem;
  margin-top: auto;
}

.lbs-result-list-wrapper .social-share {
  align-self: flex-end;
  margin-left: auto;
}

.lbs-result-list-wrapper .social-share a {
  transition: transform .3s;
}

.lbs-result-list-wrapper .social-share a:hover {
  transform: scale(1.1);
}

.lbs-result-list-wrapper .imageLink {
  display: flex;
}

.lbs-result-list-wrapper .imageLink img {
  height: auto;
}

.lbs-result-list-wrapper .wrapperImage {
  display: block;
  min-height: 1px;
  overflow: hidden;
  position: relative;
}



.lbs-result-list-wrapper .social-share {
  display: inline-block;
  height: 30px;
  position: relative;
  width: 30px;
}

.lbs-result-list-wrapper .social-share .visually-hidden {
  position: absolute;
  left: -1000rem;
  top: auto;
  width: 0.1rem;
  height: 0.1rem;
  overflow: hidden;
}

.lbs-result-list-wrapper .social-share i svg {
  fill: #001e62;
  ;
  height: 100%;
  transition: all 0.2s linear;
  width: 100%;
}

@media (hover: hover) {
  .lbs-result-list-wrapper .social-share a:hover i svg {
    fill: #5f739b;
  }
}

.social-share .share-links {
  align-items: center;
  background: #fff;
  bottom: calc(100% + 1.5rem);
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
  display: none;
  justify-content: space-between;
  padding: 1rem;
  position: absolute;
  right: -0.4rem;
  width: 13rem;
  z-index: 2;
}

.lbs-result-list-wrapper .social-share .share-links::before {
  background: #fff;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.15);
  bottom: -7px;
  display: block;
  content: " ";
  height: 18px;
  position: absolute;
  pointer-events: none;
  right: 10px;
  transform: rotate(45deg);
  width: 18px;
}

.lbs-result-list-wrapper .social-share .share-links::after {
  background: #fff;
  bottom: 0;
  content: " ";
  display: block;
  height: 25px;
  pointer-events: none;
  position: absolute;
  right: 0;
  width: 40px;
}

.lbs-result-list-wrapper .social-share .share-links li {
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  height: 3rem;
  width: 3rem;
  z-index: 3;
}

.lbs-result-list-wrapper .social-share .share-links li a {
  display: block;
  height: 3rem;
  width: 3rem;
}

.lbs-result-list-wrapper .social-share .share-links li i {
  display: block;
  height: 3rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 3rem;
}

.lbs-result-list-wrapper .social-share .share-links li i.icon-facebook {
  height: 3.5rem;
  left: -0.2rem;
  width: 3.5rem;
  top: -0.3rem;
}

.lbs-result-list-wrapper .social-share.show .share-toggle svg {
  fill: #5f739b;
  transform: rotate(90deg);
}

.lbs-result-list-wrapper .social-share.show .share-links {
  display: flex;
}

`;
