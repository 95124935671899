import Grid from "@components/Grid/Grid";
import IconLinkedIn from "../../public/assets2019/img/svg/linkedincircle.svg";
import styles from "./C261_Contact.module.scss";
import { NextContentfulImage } from "@components/Image";

interface ImageProps {
  altText: string;
  title: string;
  description: string;
  url: string;
}

export interface ContactLinkedInProps {
  linkedInLink: string;
  title: string;
  name: string;
  profilePicture: ImageProps;
}

export const LinkedInContactDetails = (props: ContactLinkedInProps) => {
  const { linkedInLink, name, title, profilePicture } = props;
  return (
    <Grid column sm={12} md={6} lg={12}>
      <div className={styles["contact-person"]}>
        {linkedInLink ? (
          <a href={linkedInLink} target="_blank" rel="noreferrer">
            <div className={styles["image-wrapper"]}>
              {profilePicture && (
                <NextContentfulImage
                  src={profilePicture.url}
                  alt={profilePicture.altText || ""}
                  width={98}
                  height={98}
                />
              )}
            </div>
            <div className={styles["contact-info"]}>
              <p className={styles.name}>
                <span>{name}</span>
                {linkedInLink.indexOf("linkedin") != -1 && (
                  <i
                    className={styles["icon-linkedincircle"]}
                    aria-label="Linkedin - Opens in a new window"
                    data-grunticon-embed="data-grunticon-embed"
                  >
                    <IconLinkedIn />
                  </i>
                )}
              </p>
              <span className={styles["position"]}>
                <span>{title}</span>
              </span>
            </div>
          </a>
        ) : (
          <>
            <div className={styles["image-wrapper"]}>
              {profilePicture && (
                <NextContentfulImage
                  src={profilePicture.url}
                  alt={profilePicture.altText || ""}
                  width={98}
                  height={98}
                />
              )}
            </div>
            <div className={styles["contact-info"]}>
              <p className={styles.name}>
                <span>{name}</span>
              </p>
              <span className={styles["position"]}>
                <span>{title}</span>
              </span>
            </div>
          </>
        )}
      </div>
    </Grid>
  );
};

export default LinkedInContactDetails;
