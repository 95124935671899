import styles from "./DeactivateAccount.module.scss";
import { useEffect, useState, useContext } from "react";
import Grid from "@components/Grid/Grid";
import ApiError from "@components/GenericApiError/Error";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { UserContext } from "../../context/user";
import React from "react";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";
import { deactivateAccount } from "../../services/forms";
import getCookie from "@utilities/getCookie";
import { useSettings } from "@utilities/context/settings";

export interface DeactivateAccountProps {
  title: string;
  deactivateAccountText: string;
  confirmText: string;
  cancelText: string;
  confirmPage: {
    fields: {
      slug: string;
    };
  };
}

export const DeactivateAccount = (props: {
  content: DeactivateAccountProps;
}) => {
  const { content } = props;
  const { title, deactivateAccountText, confirmText, cancelText, confirmPage } =
    content;
  const { user } = useContext(UserContext);
  const [userEmail, setUserEmail] = useState<any>(null);
  const [error, setError] = useState<boolean>(false);
  const { siteSettings } = useSettings();
  const router = useRouter();
  const { handleSubmit } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    const getEmail = async () => {
      if (user) {
        try {
          setUserEmail(user["userData"]["email"]);
        } catch (e) {
          console.log(e);
          setError(true);
        }
      }
    };
    getEmail();
  }, [user]);

  const onSubmit = async () => {
    try {
      let response = await deactivateAccount(getCookie("access_token"));
      if ((response.status = 200)) {
        window.location.href = `${siteSettings?.LogOutFuncLink}?returnTo=${confirmPage?.fields?.slug}`;
      } else {
        console.log(response);
        //This need to updated once we decide a standard way to handel error.
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  if (!user) return null;

  if (error) {
    return <ApiError />;
  }

  return (
    <div className="wrapper">
      <Grid row></Grid>
      <Grid column md={12} sm={12}>
        <div className={styles["deactivate-account"]}>
          <div className={styles.coont}>
            <h1>{title}</h1>
            <RichTextDisplay
              richText={deactivateAccountText}
              tinyMceRichText={null}
            />
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="row"></div>
              <div className="button-wrapper">
                <input
                  type="submit"
                  id="ConfirmButton"
                  value={confirmText}
                  className="cta primary"
                />
                <button
                  type="button"
                  className="cta tertiary"
                  onClick={() => router.back()}
                >
                  <span className="icon-cross">
                    <svg
                      width="20"
                      height="20"
                      viewBox="5 5 14 14"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      focusable="false"
                    >
                      <path
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                        stroke="none"
                        stroke-width="1"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  </span>
                  <span>{cancelText}</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default DeactivateAccount;
