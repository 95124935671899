import clsx from "clsx";
import { ctaLink } from "@customTypes/generalLink";
import styles from "./C286_ExplorePublications.module.scss";
import Cta from "@components/Cta/Cta";
import FormField from "@components/Form/FormFields/FormFields";
import { useForm } from "react-hook-form";
import SearchSvg from "../../public/image/svg/search.svg";
import { useRouter } from "next/router";
import { useState } from "react";
import Grid from "@components/Grid/Grid";

export interface ExplorePublicationsProps {
  title: string;
  longTextDescription: string;
  linkDestination: ctaLink;
  subjectAreaList: string[];
  searchTextLabel?: string;
}

export const ExplorePublications = ({
  title,
  longTextDescription,
  linkDestination,
  subjectAreaList,
  searchTextLabel,
}: ExplorePublicationsProps) => {
  const { register, watch, setValue, handleSubmit } = useForm({
    mode: "onBlur",
  });
  const [searchText, setsearchText] = useState(
    searchTextLabel.replace("{subject}", "")
  );

  const router = useRouter();

  const redirectToSearch = (link, keyword, subject) => {
    if (subject === "View by subject area") {
      window.open(`${link}#q=${keyword}`, "_blank");
    } else {
      window.open(
        `${link}#q=${keyword}&f-publicationsubjectareaslist=${subject}`,
        "_blank"
      );
    }
  };

  const onSubmit = async (data) => {
    redirectToSearch(
      linkDestination?.url,
      data?.q,
      data?.publicationsubjectareaslist
    );
  };

  const handleDropdownChange = (e) => {
    const selected = e.target.value;
    if (selected === "View by subject area") {
      setsearchText(searchTextLabel.replace("{subject}", ""));
    } else {
      setsearchText(searchTextLabel.replace("{subject}", selected));
    }
  };

  const options = subjectAreaList.map((subject) => {
    return {
      value: subject,
      id: subject,
    };
  });

  return (
    <div className="outer-wrap blue">
      <div className="wrapper">
        <Grid row>
          <form className={styles.block} onSubmit={handleSubmit(onSubmit)}>
            <Grid column sm={12} md={6}>
              <div className={styles.textWrapper}>
                <h2>{title}</h2>
                <p>{longTextDescription}</p>
                <p>
                  <Cta
                    {...linkDestination}
                    icon="icon-arrow"
                    light
                    type="tertiary"
                    customClass={styles.link}
                  />
                </p>
              </div>
            </Grid>
            <Grid column sm={12} md={6}>
              <fieldset className={styles.fieldset}>
                <label htmlFor="subjectSearch" className={styles.selectAlt}>
                  <FormField
                    watch={watch}
                    formType="select"
                    register={register}
                    placeholder=""
                    options={options}
                    name="publicationsubjectareaslist"
                    isEmptyFirstOption={false}
                    validation={{ isRequired: true }}
                    setSelectValue={setValue}
                    onChange={(e) => handleDropdownChange(e)}
                  />
                </label>
                <div className={clsx("form", "form-row", styles.searchbox)}>
                  <div className="field">
                    <FormField
                      register={register}
                      placeholder={searchText}
                      watch={watch}
                      type="text"
                      name="q"
                      validation={{ isRequired: false }}
                    />
                    <button
                      onClick={() => {}}
                      type="submit"
                      className={styles.searchButton}
                      aria-label="search"
                      data-grunticon-embed
                    >
                      <SearchSvg />
                    </button>
                  </div>
                </div>
              </fieldset>
            </Grid>
          </form>
        </Grid>
      </div>
    </div>
  );
};

export default ExplorePublications;
