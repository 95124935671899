import Cta from "@components/Cta/Cta";
import { NextContentfulImage } from "@components/Image";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { Link as LinkType } from "@customTypes/link";
import clsx from "clsx";
import Link from "@components/Link/Link";
import styles from "./C20_PromotionalArticles.module.scss";

export interface PromotionalManuallyPopulatedProps {
  title: string;
  link: LinkType;
  image: ImageWrapperType;
  manualText: string;
  readMoreText: string;
  backgroundColor?: "blue" | "white";
}

export const PromotionalManuallyPopulated = (props: {
  content: PromotionalManuallyPopulatedProps;
}) => {
  const { content } = props;
  const { title, link, image, manualText, readMoreText, backgroundColor } =
    content;

  return (
    <div
      className={clsx(
        "component",
        styles["promotional-articles"],
        backgroundColor && styles[backgroundColor]
      )}
    >
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <article>
            {image && (
              <Link passHref={true} href={link.fields.url || "#"}>
                <a>
                  <NextContentfulImage
                    alt={image.fields.altText}
                    src={image.fields.image.fields.file.url}
                    height={image.fields.image.fields.file.details.image.height}
                    width={image.fields.image.fields.file.details.image.width}
                  />
                </a>
              </Link>
            )}
            <div className={styles.text}>
              {title && link?.fields?.url && (
                <h4>
                  <Link href={link?.fields?.url}>{title}</Link>
                </h4>
              )}
              {manualText && (
                <p dangerouslySetInnerHTML={{ __html: manualText }} />
              )}
              {link && (
                <p>
                  <Cta
                    {...link.fields}
                    linkText={readMoreText}
                    type="tertiary"
                    icon="icon-arrow"
                    light={backgroundColor === "blue"}
                    openInNewTab={link.fields.openInNewTab}
                  />
                </p>
              )}
            </div>
          </article>
        </div>
      </div>
    </div>
  );
};

export default PromotionalManuallyPopulated;
