export const ResearchAndFacultyResultsTemplateStyles = (isMobile: boolean) => `

  .research-faculty-tile {
    padding: 1.5rem;
    font-family: var(--lbs-base-font-family), sans-serif;
    color: #001e62;
    display: ${isMobile ? "block" : "flex"};
    flex-direction: column;
    max-width: 400px;
  }

  .research-faculty-image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  }

  .research-faculty-image-container img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
    max-width: 400px;
    display: block;
  }

  .research-faculty-image-container:hover img {
    transform: scale(1.1);
  }

  .play-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 100%;
    pointer-events: none;
    text-align: center;

    .img-wrapper,
    img {
      display: inline-block;
      width: 12.81%;
      min-width: 8.8rem;
      max-width: 14.6rem;
    }
  }

  .research-faculty-title {
    margin-bottom: 1rem;
    font-size: 2.5rem;
    font-weight: bold;
    max-width: 400px;
  }

  .research-faculty-description {
    margin-bottom: 2rem;
    font-size: 1.6rem;
    max-width: 400px;
  }

  /* Mobile View */
  @media (max-width: 768px) {
    .research-faculty-tile {
      max-width: 100%;
    }
  }
`;
