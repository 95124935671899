import ShareSvg from "../../../public/image/svg/share.svg";
import { useState } from "react";
import { SiteSettings } from "@customTypes/SiteSettings";
import { Results } from "../CoveoSearchComponent";
import { NextContentfulImage } from "@components/Image";

interface NewsDetailsResultsTemplateProps {
  siteSettings?: SiteSettings;
  result: Results;
}

export const NewsDetailsResultsTemplate = (
  props: NewsDetailsResultsTemplateProps
) => {
  const { result, siteSettings } = props;
  const [open, setOpen] = useState(false);

  const replace = (shareLink) => {
    shareLink = shareLink.replace("%URL%", encodeURIComponent(result.clickUri));
    return shareLink;
  };

  const dateString = result?.raw?.articledatetext || "";

  return (
    <>
      <div>
        {result?.raw?.articleimageurl &&
          !result?.raw?.articleimageurl.includes("undefined") && (
            <div className="wrapperImage">
              <a className="imageLink" href={result.clickUri}>
                <NextContentfulImage
                  src={result.raw.articleimageurl}
                  alt=""
                  width={896}
                  height={504}
                  loading="eager"
                  priority
                  sizes="(max-width: 768px) 100vw, 20vw"
                />
              </a>
            </div>
          )}
        <div className="text1">
          <p className="primary-tag">NEWS</p>
          <h2>
            <a className="coveo-link" href={result.clickUri}>
              {result?.raw?.newstitle && (
                <h3 className="h3">{result?.raw?.newstitle}</h3>
              )}
            </a>
          </h2>
          <p className="sub-heading medium-light">{dateString}</p>
          <p
            className="CoveoFieldValue copy medium-light"
            data-field="@shortdescription"
          >
            <span>{result.raw.pagedescription}</span>
          </p>
          {result.raw.articleauthorsnames && (
            <p
              className="CoveoFieldValue info-field"
              data-field="@articleauthorsnames"
            >
              By
              <span className="authors">
                {result?.raw?.articleauthorsnames
                  .map((author) => author)
                  .join(", ")}
              </span>
            </p>
          )}
          <footer>
            {result.raw.taz120xonomytopics &&
              result.raw.taz120xonomytopics.map((topic, i) => (
                <div key={`${topic}-${i}`} className="component tag cf">
                  <a
                    href={`/news?${topic?.split(":")[0].toLowerCase()}=${
                      topic?.split(":")[1]
                    }`}
                  >
                    {topic?.split(":")[1]}
                  </a>
                </div>
              ))}
            <div className={`social-share ${open && "show"}`}>
              <a
                href="#"
                className="share-toggle"
                aria-label="Share content"
                onClick={(e) => {
                  e.preventDefault();
                  setOpen(!open);
                }}
              >
                <span className="visually-hidden">Share</span>
                <i
                  className="icon-share"
                  data-grunticon-embed="data-grunticon-embed"
                >
                  <ShareSvg />
                </i>
              </a>
              <ul className="share-links">
                {siteSettings &&
                  siteSettings?.globalSocialShareCollection.map(
                    ({ fields: { name, darkIcon, shareLink } }, index) => {
                      const icon = darkIcon.fields;
                      return (
                        <li key={`${name}-${index}`}>
                          <a
                            aria-label={`${name} - Opens a new window`}
                            href={replace(shareLink)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <NextContentfulImage
                              src={icon.file.url}
                              alt={name}
                              width={30}
                              height={30}
                            />
                          </a>
                        </li>
                      );
                    }
                  )}
              </ul>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
};

export default NewsDetailsResultsTemplate;
