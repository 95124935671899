import Grid from "@components/Grid/Grid";
import SwiperNavigation from "@components/Swiper/SwiperNavigation";
import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { ProgrammeType } from "@customTypes/ProgrammeType";
import { fetchApi } from "@utilities/fetchApi";
import { useDevice } from "@utilities/react/get-device/get-device";
import clsx from "clsx";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import { Pagination } from "swiper/modules";
import { Swiper as SwiperCarousel, SwiperSlide } from "swiper/react";

import IconCalendar from "../../public/image/svg/calendar.svg";
import IconDuration from "../../public/image/svg/duration.svg";
import IconLocation from "../../public/image/svg/location.svg";
import styles from "./C241_UpcomingProgrammes.module.scss";
import { Swiper as SwiperProps } from "swiper/types";

export interface UpcommingProgrammesProps {
  programmeType: ProgrammeType;
  label: string;
  title: string;
  description: string;
  BlueVariation?: boolean;
  tags;
}

export const UpcomingProgrammes = (props: {
  content: UpcommingProgrammesProps;
}) => {
  const {
    content: { programmeType, label, title, description, BlueVariation, tags },
  } = props;
  const [swiper, setSwiper] = useState<SwiperProps>();
  const [slide, setSlide] = useState(1);
  const [upcomingProgrammes, setUpcomingProgrammes] = useState<
    ProgrammeDetails[]
  >([]);
  const device = useDevice();
  const perPage = device === "mobile" ? 1 : device === "tablet" ? 2 : 3;
  var tagIDs = [];
  if (tags) {
    tagIDs = tags.map((tag) => tag?.sys?.id);
  }
  useEffect(() => {
    const getData = async () => {
      const upcomingProgrammes = await fetchApi("/api/getUpcomingProgrammes", {
        programmeType,
        tagIDs,
      });
      setUpcomingProgrammes(upcomingProgrammes);
    };
    getData();
  }, []);

  console.log("upcoming programmes length ", upcomingProgrammes.length);

  return (
    <div
      className={clsx(
        "component",
        styles["upcoming-programmes"],
        BlueVariation && [styles.blue, "blue"]
      )}
      // BlueVariation && "blue")}
    >
      <div className="wrapper">
        <Grid row customClass={styles.row}>
          <Grid column sm={12}>
            <p className={clsx("label", styles.label)}>{label}</p>
          </Grid>
          <Grid column sm={12} md={6}>
            <h2>{title}</h2>
          </Grid>
          <Grid column sm={12} md={6}>
            <p className={clsx("large", styles.large)}>{description}</p>
          </Grid>
        </Grid>
        <div className={styles.programmes}>
          {upcomingProgrammes.length > 0 && (
            <>
              <SwiperCarousel
                className={clsx(styles.swiper, {
                  ["on-dark"]: BlueVariation,
                })}
                spaceBetween={40}
                slidesPerView={perPage}
                slidesPerGroup={perPage}
                onSlideChange={() => {
                  swiper?.pagination?.bullets.forEach((item, index) => {
                    if (
                      item.classList.contains("swiper-pagination-bullet-active")
                    ) {
                      setSlide(index + 1);
                    }
                  });
                }}
                loop={true}
                loopAddBlankSlides={false}
                enabled={upcomingProgrammes.length > perPage}
                // loopFillGroupWithBlank={upcomingProgrammes.length <= perPage}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                onSwiper={(swiper) => {
                  setSwiper(swiper);
                }}
              >
                {orderBy(upcomingProgrammes, [
                  "programmeImportData.programmeStartDate",
                ]).map((programmeDetails) => {
                  const {
                    slug,
                    programmeTitle,
                    programmeDescription,
                    isNew,
                    programmeImportData,
                    contentfulMetadata,
                  } = programmeDetails;

                  const link = slug.charAt(0) !== "/" ? `/${slug}` : slug;

                  return (
                    <SwiperSlide key={slug}>
                      <div className={styles.programme}>
                        {isNew ? <div className={styles.new}>NEW</div> : null}
                        <h3>
                          <a href={link}>{programmeTitle}</a>
                        </h3>
                        <p>{programmeDescription}</p>
                        <div className={styles.bottom}>
                          <ul>
                            {programmeImportData &&
                              programmeImportData[0]
                                ?.programmeInclusiveDates && (
                                <li>
                                  <i className="icon-calendar">
                                    <IconCalendar />
                                  </i>
                                  {
                                    programmeImportData[0]
                                      .programmeInclusiveDates
                                  }
                                </li>
                              )}
                            <li>
                              <i className="icon-duration">
                                <IconDuration />
                              </i>
                              {programmeImportData
                                ? programmeImportData[0]?.programmeDuration
                                : ""}
                            </li>
                            <li>
                              <i className="icon-location">
                                <IconLocation />
                              </i>
                              {programmeImportData
                                ? programmeImportData[0]?.programmeLocation
                                : ""}
                            </li>
                          </ul>
                          {!!contentfulMetadata?.tags.length && (
                            <p className="small">
                              <strong>Tags: </strong>
                              {contentfulMetadata?.tags
                                .map((tag) => {
                                  return tag.name;
                                })
                                .join(", ")}
                            </p>
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </SwiperCarousel>
              {upcomingProgrammes.length > perPage && (
                <SwiperNavigation
                  className={styles.next}
                  swiper={swiper}
                  direction="next"
                  perPage={perPage}
                  variation={device === "desktop-large" ? "fraction" : "arrow"}
                  onDark={BlueVariation}
                  currentPage={slide}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpcomingProgrammes;
