import styles from "./C210_2_ArticleRichTextTriple.module.scss";
import clsx from "clsx";
import Grid from "@components/Grid/Grid";
import C16_RichTextFullWidth from "@components/C16_RichTextFullWidth/C16_RichTextFullWidth";

export interface ArticleRichTextTripleProps {
  titleLabel?: string;
  richTextContent: any[];
  backgroundColor?: "blue" | "white";
}

export const ArticleRichTextTriple = (props: {
  content: ArticleRichTextTripleProps;
}) => {
  const { content } = props;
  const { titleLabel, richTextContent, backgroundColor } = content;

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12}>
          <section
            className={clsx(
              "component",
              backgroundColor && styles[backgroundColor],
              styles["rich-text-triple"]
            )}
          >
            <div className="wrapper">
              <Grid row>
                <Grid column sm={12}>
                  <section className="component rich-text">
                    <h2 className="h3">{titleLabel || ""}</h2>
                  </section>
                </Grid>
              </Grid>
              <Grid row>
                {richTextContent.map((rc, i) => (
                  <Grid key={"rc" + i} column sm={12} md={4}>
                    <C16_RichTextFullWidth
                      content={rc?.fields?.content}
                      capitaliseFirstLetter={rc?.fields?.capitaliseFirstLetter}
                      addBorder={false}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default ArticleRichTextTriple;
