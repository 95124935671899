import { useState } from "react";
import clsx from "clsx";
import RichTextContent from "contentful";
import { useRouter } from "next/router";

import Grid from "@components/Grid/Grid";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import ClipLoader from "react-spinners/ClipLoader";
import IconArrowSmall from "../../public/assets2019/img/svg/arrow.svg";
import { LOGIN_URL } from "../../constants";
import styles from "@components/LoginOrRegister/LoginOrRegister.module.scss";

export interface LoginOrRegisterProps {
  loginOrRegisterHeadingTitle: string;
  descriptionSecondaryTitleText: string;
  createProfileText: RichTextContent;
  createProfileLinkTitleText: string;
  createProfileUrlLink: {
    fields: {
      slug: string;
    };
  };
  loginTitleText: string;
  loginButtonTitleText: string;
}

const LoginOrRegister = (props: { content: LoginOrRegisterProps }) => {
  const { content } = props;
  const {
    loginOrRegisterHeadingTitle,
    descriptionSecondaryTitleText,
    createProfileText,
    createProfileLinkTitleText,
    createProfileUrlLink,
    loginTitleText,
    loginButtonTitleText,
  } = content;
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  const returnUrl = router?.query?.returnUrl;

  const redirectUrl = `${LOGIN_URL}?returnUrl=${encodeURIComponent(
    `${process.env.NEXT_PUBLIC_URL}/${returnUrl}`
  )}`;

  const handleLoginClick = () => {
    setLoading(true);
  };

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column sm={12}>
          <section className={clsx("component", styles["apply-page"])}>
            <h1 className={clsx(styles["section-title"], styles.h2)}>
              {loginOrRegisterHeadingTitle}
            </h1>
            <Grid row>
              <Grid column sm={12} md={6}>
                <h3 className={styles["sub-title"]}>
                  {descriptionSecondaryTitleText}
                </h3>
                <div className={styles["create-profile"]}>
                  <RichTextDisplay
                    richText={createProfileText}
                    tinyMceRichText=""
                  />
                  <p>
                    <button
                      className="tertiary"
                      onClick={() =>
                        router.push({
                          pathname: createProfileUrlLink?.fields?.slug,
                          query: {
                            returnUrl: router?.query?.returnUrl,
                          },
                        })
                      }
                    >
                      <IconArrowSmall className="tertiary-arrow" />
                      {createProfileLinkTitleText}
                    </button>
                  </p>
                </div>
              </Grid>

              <Grid column sm={12} md={6}>
                <h3 className={styles["sub-title"]}>{loginTitleText}</h3>
                <div className="btn-and-text-wrapper">
                  <div
                    className={clsx(
                      styles["button-wrapper"],
                      styles["form-row"]
                    )}
                  >
                    <a
                      href={redirectUrl}
                      onClick={handleLoginClick}
                      className={`cta ${loading ? "loading" : ""}`}
                    >
                      {loading && (
                        <ClipLoader
                          color="#001e62"
                          size={20}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      )}
                      {loginButtonTitleText}
                    </a>
                  </div>
                </div>
              </Grid>
            </Grid>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginOrRegister;
