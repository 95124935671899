import React from "react";
import styles from "./Grid.module.scss";

type GridItemsAlignment =
  | "flex-start"
  | "center"
  | "flex-end"
  | "stretch"
  | "baseline";

type GridJustify =
  | "flex-start"
  | "center"
  | "flex-end"
  | "space-between"
  | "space-around"
  | "space-evenly";

export type GridSizes =
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | number;

interface GridProps {
  alignItems?: GridItemsAlignment;
  column?: boolean;
  expanded?: boolean;
  justify?: GridJustify;
  center?: boolean;
  lg?: GridSizes;
  md?: GridSizes;
  row?: boolean;
  sm?: GridSizes;
  slim?: boolean;
  thick?: boolean;
  key?: any;
  customClass?: string;
  direction?: "direction-column" | "direction-row";
  children?: any;
}

const Grid: React.FunctionComponent<GridProps> = (props) => {
  const {
    alignItems,
    children,
    column,
    expanded,
    justify,
    center,
    lg,
    md,
    row,
    sm,
    slim,
    thick,
    customClass,
    direction,
  } = props;
  const isRow: boolean = row || !column;
  // TODO: change to clsx()
  const classes: string =
    (!isRow ? styles.column : styles.row) +
    // Row styling
    (isRow && expanded ? ` ${styles.expanded}` : "") +
    (isRow && direction ? ` ${styles[direction]}` : "") +
    (isRow && justify ? ` ${styles[justify]}` : "") +
    (isRow && alignItems ? ` ${styles["align-" + alignItems]}` : "") +
    (isRow && slim ? ` ${styles.Slim}` : "") +
    (isRow && thick ? ` ${styles.Thick}` : "") +
    (isRow && center ? ` ${styles.center}` : "") +
    // Column styling
    (!isRow && sm ? ` ${styles["sm-" + sm]}` : "") +
    (!isRow && md ? ` ${styles["md-" + md]}` : "") +
    (!isRow && lg ? ` ${styles["lg-" + lg]}` : "") +
    ` ${customClass ? customClass : ""}`;

  return <div className={classes}>{children}</div>;
};

export default Grid;
