import clsx from "clsx";
import { NavigationStateContext } from "../../../context/navigationContext";
import React, { useContext, useEffect, useState } from "react";
import styles from "./MobileLevelSection.module.scss";
import {
  handleBackClick,
  handleItemClick,
} from "@components/C01_SiteHeader/mobileMenu/utils";
import Link from "@components/Link/Link";
import { navigationGAClick } from "@utilities/navigationGAClick";
import { useRouter } from "next/router";
import { GeneralLinkNornalized } from "types/generalLink";
import { LOGIN_URL, LOGOUT_URL } from "../../../constants";
import Cta from "@components/Cta/Cta";

export const MobileLevelSection = ({
  levelData,
  isFirstLevel = false,
  secondaryLinks,
  user,
}: {
  levelData: {
    items: any[];
    visibility: boolean;
    ownLink: any;
    promoItems?: any[];
    promoCtAs?: any[];
  };
  isFirstLevel: boolean;
  secondaryLinks?: GeneralLinkNornalized[] | undefined;
  user?: any;
}) => {
  const { mobileMenuHistory, setMobileMenuHistory } = useContext(
    NavigationStateContext
  );
  const router = useRouter();
  const [isOpenMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    setOpenMenu(levelData?.visibility);
  }, [levelData?.visibility]);

  const renderItemCaption = (menuItem) => {
    if (menuItem?.link) return menuItem?.link?.fields?.linkText; // column link
    if (menuItem?.linkText) return menuItem?.linkText; // link
    if (menuItem?.label) return menuItem?.label; // title
    return menuItem?.ownLink?.fields?.linkText; // nested item
  };

  const checkFolder = (menuItem) => {
    return menuItem?.linkList || menuItem?.column1;
  };

  const checkTitle = (menuItem) => {
    return !!menuItem?.label;
  };

  const prepareItemData = (menuItem) => {
    if (menuItem?.column3)
      return [...menuItem?.column1, ...menuItem?.column2, ...menuItem?.column3];
    if (menuItem?.column2) return [...menuItem?.column1, ...menuItem?.column2];
    if (menuItem?.column1) return [...menuItem?.column1];
    return menuItem?.linkList;
  };

  const renderItems = () => {
    return levelData?.items?.map((menuItem, index) => {
      return (
        <li
          key={index}
          className={clsx(
            styles.menuItem,
            checkFolder(menuItem) && styles.folder,
            checkTitle(menuItem) && styles.itemTitle,
            !isFirstLevel && styles.nestItem
          )}
        >
          <a
            href={"/"}
            onClick={(e) => {
              e.preventDefault();
              if (!!menuItem?.label && !menuItem?.link) return;
              handleItemClick(
                mobileMenuHistory,
                setMobileMenuHistory,
                prepareItemData(menuItem),
                menuItem?.ownLink?.fields,
                menuItem?.promoItems,
                menuItem?.promoCtAs
              );
              window.scrollTo(0, 0);
              let linkUrl = "";
              if (menuItem?.url) {
                linkUrl = menuItem?.url;
              } // regular link
              if (menuItem?.link?.fields?.url) {
                linkUrl = menuItem?.link?.fields?.url;
              } // column link

              if (linkUrl) {
                // link
                menuItem?.openInNewTab
                  ? (window.location.href = linkUrl)
                  : router.push(linkUrl);
              }
            }}
            key={index}
          >
            {renderItemCaption(menuItem)}
          </a>
        </li>
      );
    });
  };

  const renderBackBtn = () => {
    return (
      <div
        onClick={() => handleBackClick(mobileMenuHistory, setMobileMenuHistory)}
        className={styles.btnBack}
      >
        Back
      </div>
    );
  };

  const renderGoToParent = (ownLink) => {
    if (!ownLink?.url) return;
    return (
      <Link href={ownLink?.url || "#"}>
        <a
          className={styles.goToBtn}
          onClick={(e) => {
            setMobileMenuHistory([]);
            navigationGAClick(e);
          }}
        >
          Go to {ownLink?.linkText}
        </a>
      </Link>
    );
  };

  const renderSecondaryLinks = () => {
    return (
      <ul className={styles.secondaryList}>
        {secondaryLinks?.map((item, i) => {
          if (user && item?.url === "/create-a-profile") return;
          return (
            <li key={`${item}${i}`}>
              <Link href={item?.url || "#"}>
                <a
                  onClick={(e) => {
                    setMobileMenuHistory([]);
                    navigationGAClick(e);
                  }}
                >
                  {item?.linkText}
                </a>
              </Link>
            </li>
          );
        })}
        <li key={"login_link"}>
          <a
            href={user ? LOGOUT_URL : LOGIN_URL}
            onClick={(e) => {
              setMobileMenuHistory([]);
              navigationGAClick(e);
            }}
          >
            {user ? "Logout" : "Login"}
          </a>
        </li>
      </ul>
    );
  };

  const renderPromoCtAs = (promoCtAs: any[]) => {
    return (
      <div className={styles.cPromoCTAs}>
        {promoCtAs.map((promoCTA, index) => {
          return (
            <Cta
              key={`main-menu-link${index}`}
              btnTheme={styles["ctaBottom"]}
              linkText={promoCTA?.fields?.linkText}
              type={"secondary"}
              light
              url={promoCTA?.fields?.url}
              onClick={(e) => {
                navigationGAClick(e);
                router.push(promoCTA?.fields?.url);
                setMobileMenuHistory([]);
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderPromoItems = (promoItems: any[]) => {
    return (
      <div className={styles.cPromoItems}>
        {promoItems.map((promoCTA, index) => {
          const isSearch =
            promoCTA?.fields?.link?.fields?.linkText === "Course finder";
          if (isSearch) return;
          return (
            <Cta
              key={`main-menu-link${index}`}
              btnTheme={styles["ctaBottom"]}
              linkText={promoCTA?.fields?.link?.fields?.linkText}
              icon={"icon-arrow"}
              type={"tertiary"}
              light
              url={promoCTA?.fields?.link?.fields?.url}
              onClick={(e) => {
                navigationGAClick(e);
                router.push(promoCTA?.fields?.link?.fields?.url);
                setMobileMenuHistory([]);
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderBottomSection = () => {
    return (
      <div className={clsx(styles.cBottom, !secondaryLinks && styles.redLine)}>
        {levelData?.promoItems && renderPromoItems(levelData?.promoItems)}
        {levelData?.promoCtAs && renderPromoCtAs(levelData?.promoCtAs)}
        {!!secondaryLinks && renderSecondaryLinks()}
      </div>
    );
  };

  return (
    <div className={clsx(styles.cLevel, isOpenMenu && styles.open)}>
      <ul>
        {!isFirstLevel && renderBackBtn()}
        {!isFirstLevel && renderGoToParent(levelData?.ownLink)}

        {renderItems()}
        {renderBottomSection()}
      </ul>
    </div>
  );
};

export default MobileLevelSection;
