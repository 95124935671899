import { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import styles from "./C254_ProgrammeCalendar.module.scss";
import { fetchApi } from "@utilities/fetchApi";
import { format, parseISO } from "date-fns";
import { ProgrammeContext } from "../../context/programmeComparison";
import Link from "@components/Link/Link";
import { convertToBulletedList } from "@utilities/convertToBulletedList";
import { useSettings } from "@utilities/context/settings";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import { UserContext } from "../../context/user";
import ClipLoader from "react-spinners/ClipLoader";

type Filter = {
  label: string;
  name: string;
  open: boolean;
  options: {
    label: string;
    value: string;
    checked: boolean;
  }[];
};

type MobOpen = boolean | "done";

export const ProgrammeCalendar = () => {
  const { programmes, addProgramme, removeProgramme } =
    useContext(ProgrammeContext);

  const [filters, setFilters] = useState<Filter[]>([]);
  const [mobOpen, setMobOpen] = useState<MobOpen>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [results, setResults] = useState<any>();
  const { siteSettings } = useSettings();
  const reserveAPlace = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.ReserveAPlace
  )[0];
  const apply = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.Apply
  )[0];
  const { user } = useContext(UserContext);

  useEffect(() => {
    const getData = async () => {
      const upcomingProgrammes = await fetchApi("/api/getUpcomingProgrammes", {
        programmeType: "EE",
      });

      const dateFilters: Filter = {
        name: "date",
        label: "Date",
        open: false,
        options: [],
      };

      const locationFilters: Filter = {
        name: "location",
        label: "Location",
        open: false,
        options: [],
      };

      const stageFilters: Filter = {
        name: "Career Stage",
        label: "Career Stage",
        open: false,
        options: [],
      };

      const addFilter = (filter, value) => {
        if (!filter.find((f) => f.value === value)) {
          filter.push({
            value: value,
            label: value,
            checked: false,
          });
        }
      };

      const mappedResults = upcomingProgrammes.reduce((acc, curr) => {
        curr.programmeImportData &&
          curr.programmeImportData.forEach((p) => {
            const date = format(parseISO(p.programmeStartDate), "MMMM yyyy");

            addFilter(dateFilters.options, date);
            addFilter(locationFilters.options, p.programmeLocation);

            curr.programmeCareerStagesEeList?.forEach((tag) => {
              addFilter(stageFilters.options, tag);
            });

            const applyUrlLogin = `/interact?applyID=${curr.programmeApplyOnlineParameterValue}`;
            const applyUrlNotLogin = `${apply?.url.slug}?applyID=${curr.programmeApplyOnlineParameterValue}`;
            let intractLink = user ? applyUrlLogin : applyUrlNotLogin;
            const course = {
              title: curr.programmeTitle,
              description: curr.programmeDescription,
              delivery: curr.programmeDeliveryText,
              date: format(parseISO(p.programmeStartDate), "dd MMMM yyyy"),
              ProgrammeStartDate: p.programmeStartDate,
              duration: p.programmeDuration,
              availability: null,
              locationFormatted: p.programmeLocation,
              fees: p.programmeFee,
              tags: curr.programmeAttribute,
              infomation: null,
              "Career Stage": curr.programmeCareerStagesEeList,
              location: p.programmeLocation,
              programmeLink: `/${curr.slug}`,
              applyLink: curr.programmeApplyNowExternalLink || intractLink,
              reserveLink: `/${reserveAPlace?.url?.slug}?programmeTypeCode=${curr?.programmeTypeCode}&programmeTypeGroup=EXECED&referPage=/executive-education/programme-calendar`,
              programmeTypeCode: curr.programmeTypeCode,
            };

            if (acc[date]) {
              acc[date].push(course);
            } else {
              acc[date] = [course];
            }
          });

        /*const date = format(
          parseISO(curr.programmeImportData[0].programmeStartDate),
          "MMMM yyyy"
        );

        addFilter(dateFilters.options, date);*/

        return acc;
      }, {});

      setResults({ programmes: mappedResults });
      dateFilters.options.sort((a, b) => {
        return new Date(a.value).getTime() - new Date(b.value).getTime();
      });
      setFilters([stageFilters, locationFilters, dateFilters]);
    };
    getData();
  }, []);

  const filterToggle = (i) => {
    let stateCopy = [...filters];

    stateCopy[i].open = !stateCopy[i].open;

    stateCopy.forEach((filter, ii) => {
      if (ii !== i) {
        filter.open = false;
      }
    });

    setFilters(stateCopy);
  };

  const filterKeyDown = (i, e) => {
    switch (e.keyCode) {
      case 13:
        filterToggle(i);
        break;
    }
  };

  const toggleDate = (key, e) => {
    e.preventDefault();
    let stateCopy = structuredClone(results);

    stateCopy.programmes[key].open = !stateCopy.programmes[key].open;

    setResults(stateCopy);
  };

  const checkToggle = (filterIn, checkboxIn, e) => {
    e.stopPropagation();
    let stateCopy = [...filters];

    stateCopy[filterIn].options[checkboxIn].checked =
      !stateCopy[filterIn].options[checkboxIn].checked;

    setFilters(stateCopy);
  };

  const toggleShowFilter = () => {
    setMobOpen(!mobOpen);
  };

  useEffect(() => {
    if (mobOpen) {
      setTimeout(() => {
        setMobOpen("done");
      }, 300);
    }
  }, [mobOpen]);

  const clearAll = (e) => {
    e.preventDefault();
    let stateCopy = [...filters];

    stateCopy.forEach((filter) => {
      filter.options.forEach((option) => {
        option.checked = false;
      });
    });

    setFilters(stateCopy);
  };

  const filterBlur = (i, e) => {
    let stateCopy = [...filters],
      target = e.relatedTarget;

    if (!e.relatedTarget) {
      target = document.activeElement;
    }

    if (
      !target.classList.contains("value") &&
      (!target || target.type !== "checkbox")
    ) {
      stateCopy[i].open = false;
      setFilters(stateCopy);
    }
  };
  const checkBlur = (i, e) => {
    let stateCopy = [...filters],
      filtersEl = document.querySelectorAll(".filters .inner .filter");

    setTimeout(() => {
      if (
        !document.activeElement ||
        (!document.activeElement.classList.contains("value") &&
          //@ts-ignore
          document.activeElement.type !== "checkbox" &&
          document.activeElement != filtersEl[i])
      ) {
        stateCopy[i].open = false;
        setFilters(stateCopy);
      }
    }, 50);
  };

  const getSelectedFilters = (stateCopy) => {
    return stateCopy.filters
      .map((filter, i) => {
        let end = {},
          returnFilter = Object.assign({}, filter),
          selected = filter.options.map((option, i) => {
            if (option.checked) {
              option.index = i;
              return option;
            }
            return false;
          });

        selected = selected.filter((a) => {
          return a;
        });

        if (selected.length) {
          returnFilter.options = selected;
          returnFilter.index = i;
          return returnFilter;
        }
        return false;
      })
      .filter((a) => a);
  };

  const getFilteredResults = (filteredResults, selectedFilters) => {
    if (!results) return filteredResults;
    Object.keys(results.programmes).forEach((dateGroupKey) => {
      let dateIndex,
        includeDate = false,
        includes: any = [];

      selectedFilters.forEach((filter, i) => {
        if (filter.name === "date") {
          dateIndex = i;
        } else {
          includes.push(false);
        }
      });

      if (dateIndex !== undefined) {
        selectedFilters[dateIndex].options.forEach((option) => {
          if (dateGroupKey === option.value) {
            includeDate = true;
          }
        });
      } else {
        includeDate = true;
      }

      if (includeDate) {
        let options = results.programmes[dateGroupKey].slice(0);

        options = options.map((option, a) => {
          let optionIncludes = includes.slice(0),
            x = 0;

          selectedFilters.forEach((filter, i) => {
            if (i !== dateIndex) {
              let include = false;

              filter.options.forEach((filterOption) => {
                if (Array.isArray(option[filter.name])) {
                  if (option[filter.name].indexOf(filterOption.value) > -1) {
                    include = true;
                  }
                } else {
                  if (option[filter.name] === filterOption.value) {
                    include = true;
                  }
                }
              });
              optionIncludes[x] = include;
              x += 1;
            }
          });

          if (optionIncludes.indexOf(false) < 0) {
            return option;
          }

          return false;
        });
        options = options.filter((option) => option);
        if (options.length) {
          filteredResults[dateGroupKey] = options;
          filteredResults[dateGroupKey].open =
            results.programmes[dateGroupKey].open;
        }
      }
    });

    return filteredResults;
  };

  const overLayCheck = (e, programme) => {
    if (e.target.checked) {
      addProgramme({
        programmeTitle: programme.title,
        programmeCode: programme.programmeTypeCode,
        programmeType: "EE",
      });
      return;
    }

    removeProgramme({
      programmeTitle: programme.title,
      programmeCode: programme.programmeTypeCode,
    });
  };

  const computeFilterHeight = () => {
    let height;
    if (mobOpen && mobOpen === "done") {
      return { height: "auto" };
    } else if (mobOpen) {
      height = document
        ?.querySelector(".filters .inner")
        ?.getBoundingClientRect().height;

      return {
        height:
          document?.querySelector(".filters .inner")?.getBoundingClientRect()
            .height + "px",
      };
    }

    return {};
  };
  let totalCount = 0;
  let filteredResults = {};
  let selectedFilters = getSelectedFilters({ filters: [...filters] });
  filteredResults = getFilteredResults(filteredResults, selectedFilters);

  useEffect(() => {
    if (results && filters) {
      setIsLoading(false);
    }
  }, [results, filters]);

  return (
    <div className={styles["programme-calendar"]}>
      <div className="wrapper">
        <div className={styles.filters} style={computeFilterHeight()}>
          <div className={styles.inner}>
            {filters.map((filter, i) => {
              let count: any = 0;

              filter.options.forEach((option) => {
                if (option.checked) {
                  count += 1;
                  totalCount += 1;
                }
              });
              if (!count) {
                count = false;
              }
              return (
                <div
                  key={`${filter.label}${i}`}
                  tabIndex={0}
                  className={styles.filter}
                  data-open={filter.open}
                  onKeyDown={(e) => {
                    filterKeyDown(i, e);
                  }}
                  onBlur={(e) => {
                    filterBlur(i, e);
                  }}
                >
                  <div
                    className={styles.header}
                    data-count={count || null}
                    onClick={() => {
                      filterToggle(i);
                    }}
                  >
                    {filter.label}
                  </div>
                  <div className={styles.values}>
                    <div className={styles.inner}>
                      {filter.options.map((value, ii) => {
                        return (
                          <div
                            className={styles.value}
                            key={`${value.value}${i}`}
                          >
                            <input
                              onBlur={(e) => {
                                checkBlur(i, e);
                              }}
                              onChange={(e) => {
                                checkToggle(i, ii, e);
                              }}
                              type="checkbox"
                              id={`${filter.label}-${value.value}`}
                              checked={value.checked}
                            ></input>
                            <span className={styles.tick}></span>
                            <label
                              title={value.label}
                              htmlFor={`${filter.label}-${value.value}`}
                            >
                              {value.label}
                            </label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <button
          className={clsx("cta", "secondary", styles["show-filters"])}
          data-count={(!mobOpen && totalCount) || null}
          onClick={toggleShowFilter}
        >
          {mobOpen ? "Hide" : "Show"} Filters
        </button>
        <div className={styles["filter-clear"]}>
          {selectedFilters.map(function (filter, i) {
            return (
              <div className={styles.filter} key={`${filter.label}${i}`}>
                <label>{filter.label}:</label>
                <div className={styles.items}>
                  {filter.options.map(function (option, ii) {
                    return (
                      <a
                        key={`${option.label}${i}`}
                        href={"#" + option.label}
                        className={styles.option}
                        onClick={(e) => {
                          checkToggle(filter.index, option.index, e);
                        }}
                      >
                        {option.label}{" "}
                        <svg
                          width="20"
                          height="20"
                          viewBox="5 5 14 14"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#fff"
                          focusable="false"
                        >
                          <path
                            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                            id="Page-1"
                            stroke="none"
                            stroke-width="1"
                            fill-rule="evenodd"
                          ></path>
                        </svg>
                      </a>
                    );
                  })}
                </div>
              </div>
            );
          })}
          {selectedFilters.length > 0 && (
            <a
              href="#clear-all"
              className={styles["clear-all"]}
              onClick={clearAll}
            >
              <svg
                width="20"
                height="20"
                viewBox="5 5 14 14"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                fill="#fff"
                focusable="false"
              >
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  id="Page-1"
                  stroke="none"
                  stroke-width="1"
                  fill-rule="evenodd"
                ></path>
              </svg>{" "}
              Clear Filters
            </a>
          )}
        </div>
        <div className={styles["search-results"]}>
          {Object.keys(filteredResults)
            .sort((a, b) => {
              return new Date(a).valueOf() - new Date(b).valueOf();
            })
            .map(function (key, i) {
              if (filteredResults[key].length) {
                return (
                  <div className={styles["date-group"]} key={`${key}${i}`}>
                    <a
                      href={"#" + key}
                      className={styles.date}
                      data-open={filteredResults[key].open}
                      onClick={(e) => {
                        toggleDate(key, e);
                      }}
                    >
                      {key}
                      <span>
                        {filteredResults[key].length}{" "}
                        {filteredResults[key].length > 1
                          ? "programmes"
                          : "programme"}
                      </span>
                    </a>
                    <div className={styles.programmes}>
                      {filteredResults[key].map(function (programme, i) {
                        return (
                          <div
                            className={styles["course-search-results"]}
                            data-guid={`item-${key}-${i}`}
                            key={`${key}${i}`}
                          >
                            <div
                              className={`${styles["application-wrapper"]} wrapper`}
                            >
                              <div className="row">
                                <div className="base12">
                                  <div className={styles["application-box"]}>
                                    <div className={styles["left-section"]}>
                                      <Link
                                        href={programme.programmeLink || "#"}
                                      >
                                        <a
                                          className="CoveoResultLink title-link"
                                          tabIndex={0}
                                        >
                                          <h4>{programme.title}</h4>
                                        </a>
                                      </Link>
                                      <p>{programme.description}</p>
                                      <div className={`form ${styles["form"]}`}>
                                        <div
                                          className={`checkbox ${styles["checkbox"]}`}
                                          tabIndex={0}
                                        >
                                          <input
                                            id={"demo-" + key + i}
                                            type="checkbox"
                                            onClick={(e) => {
                                              overLayCheck(e, programme);
                                            }}
                                            checked={
                                              !!programmes.find(
                                                (p) =>
                                                  p.programmeCode ===
                                                  programme.programmeTypeCode
                                              )
                                            }
                                          />
                                          <span className="tick"></span>
                                          <label htmlFor={"demo-" + key + i}>
                                            Compare
                                          </label>
                                        </div>
                                      </div>
                                      <div
                                        className={clsx(
                                          styles["tag-info"],
                                          styles["m-hide"]
                                        )}
                                      >
                                        {programme.tags &&
                                          programme.tags.length > 0 && (
                                            <span
                                              className={styles["tag-label"]}
                                            >
                                              Tags
                                            </span>
                                          )}
                                        <span>
                                          {programme.tags &&
                                            programme.tags.join(", ")}
                                        </span>
                                        {programme.infomation && (
                                          <div
                                            className={
                                              styles["message-wrapper"]
                                            }
                                            tabIndex={0}
                                          >
                                            <p className={styles.message}>
                                              {programme.infomation}
                                              <a
                                                href="#close"
                                                aria-label="Close"
                                                className={styles["icon-cross"]}
                                              ></a>
                                            </p>
                                          </div>
                                        )}
                                      </div>

                                      <div
                                        className={styles["button-margin"]}
                                      ></div>
                                      <div className={styles["apply-wrapper"]}>
                                        <div
                                          className={styles["button-with-link"]}
                                        >
                                          <Link
                                            href={programme.applyLink || "#"}
                                          >
                                            <a className={`${styles.cta} cta`}>
                                              Apply now
                                            </a>
                                          </Link>
                                          {programme.reserveLink != null && (
                                            <Link
                                              href={
                                                programme.reserveLink || "#"
                                              }
                                            >
                                              <a className="cta tertiary">
                                                <span
                                                  className="icon-arrow"
                                                  // style="background-image: none;"
                                                >
                                                  <svg
                                                    height="20"
                                                    width="20"
                                                    viewBox="4 4 16 16"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    focusable="false"
                                                  >
                                                    <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"></path>
                                                  </svg>
                                                </span>
                                                <span>Reserve a place</span>
                                              </a>
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    <div className={styles["right-section"]}>
                                      <div className={styles["inner-wrapper"]}>
                                        <div
                                          className={
                                            styles["section-row-wrapper"]
                                          }
                                        >
                                          <p className={styles["section-row"]}>
                                            <span className={styles.label}>
                                              Next
                                            </span>
                                            <span className={styles.info}>
                                              {programme.date}
                                              {programme.availability &&
                                                programme.availability ===
                                                  "Limited Availability" && (
                                                  <span className="sub-text">
                                                    Limited availability
                                                  </span>
                                                )}
                                            </span>
                                          </p>
                                          <p className={styles["section-row"]}>
                                            <span className={styles.label}>
                                              Duration
                                            </span>
                                            <span className={styles.info}>
                                              {programme.duration}
                                            </span>
                                          </p>
                                          <p className={styles["section-row"]}>
                                            <span className={styles.label}>
                                              Location
                                            </span>
                                            <span className={styles.info}>
                                              {programme.locationFormatted}
                                            </span>
                                          </p>
                                          <p className={styles["section-row"]}>
                                            <span className={styles.label}>
                                              Fees
                                            </span>
                                            <span className={styles.info}>
                                              {programme.fees}
                                            </span>
                                          </p>
                                          <span
                                            className={`${styles["section-row"]} rich-text`}
                                          >
                                            <span className={styles.label}>
                                              Delivery
                                            </span>
                                            <span
                                              className={clsx(
                                                styles.info,
                                                styles["delivery-text"]
                                              )}
                                            >
                                              {convertToBulletedList(
                                                programme.delivery
                                              )}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }
              return false;
            })}
          {isLoading ? (
            <div className={styles.centered}>
              <ClipLoader
                color="#001e62"
                size={70}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
              <p>Loading...</p>
            </div>
          ) : (
            Object.keys(filteredResults).length === 0 && (
              <div className={styles["no-results"]}>No Results available</div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ProgrammeCalendar;
