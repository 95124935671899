import Grid from "@components/Grid/Grid";
import clsx from "clsx";
import AccordionContainer, { AccordionData } from "./AccordionContainer";
import styles from "./C11_VerticalAccordion.module.scss";

interface VerticalAccordionProps {
  accordions: AccordionData[];
  backgroundColor?: "grey" | "white" | "dark-blue";
  isSmall?: boolean;
  customClass?: string;
}

const VerticalAccordion = (props: { content: VerticalAccordionProps }) => {
  const { accordions, backgroundColor, isSmall, customClass } = props.content;

  return (
    <>
      {isSmall ? (
        <AccordionContainer accordionFields={accordions[0].fields} />
      ) : (
        <div className={clsx("outer-wrap", backgroundColor, customClass)}>
          <div className="wrapper">
            {accordions?.length > 0 && (
              <Grid row>
                {accordions.map((item, index) => {
                  return (
                    <Grid
                      column
                      sm={12}
                      md={accordions.length === 2 ? 6 : 12}
                      key={`vertical-accordion-${index}`}
                    >
                      <AccordionContainer accordionFields={item.fields} />
                    </Grid>
                  );
                })}
              </Grid>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default VerticalAccordion;
