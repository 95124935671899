import { memo, useEffect, useRef, useState } from "react";
import { Results } from "../CoveoSearchComponent";
import IconChevron from "../../../public/image/svg/chevron.svg";
import {
  NextContentfulImage,
  NextContentfulLoaderProps,
} from "@components/Image";

interface FacultyProfileResultsTemplateProps {
  result: Results;
  priority?: boolean;
}

const loaderConfig: NextContentfulLoaderProps = {
  fit: "fill",
  f: "faces",
  h: "206",
};

export const FacultyProfileResultsTemplate = (
  props: FacultyProfileResultsTemplateProps
) => {
  const { result, priority = false } = props;
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  const [showEllipsis, setShowEllipsis] = useState(false);
  const textRef = useRef<any>(null);
  const facultyPageTitle = result.title?.split("|");
  const facultyName =
    facultyPageTitle?.length != 0 ? facultyPageTitle[0] : result.title;

  useEffect(() => {
    const containerHeight = textRef?.current.offsetHeight;
    const lineHeight = parseFloat(
      getComputedStyle(textRef?.current).lineHeight
    );
    const maxHeight = lineHeight * 3;

    if (containerHeight > maxHeight) {
      setShowEllipsis(true);
    }
  }, []);

  const isStoryPage = result.raw.contenttypeid == "storyPage" ? true : false;

  const imageOnly = result?.raw?.profilephotosmallurl?.split("?")[0];
  return (
    <>
      <div className="component results">
        <a
          className={`imageLink desktop no-top${
            imageOnly && imageOnly !== "undefined" ? "" : " placeholder"
          }`}
          href={result.clickUri}
        >
          {imageOnly && imageOnly !== "undefined" && (
            <NextContentfulImage
              src={imageOnly || ""}
              alt={result.raw.profilephotosmallalt || ""}
              width={274}
              height={206}
              {...(priority ? { priority: true, loading: "eager" } : {})}
              loaderConfig={loaderConfig}
            />
          )}
        </a>

        <div className="details">
          <h2>
            <a href={result.clickUri}>{facultyName}</a>
          </h2>

          <div className="info-container">
            <a className="imageLink mobile-only" href={result.clickUri}>
              {imageOnly !== "undefined" && (
                <NextContentfulImage
                  src={imageOnly || ""}
                  alt={result.raw.profilephotosmallalt || ""}
                  width={274}
                  height={206}
                  {...(priority ? { priority: true, loading: "eager" } : {})}
                  loaderConfig={loaderConfig}
                />
              )}
            </a>

            <div className="info">
              {result.raw.profilenationality && (
                <p className="text-with-icon">
                  <span className="icon">
                    <svg
                      width="20"
                      height="20"
                      viewBox="2 2 20 20"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      focusable="false"
                    >
                      <title>Global</title>
                      <path d="M3.782 9.442A8.602 8.602 0 003.395 12c0 .89.136 1.75.387 2.558H7.27c-.1-.818-.154-1.675-.154-2.558 0-.883.053-1.74.154-2.558H3.782zm.573-1.395h3.14c.334-1.658.878-3.088 1.579-4.141a8.633 8.633 0 00-4.719 4.14zm15.863 1.395H16.73c.1.818.154 1.675.154 2.558 0 .883-.053 1.74-.154 2.558h3.488A8.602 8.602 0 0020.605 12c0-.89-.136-1.75-.387-2.558zm-.573-1.395a8.633 8.633 0 00-4.719-4.141c.701 1.053 1.245 2.483 1.58 4.14h3.139zM8.679 9.442A19.293 19.293 0 008.512 12c0 .893.06 1.752.167 2.558h6.642c.108-.806.167-1.665.167-2.558 0-.893-.06-1.752-.167-2.558H8.679zm.246-1.395h6.15C14.46 5.27 13.245 3.395 12 3.395S9.54 5.271 8.925 8.047zm-4.57 7.906a8.633 8.633 0 004.719 4.141c-.701-1.053-1.245-2.483-1.58-4.14H4.355zm15.29 0h-3.14c-.334 1.658-.878 3.088-1.579 4.141a8.633 8.633 0 004.719-4.14zm-10.72 0c.615 2.776 1.83 4.652 3.075 4.652s2.46-1.876 3.075-4.652h-6.15zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"></path>
                    </svg>
                  </span>
                  {result.raw.profilenationality}
                </p>
              )}

              {result.raw.profileprogramme && (
                <p className="text-with-icon">
                  <span className="icon">
                    <svg
                      width="22"
                      height="17"
                      viewBox="1 3 21.7 16.3"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      focusable="false"
                    >
                      <title>Degree</title>
                      <path d="M20 10.438l-1.5.664v3.925a.75.75 0 01-.042.248c-.941 2.68-3.237 4.03-6.708 4.03-3.47 0-5.767-1.35-6.708-4.03A.75.75 0 015 15.027v-3.984l-3.56-1.62a.75.75 0 01-.02-1.355l10.16-4.991a.75.75 0 01.664.001l10.068 4.991a.75.75 0 01-.03 1.358l-.782.347v5.323H20v-4.66zm-3 1.328l-4.966 2.2a.75.75 0 01-.614-.003L6.5 11.725v3.17c.735 1.947 2.428 2.91 5.25 2.91s4.515-.963 5.25-2.91v-3.129zM3.504 8.715l8.23 3.743 8.48-3.755-8.304-4.117-8.406 4.129z"></path>
                    </svg>
                  </span>
                  {result.raw.profileprogramme}
                </p>
              )}

              {result.raw.profilepositiontitle && (
                <p className="text-with-icon">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      focusable="false"
                    >
                      <title>job</title>
                      <path d="M19 7.5h-1v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2v-9a2 2 0 00-2-2zm-11-2h8v2H8zm11 13H5v-9h14z"></path>
                    </svg>
                  </span>
                  {result.raw.profilepositiontitle}
                </p>
              )}
            </div>
          </div>

          <div
            className="summary-text-container"
            style={{
              top: show ? "0" : "unset",
              height: show ? "auto" : "11rem",
            }}
            tabIndex={0}
          >
            <p
              className="summary-text"
              ref={textRef}
              style={{ display: !show ? "-webkit-box" : "block" }}
            >
              {result.raw.pagedescription} {showEllipsis && show && "..."}
            </p>
            <IconChevron
              onClick={toggleShow}
              style={{
                zIndex: 9,
                position: "absolute",
                right: "8px",
                top: "8px",
                transform: !show ? "rotate(90deg)" : "rotate(270deg)",
              }}
            ></IconChevron>
          </div>
        </div>

        <div className="bottom-cta">
          <a href={result.clickUri} className="cta tertiary light">
            <span className="icon-arrow">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                focusable="false"
              >
                <title>arrow</title>
                <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z"></path>
              </svg>
            </span>
            {isStoryPage && isStoryPage == true ? (
              <span>View story</span>
            ) : (
              <span>View profile</span>
            )}
          </a>
        </div>
      </div>
    </>
  );
};

export default memo(FacultyProfileResultsTemplate);
