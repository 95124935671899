import { useContext, useEffect } from "react";
import clsx from "clsx";
import { NavigationStateContext } from "../../context/navigationContext";
import { NavLinkPanel } from "@customTypes/megaNav";
import PanelLinkList from "./PanelLinkList";
import PromoItemAside from "./PromoItemAside";
import styles from "./MegaNav.module.scss";

export const MegaNavTabPanel = ({
  menuActive,
  linkList,
}: {
  menuActive: boolean;
  linkList: NavLinkPanel;
}) => {
  const mainMenuLink = linkList?.fields;
  const { mainNavSelected, setNavHeight, handleReset } = useContext(
    NavigationStateContext
  );

  useEffect(() => {
    let openPanel;
    if (mainNavSelected && menuActive) {
      openPanel = document.querySelector(
        `.${styles.panel}.${styles.secondary}.${styles.open}`
      );
    }

    if (openPanel) {
      setNavHeight(openPanel.clientHeight);
    }
  }, [mainNavSelected]);

  return (
    <>
      <div className={clsx(styles.panels, styles.secondary)}>
        <nav
          className={clsx(
            styles.panel,
            styles.secondary,
            mainNavSelected && menuActive && styles.open
          )}
        >
          <div className={styles.listing}>
            {mainMenuLink.column1 && (
              <PanelLinkList
                linkList={mainMenuLink.column1}
                menuActive={menuActive}
                handleReset={handleReset}
              />
            )}
            {mainMenuLink.column2 && (
              <PanelLinkList
                linkList={mainMenuLink.column2}
                menuActive={menuActive}
                handleReset={handleReset}
              />
            )}
            {mainMenuLink.column3 && (
              <PanelLinkList
                linkList={mainMenuLink.column3}
                menuActive={menuActive}
                handleReset={handleReset}
              />
            )}
          </div>
        </nav>
      </div>
      <PromoItemAside mainMenuLink={mainMenuLink} />
      <div className={clsx(styles.bottom, styles.desktop)}>
        <p>{mainMenuLink.description || ""}</p>
      </div>
    </>
  );
};
