import Grid from "@components/Grid/Grid";

import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import clsx from "clsx";
import Link from "@components/Link/Link";
import {
  NextContentfulImage,
  NextContentfulLoaderProps,
} from "@components/Image";
import styles from "./DPOTestimonial.module.scss";

interface DPOTestimonialProps {
  title?: string;
  description?: string;
  testimonee?: any;
  sideText?: string;
  profilelinktext?: string;
}

const loaderConfig: NextContentfulLoaderProps = {
  fit: "thumb",
  f: "faces",
  h: 200,
};

const DPOTestimonial = (props: { content: DPOTestimonialProps }) => {
  const { content } = props;
  const { title, description, testimonee, sideText, profilelinktext } = content;
  let name, picture, link;

  if (testimonee.sys.contentType.sys.id === "studentProfileDetail") {
    name = [
      testimonee.fields.profileFirstname,
      testimonee.fields.profileSurname,
    ].join(" ");
    link = testimonee.fields.slug;
    picture = testimonee.fields.profilePhoto.fields.file.url;
  } else if (testimonee.sys.contentType.sys.id === "facultyProfileDetail") {
    name = testimonee.fields.facultyProfileData.fields.profileName;
    link = testimonee.fields.slug;
    picture = testimonee.fields.profilePhoto.fields.file.url;
  } else if (testimonee.sys.contentType.sys.id === "contributorProfileDetail") {
    name = [
      testimonee.fields.profileFirstname,
      testimonee.fields.profileSurname,
    ].join(" ");
    link = testimonee.fields.slug;
    picture = testimonee.fields.profilePhoto?.fields.file.url;
  }

  return (
    <div className="wrapper">
      <div
        className={clsx(
          "component",
          "dpo-testimonials",
          styles["dpo-testimonials"]
        )}
      >
        <Grid row>
          <Grid column sm={12} md={8}>
            <h2
              className={clsx("h2", "section-title", styles["section-title"])}
            >
              {title}
            </h2>
          </Grid>
        </Grid>
        <div className={clsx("list-container", styles["list-container"])}>
          <Grid row>
            <Grid column sm={12} md={3} customClass={styles["base2"]}>
              <NextContentfulImage
                alt=""
                src={picture}
                width={200}
                height={200}
                loaderConfig={loaderConfig}
              />
            </Grid>
            <Grid column sm={12} md={7} customClass={styles["base8"]}>
              <div className={styles["text"]}>
                <h3>{[name, sideText].filter(Boolean).join(" ")}</h3>
                <RichTextDisplay
                  richText={null}
                  tinyMceRichText={description}
                />
                {link && (
                  <p>
                    <Link href={link} passHref={true}>
                      <a className={styles.cta}>
                        <span style={{ backgroundImage: "none" }}>
                          <svg
                            viewBox="2 4 18 16"
                            width="16"
                            height="16"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            focusable="false"
                          >
                            <path d="M12 4l-1.41 1.41L16.17 11H2v2h14.17l-5.58 5.59L12 20l8-8z"></path>
                          </svg>
                        </span>
                        <span>{profilelinktext || "View profile"}</span>
                      </a>
                    </Link>
                  </p>
                )}
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default DPOTestimonial;
