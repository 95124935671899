import Cta from "@components/Cta/Cta";
import { GeneralLink } from "@customTypes/generalLink";
import styles from "./C270_CtaContainer.module.scss";

declare global {
  interface Window {
    CoveoForSitecore: any;
    Coveo: any;
  }
}

interface CtaLink {
  fields: {
    description?: string;
    cta: GeneralLink;
    isSecondaryCta: boolean;
  };
}

interface Cta {
  description?: string;
  cta: GeneralLink;
  isSecondaryCta: boolean;
}

export interface CtaContainerLinkProps {
  content: Cta;
  isBlue: boolean;
  ctaContent: CtaLink;
}

export const GatedCtaContainerLink = (props: CtaContainerLinkProps) => {
  const { content, ctaContent, isBlue } = props;

  let ctaData = content || ctaContent?.fields;

  const url =
    typeof window !== "undefined"
      ? `${window?.location?.pathname}?article=${ctaData.cta.fields.url}#popupGate`
      : "/";

  return (
    <div className={styles["cta-content"]}>
      {ctaData?.description && <p>{ctaData?.description}</p>}
      <div className={styles["button-wrapper"]}>
        <Cta
          type={ctaData?.isSecondaryCta ? "secondary" : "primary"}
          {...ctaData?.cta?.fields}
          light={isBlue}
          url={url}
        />
      </div>
    </div>
  );
};

export default GatedCtaContainerLink;
