import clsx from "clsx";
import styles from "./C03_AnchorHeading.module.scss";

export interface AnchorHeadingProps {
  mainTitle: string;
  description: string;
  pageTitle: string;
  anchorKey: string;
}

export const AnchorHeading = (props: { content: AnchorHeadingProps }) => {
  const { content } = props;
  const { mainTitle, description, pageTitle, anchorKey } = content;
  const cleanAnchor = anchorKey?.replace(/\s+/g, "");

  return (
    <div className={clsx("component", "wrapper", styles["subnav-header"])}>
      {!mainTitle && !description ? (
        <h2 id={cleanAnchor}>{pageTitle}</h2>
      ) : (
        <>
          <div className={styles["top-heading"]}>
            <h2 id={cleanAnchor}>{pageTitle}</h2>
          </div>
          <div className={styles["title-with-intro"]}>
            <div className={styles["left-section"]}>
              <h3>{mainTitle}</h3>
            </div>
            <div className={styles["right-section"]}>
              <p>{description}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AnchorHeading;
