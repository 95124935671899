import React, { memo } from "react";
import dynamic from "next/dynamic";
import { VideoPlayerProps } from "./VideoPlayer.interfaces";

const ReactPlayer = dynamic(() => import("react-player/lazy"), { ssr: false });

function VideoPlayer(props: VideoPlayerProps) {
  return (
    <ReactPlayer
      pip={false}
      playsinline
      width="100%"
      height="100%"
      className="chromatic-ignore"
      {...props}
    />
  );
}

export default memo(VideoPlayer);
