import { useInView } from "react-hook-inview";
import clsx from "clsx";
import AnimatedNumber, { AnimatedNumbersData } from "./C245_AnimatedNumber";
import styles from "./C245_AnimatedNumbers.module.scss";

interface AnimatedNumbersProps extends AnimatedNumbersData {}

export const AnimatedNumbers = (props: { content: AnimatedNumbersProps }) => {
  const { content } = props;
  const { animatedNumbers } = content;
  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });
  9853;
  return (
    <div
      ref={ref}
      className={clsx(
        "component",
        styles["animated-numbers"],
        animatedNumbers.length === 1 && styles.solo
      )}
    >
      <div className="wrapper">
        {animatedNumbers.map((animatedNumber, index) => {
          return (
            <AnimatedNumber
              key={`${animatedNumber.heading}${index}`}
              isVisible={isVisible}
              {...animatedNumber}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AnimatedNumbers;
