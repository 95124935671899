import { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";

import Components from "@components/__Components__/Components";
import { StoryComponentType } from "@customTypes/StoryComponentType";
import { useDevice } from "@utilities/react/get-device/get-device";
import { StoryContext } from "./C253_StoriesProvider";

import styles from "./C253_StoriesContainer.module.scss";

export interface StoriesContainerProps {
  bodyContent?: any;
  children?: any; // Used for Storybook
}

export const StoriesContainer = (props: { content: StoriesContainerProps }) => {
  const { content } = props;
  const { bodyContent, children } = content;
  const device = useDevice();
  const isDesktop = ["desktop", "desktop-large"].includes(device);
  const isMobile = device === "mobile";

  const navRef: any = useRef();

  const [initalised, setInitialised] = useState(false);

  const {
    updateNavigation,
    setLineHeights,
    activeIndex,
    darkNav,
    lineHeights,
  } = useContext(StoryContext);

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      if (navRef?.current?.getBoundingClientRect().top <= 1) {
        setInitialised(true);
      } else if (navRef?.current?.getBoundingClientRect().top > 5) {
        setInitialised(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isMobile) {
      setInitialised(true);
    }
  }, [isMobile]);

  const navScroll = (e, index) => {
    e.preventDefault();
    const storySection = navRef.current.querySelectorAll("section")[index];

    updateNavigation(
      index,
      storySection?.getAttribute("data-bg") === "light" ? true : false
    );

    window.scroll({
      top: storySection.getBoundingClientRect().top + window.scrollY,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (activeIndex > 0) {
      const storySection =
        navRef.current.querySelectorAll("section")[activeIndex];

      const margin = isDesktop ? 70 : 25;
      const nextSectionY = storySection.getBoundingClientRect().top;
      const newState = [...lineHeights];
      newState[activeIndex - 1] = nextSectionY + margin;
      setLineHeights([...newState]);
    }
  }, [activeIndex]);

  const listItems = bodyContent
    ?.filter((component) => {
      const type = component?.sys?.contentType?.sys?.id;
      if (Object.values(StoryComponentType).includes(type)) {
        return component;
      }
    })
    .map((item, index) => {
      const title = item?.fields?.h3Text;

      return (
        <li
          key={`story-item-${index}`}
          className={clsx(index === activeIndex && styles.active)}
        >
          <a
            href="#"
            aria-label={title ? title : ""}
            onClick={(e) => navScroll(e, index)}
          >
            <div className={styles.title}>{title && title}</div>
          </a>
        </li>
      );
    });

  function RenderStoryComponents(components) {
    if (!components) {
      return null;
    }

    let index = -1;

    return components.map((block: any) => {
      const component = {
        component: block?.sys?.contentType?.sys?.id,
        uid: block.sys.id,
        ...block.fields,
      };

      index = Object.values(StoryComponentType).includes(component.component)
        ? index + 1
        : index;

      return Components(component, block.fields, null, index);
    });
  }

  return (
    <div
      className={clsx(
        "component",
        "stories",
        styles.stories,
        initalised && styles.initialized
      )}
      ref={navRef}
    >
      {bodyContent ? (
        <>
          <nav className={clsx(darkNav && styles.blue)}>
            <div className={styles.pages}>
              <span className={styles.page}>
                {(activeIndex + 1).toString().padStart(2, "0")}
              </span>{" "}
              <strong></strong>{" "}
              <span className={styles.max}>
                {listItems?.length.toString().padStart(2, "0")}
              </span>
            </div>
            <div className={styles.dots}>
              <ul>{listItems}</ul>
            </div>
          </nav>
          {RenderStoryComponents(bodyContent)}
        </>
      ) : (
        <>{children}</>
      )}
    </div>
  );
};

export default StoriesContainer;
