import { useState } from "react";
import styles from "./C285_SocialShare.module.scss";
import { useSettings } from "@utilities/context/settings";
import ShareSvg from "../../public/image/svg/share.svg";
import { NextContentfulImage } from "@components/Image";

export const SocialShare = ({ url }) => {
  const [open, setOpen] = useState(false);

  const { siteSettings } = useSettings();

  const replace = (shareLink) => {
    shareLink = shareLink.replace("%URL%", encodeURIComponent(url));
    return shareLink;
  };

  return (
    <div className={`social-share ${open && "show"}`}>
      <a
        href="#"
        className="share-toggle"
        aria-label="Share content"
        onClick={(e) => {
          e.preventDefault();
          setOpen(!open);
        }}
      >
        <span className="visually-hidden">Share</span>
        <i className="icon-share" data-grunticon-embed="data-grunticon-embed">
          <ShareSvg />
        </i>
      </a>
      <ul className="share-links">
        {siteSettings?.globalSocialShareCollection.map(
          ({ fields: { name, darkIcon, shareLink } }, index) => {
            const icon = darkIcon.fields;
            return (
              <li key={`${name}${index}`}>
                <a
                  aria-label={`${name} - Opens a new window`}
                  href={replace(shareLink)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <NextContentfulImage
                    src={icon.file.url}
                    alt={name}
                    width={30}
                    height={30}
                  />
                </a>
              </li>
            );
          }
        )}
      </ul>
    </div>
  );
};

export default SocialShare;
