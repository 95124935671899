import { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import { useRouter } from "next/router";

import { getTitles, postCreateProfile } from "../../services/forms";
import Grid from "@components/Grid/Grid";
import { UserContext } from "../../context/user";
import FormField from "@components/Form/FormFields/FormFields";
import FormPrivacySection from "@components/FormPrivacySection/FormPrivacySection";
import { useSettings } from "@utilities/context/settings";
import { setCookie } from "@utilities/cookies";
import LinkedIn from "@components/LinkedIn/LinkedIn";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import { handleSubmitFormErrors } from "@utilities/handleSubmitFormErrors";
import { LOGIN_URL } from "../../constants";
import setGADataLayer, {
  dataLayerEventKey,
  dataLayerStatusKey,
} from "@utilities/setGADataLayer";
import { ProfileDetailsProps } from "./interfaces";

import styles from "./RegistrationForm.module.scss";
import { getBusinessUnit } from "@utilities/getBusinessUnit";
import { SiteSettings } from "@customTypes/SiteSettings";

export interface ProgrammeType {
  programmeTitle: string;
}

let firstInteract = 0;

export const RegistrationForm = (props: { content: ProfileDetailsProps }) => {
  const { apiErrorMessages, siteSettings } = useSettings();
  const { privacyAndPolicyEmbagTextItems } = siteSettings as SiteSettings;

  const router = useRouter();

  const { content } = props;
  const {
    registrationFormTitleText,
    introText,
    emailLabelText,
    titleLabelText,
    firstNameLabelText,
    lastNameLabelText,
    passwordLabelText,
    passwordHintText,
    confirmPasswordLabelText,
    failedMessage,
    successPageUrl,
  } = content;

  const [program, setProgram] = useState<"regular" | "embag">("regular");
  const [submittedUserEmail, setSubmittedUserEmail] = useState<string>("");
  const [titles, setTitles] = useState<any>(null);
  const [handledSubmitFormErrors, setHandledSubmitFormErrors] =
    useState<Array<string> | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  const { register, handleSubmit, formState, watch } = useForm({
    mode: "onBlur",
  });

  const [loggedInUser, setLoggedInUser] = useState<any>(null);

  useEffect(() => {
    if (user) {
      setLoggedInUser(true);
      router.push("/my-profile");
    } else {
      setLoggedInUser(null);
    }
  }, [user]);

  if (loggedInUser) {
    router.push("/my-profile");
  }

  useEffect(() => {
    if (router?.query?.slug && router?.query?.slug[0] === "embag") {
      setProgram(router?.query?.slug[0]);
    } else {
      setProgram("regular");
    }

    const registrationFormData = async () => {
      try {
        const titles = await getTitles();
        setTitles(titles);
      } catch (e) {
        setError(true);
      }
    };

    registrationFormData();
  }, []);

  useEffect(() => {
    if (formState.submitCount > 0) {
      //croud registration attempt
      setGADataLayer({
        data: {
          status: dataLayerStatusKey.SUBMIT_ATTEMPT,
        },
        _clear: true,
        event: dataLayerEventKey.REGISTRATION,
      });

      //failed attempt
      const errorList = Object.keys(formState.errors)
        .map((key) => key)
        ?.join(",");
      if (errorList) {
        //set dataLayer
        setGADataLayer({
          data: {
            status: dataLayerStatusKey.SUBMIT_FAIL,
            form_field: errorList,
          },
          _clear: true,
          event: dataLayerEventKey.REGISTRATION,
        });
      }
    }
  }, [formState.submitCount]);

  const FormFields = [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
    },
    {
      propertyName: "title",
      validation: {
        isRequired: true,
      },
      labelText: titleLabelText,
      options: titles || [{ value: "Mr" }, { value: "Miss" }],
      formType: "select",
      dataText: titles && titles[0]?.value,
    },
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabelText,
    },
    {
      propertyName: "password",
      validation: {
        isRequired: true,
        maxLength: 30,
        password: true,
      },
      type: "password",
      hintText: passwordHintText,
      labelText: passwordLabelText,
    },
    {
      propertyName: "confirmPassword",
      validation: {
        isRequired: true,
        maxLength: 30,
        confirmPassword: true,
      },
      disabled: !watch("password"),
      type: "password",
      labelText: confirmPasswordLabelText,
    },
  ];

  const formId = registrationFormTitleText?.replace(/\s+/g, "-");

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "createAProfile",
        });
        firstInteract++;
        return () => subscription.unsubscribe();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    setLoading(true);

    const titleId = titles?.find(({ value }) => value === data.title);
    let callbackUrl = document.referrer;

    if (router.query.applyID) {
      callbackUrl = `${process.env.NEXT_PUBLIC_URL}/interact?applyID=${router.query.applyID}`;
    }

    if (router.query.returnUrl) {
      callbackUrl = `${process.env.NEXT_PUBLIC_URL}/${router.query.returnUrl}`;
    }

    const formData = {
      ...data,
      title: titleId.id,
      callbackUrl,
    };

    formData.isStayInformed = formData.isStayInformed === "true";
    setSubmittedUserEmail(formData.emailAddress);
    const response = await postCreateProfile(
      formData,
      sessionStorage.getItem("crm_campaign")
    );

    if (response.status) setLoading(false);

    if (response.status === 201) {
      setCookie({
        cookieName: "registration_completed",
        value: true,
        expiryDays: 999999,
      });

      setGADataLayer({
        event: "formComplete",
        formName: "createAProfile",
        webinar: callbackUrl,
        businessUnit: getBusinessUnit(),
        programmeCode: "",
        eventType: "",
      });
      router.push(successPageUrl);
    } else {
      const handledSubmitFormErrors = handleSubmitFormErrors(
        response,
        apiErrorMessages
      );
      window.scrollTo(0, 0);
      setHandledSubmitFormErrors(handledSubmitFormErrors);

      const errorList = handledSubmitFormErrors?.map((key) => key)?.join(",");
      if (errorList) {
        //set dataLayer
        setGADataLayer({
          data: {
            status: dataLayerStatusKey.SUBMIT_FAIL,
            form_field: errorList,
          },
          _clear: true,
          event: dataLayerEventKey.REGISTRATION,
        });
      }
    }
  };

  if (loggedInUser) return;

  return (
    <div className={clsx(styles["edit-details"], "wrapper")}>
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className={clsx(styles.component, "component", styles.form)}>
            <h1 className="h2">{registrationFormTitleText}</h1>
          </div>
          {error && (
            <FormErrorMessage
              failedRichText={failedMessage?.fields?.content}
              failedTinyMceRichText={failedMessage?.fields?.contentTinyMce}
            />
          )}
          {handledSubmitFormErrors &&
            handledSubmitFormErrors.map((error, index) => (
              <FormErrorMessage
                key={index}
                text={error}
                userEmail={submittedUserEmail}
              />
            ))}
          <section
            className={clsx(styles.component, styles.form, "form", styles.cf)}
          >
            <form
              id={formId || "mainform"}
              className="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className={styles["fields-floated"]}>
                <>
                  <Grid row>
                    <Grid column sm={12}>
                      <p>{introText}</p>
                      <br />
                      <br />
                      {!loggedInUser && (
                        <p>
                          Already registered?{" "}
                          <a href={LOGIN_URL} className="underline">
                            Log in here
                          </a>
                        </p>
                      )}
                    </Grid>
                  </Grid>

                  <LinkedIn formId={formId || "mainform"} />

                  <section className={styles["form-group-wrapper"]}>
                    <div className={styles["form-row"]}></div>
                    {FormFields.map((field: any, index) => {
                      return (
                        <FormField
                          formType={field?.formType}
                          watch={watch}
                          validation={field?.validation}
                          type={field.type || "text"}
                          register={register}
                          property={field.dataText}
                          key={`${field.labelText}${index}`}
                          hintText={field?.hintText}
                          placeholder={field.labelText}
                          options={field.options}
                          name={field?.propertyName}
                          errors={formState.errors}
                          disabled={field?.disabled}
                        />
                      );
                    })}
                  </section>

                  <FormPrivacySection
                    register={register}
                    formState={formState}
                    policyType={program}
                    embagPrivacyInformRadioYes={String(
                      privacyAndPolicyEmbagTextItems?.stayInformRadioYes
                    )}
                    embagPrivacyInformRadioNo={String(
                      privacyAndPolicyEmbagTextItems?.stayInformRadioNo
                    )}
                  />

                  <div className="btn-and-text-wrapper">
                    <SubmitButton
                      loading={loading}
                      id="submitButton"
                      text="Submit"
                    />
                  </div>
                </>
              </div>
            </form>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegistrationForm;
