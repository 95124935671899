import clsx from "clsx";
import { FC, Fragment } from "react";
import Swiper from "swiper/react";
import IconArrow from "../../public/image/svg/arrow.svg";
import { useEffect, useState } from "react";
import styles from "./Swiper.module.scss";

interface SwiperNavigationProps {
  swiper?: Swiper;
  perPage: number;
  direction: "next" | "prev";
  variation: "fraction" | "number" | "arrow" | "square";
  hideOnEdge?: boolean;
  onDark?: boolean;
  currentPage: number;
  className?: string;
}

const SwiperNavigation: FC<SwiperNavigationProps> = ({
  swiper,
  perPage,
  direction = "next",
  variation = "arrow",
  hideOnEdge = false,
  onDark = false,
  className,
  currentPage,
}) => {
  if (!swiper) return null;

  const totalPages = swiper?.pagination?.bullets?.length;
  const pageToNavigateTo = currentPage + 1 * (direction === "next" ? 1 : -1);

  const pad = (num: number): string => {
    return num?.toString()?.padStart(2, "0");
  };

  if (
    hideOnEdge &&
    ((direction === "prev" && pageToNavigateTo < 1) ||
      (direction === "next" && pageToNavigateTo > totalPages))
  )
    return null;

  return (
    <div
      className={clsx(
        styles["swiper-navigation"],
        styles[`swiper-navigation--${direction}`],
        styles[`swiper-navigation--${variation}`],
        className,
        {
          [styles["swiper-navigation--light"]]: onDark,
        }
      )}
      tabIndex={0}
      role="button"
      onClick={() => {
        //@ts-ignore
        direction === "prev" ? swiper.slidePrev() : swiper.slideNext();
      }}
    >
      {["fraction", "number"].includes(variation) && (
        <div className={styles["swiper-navigation__numbers"]}>
          {pad(currentPage)}
          {variation === "fraction" && (
            <Fragment>
              <strong>/</strong>
              {pad(totalPages)}
            </Fragment>
          )}
        </div>
      )}
      <IconArrow className={styles["swiper-navigation__arrow"]} />
    </div>
  );
};

export default SwiperNavigation;
