import styles from "./C283_DonateTool.module.scss";
import clsx from "clsx";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { TaxResidentCountry } from "./C283_DonateTool";

export interface DonateDetailsProps {
  formattedCountries: TaxResidentCountry[];
  taxResident: string;
  journeyStep: number;
}

export const DonateDetails = (props: { content: DonateDetailsProps }) => {
  const { content } = props;

  const { formattedCountries, taxResident, journeyStep } = content;

  return (
    <>
      <div
        className={styles["donate-details"]}
        aria-hidden="true"
        data-default-info-panel
      >
        <p>Default Information Panel</p>
      </div>
      {formattedCountries.map((country) => {
        if (taxResident && journeyStep === 2 && country.displayDonateDetails) {
          return (
            <div
              className={clsx(
                styles["donate-details"],
                country?.countryName === taxResident && styles["display-panel"]
              )}
              aria-hidden="true"
              data-country-info-panel={country?.countryName}
              key={`donate-details-${country.countryName}`}
            >
              <RichTextDisplay
                richText={country?.donateDetailsIntro}
                tinyMceRichText={country?.donateDetailsIntroTinyMce}
              />
              {country?.donateDetailsAddressTitle && (
                <>
                  {country.donateDetailsAddressTitle}
                  <br />
                </>
              )}
              {country?.donateDetailsAddressLine1 && (
                <>
                  {country.donateDetailsAddressLine1}
                  <br />
                </>
              )}
              {country?.donateDetailsAddressLine2 && (
                <>
                  {country.donateDetailsAddressLine2}
                  <br />
                </>
              )}
              {country?.donateDetailsTelephone && (
                <>
                  Tel. : {country.donateDetailsTelephone}
                  <br />
                </>
              )}
              {country?.donateDetailsFax && (
                <>
                  Fax: {country.donateDetailsFax}
                  <br />
                </>
              )}
              {country?.donateDetailsEmail && (
                <>
                  Email: {country.donateDetailsEmail}
                  <br />
                </>
              )}
              {country?.donateDetailsWebsite && (
                <>
                  Website:{" "}
                  <a
                    href={country.donateDetailsWebsite}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {country.donateDetailsWebsite}
                  </a>
                  <br />
                </>
              )}
            </div>
          );
        }
      })}
    </>
  );
};

export default DonateDetails;
