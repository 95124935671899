import clsx from "clsx";
import { NavigationStateContext } from "../../context/navigationContext";
import { useContext, useEffect, useRef } from "react";
import { NavLinkList, NavLinkPanel, NavLinkType } from "../../types/megaNav";
import styles from "./MegaNav.module.scss";
import { MegaNavTabList } from "./MegaNavTabList";
import { MegaNavTabPanel } from "./MegaNavTabPanel";

export const MegaNav = ({
  megaNav,
}: {
  megaNav: (NavLinkList | NavLinkPanel | any)[];
}) => {
  const navWrapperRef = useRef<HTMLDivElement | null>(null);
  const {
    mainNavSelected,
    mainNavSelectedIndex,
    tabSelected,
    tabSelectedIndex,
    primaryNavSelectedIndex,
    secondaryNavSelected,
    secondaryNavSelectedIndex,
    handleEnter,
    handleLeave,
    navHeight,
    setNavHeight,
  } = useContext(NavigationStateContext);

  useEffect(() => {
    const openPanel =
      document.querySelector(`.${styles.open} .${styles.panel}.${styles.open}`)
        ?.clientHeight || 0;
    const openTab =
      document.querySelector(`.${styles.open} .${styles.tabs}`)?.clientHeight ||
      0;
    const openAside =
      document.querySelector(`.${styles.open} aside`)?.clientHeight || 0;

    const getPanelHeight = () => {
      return openPanel;
    };

    const height = Math.max(getPanelHeight(), openTab, openAside);

    setNavHeight(height);
  }, [
    mainNavSelected,
    mainNavSelectedIndex,
    tabSelectedIndex,
    primaryNavSelectedIndex,
    secondaryNavSelected,
    secondaryNavSelectedIndex,
  ]);

  useEffect(() => {
    if (navWrapperRef.current) {
      navWrapperRef.current.style.height = navHeight + "px";
    }
  }, [navHeight]);

  return (
    <>
      <div
        ref={navWrapperRef}
        className={clsx(
          "component",
          styles["primary-nav"],
          (mainNavSelected || tabSelected) && styles.show
        )}
      >
        {megaNav.map((mainNavLink, index) => {
          return (
            <nav
              key={index}
              className={clsx(mainNavSelectedIndex === index && styles.open)}
              onMouseEnter={() => handleEnter(index)}
              onMouseLeave={() => handleLeave()}
            >
              {mainNavLink?.sys?.contentType?.sys?.id ===
                NavLinkType.NavLinkList && (
                <MegaNavTabList
                  linkList={mainNavLink as NavLinkList}
                  menuActive={mainNavSelectedIndex === index}
                />
              )}
              {mainNavLink?.sys?.contentType?.sys?.id ===
                NavLinkType.NavLinkPanel && (
                <MegaNavTabPanel
                  linkList={mainNavLink as NavLinkPanel}
                  menuActive={mainNavSelectedIndex === index}
                />
              )}
            </nav>
          );
        })}
      </div>
    </>
  );
};

export default MegaNav;
