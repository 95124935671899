import { CSSProperties } from "react";
import { HeaderBannerType } from "./C46_HeaderBanner.types";

export function getImagePanelStyle(
  bannerType: HeaderBannerType
): CSSProperties {
  switch (bannerType) {
    case "Video Background":
      return { maxWidth: "1094px", maxHeight: "615px" };
    case "Video Overlay":
      return { maxWidth: "inherit", maxHeight: "inherit" };
    case "Image":
      return { maxWidth: "486px", maxHeight: "486px" };
  }
}

export function getImageStyle(bannerType: HeaderBannerType): CSSProperties {
  switch (bannerType) {
    case "Video Background":
      return { maxWidth: "inherit", maxHeight: "inherit", objectFit: "cover" };
    case "Video Overlay":
      return { maxWidth: "inherit", maxHeight: "inherit" };
    case "Image":
      return { maxWidth: "inherit", maxHeight: "inherit" };
  }
}

export function getImageSizes(bannerType: HeaderBannerType) {
  switch (bannerType) {
    case "Video Background":
      return "(max-width: 480px) 45vw, (max-width: 640px) 50vw, (max-width: 768px) 70vw, 100vw";
    case "Video Overlay":
    case "Image":
      return "(max-width: 480px) 45vw, (max-width: 640px) 50vw, (max-width: 768px) 70vw, (max-width: 1024px) 45vw, 100vw";
  }
}

export function getImageSize(bannerType: HeaderBannerType) {
  switch (bannerType) {
    case "Video Background":
      return { height: 615, width: 1094 };
    case "Video Overlay":
      return { height: 604, width: 665 };
    case "Image":
      return { height: 486, width: 486 };
  }
}
