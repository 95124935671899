import { ImageWrapperType } from "@customTypes/imageWrapper";
import clsx from "clsx";
import { NextContentfulImage } from "@components/Image";

import styles from "./C263_ResponsiveImage.module.scss";

type ASPECT_RATIO = "Retain Aspect Ratio" | "Square (1:1)" | "9:5";

export interface ResponsiveImageProps {
  image: ImageWrapperType;
  inSublayout: boolean;
  includeWrapper: boolean;
  mobileAspectRatio: ASPECT_RATIO;
  maintainQuality: boolean;
}

const ResponsiveImage = (props: { content: ResponsiveImageProps }) => {
  const { content } = props;

  const image = content?.image?.fields?.image;

  if (!image) {
    return null;
  }

  const altText = content?.image?.fields?.altText;
  const { includeWrapper, inSublayout } = content;

  return (
    <div
      className={clsx(
        "component",
        styles["responsive-image"],
        includeWrapper && "wrapper"
      )}
    >
      <NextContentfulImage
        alt={altText}
        src={image.fields.file.url}
        height={image.fields.file.details.image.height}
        width={image.fields.file.details.image.width}
        sizes={inSublayout ? "(max-width: 768px) 100vw, 25vw" : "100vw"}
        loading="eager"
        priority
      />
    </div>
  );
};

export default ResponsiveImage;
