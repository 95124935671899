import C11_VerticalAccordion from "@components/C11_VerticalAccordion/C11_VerticalAccordion";
import { C71_EventRegistrationForm } from "@components/C71_EventRegistrationForm/C71_EventRegistrationForm";
import Grid from "@components/Grid/Grid";
import { EventsDetailProps } from "@components/__templates__/EventsDetail/EventsDetail";
import clsx from "clsx";

import IconInformation from "../../public/image/svg/information.svg";
import IconLocation from "../../public/image/svg/location.svg";
import IconProfile from "../../public/image/svg/profile.svg";
import styles from "./C37.5_EventDetail.module.scss";
import ActionsSection from "./components/ActionsSection";
import AttachmentsSection from "./components/AttachmentsSection";
import ContactsSection from "./components/ContactsSection";
import DateTimeSection from "./components/DateTimeSection";
import DescriptionSection from "./components/DescriptionSection";
import EssentialInformationSection from "./components/EssentialInformationSection";
import LinksSection from "./components/LinksSection";
import LocationSection from "./components/LocationSection";
import SpeakersSection from "./components/SpeakersSection";
import ClipLoader from "react-spinners/ClipLoader";
import { useContext, useEffect, useState } from "react";
import { getUserHasBookedEvent } from "../../services/forms";
import { UserContext } from "../../context/user";

export interface EventDetailProps
  extends Omit<EventsDetailProps, "pageTitle" | "slug"> {}

const EventDetail = (props: { content: EventDetailProps }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isBookedEvent, setIsBookedEvent] = useState<boolean>(false);
  const { content } = props;
  const {
    eventDetails,
    eventDetailsTinyMce,
    eventShortDescription,
    eventLongDescription,
    eventStarts,
    eventEnds,
    eventLocationGoogleMapsLink,
    eventLocationName,
    eventWhoIsAimedAtList,
    eventOrganisedByList,
    eventInvitationType,
    eventPriceList,
    eventDressCode,
    eventClosed,
    eventClosedMessage,
    eventSignupUrl,
    eventMainSpeaker,
    eventPrimaryContact,
    eventCode,
  } = content;
  const { user } = useContext(UserContext);

  useEffect(() => {
    checkIsBookedEvent(eventCode);
  }, [user]);

  const checkIsBookedEvent = async (eventCode: string = "") => {
    if (user) {
      setLoading(true);
      const response = await getUserHasBookedEvent(eventCode);
      setIsBookedEvent(response?.result?.result?.result);
      setLoading(false);
    }
  };
  // TODO: Find out where this data comes from;
  const eventDetailsLinks: any[] = [];
  const eventDetailsAttachments: any = [];
  const isCampaignNowFull = false; // viewbag
  const eventNowFullMessage = ""; // viewbag
  const eventBookingUrl = ""; // viewbag

  const renderBookingForm = () => {
    if (loading) {
      return (
        <div className={styles.spinner}>
          <ClipLoader
            color="#001e62"
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      );
    }

    if (isBookedEvent) {
      return (
        <div className={styles["inner-container"]}>
          <p className={styles.offsetTop1}>
            You are already booked on this event.
          </p>
        </div>
      );
    }

    return (
      <div className={styles["inner-container"]}>
        {!eventSignupUrl && <C71_EventRegistrationForm content={content} />}
      </div>
    );
  };

  return (
    <div
      className={clsx(
        "component",
        styles["events-details"],
        !eventSignupUrl ? styles["registration-form"] : null
      )}
    >
      <div className={clsx("wrapper", styles["cu-wrapper"])}>
        <div className={styles["desktop-only"]}>
          <Grid row customClass={clsx(styles["col-12"])}>
            <Grid column sm={12} md={6} customClass={clsx(styles["col-12"])}>
              <DateTimeSection
                eventStarts={eventStarts}
                eventEnds={eventEnds}
              />

              <p className={clsx("label", styles["label"])}>Description:</p>
              <DescriptionSection
                eventDetails={eventDetails}
                eventDetailsTinyMce={eventDetailsTinyMce}
                eventShortDescription={eventShortDescription}
                eventLongDescription={eventLongDescription}
              />
            </Grid>
            <Grid
              column
              sm={12}
              md={6}
              customClass={clsx(styles["two-col"], styles["col-12"])}
            >
              <div className={clsx(styles["unit-section"])}>
                <p className={clsx("label", styles["label"])}>
                  <i className={styles["icon-location"]}>
                    <IconLocation />
                  </i>
                  Address
                </p>
                <LocationSection
                  eventLocationName={eventLocationName}
                  eventLocationGoogleMapsLink={eventLocationGoogleMapsLink}
                />
              </div>
              <div className={clsx(styles["unit-section"])}>
                <p className={clsx("label", styles["label"])}>
                  <i className={styles["icon-information"]}>
                    <IconInformation />
                  </i>
                  Essential information
                </p>
                <EssentialInformationSection
                  eventWhoIsAimedAtList={eventWhoIsAimedAtList}
                  eventOrganisedByList={eventOrganisedByList}
                  eventInvitationType={eventInvitationType}
                  eventPriceList={eventPriceList}
                  eventDressCode={eventDressCode}
                />
              </div>
              <div className={clsx(styles["unit-section"])}>
                {!!eventMainSpeaker && (
                  <>
                    <p className={clsx("label", styles["label"])}>
                      <i className={styles["icon-profile"]}>
                        <IconProfile />
                      </i>
                      Speaker
                    </p>
                    <p>{eventMainSpeaker}</p>
                  </>
                )}
              </div>
              <div className={clsx(styles["unit-section"])}>
                {!!eventPrimaryContact && (
                  <>
                    <p className={clsx("label", styles["label"])}>Contact</p>
                    <ContactsSection contacts={[eventPrimaryContact]} />
                  </>
                )}
                {eventDetailsLinks && eventDetailsLinks.length > 0 && (
                  <>
                    <p className={clsx("label", styles["label"])}>Links</p>
                    <LinksSection links={eventDetailsLinks} />
                  </>
                )}
              </div>
            </Grid>
          </Grid>
          <Grid row customClass={styles["action-bar"]}>
            <Grid column sm={12} md={6}>
              <AttachmentsSection attachments={eventDetailsAttachments} />
            </Grid>
            <Grid column sm={12} md={6} customClass={styles["actions"]}>
              <ActionsSection
                eventClosed={eventClosed}
                eventClosedMessage={eventClosedMessage}
                eventSignupUrl={eventSignupUrl}
                isCampaignNowFull={isCampaignNowFull}
                eventNowFullMessage={eventNowFullMessage}
                eventBookingUrl={eventBookingUrl}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles["mobile-only"]}>
          <Grid row>
            <Grid column sm={12}>
              <div className={styles["summary-content"]}>
                <DateTimeSection
                  eventStarts={eventStarts}
                  eventEnds={eventEnds}
                />
              </div>
              <C11_VerticalAccordion
                content={{
                  backgroundColor: "white",
                  customClass: styles.accordion,
                  accordions: [
                    {
                      fields: {
                        accordionItems: [
                          {
                            fields: {
                              title: "Description",
                              childContent: (
                                <DescriptionSection
                                  eventDetails={eventDetails}
                                  eventDetailsTinyMce={eventDetailsTinyMce}
                                  eventShortDescription={eventShortDescription}
                                  eventLongDescription={eventLongDescription}
                                />
                              ),
                            },
                          },
                          {
                            fields: {
                              title: "Address",
                              childContent: (
                                <LocationSection
                                  eventLocationName={eventLocationName}
                                  eventLocationGoogleMapsLink={
                                    eventLocationGoogleMapsLink
                                  }
                                />
                              ),
                            },
                          },
                          {
                            fields: {
                              title: "Essential Information",
                              childContent: (
                                <EssentialInformationSection
                                  eventWhoIsAimedAtList={eventWhoIsAimedAtList}
                                  eventOrganisedByList={eventOrganisedByList}
                                  eventInvitationType={eventInvitationType}
                                  eventPriceList={eventPriceList}
                                  eventDressCode={eventDressCode}
                                />
                              ),
                            },
                          },
                          ...(eventMainSpeaker
                            ? [
                                {
                                  fields: {
                                    title: "Speaker",
                                    childContent: (
                                      <SpeakersSection
                                        speakers={[eventMainSpeaker]}
                                      />
                                    ),
                                  },
                                },
                              ]
                            : []),
                          ...(!!eventPrimaryContact
                            ? [
                                {
                                  fields: {
                                    title: "Contact",
                                    childContent: (
                                      <ContactsSection
                                        contacts={[eventPrimaryContact]}
                                      />
                                    ),
                                  },
                                },
                              ]
                            : []),
                          ...(eventDetailsLinks && eventDetailsLinks.length > 0
                            ? [
                                {
                                  fields: {
                                    title: "Links",
                                    childContent: (
                                      <LinksSection links={eventDetailsLinks} />
                                    ),
                                  },
                                },
                              ]
                            : []),
                        ],
                        defaultOpen: true,
                      },
                    },
                  ],
                }}
              />
            </Grid>
          </Grid>
          <Grid row customClass={styles["action-bar"]}>
            <Grid column sm={12}>
              <ActionsSection
                eventClosed={eventClosed}
                eventClosedMessage={eventClosedMessage}
                eventSignupUrl={eventSignupUrl}
                isCampaignNowFull={isCampaignNowFull}
                eventNowFullMessage={eventNowFullMessage}
                eventBookingUrl={eventBookingUrl}
              />
              <AttachmentsSection attachments={eventDetailsAttachments} />
            </Grid>
          </Grid>
        </div>
        <div className={styles["form-container"]}>{renderBookingForm()}</div>
      </div>
    </div>
  );
};

export default EventDetail;
