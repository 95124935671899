import { isLinkList } from "@customTypes/megaNav";
import clsx from "clsx";
import { useContext } from "react";
import { NavigationStateContext } from "../../context/navigationContext";
import styles from "./MegaNav.module.scss";
import PrimaryLinkList from "./PrimaryLinkList";
import SecondaryLinkList from "./SecondaryLinkList";

const LinkPanels = ({ mainMenuLink, menuActive }) => {
  const { tabSelectedIndex, primaryNavSelectedIndex, handleReset } = useContext(
    NavigationStateContext
  );

  const renderSecondLinks = (secondaryLinkList) => {
    const SECONDARY_MAX_COUNT = 14;

    let firstColumn = secondaryLinkList?.slice(0, SECONDARY_MAX_COUNT);
    let secondColumn = secondaryLinkList?.slice(SECONDARY_MAX_COUNT);
    if (secondaryLinkList.length === SECONDARY_MAX_COUNT + 1) {
      firstColumn = secondaryLinkList?.slice(0, SECONDARY_MAX_COUNT - 1);
      secondColumn = secondaryLinkList?.slice(SECONDARY_MAX_COUNT - 1);
    }

    return (
      <>
        <div className={styles.listing}>
          <div className={styles["third-level-wrapper"]}>
            <ul>
              <SecondaryLinkList
                secondaryLinkList={firstColumn}
                handleReset={handleReset}
              />
            </ul>
          </div>
        </div>
        {!!secondColumn.length && (
          <div className={styles.listing}>
            <div className={styles["third-level-wrapper"]}>
              <ul>
                <SecondaryLinkList
                  secondaryLinkList={secondColumn}
                  handleReset={handleReset}
                />
              </ul>
            </div>
          </div>
        )}
      </>
    );
  };

  return menuActive ? (
    <div className={clsx(styles.panels, styles.primary)}>
      {mainMenuLink?.linkList?.filter(isLinkList).map((tabLink, index) => {
        const primaryLinkList = tabLink?.fields?.linkList;
        return (
          <div
            key={`link-panel-${index}`}
            className={clsx(
              styles.panel,
              tabSelectedIndex === index && styles.open
            )}
          >
            <nav className={clsx(styles.secondary, styles["second-column"])}>
              <ul className={clsx(styles.watch, styles["cu-second-col"])}>
                <PrimaryLinkList primaryLinkList={primaryLinkList} />
              </ul>

              {primaryLinkList.filter(isLinkList).map((primaryLink, index) => {
                const secondaryLinkList = primaryLink?.fields?.linkList;

                return primaryNavSelectedIndex === index && menuActive ? (
                  <div
                    key={`${primaryLink.url}${index}`}
                    className={clsx(styles.panels, styles.secondary)}
                  >
                    <nav
                      className={clsx(
                        styles.panel,
                        styles.secondary,
                        primaryNavSelectedIndex === index &&
                          menuActive &&
                          styles.open
                      )}
                    >
                      {renderSecondLinks(secondaryLinkList)}
                    </nav>
                  </div>
                ) : null;
              })}
            </nav>
          </div>
        );
      })}
    </div>
  ) : null;
};

export default LinkPanels;
