import clsx from "clsx";
import styles from "./Breadcrumb.module.scss";
import Breadcrumb, { CrumbProps } from "./C45_Breadcrumb";
import SocialShare from "./SocialShare";

export interface BreadcrumbSocialShareWrapperProps {
  slug: string;
  title: string;
  breadcrumbs?: CrumbProps[];
}

export const BreadcrumbSocialShareWrapper = ({
  slug,
  title,
  breadcrumbs,
}: BreadcrumbSocialShareWrapperProps) => {
  return (
    <div
      className={clsx("component", styles.socialshare, styles.blue, "flush")}
    >
      <div className={clsx("wrapper", styles.wrapper)}>
        <Breadcrumb slug={slug} title={title} breadcrumbs={breadcrumbs} />
        <span></span>
        <SocialShare />
      </div>
    </div>
  );
};

export default BreadcrumbSocialShareWrapper;
