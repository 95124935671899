import { Results } from "../CoveoSearchComponent";
import { NextContentfulImage } from "@components/Image";
import Cta from "@components/Cta/Cta";
import Lightbox, { LightboxRefProps } from "@components/Lightbox/Lightbox";
import { useEffect, useRef, useState } from "react";
import FacultyProfileSolo from "../ResearchAndFacultyResultsTemplate/ResearchAndFacultyProfileSolo";
import { fetchApi } from "@utilities/fetchApi";
import videoIcon from "../../../public/assets2019/img/vidicon.png";

interface ResearchAndFacultyResultsTemplateProps {
  result: Results;
  priority?: boolean;
}

export const ResearchAndFacultyResultsTemplate = (
  props: ResearchAndFacultyResultsTemplateProps
) => {
  const { result, priority = false } = props;

  const lightboxRef = useRef<LightboxRefProps>();
  const [showLightbox, setShowLightbox] = useState(false);
  const [reserachDetails, setReserachDetails] = useState<any>();

  const closeLightbox = () => {
    setTimeout(() => {
      setShowLightbox(false);
    });
  };

  const extractPathWithoutLeadingSlash = (url) => {
    const regex = /^(?:https?:\/\/)?[^\/]+(\/.*)$/;
    const match = url.match(regex);
    if (match) {
      // Remove the first slash if it exists.
      return match[1].startsWith("/") ? match[1].slice(1) : match[1];
    }
    return "";
  };

  const slug = extractPathWithoutLeadingSlash(result.ClickUri);

  useEffect(() => {
    const getData = async () => {
      const researchDetails = await fetchApi("/api/getResearchDetailsBySlug", {
        slug,
      });
      let data: any = {};

      if (researchDetails.video !== null) {
        researchDetails.video.isAutoPlay = true;
      }

      data["researchTitle"] = researchDetails.researchTitle;
      data["authors"] = researchDetails.authorsListCollection.items;
      data["imageData"] = researchDetails.thumbnailImage;
      data["longDescription"] = researchDetails.longDescription.json;
      data["longDescriptionPartTwo"] =
        researchDetails.longDescriptionPartTwo.json;
      data["relatedResearchLink"] = researchDetails.cta;
      data["videoData"] = researchDetails.video;

      setReserachDetails(data);
    };
    getData();
  }, []);

  const researchPageTitle = result?.raw?.researchtitle;
  const imageUrl = result?.raw?.articleimageurl;

  const description = result.raw?.pagedescription || "";

  return (
    <div className="research-faculty-tile">
      <a
        href={null}
        onClick={() => {
          setShowLightbox(true);
        }}
        className="research-faculty-image-container"
      >
        {imageUrl && (
          <NextContentfulImage
            src={imageUrl}
            alt={researchPageTitle}
            width={400}
            height={250}
            sizes="20vw"
            {...(priority ? { priority: true, loading: "eager" } : {})}
          />
        )}

        {reserachDetails?.videoData && (
          <div className="play-icon">
            <NextContentfulImage
              src={videoIcon}
              alt="play icon"
              width={160}
              height={160}
            />
          </div>
        )}
      </a>

      <div className="research-faculty-content">
        <h4 className="research-faculty-title">
          <a
            onClick={() => {
              setShowLightbox(true);
            }}
            href={null}
          >
            {researchPageTitle}
          </a>
        </h4>

        <p className="research-faculty-description">{description}</p>

        <Cta
          linkText="Find out more"
          type="tertiary"
          url={null}
          icon="icon-arrow"
          onClick={() => {
            setShowLightbox(true);
          }}
        />
      </div>
      {showLightbox && (
        <Lightbox
          ref={lightboxRef}
          onCloseFunction={closeLightbox}
          customInnerClass="faculty-profile-carousel"
        >
          {reserachDetails && (
            <FacultyProfileSolo
              content={reserachDetails}
              lightbox={true}
              onCloseFunction={() => lightboxRef.current.close()}
            />
          )}
        </Lightbox>
      )}
    </div>
  );
};

export default ResearchAndFacultyResultsTemplate;
