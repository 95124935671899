import {
  AtomicBreadbox,
  AtomicDidYouMean,
  AtomicFacetManager,
  AtomicLayoutSection,
  AtomicNoResults,
  AtomicPager,
  AtomicQueryError,
  AtomicQuerySummary,
  AtomicResultsPerPage,
  AtomicSearchBox,
  AtomicSearchBoxQuerySuggestions,
  AtomicSearchInterface,
  AtomicSearchLayout,
  AtomicSortDropdown,
  AtomicSortExpression,
  buildSearchEngine,
  buildResultList,
} from "@coveo/atomic-react";
import {
  SearchEngine,
  buildQuerySummary,
  getOrganizationEndpoints,
} from "@coveo/headless";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useSettings } from "@utilities/context/settings";
import { useDevice } from "@utilities/react/get-device/get-device";
import { useEffect, useState } from "react";
import { CoveStyles } from "./CoveoSeachComponentStyles";
import { CoveoSearchFacet } from "../CoveoSearchFacet/CoveoSearchFacet";
import Grid from "@components/Grid/Grid";
import { useRouter } from "next/router";
import { CoveoDPOSearchResults } from "./CoveoDPOSearchResults/CoveoDPOSearchResults";
import RichTextFullWidth from "@components/C16_RichTextFullWidth/C16_RichTextFullWidth";
import Cta from "@components/Cta/Cta";
import { GeneralLink } from "@customTypes/generalLink";
import { SearchLandingType } from "@customTypes/SearchLandingType";
import { LBSResultList } from "./LBSResultList";
import facetsConfig from "./facetsConfig";
import setGADataLayer, { dataLayerEventKey } from "@utilities/setGADataLayer";

export interface CoveoSearchComponentProps {
  description?: string;
  preFilterTaxonomyFieldName?: string;
  facets?: Facet[];
  preFilterTags?: string[];
  sortOptions?: SortOption[];
  showSearchBar?: boolean;
  pageTypeRestrictions?: PageTypeRestriction[];
  resultsPerPage?: string;
  searchPlaceholder: string;
  redirectUrl: string;
  displayPagination: boolean;
  title: string;
  pageFormat: PageFormats;
  optionalCta?: GeneralLink;
  desktopResultsColumnsNumber: number;
  tags: any;
  searchHubParameter: any;
}

export interface Raw {
  eventsyear?: string;
  profilesurname?: string;
  articledateobject?: number;
  articledatetext?: string;
  taz120xonomytopics?: string[];
  articledateyear?: string;
  date?: number;
  pagetitle?: string;
  source?: string;
  pagedescription?: string;
  collection?: string;
  articleimageurl?: string;
  taxonomyattributes?: string[];
  newstitle?: string;
  newstopic?: string[];
  articletitle?: string;
  articleauthorsnames?: {
    fields: {
      articleAuthorName: string;
    };
  }[];
  profilepositiontitle?: string;
  dpoopento?: string;
  dpofundingtype?: string;
  dpovalue?: string;
  dpotitle?: string;
  dpovaluetext?: string;
  contenttypeid?: string;
  taxonomytopics?: string;
  keywords?: string;
  profilenationality?: string;
  profileprogramme?: string;
  profileisambassador?: string;
  profileisscholar?: string;
  eventtitle?: string;
  eventorganisedbylist?: string[];
  eventlocationname?: string;
  eventregion?: string;
  profilephotosmallurl?: string;
  profilephotosmallalt?: string;
  eventprogrammetype?: string;
  eventstarttime?: string;
  eventmonth?: string;
  eventtype?: string;
  eventendtime?: string;
  eventwhoisaimedat?: string;
  eventyear?: string;
  eventdepartment?: string;
  eventcity?: string;
  programmetopicsnames?: string[] | undefined;
  programmeapplynowlink?: string;
  programmereserveaplacelink?: string;
  programmedescription?: string;
  programmestartdatestring?: string;
  programmeduration?: string;
  programmelocation?: string;
  programmefees?: string;
  programmedelivery?: string;
  publicationyearstring?: string;
  publicationyear?: number;
  publicationpublishingdetails?: string;
  publicationauthorslist?: string;
  publicationsubjectareaslist?: string;
  publicationresearchcenternamelist?: string;
  subjectarea?: string;
  researchtitle?: string;
}

export interface Results {
  title: string;
  uri: string;
  printableUri: string;
  clickUri: string;
  uniqueId: string;
  excerpt: string;
  raw: Raw;
  ClickUri?: string;
}

export interface Facet {
  fields: {
    label: string;
    coveoFieldName: string;
    coveoFacetType: string;
  };
}

export interface SortOption {
  fields: {
    label: string;
    expression: string;
  };
}

interface PageTypeRestriction {
  fields: {
    title: string;
    contentType: string;
    numberOfEntries?: number;
  };
}

export enum ContentTypes {
  GLOBAL = "Global",
  BSR_CONTENT_PAGE = "bsrContentPage",
  PROGRAMME_DETAILS = "programmeDetails",
  EVENTS_DETAIL = "eventsDetail",
  NEWS_DETAIL = "newsDetail",
  FACULTY_PROFILE_DETAIL = "facultyProfileDetail",
  CONTRIBUTOR_PROFILE_DETAIL = "contributorProfileDetail",
  STUDENT_PROFILE_DETAIL = "studentProfileDetail",
  PUBLICATION_DETAIL = "publicationDetail",
  STORY_PAGE = "storyPage",
  RESEARCH_PAGE = "ResearchDetailsPage",
  DEFAULT = "defaultResultsTemplate",
}

enum PageFormats {
  DPO_LANDING_PAGE = "DPO Results page",
  THINK_LANDING_PAGE = "Think Landing Page",
}

//get the source here from the variable in the .env file
//NEXT_PUBLIC_COVEO_SOURCE
const envSource = process.env.NEXT_PUBLIC_COVEO_SOURCE ?? "";

function GetSourceFilter(envSource: string): string {
  return envSource ? `@source=${envSource}` + " " + "&&" + " " : "";
}

// add filter based on contentTypeID
function GetContentTypeFilter(contentTypeIDs: PageTypeRestriction[]) {
  return (
    `@contenttypeid=${
      contentTypeIDs &&
      contentTypeIDs.map((id) => id.fields.contentType).join('", "')
    }` +
    " " +
    "&&" +
    " "
  );
}

function GetFilterTaxonomyFieldName(taxonomy: string, topics: string[]) {
  if (topics) {
    const mergedTopics = topics
      .map((topic) => topic.replace(":", " "))
      .join('", "');
    const mergedTopicsString = `("${mergedTopics}")`;
    return `(@${taxonomy}==${mergedTopicsString})`;
  }
  return "";
}

export const CoveoSearchComponent = (props: {
  content: CoveoSearchComponentProps;
}) => {
  const { content } = props;

  const {
    desktopResultsColumnsNumber,
    showSearchBar,
    facets,
    resultsPerPage,
    sortOptions,
    pageTypeRestrictions,
    redirectUrl,
    displayPagination,
    title,
    description,
    pageFormat,
    optionalCta,
    tags,
    searchHubParameter,
  } = content;

  const appInsights = useAppInsightsContext();
  const { siteSettings, allTags } = useSettings();
  const [engine, setEngine] = useState<SearchEngine>();
  const { route } = useRouter();
  const articleAuthorsPage =
    siteSettings?.searchResultsPagesCollection?.items?.filter(
      ({ type }) => type === SearchLandingType.ThinkAuthors
    )[0];
  const articleAuthorsUrl = articleAuthorsPage
    ? articleAuthorsPage.url?.slug
    : "";

  let pageTitle = title;
  let isArticleAuthorsPage = false;
  let urlParams: any = [];
  let queryTag;
  let searchTerm;

  if (typeof window !== "undefined") {
    if (window.location?.href?.includes(articleAuthorsUrl))
      isArticleAuthorsPage = true;
    urlParams = new URLSearchParams(window.location.search);
    const url = window.location.href;
    searchTerm = url.includes("#q=")
      ? decodeURI(url.split("#q=").reverse()[0].split("&")[0])
      : undefined;
    const params = {};
    const queryFacets = [
      ...facetsConfig.map((f) => f.facetId),
      "topics",
      "articleauthors",
    ];
    urlParams.forEach((value, key) => {
      //only use allowed key values.
      if (queryFacets.find((f) => f === key)) {
        params[key] = value;
      }
    });
    for (const key in params) {
      const value = params[key];
      queryTag = `${key} ${value.replace(/[^a-zA-Z ]/g, " ")}`;
      if (key === "articleauthors") {
        const rex = /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g;
        pageTitle = value.replace(rex, "$1$4 $2$3$5");
      }
    }
  }

  useEffect(() => {
    (async () => {
      function GetSearchHub(searchHubParameter) {
        let searchHub = "";

        searchHub =
          searchHubParameter == undefined ? "default" : searchHubParameter;

        return searchHub;
      }

      let cqFilters = "";

      cqFilters += envSource ? GetSourceFilter(envSource) : "";
      cqFilters += pageTypeRestrictions
        ? GetContentTypeFilter(pageTypeRestrictions)
        : "";

      // get the tag names of all the tags set on a specific page

      function getTagName(pageTags, allTags) {
        const tagsCollection = pageTags?.map((tag) => {
          const name = allTags?.find(
            (t) => t.id.toLocaleLowerCase() === tag.sys.id.toLocaleLowerCase()
          )?.name;
          return { name: name, id: tag?.sys?.id };
        });

        return tagsCollection;
      }

      if (tags) {
        let tagsNameIdCollection = getTagName(tags, allTags);

        let preFilterTagName = "";
        tagsNameIdCollection?.forEach((tagElement) => {
          preFilterTagName = tagElement.name.split(":")[0];

          //check if the second part has a comma
          if (tagElement.name.split(":")[1].indexOf(",") > -1) {
            let preFilterTagCOllection = tagElement.name
              .split(":")[1]
              .split(",");

            preFilterTagCOllection.forEach((preFilterTagElement) => {
              cqFilters +=
                preFilterTagName && preFilterTagCOllection && !queryTag
                  ? `@${preFilterTagName}=${preFilterTagElement}`
                  : "";
            });
          } else {
            let preFilterTag = "";

            //when prefilter tag value has a colon within it
            //Location:Online
            if (tagElement.name.split(":").length > 2) {
              preFilterTag = `${tagElement.name.split(":")[1]}:${
                tagElement.name.split(":")[2]
              }`;
            } else {
              preFilterTag = tagElement.name.split(":")[1];
            }

            cqFilters +=
              preFilterTagName && preFilterTag && !queryTag
                ? `@${preFilterTagName}==\"${preFilterTag}\"`
                : "";
          }
        });
      }
      // add filter based on taxonomyattributes and url query
      if (isArticleAuthorsPage) {
        cqFilters += `@articleauthorsnames=${pageTitle}`;
      } else {
        cqFilters += queryTag
          ? GetFilterTaxonomyFieldName("taxonomyattributes", [queryTag])
          : "";
      }

      //here get all the value from the .env variables
      //access token, organisationid and pipeline
      //NEXT_PUBLIC_COVEO_ORGANISATION_ACCESS_TOKEN
      //NEXT_PUBLIC_COVEO_ORGANISATION_ID
      //NEXT_PUBLIC_COVEO_PIPELINE
      const configuration = {
        accessToken:
          process.env.NEXT_PUBLIC_COVEO_ORGANISATION_ACCESS_TOKEN ?? "",
        organizationId: process.env.NEXT_PUBLIC_COVEO_ORGANISATION_ID ?? "",
        organizationEndpoints: getOrganizationEndpoints(
          process.env.NEXT_PUBLIC_COVEO_ORGANISATION_ID ?? ""
        ),
        search: {
          pipeline: GetSearchHub(searchHubParameter),
          searchHub: GetSearchHub(searchHubParameter),
        },
        preprocessRequest: (request, clientOrigin, metadata) => {
          if (
            metadata?.method === "search" &&
            clientOrigin === "searchApiFetch"
          ) {
            const body = JSON.parse(request.body);
            body.cq = cqFilters;
            request.body = JSON.stringify(body);
          }

          return request;
        },
      };

      const engine = await buildSearchEngine({ configuration });
      setEngine(engine);
    })();
  }, [route]);
  const [totalResults, setTotalResults] = useState<number>();

  useEffect(() => {
    if (engine) {
      const controller = buildQuerySummary(engine);
      controller.subscribe(() => {
        const { total } = controller.state;
        setTotalResults(total);
      });
    }
  }, [engine]);

  useEffect(() => {
    if (totalResults && searchTerm && !searchTerm.startsWith("http")) {
      setGADataLayer({
        data: {
          search_term: searchTerm,
          result_count: totalResults,
        },
        _clear: true,
        event: dataLayerEventKey.SEARCH,
      });
    }
  }, [totalResults]);

  const FullWidth = {
    width: "100%",
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    gridTemplateColumns: "25% 1fr",
  };

  const device = useDevice();
  const isMobile = device === "mobile";

  if (
    !process.env.NEXT_PUBLIC_COVEO_ORGANISATION_ACCESS_TOKEN ||
    !process.env.NEXT_PUBLIC_COVEO_ORGANISATION_ID
  ) {
    appInsights.trackEvent({
      name: "MissingCoveoSearchCredentials",
    });
    return <p>Missing Coveo Search Credentials</p>;
  }

  return (
    <div className="App">
      {engine ? (
        <AtomicSearchInterface
          languageAssetsPath="/lang"
          engine={engine}
          fieldsToInclude='[
        "profilephotosmallalt",
        "articledateobject",
        "articledatetext",
        "dpoopento",
        "dpofundingtype",
        "profilepositiontitle",
        "dpovalue",
        "dpotitle",
        "dpovaluetext",
        "articleauthorsnames",
        "taxonomyattributes",
        "pagedescription",
        "contenttypeid",
        "pagetitle",
        "taxonomytopics",
        "taz120xonomytopics",
        "articledateyear",
        "eventsyear",
        "keywords",
        "articletitle",
        "articleimageurl",
        "profilenationality",
        "profileprogramme",
        "profileisambassador",
        "profileisscholar",
        "eventtitle",
        "eventorganisedbylist",
        "eventlocationname",
        "eventregion",
        "profilephotosmallurl",
        "eventprogrammetype",
        "eventstarttime",
        "eventmonth",
        "eventtype",
        "eventendtime",
        "eventwhoisaimedat",
        "newstopic",
        "eventyear",
        "eventdepartment",
        "eventcity",
        "profilesurname",
        "newstitle",
        "profilesubjectarea",
        "programmetopicsnames",
        "programmeapplynowlink",
        "programmereserveaplacelink",
        "programmedescription",
        "programmestartdatestring",
        "programmeduration",
        "programmelocation",
        "programmefees",
        "programmedelivery",
        "publicationyearstring",
        "publicationyear",
        "publicationpublishingdetails",
        "publicationauthorslist",
        "publicationsubjectareaslist",
        "publicationresearchcenternamelist",
        "subjectarea",
        "researchtitle"
      ]'
          scrollContainer=".coveo-results"
        >
          {pageFormat !== PageFormats.DPO_LANDING_PAGE ? (
            <AtomicSearchLayout style={FullWidth}>
              <style>
                {CoveStyles(
                  isMobile,
                  desktopResultsColumnsNumber || 3,
                  pageFormat === PageFormats.THINK_LANDING_PAGE
                )}
              </style>
              {pageTitle && <h3>{pageTitle}</h3>}
              {description && (
                <RichTextFullWidth addBorder={false} content={description} />
              )}
              {showSearchBar ? (
                <AtomicLayoutSection section="search">
                  <AtomicSearchBox
                    redirectionUrl={redirectUrl || ""}
                    numberOfQueries={5}
                  >
                    <AtomicSearchBoxQuerySuggestions />
                  </AtomicSearchBox>
                </AtomicLayoutSection>
              ) : null}

              <AtomicResultsPerPage
                choicesDisplayed={resultsPerPage || "21"}
              ></AtomicResultsPerPage>
              {facets && pageFormat !== PageFormats.THINK_LANDING_PAGE ? (
                <AtomicLayoutSection section="facets">
                  <AtomicFacetManager>
                    <Grid row>
                      {facets.map((facet) => (
                        <Grid key={facet.fields.coveoFieldName} column lg={3}>
                          <CoveoSearchFacet
                            key={facet.fields.coveoFieldName}
                            content={facet.fields}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AtomicFacetManager>
                </AtomicLayoutSection>
              ) : null}
              <AtomicLayoutSection section="main">
                <AtomicLayoutSection section="status">
                  <AtomicBreadbox />
                  {sortOptions && (
                    <div
                      className={
                        pageFormat !== PageFormats.THINK_LANDING_PAGE
                          ? ""
                          : "thinkLanding"
                      }
                    >
                      <AtomicSortDropdown>
                        {sortOptions.map((option, i) => (
                          <AtomicSortExpression
                            key={`${option.fields?.label}${i}`}
                            expression={option.fields.expression}
                            label={option.fields.label}
                          ></AtomicSortExpression>
                        ))}
                      </AtomicSortDropdown>
                    </div>
                  )}

                  <AtomicDidYouMean />
                </AtomicLayoutSection>
                <AtomicLayoutSection
                  section="results"
                  className="coveo-results"
                >
                  {pageFormat !== PageFormats.THINK_LANDING_PAGE && (
                    <AtomicQuerySummary />
                  )}
                  {engine ? (
                    <LBSResultList
                      display="grid"
                      imageSize="small"
                      controller={buildResultList(engine)}
                      pageFormat={pageFormat}
                    />
                  ) : null}
                  <AtomicQueryError />
                  <AtomicNoResults />
                </AtomicLayoutSection>
                {displayPagination && (
                  <AtomicLayoutSection section="pagination">
                    <AtomicPager />
                  </AtomicLayoutSection>
                )}
              </AtomicLayoutSection>
            </AtomicSearchLayout>
          ) : (
            <CoveoDPOSearchResults
              resultsPerPage={resultsPerPage}
              showSearchBar={showSearchBar}
              redirectUrl={redirectUrl}
              facets={facets}
              sortOptions={sortOptions}
              displayPagination={displayPagination}
              isMobile={isMobile}
            />
          )}
        </AtomicSearchInterface>
      ) : null}
      {optionalCta && (
        <div>
          <Cta
            customClass="centered"
            type="secondary"
            {...optionalCta.fields}
          />
        </div>
      )}
    </div>
  );
};

export default CoveoSearchComponent;
