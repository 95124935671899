import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { useForm } from "react-hook-form";
import CryptoJS from "crypto-js";
import ClipLoader from "react-spinners/ClipLoader";

import FormField from "@components/Form/FormFields/FormFields";
import { NextContentfulImage } from "@components/Image";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { UserContext } from "../../context/user";
import {
  getPersonalDetails,
  subscribeThink,
  updateSubscribeThink,
} from "../../services/forms";
import getCookie from "@utilities/getCookie";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import { useDevice } from "@utilities/react/get-device/get-device";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import { useSettings } from "@utilities/context/settings";

import styles from "./SubscribeThink.module.scss";
import setGADataLayer, {
  dataLayerEventKey,
  dataLayerStatusKey,
} from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";

interface SubscribeThinkProps {
  content: {
    introText: string;
    verifyTitle: string;
    errorMessage: string;
    thankYouMessage: any;
    thankYouMessageTinyMce: TinyMceRichText;
    thankYouTitle: string;
    verifyMessage: any;
    verifyMessageTinyMce: TinyMceRichText;
    description: any;
    descriptionTinyMce: TinyMceRichText;
    bannerImage: ImageWrapperType;
    bannerImageMobile: ImageWrapperType;
    emailLabelText: string;
    familyNameLabelText: string;
    firstNameLabelText: string;
  };
}
let firstInteract = 0;

export const SubscribeThink = ({ content }: SubscribeThinkProps) => {
  const {
    introText,
    verifyTitle,
    errorMessage,
    thankYouMessage,
    thankYouTitle,
    thankYouMessageTinyMce,
    verifyMessage,
    verifyMessageTinyMce,
    description,
    descriptionTinyMce,
    bannerImage,
    bannerImageMobile,
    emailLabelText,
    familyNameLabelText,
    firstNameLabelText,
  } = content;
  const { register, handleSubmit, formState, watch } = useForm({
    mode: "onBlur",
  });
  const { siteSettings } = useSettings();
  const { user } = useContext(UserContext);

  const router = useRouter();

  const [show, setShow] = useState(false);
  const [url, setUrl] = useState("");
  const [activeStep, setActiveStep] = useState<
    "subscribeScreen" | "verifyScreen" | "thankYouScreen" | "errorScreen"
  >("subscribeScreen");
  const [userPersonalDetails, setUserPersonalDetails] = useState<any>(null);
  const [hideForm, setHideForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const getUser = () => {
    const getUserData = async () => {
      try {
        const personalDetails = await getPersonalDetails(
          getCookie("access_token")
        );
        setUserPersonalDetails(personalDetails);
      } catch (e) {
        setActiveStep("errorScreen");
      }
    };
    getUserData();
  };

  useEffect(() => {
    if (router.query.usertoken) {
      const submitData = async () => {
        const usertoken: string | undefined = router?.query
          ?.usertoken as string;
        const bytes = CryptoJS.AES.decrypt(
          // @ts-ignore
          usertoken?.replaceAll(" ", "+"),
          "w00tz0r"
        );
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        decryptedData.isStayInformed = true;

        try {
          await subscribeThink(
            decryptedData,
            sessionStorage.getItem("crm_campaign") ||
              router?.query?.utm_campaign
          );
          setActiveStep("thankYouScreen");
        } catch (e) {
          console.log("error", e);
        }
      };

      submitData();
    }

    if (router["asPath"].split("#")[1] === "subscribeScreen") {
      const maintanancePage = siteSettings?.siteMaintenancePage?.slug;
      const isMaintananceModeOn = siteSettings?.turnOnSiteMaintenanceMode;
      if (isMaintananceModeOn) {
        router.push(`/${maintanancePage}`);
      }
      /*const checkApiStatus = async () => {
        const response = await checkApiHealth();
        if (response.statusCode != 200 || response.status === "Unhealthy") {
          router.push(`/${maintanancePage}`);
        }
      };
      checkApiStatus();*/

      if (user) {
        getUser();
      } else {
        setShow(true);
      }
      setUrl(document.URL);
    } else {
      setShow(false);
    }
  }, [router, user]);

  useEffect(() => {
    if (router.query.usertoken) {
      setHideForm(true);
    }
  }, [router]);

  useEffect(() => {
    if (userPersonalDetails) {
      subscribeUser();
    }
  }, [userPersonalDetails]);

  const subscribeUser = async () => {
    try {
      setShow(true);
      setActiveStep("thankYouScreen");
      await updateSubscribeThink(sessionStorage.getItem("crm_campaign"));
    } catch (e) {
      setActiveStep("errorScreen");
    }
  };

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "subscribeThink",
        });
        firstInteract++;
        return () => subscription.unsubscribe();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    //set newsletter attempt
    if (formState.submitCount > 0) {
      setGADataLayer({
        data: {
          status: dataLayerStatusKey.SUBMIT_ATTEMPT,
        },
        _clear: true,
        event: dataLayerEventKey.NEWSLETTER,
      });

      //failed attempt
      const errorList = Object.keys(formState.errors)
        .map((key) => key)
        ?.join(",");
      if (errorList) {
        //set dataLayer
        setGADataLayer({
          data: {
            status: dataLayerStatusKey.SUBMIT_FAIL,
            form_field: errorList,
          },
          _clear: true,
          event: dataLayerEventKey.NEWSLETTER,
        });
      }
    }
  }, [formState.submitCount]);

  const formFields = [
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
      },
      labelText: firstNameLabelText,
      dataText: userPersonalDetails?.firstName,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        lastName: true,
        stringValidator: true,
      },
      labelText: familyNameLabelText,
      dataText: userPersonalDetails?.lastName,
    },

    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 100,
      },
      labelText: emailLabelText,
      dataText: userPersonalDetails?.email,
    },
  ];

  const sendGAEvent = (event) => {
    setGADataLayer({
      event,
      formName: "subscribeThink",
      programmeCode: router?.query?.programmeTypeCode || "",
      businessUnit: getBusinessUnit(),
    });
  };

  useEffect(() => {
    if (activeStep === "thankYouScreen") {
      sendGAEvent("formComplete");
    }
  }, [activeStep]);

  const onSubmit = async (data) => {
    console.log("here");
    setLoading(true);
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      "w00tz0r"
    ).toString();

    // call to the Netlify Function
    sendGAEvent("formSubmit");
    try {
      const response = await fetch(
        "/.netlify/functions/triggerThinkVerifyEmail",
        {
          method: "POST",
          body: JSON.stringify({
            firstName: data.firstName,
            lastName: data.lastName,
            emailAddress: data.emailAddress,
            token: ciphertext,
            crmCampaign: sessionStorage.getItem("crm_campaign"),
          }),
        }
      );

      if (response.ok) {
        setActiveStep("verifyScreen");
        setLoading(false);
      } else {
        setActiveStep("errorScreen");
        setLoading(false);
        const error = await response.json();
        console.log(error);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const device = useDevice();
  const isMobile = device === "mobile";

  const LinkImageJSX = () => (
    <>
      <a
        href={"/"}
        onClick={(e) => {
          e.preventDefault();
          let pageURL = router["asPath"];
          router?.push(pageURL?.split("?")[0]);
        }}
        className={styles.cancel}
      >
        ×
      </a>
      <div className={styles["subscribe-think__image"]}>
        <NextContentfulImage
          src={
            !isMobile || (isMobile && !bannerImageMobile)
              ? bannerImage?.fields.image.fields.file.url
              : bannerImageMobile?.fields.image.fields.file.url
          }
          alt={
            !isMobile
              ? bannerImage?.fields?.altText || ""
              : bannerImageMobile?.fields?.altText || ""
          }
          width={900}
          height={!isMobile ? 100 : 50}
        />
      </div>
    </>
  );

  if (!router.query.entry && !router.query.usertoken) {
    return (
      <div
        className={clsx(
          "component",
          "subscribeThink",
          show && styles.show,
          styles["subscribe-think"]
        )}
      >
        <div className="wrapper subscribeThink">
          {activeStep === "subscribeScreen" && (
            <div id="subscribeScreen" className={styles["overlay-content"]}>
              <div
                className={clsx(
                  styles["subscribe-screen-content"],
                  styles["popup"],
                  "wrapper"
                )}
              >
                <LinkImageJSX />
                <h4>{introText}</h4>
                <div className={styles.spinner}>
                  <ClipLoader
                    color="#001e62"
                    size={80}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(
        "component",
        "subscribeThink",
        show && styles.show,
        styles["subscribe-think"]
      )}
    >
      <div className="wrapper subscribeThink">
        {activeStep === "subscribeScreen" && !hideForm && (
          <div id="subscribeScreen" className={styles["overlay-content"]}>
            <div
              className={clsx(
                styles["subscribe-screen-content"],
                styles.popup,
                "wrapper"
              )}
            >
              <LinkImageJSX />
              <h4>{introText}</h4>
              <form
                onSubmit={handleSubmit(onSubmit)}
                className={clsx(
                  styles.form,
                  "form",
                  styles["my-profile"],
                  "my-profile"
                )}
              >
                <div className={styles["fields-floated"]}>
                  {formFields.map(
                    (
                      { dataText, labelText, propertyName, validation },
                      index
                    ) => (
                      <div
                        className={clsx(
                          "form-row",
                          styles["form-row"],
                          styles["field-row"]
                        )}
                        key={`${labelText}${index}`}
                      >
                        <FormField
                          watch={watch}
                          validation={validation}
                          type="text"
                          register={register}
                          property={dataText}
                          placeholder={labelText}
                          name={propertyName || ""}
                          errors={formState.errors}
                        />
                      </div>
                    )
                  )}
                </div>
                <RichTextDisplay
                  richText={description}
                  tinyMceRichText={descriptionTinyMce}
                />
                <SubmitButton
                  loading={loading}
                  id="submitButton"
                  text="Subscribe"
                />
              </form>
            </div>
          </div>
        )}
        {activeStep === "verifyScreen" && (
          <div id="verifyScreen" className={styles["overlay-content"]}>
            <div className={clsx(styles.popup, "wrapper")}>
              <LinkImageJSX />
              <h4>{verifyTitle}</h4>
              <RichTextDisplay
                richText={verifyMessage}
                tinyMceRichText={verifyMessageTinyMce}
              />
            </div>
          </div>
        )}
        {activeStep === "thankYouScreen" && (
          <div id="thankYouScreen" className={styles["overlay-content"]}>
            <div className={clsx(styles.popup, "wrapper")}>
              <LinkImageJSX />
              <div className={styles["header-align"]}>
                <svg width="35" height="35" xmlns="http://www.w3.org/2000/svg">
                  <g fill="none" fillRule="evenodd">
                    <rect
                      stroke="#5CB300"
                      x=".5"
                      y=".5"
                      width="34"
                      height="34"
                      rx="17"
                    />
                    <path
                      stroke="#65C500"
                      strokeWidth="4"
                      strokeLinecap="square"
                      d="m9 20 6 5M26 12 15.75 24.75"
                    />
                  </g>
                </svg>

                <h4>{thankYouTitle}</h4>
              </div>
              <p>
                <RichTextDisplay
                  richText={thankYouMessage}
                  tinyMceRichText={thankYouMessageTinyMce}
                />
              </p>
            </div>
          </div>
        )}
        {activeStep === "errorScreen" && (
          <div id="errorScreen" className={styles["overlay-content"]}>
            <div className={clsx(styles.popup, "wrapper")}>
              <LinkImageJSX />
              <p className={styles.error}>{errorMessage}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscribeThink;
