import facetsConfig from "./facetsConfig";

export const CoveStyles = (isMobile, gridColumns, thinkPage = false) => {
  const generateFacetCSS = (facet) => {
    return `
      atomic-facet-manager div[class^="Grid_column"] div:has(> atomic-facet[facet-id="${facet.facetId}"].atomic-hidden)::after {
        content: "${facet.content}";
        display: block;
        box-sizing: border-box;
        width: 100%;
        height: 48px;
        margin-top: 2rem;
        margin-left: auto;
        margin-right: auto;
        padding-left: 2rem;
        padding-right: 2rem;
        position: relative;
        background-color: #D7D2CB;
        line-height: 2.4rem;
        font-size: 15px;
        padding: 12px;
        font-family: var(--lbs-base-font-family),sans-serif;
        font-weight: var(--atomic-font-normal);
        color: #6e6259;
      }
    `;
  };

  // Generate the CSS for all facets
  const facetsCSS = facetsConfig?.map(generateFacetCSS).join("");

  // Include generated CSS with our base CSS
  return `
    ${facetsCSS}

    atomic-facet-manager div[class^="Grid_column"] {
      position: relative;
    }

atomic-search-interface atomic-search-layout.hydrated{
  max-width: 1150px !important;
}

div[class^="thinkLanding"]{
  display:none;
}

atomic-search-interface atomic-search-layout atomic-layout-section[section="search"] {
  width: 100% !important;
  max-width: none;
}

atomic-search-layout atomic-layout-section[section='facets'] {
  display: block;
}

atomic-search-layout atomic-layout-section[section='facets'] * {
  margin-bottom: 0px;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 0px;
  position: relative;
}

atomic-facet::part(facet) {
  background: none;
  padding: 0;
  margin-top: 2rem;
  border-style: solid;
  border: 1px;
}

atomic-facet::part(label-button)[aria-expanded='true']{
  border: 1px solid #001e62;
}

atomic-facet::part(clear-button-icon) {
  width: 12px;
  height: 12px;
  margin-right: 1rem;
}

atomic-facet::part(clear-button) {
  font-size: 16px;
  background: initial;
  color: #001e62;
  display:none;
}

atomic-facet::part(label-button) {
  line-height: 2.4rem;
  color: #001e62;
  font-size: 15px;
  padding: 12px;
  font-family: var(--lbs-base-font-family),sans-serif;
  font-weight:var(--atomic-font-normal);
  background-color: var(--atomic-background);
}

atomic-facet::part(value-label) {
  font-family: var(--lbs-base-font-family),sans-serif;
  color: #001e62;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

atomic-facet::part(show-more) {
  font-family: var(--lbs-base-font-family);  
}

atomic-facet::part(value-count) {
  font-family: var(--lbs-base-font-family);  
}

atomic-facet::part(search-input) {
  padding: 2.5rem;
  font-size: 1.6rem;
  color: #001e62;
  font-family:  var(--lbs-base-font-family),sans-serif;
  border-radius: 0px;
}
atomic-facet::part(search-icon) {
  display:none;
 }
atomic-facet::part(search-wrapper) {
  height: 6rem;
  padding:0;
}
atomic-facet::part(value-checkbox-label):hover{
 background:none;
}

atomic-facet::part(value-checkbox){
  border-radius: 0px;
}

atomic-facet::part(values) {
  position: absolute;
  background: white;
  z-index: 1;
  margin-top: 0.25rem;
  width: 100%;
  overflow: hidden;
  box-shadow: 0px 4px 15px -3px rgba(0,0,0,0.5);
  max-height: 30rem;
  overflow-y: auto;
  padding: 15px;
} 

atomic-facet::part(values) li {
  flex-wrap: wrap;
}
atomic-facet::part(values) .items-center {
  flex-wrap: wrap;
}


atomic-search-box {
  height:48px;
  padding-left: 0;
  margin-bottom:16px;
}

atomic-facet::part(value-label) {
  white-space: normal;
  padding-left: 15px;
  }

atomic-search-box::part(query-suggestion-content) {
  color: #001e62;
  font-family: var(--lbs-base-font-family),sans-serif;
  font-size: 1.6rem;
}
atomic-search-box::part(submit-icon) { 
  fill:  #001e62;
  width: 2rem;
  height: 2rem;
}
atomic-search-box::part(wrapper) { 
  align-items: center;
}
atomic-search-box::part(clear-icon) {
  fill:  #001e62;
} 
atomic-search-box::part(submit-button) { 
  background:#fff;
  width:40px;
  height: 38px;
  margin-right: 0.5rem;
}

atomic-sort-dropdown {
  font-family: var(--lbs-base-font-family),sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
  line-height: 1.5;
  font-size: 1.2rem;
}

@media screen and (min-width: 480px) {
  atomic-sort-dropdown {
  font-size: 1.6rem;
  }
}

atomic-sort-dropdown::part(label) {
  font-weight: normal;
  font-size: 1.2rem;
}

@media screen and (min-width: 480px) {
  atomic-sort-dropdown::part(label) {
  font-size: 1.6rem;
  }
}

atomic-sort-dropdown::part(select-separator) {
  border-color: transparent;
  color: #001e62;
}

atomic-search-box::part(input) {
  font-size: 1.6rem;
  padding: 2rem 7rem 2rem 1rem;
  text-indent: unset;
  color:#001e62;
  font-family: var(--lbs-base-font-family),sans-serif;
}

atomic-search-box::part(input)::-webkit-input-placeholder { /* WebKit browsers */
  text-transform: capitalize;
}
atomic-search-box::part(input):-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  text-transform: capitalize;
}
atomic-search-box::part(input)::-moz-placeholder { /* Mozilla Firefox 19+ */
  text-transform: capitalize;
}
atomic-search-box::part(input):-ms-input-placeholder { /* Internet Explorer 10+ */
  text-transform: capitalize;
}
atomic-search-box::part(input)::placeholder { /* Recent browsers */
  text-transform: capitalize;
}

atomic-query-summary::part(container) {
  color: #001e62;
  line-height: 1.5;
  font-size: 1.2rem;
  margin-right: 8px;
  font-family: var(--lbs-base-font-family),sans-serif;
}

@media screen and (min-width: 480px) {
  atomic-query-summary::part(container) {
    font-size: 1.6rem;
  }
}

atomic-results-per-page {
  display: none;
}

atomic-results-per-page::part(label) {
  margin: 19px;
  margin-left: 0;
  text-transform: capitalize;
  color: #001e62;
  font-size: 12px;
  margin-right: 8px;
}

atomic-sort-dropdown::part(select) {
  border-radius: 0;
  font-size: 12px;
  font-family: var(--lbs-base-font-family),sans-serif;
  font-weight: 500;
  color: #001e62;
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0.1rem solid #001e62;
  padding: 0 3rem 0.5rem 0;
  margin: 0.4rem 0 0 0.2rem;
}

@media screen and (min-width: 480px) {
  atomic-sort-dropdown::part(select) {
    font-size: 15px;
    margin: 0.4rem 0 0 1.1rem;
  }
}

atomic-results-per-page::part(button active-button) {
  border: 0;
  background: #eae8e5;
  font-size: 14px;
  font-weight: 700;
  font-family: var(--lbs-base-font-family),sans-serif;
}

atomic-search-layout atomic-layout-section[section='status'] {
  margin-top: 20px;
  display: block;
}

atomic-breadbox::part(label){   
  font-family: var(--lbs-base-font-family),sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0 1.3rem 0 0;
  font-weight: 400;
}

atomic-breadbox::part(breadcrumb-list) {
  padding-top: 0.5rem;
}

atomic-breadbox::part(breadcrumb-button),
atomic-breadbox::part(show-more),
atomic-breadbox::part(clear),
atomic-breadbox::part(show-less) {   
  font-family: var(--lbs-base-font-family),sans-serif;
  line-height: 1.5;
  font-size: 1.6rem;
  color: #001e62;
  padding: 0.3rem 0.6rem;
  border: 0.1rem solid #d7d2cb;
  font-size: 1.2rem;
  position: relative;
  text-decoration: none;
  font-weight: 700;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
}

atomic-breadbox::part(clear) {
  margin-left: 8px;
}

atomic-breadbox::part(breadcrumb-button):hover,
atomic-breadbox::part(show-more):hover,
atomic-breadbox::part(clear):hover,
atomic-breadbox::part(show-less):hover {
  background-color: #001e62;
  color: #fff;
}

atomic-breadbox::part(breadcrumb-label) {
  color: inherit;
}

atomic-breadbox::part(clear){   
  font-family: var(--lbs-base-font-family),sans-serif;
}

atomic-result-list::part(result-list-grid-clickable-container) {
  padding: 0;
}

.list-wrapper {
  word-break: break-word;
}

atomic-result-list::part(result-list),
.result-list {
  grid-template-columns: ${
    isMobile ? "repeat(1, 1fr)" : `repeat(${gridColumns}, 1fr)`
  } ;
}

atomic-search-layout atomic-layout-section[section='pagination']>* {
  margin-top: var(--atomic-layout-spacing-y);
}
atomic-search-layout atomic-layout-section[section='main'] {
  margin-bottom: var(--atomic-layout-spacing-y);
}

${
  thinkPage &&
  `
atomic-search-interface atomic-search-layout atomic-layout-section[section="search"]>* {
  padding-top: 0;
  margin-bottom: 4rem;
}
`
}

.result-list-grid-clickable {
  content: "";
  position: inherit;
  inset: 0;
}

.result-list-grid-clickable-container {
  padding: 0;
}

.outline .result-list-grid-clickable-container {
  position: relative;
}

@media (min-width: 1024px) {
  .outline.result-list-grid-clickable-container {
    border: 1px solid transparent;
    border-radius: 1rem;
    transition: all 0.12s ease-out 0s;
  }
}

@media (min-width: 1024px) {
  .list-root.display-grid.image-small {
    grid-template-columns: repeat(${gridColumns}, 1fr);
  }
}

@media (min-width: 1024px) {
  .list-root.display-grid.density-normal, 
  .list-root.display-grid.density-compact {
    gap: 3rem 1.5rem;
  }
}

.list-root.display-grid {
  display: grid;
  justify-content: space-evenly;
}

.list-root.display-grid .result-component {
  height: 100%;
  box-sizing: border-box;
}

@media not all and (min-width: 1024px) {
  .list-root.display-grid.image-small, 
  .list-root.display-grid.image-icon, 
  .list-root.display-grid.image-none {
      gap: 0.5rem;
  }
}
@media (min-width: 128px) and (max-width: 767px) {
  .course-search-results .application-box {
      box-shadow:none;
  }
}

.imageLink .Image {
  height: auto;
}
.coveo-loading-center {
  text-align: center;
  padding: 6rem 0;
}
.coveo-loading-center .loader {
  margin-bottom: 1rem;
}

`;
};
