import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import IconCross from "../../public/image/svg/cross.svg";
import * as basicLightbox from "basiclightbox";

interface LightboxProps {
  children: React.ReactNode;
  onCloseFunction: Function;
  isBanner?: boolean;
  customClass?: string;
  customInnerClass?: string;
}

export interface LightboxRefProps {
  close: () => void;
}

const Lightbox = forwardRef<LightboxRefProps, LightboxProps>((props, ref) => {
  const lightboxRef = useRef<HTMLDivElement>();
  const instance = useRef<basicLightbox.BasicLightBox>();
  const { isBanner, children, onCloseFunction, customClass, customInnerClass } =
    props;

  useEffect(() => {
    instance.current = basicLightbox.create(lightboxRef.current, {
      onClose: () => {
        document.documentElement.style.overflowY = "auto";

        // Bespoke function to fire when lightbox closes
        if (onCloseFunction) {
          onCloseFunction();
        }
        return true;
      },
      onShow: (instance: basicLightbox.BasicLightBox) => {
        customClass && instance.element().classList.add(customClass);
        customInnerClass &&
          instance
            .element()
            .querySelector(".basicLightbox__placeholder")
            .classList.add(customInnerClass);
        isBanner && instance.element().classList.add("banner");
        isBanner && instance.element().classList.add("basicLightbox--iframe");
        document.documentElement.style.overflowY = "hidden";
        //@ts-ignore
        instance.element().querySelector(".close").onclick = instance.close;

        return true;
      },
    });
    instance.current.show();
  }, []);

  useImperativeHandle(ref, () => {
    return {
      close() {
        instance.current.close();
      },
    };
  }, []);

  return (
    <div ref={lightboxRef}>
      <div className={`${isBanner ? "video-wrapper" : ""}`}>{children}</div>
      <span className="close" tabIndex={0} aria-label="close">
        <IconCross />
      </span>
    </div>
  );
});

export default Lightbox;
