import Cta from "@components/Cta/Cta";
import { GeneralLink } from "@customTypes/generalLink";
import { PopupGate } from "@components/PopupGate/PopupGate";
import styles from "./CtaGatedContent.module.scss";
import { useState } from "react";
import { gatedContentUser } from "../../services/forms";
import getCookie from "@utilities/getCookie";
import { useRouter } from "next/router";

export interface CtaGatedContentProps {
  cta: GeneralLink;
  ctaStyle?: "Primary" | "Secondary" | "Primary Light" | "Secondary Light";
  popup: any;
  marketingCluster: {
    fields: {
      name: string;
      guid: string;
    };
  };
}

export const CtaGatedContent = (props: CtaGatedContentProps) => {
  const { cta, popup, marketingCluster, ctaStyle } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<
    "subscribeScreen" | "errorScreen"
  >("subscribeScreen");
  const router = useRouter();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await gatedContentUser(
        {
          marketingClusterId: marketingCluster?.fields?.guid,
        },
        sessionStorage.getItem("crm_campaign")
      );

      if (response.status === 201) {
        router.push(cta?.fields.url);
      } else {
        setActiveStep("errorScreen");
        setShowPopup(true);
        setLoading(false);
        const error = await response.json();
        console.log(error);
      }
    } catch (e) {
      setShowPopup(true);
      setLoading(false);
      console.log("error", e);
    }
  };

  return (
    <div className={styles["cta-content"]}>
      {showPopup && (
        <PopupGate
          content={popup.fields}
          onClose={() => setShowPopup(false)}
          articlePath={cta?.fields.url}
          guid={marketingCluster?.fields?.guid}
          activePopup={activeStep}
        />
      )}
      <Cta
        type={ctaStyle?.includes("Secondary") ? "secondary" : "primary"}
        light={ctaStyle?.includes("Light")}
        loading={loading}
        {...cta?.fields}
        onClick={() => {
          if (getCookie("access_token")) {
            onSubmit();
          } else {
            setShowPopup(true);
          }
        }}
      />
    </div>
  );
};

export default CtaGatedContent;
