import React, { useEffect, useState } from "react";
import Grid from "@components/Grid/Grid";

import {
  AtomicBreadbox,
  AtomicDidYouMean,
  AtomicFacetManager,
  AtomicLayoutSection,
  AtomicNoResults,
  AtomicPager,
  AtomicQueryError,
  AtomicQuerySummary,
  AtomicResultList,
  AtomicResultsPerPage,
  AtomicSearchBox,
  AtomicSearchBoxQuerySuggestions,
  AtomicSearchLayout,
  AtomicSortDropdown,
  AtomicSortExpression,
  Result,
} from "@coveo/atomic-react";
import { CoveStyles } from "../CoveoSeachComponentStyles";
import CoveoSearchFacet from "@components/CoveoSearchFacet/CoveoSearchFacet";
import { CoveoDPOSearchResultsStyles } from "./CoveoDPOSearchResultsStyles";
import { Facet, SortOption } from "../CoveoSearchComponent";
import Cta from "@components/Cta/Cta";

export interface dpoSettingsProps {
  keyDetailsHeading?: string;
  fundedByLabel?: string;
  valueLabel?: string;
  openToLabel?: string;
  programmesLabel?: string;
}

export interface DPOPageProps {
  resultsPerPage?: string;
  showSearchBar?: boolean;
  redirectUrl?: string;
  facets?: Facet[];
  sortOptions?: SortOption[];
  displayPagination?: boolean;
  isMobile?: boolean;
}

export const CoveoDPOSearchResults = (props: DPOPageProps) => {
  const [showFilters, setShowFilters] = useState(false);

  const {
    resultsPerPage,
    showSearchBar,
    redirectUrl,
    facets,
    sortOptions,
    displayPagination,
    isMobile,
  } = props;

  const TemplateResovler = (result: Result) => (
    <>
      <style>{CoveoDPOSearchResultsStyles(isMobile)}</style>
      <div className="funding-provider-tile base6">
        <a href={result.clickUri} className="funding-provider">
          <div className="funding-provider-labels">
            {" "}
            <span className="funding-provider-type">
              {result.raw.dpofundingtype}
            </span>
          </div>{" "}
          <h3 className="tile-title">{result.raw.dpotitle}</h3>{" "}
          {result.raw.dpovaluetext ? (
            <p className="value-label">Value: {result.raw.dpovaluetext}</p>
          ) : null}{" "}
          <p className="open-to-label">Open to : {result.raw.dpoopento}</p>
        </a>
      </div>
    </>
  );
  const FullWidth = {
    width: "100%",
    maxWidth: "1250px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    gridTemplateColumns: "25% 1fr",
    marginTop: "5.6rem",
  };

  const subTitle = {
    color: "#001e62",
    fontFamily: "var(--lbs-heading-font-family),serif",
    fontSize: "2rem",
    lineHeight: "2.4rem",
    marginBottom: "1.3rem",
    display: "block",
  };

  useEffect(() => {
    isMobile && setShowFilters(false);
  }, [isMobile]);

  return (
    <AtomicSearchLayout style={FullWidth}>
      <style>{CoveStyles(isMobile, 3)}</style>
      <style>{CoveoDPOSearchResultsStyles(isMobile)}</style>
      <style>
        {`
          atomic-facet::part(value-count) {
            display: none;
          }
        `}
      </style>
      <Grid row>
        <AtomicResultsPerPage
          choicesDisplayed={resultsPerPage || "9"}
        ></AtomicResultsPerPage>
        <Grid column sm={12} md={4} lg={4}>
          {showSearchBar ? (
            <AtomicLayoutSection section="search">
              <span style={subTitle}>Refine</span>
              {isMobile ? (
                <Cta
                  url=""
                  onClick={() => setShowFilters(!showFilters)}
                  type="primary"
                  linkText={showFilters ? "Filter results" : "Apply filters"}
                />
              ) : (
                <></>
              )}
              {(showFilters || !isMobile) && (
                <>
                  <p style={{ marginTop: "16px" }}>
                    Know which scholarship, loan, or funding provider you are
                    looking for?
                  </p>
                  <AtomicSearchBox
                    redirectionUrl={redirectUrl || ""}
                    numberOfQueries={5}
                  >
                    <AtomicSearchBoxQuerySuggestions />
                  </AtomicSearchBox>
                </>
              )}
            </AtomicLayoutSection>
          ) : null}

          {facets ? (
            <div
              style={{ display: !showFilters && isMobile ? "none" : "block" }}
            >
              <AtomicLayoutSection section="facets">
                <AtomicFacetManager>
                  <Grid row>
                    {facets.map((facet) => (
                      <Grid
                        key={facet.fields.coveoFieldName}
                        column
                        sm={12}
                        lg={12}
                      >
                        <CoveoSearchFacet
                          key={facet.fields.coveoFieldName}
                          content={facet.fields}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </AtomicFacetManager>
              </AtomicLayoutSection>
            </div>
          ) : null}
        </Grid>
        {!showFilters && (
          <Grid column sm={12} md={8} lg={8}>
            <AtomicLayoutSection section="main">
              <AtomicLayoutSection section="status">
                <div
                  style={{
                    borderBottom: "0.1rem solid #001e62",
                    paddingBottom: "1.9rem",
                    marginBottom: "2.5rem",
                  }}
                >
                  <Grid row alignItems={"center"}>
                    <Grid column sm={5} md={6}>
                      <AtomicQuerySummary />
                    </Grid>

                    <Grid column sm={7} md={6}>
                      {sortOptions && (
                        <AtomicSortDropdown>
                          {sortOptions.map((option, i) => (
                            <AtomicSortExpression
                              key={`${option.fields?.label}${i}`}
                              expression={option?.fields.expression}
                              label={option?.fields.label}
                            ></AtomicSortExpression>
                          ))}
                        </AtomicSortDropdown>
                      )}
                    </Grid>
                  </Grid>
                </div>

                <AtomicBreadbox style={{ marginBottom: "2rem" }} />
                <AtomicDidYouMean />
              </AtomicLayoutSection>
              <AtomicLayoutSection section="results">
                <AtomicResultList
                  display="grid"
                  imageSize="small"
                  template={TemplateResovler}
                />

                <AtomicQueryError />
                <AtomicNoResults />
              </AtomicLayoutSection>
              {displayPagination && (
                <AtomicLayoutSection section="pagination">
                  <AtomicPager />
                </AtomicLayoutSection>
              )}
            </AtomicLayoutSection>
          </Grid>
        )}
      </Grid>
    </AtomicSearchLayout>
  );
};

export default CoveoDPOSearchResults;
