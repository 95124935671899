import Cta from "@components/Cta/Cta";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import { commaConjunction } from "@utilities/commaConjunction";
import { useDevice } from "@utilities/react/get-device/get-device";
import clsx from "clsx";
import Link from "@components/Link/Link";
import { Pagination } from "swiper/modules";
import { Swiper as SwiperCarousel, SwiperSlide } from "swiper/react";
import { NextContentfulImage } from "@components/Image";
import styles from "./C19_PromotionalArticles.module.scss";

export type ArticlePromotionType = {
  slug: string;
  title: string;
  description: string;
  image: ImageWrapperType;
  subtitle?: string;
  authors?: string[];
};

export interface PromotionalArticlesProps {
  articles: ArticlePromotionType[];
  ctaText?: string;
  isBlue?: boolean;
}

export const PromotionalArticles = (props: PromotionalArticlesProps) => {
  const { articles, isBlue, ctaText } = props;
  const device = useDevice();
  const isDesktop = ["desktop", "desktop-large"].includes(device);

  const buildArticles = (articles: ArticlePromotionType[]) => {
    return articles?.map((article, index) => {
      return isDesktop ? (
        <ArticlePromotion
          key={`${article.title}${index}`}
          isBlue={isBlue}
          ctaText={ctaText}
          article={article}
        />
      ) : (
        <SwiperSlide key={`${article.title}${index}`}>
          <ArticlePromotion
            isBlue={isBlue}
            ctaText={ctaText}
            article={article}
          />
        </SwiperSlide>
      );
    });
  };

  return (
    <div
      className={clsx(styles["promotional-articles"], {
        [styles["blue"]]: isBlue,
      })}
      data-items={articles?.length || null}
    >
      <div className="wrapper">
        <div className={styles.container}>
          {isDesktop ? (
            buildArticles(articles)
          ) : (
            <SwiperCarousel
              className={clsx({ ["on-dark bullet-white"]: isBlue })}
              allowTouchMove={true}
              spaceBetween={10}
              slidesPerView={"auto"}
              centeredSlides={true}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
            >
              {buildArticles(articles)}
            </SwiperCarousel>
          )}
        </div>
      </div>
    </div>
  );
};

const ArticlePromotion = (props: {
  article: ArticlePromotionType;
  ctaText?: string;
  isBlue?: boolean;
}) => {
  const { article, ctaText, isBlue } = props;

  const href = `/${article.slug}`;

  return (
    <article>
      {!!article.image && (
        <Link href={href} passHref>
          <a>
            <NextContentfulImage
              src={article.image.fields.image.fields.file.url}
              alt={article.image.fields.altText}
              height={
                article.image.fields.image.fields.file.details.image.height
              }
              width={article.image.fields.image.fields.file.details.image.width}
              sizes="(max-width: 768px) 100vw, 30vw"
            />
          </a>
        </Link>
      )}
      <div className={styles.text}>
        <div className={styles.line}></div>
        {article.subtitle && <p className={styles.label}>{article.subtitle}</p>}
        <h4>
          <Link href={href}>
            <a>{article.title}</a>
          </Link>
        </h4>
        <p>{article.description}</p>
        {article.authors && article.authors.length > 0 && (
          <p>
            By <strong>{commaConjunction.format(article.authors)}</strong>
          </p>
        )}
        {ctaText && (
          <Cta
            linkText={ctaText}
            type="tertiary"
            url={href}
            light={isBlue}
            icon="icon-arrow"
          />
        )}
      </div>
    </article>
  );
};

export default PromotionalArticles;
