import { ImageWrapperType } from "@customTypes/imageWrapper";
import {
  NextContentfulImage,
  NextContentfulLoaderProps,
} from "@components/Image";
import { useState } from "react";
import VideoPlayer from "@components/VideoPlayer";
import { getVideoUrl } from "@components/VideoPlayer/utils";
import videoIcon from "../../public/image/vidicon.png";

import styles from "./C09_VideoVimeoAndYoutube.module.scss";
import clsx from "clsx";

export interface VideoVimeoAndYoutubeProps {
  inSublayout?: boolean;
  videoCode: string;
  isVimeoCode?: boolean;
  overlayImage: ImageWrapperType;
  isAutoPlay?: boolean;
}

const loaderConfig: NextContentfulLoaderProps = { fit: "fill" };

export const VideoVimeoAndYoutube = (props: {
  content: VideoVimeoAndYoutubeProps;
}) => {
  const { content } = props;

  const { inSublayout, videoCode, isVimeoCode, overlayImage, isAutoPlay } =
    content;

  const videoUrl = getVideoUrl(isVimeoCode ? "vimeo" : "youtube", videoCode);

  const posterImage = isVimeoCode
    ? overlayImage?.fields?.image?.fields?.file?.url
    : overlayImage
      ? overlayImage?.fields?.image?.fields?.file?.url
      : videoCode
        ? `https://img.youtube.com/vi/${videoCode}/hqdefault.jpg`
        : null;
  const posterAltText: string = overlayImage?.fields?.altText || "";
  const [isPlaying, setIsPlaying] = isAutoPlay
    ? useState(true)
    : useState(false);

  const videoJSX = () => {
    return !videoCode ? null : (
      <div
        className={clsx(
          "component",
          styles.youtube,
          inSublayout && styles["two-col"]
        )}
      >
        <div className={styles["youtube-embed"]}>
          {!isPlaying ? (
            <div
              className={styles["overlay-image"]}
              onClick={() => setIsPlaying(true)}
            >
              <div
                className={styles["play-icon"]}
                aria-label="play icon that starts video"
              >
                <NextContentfulImage
                  src={videoIcon}
                  alt="play icon"
                  width={160}
                  height={160}
                />
              </div>
              {/* need to change null to a fallback image */}
              {posterImage && (
                <div className={styles["poster-image"]}>
                  <NextContentfulImage
                    src={posterImage}
                    alt={posterAltText}
                    width={inSublayout ? 640 : 1440}
                    height={inSublayout ? 360 : 810}
                    loaderConfig={loaderConfig}
                  />
                </div>
              )}
            </div>
          ) : (
            <VideoPlayer url={videoUrl} playing={isPlaying} controls />
          )}
        </div>
      </div>
    );
  };

  return <>{inSublayout ? <div>{videoJSX()}</div> : <>{videoJSX()}</>}</>;
};

export default VideoVimeoAndYoutube;
