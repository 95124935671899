import { useEffect, useState, useContext, Fragment } from "react";
import { useForm } from "react-hook-form";
import clsx from "clsx";
import {
  getPersonalDetails,
  getTitles,
  getProgrammesInterest,
  setEventBookingNotLoggedIn,
  setEventBookingLoggedIn,
} from "../../services/forms";
import Grid from "@components/Grid/Grid";
import { UserContext } from "../../context/user";
import FormField from "@components/Form/FormFields/FormFields";
import getCookie from "@utilities/getCookie";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import { handleSubmitFormErrors } from "@utilities/handleSubmitFormErrors";
import { useSettings } from "@utilities/context/settings";
import { getFormInitialValues, permanentFields } from "./InitialData";
import { useRouter } from "next/router";
import ClipLoader from "react-spinners/ClipLoader";
import { LOGIN_URL } from "../../constants";
import CountryStateSection from "@components/CountryStateSection/CountryStateSection";
import { findMatchingSelectId } from "@utilities/mapSelectValue";
import FormPrivacySection from "@components/FormPrivacySection/FormPrivacySection";
import setGADataLayer from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";
import { EventDetailProps } from "@components/C37.5_EventDetail/C37.5_EventDetail";
import { EventRegistrationForm } from "@customTypes/SiteSettings";
import ProgrammesListSection from "@components/ProgrammesListSection/ProgrammesListSection";
import styles from "./C71_EventRegistrationForm.module.scss";

let firstInteract = 0;

export const C71_EventRegistrationForm = (props: {
  content: EventDetailProps;
}) => {
  const { content } = props;
  const { eventCode, eventTitle, eventType } = content;
  const [submittedUserEmail, setSubmittedUserEmail] = useState<string>("");
  const [showNationalCountriesList, setShowNationalCountriesList] =
    useState<boolean>(false);
  const [countryInputValue, setCountryInputValue] = useState<any>("");
  const [countriesList, setCountriesList] = useState<any>(null);
  const [userPersonalDetails, setUserPersonalDetails] = useState<any>(null);
  const [titles, setTitles] = useState<any>(null);
  const [programmesListData, setProgrammesListData] = useState<any>(null);
  const [isFullEvent, setFullEvent] = useState<boolean>(false);

  const [handledSubmitFormErrors, setHandledSubmitFormErrors] =
    useState<Array<string> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [initialDataLoading, setInitialDataLoading] = useState<boolean>(false);
  const router = useRouter();
  const { user } = useContext(UserContext);

  const [loggedInUser, setLoggedInUser] = useState<any>(null);
  const [authToken, setAuthToken] = useState<string>("");
  const [canContactByEmail, setCanContactByEmail] = useState<boolean>(false);
  const { apiErrorMessages, siteSettings } = useSettings();

  const eventRegistrationForm: EventRegistrationForm =
    siteSettings?.eventRegistrationForm || ({} as EventRegistrationForm);
  const {
    eventRegistrationFormTitleText,
    loggedInTitleText,
    subTitleText,
    introText,
    introLoggedInText,
    successPageUrl,
    fullyBookedEventMessage,
  } = eventRegistrationForm;

  useEffect(() => {
    user ? setLoggedInUser(true) : setLoggedInUser(null);
    if (user) {
      setAuthToken(getCookie("access_token"));
    }
  }, [user]);

  const { register, handleSubmit, formState, watch, setValue, control } =
    useForm({
      mode: "onBlur",
    });

  const setLoggedInUserInitialData = async () => {
    const personalDetails = await getPersonalDetails(authToken);
    if (personalDetails?.result) {
      const { emailAddress, firstName, lastName, address, title } =
        personalDetails.result;

      setValue("emailAddress", emailAddress);
      setValue("firstName", firstName);
      setValue("title", title);
      setValue("lastName", lastName);
      setValue("emailAddress", emailAddress);
      setValue("homeCountry", address?.homeCountry);
      setValue("programmeSection", []);

      setUserPersonalDetails(personalDetails);
      setCanContactByEmail(
        loggedInUser && personalDetails?.result?.isStayInformed
      );
    }
  };

  const prepareFormData = async (loggedInUser: boolean) => {
    try {
      setHandledSubmitFormErrors(null);
      setInitialDataLoading(true);
      const titles = await getTitles();
      const programmesInterest = await getProgrammesInterest(eventCode || "");
      if (!programmesInterest.programmeTypes) {
        const handledSubmitFormErrors = handleSubmitFormErrors(
          programmesInterest,
          apiErrorMessages
        );
        window.scrollTo(0, 0);
        setHandledSubmitFormErrors(handledSubmitFormErrors);
      }
      let programs = programmesInterest?.programmeTypes.map((programme) => {
        return {
          id: programme.id,
          name: programme.value,
          value: programme.id,
        };
      });
      setProgrammesListData({ EVENT_PROGRAMS: programs });
      setFullEvent(!!programmesInterest?.isFull);
      setTitles(titles);

      if (loggedInUser) {
        await setLoggedInUserInitialData();
      }
      setInitialDataLoading(false);
    } catch (e) {
      const handledSubmitFormErrors = handleSubmitFormErrors(
        null,
        apiErrorMessages
      );
      window.scrollTo(0, 0);
      setHandledSubmitFormErrors(handledSubmitFormErrors);
      setInitialDataLoading(false);
    }
  };

  useEffect(() => {
    prepareFormData(loggedInUser);
  }, [loggedInUser]);

  const FormFields = getFormInitialValues(
    eventRegistrationForm,
    userPersonalDetails,
    loggedInUser,
    titles,
    watch
  );

  const redirectToSuccessPage = () => {
    setGADataLayer({
      event: "formComplete",
      formName: "event registration",
      programmeCode: "",
      businessUnit: getBusinessUnit(),
      eventType: eventType,
    });

    if (isFullEvent) {
      window.scrollTo(0, 0);
      setHandledSubmitFormErrors([fullyBookedEventMessage]);
      return;
    }

    let genuinePath = router?.query?.referPage || router?.asPath;
    let status = user ? "" : "verification";

    const fullSuccessPageUrl: string =
      "/" +
      successPageUrl +
      "?returnToPreviousPageUrl=" +
      genuinePath +
      "&referPage=" +
      genuinePath +
      "&eventName=" +
      eventTitle.replaceAll("&", "%26") +
      "&status=" +
      status;

    router.push(fullSuccessPageUrl);
  };

  useEffect(() => {
    const subscription = watch((e, a) => {
      if (a.type === "change" && firstInteract < 1) {
        setGADataLayer({
          event: "formStart",
          formName: "event registration",
          eventType: eventType,
        });
        firstInteract++;
      }
      return () => subscription.unsubscribe();
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = async (data) => {
    setHandledSubmitFormErrors(null);
    setLoading(true);

    const submittedData = {
      profile: {
        isStayInformed: canContactByEmail || data?.isStayInformed === "true",
        emailAddress: data.emailAddress,
        title: loggedInUser
          ? data.title
          : findMatchingSelectId(titles, data.title),
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
      },
      eventCode: eventCode,
      programmeTypesOfInterest:
        typeof data.programmeSection === "string"
          ? [data.programmeSection]
          : data.programmeSection,
      homeCountry: data.homeCountry,
    };

    try {
      setSubmittedUserEmail(data.emailAddress);
      const setEventBooking = loggedInUser
        ? setEventBookingLoggedIn
        : setEventBookingNotLoggedIn;
      const response = await setEventBooking(
        submittedData,
        sessionStorage.getItem("crm_campaign")
      );

      if (response.status === 201) {
        redirectToSuccessPage();
      } else {
        const handledSubmitFormErrors = handleSubmitFormErrors(
          response,
          apiErrorMessages
        );
        window.scrollTo(0, 0);
        setHandledSubmitFormErrors(handledSubmitFormErrors);
      }
      setLoading(false);
    } catch (e) {
      const handledSubmitFormErrors = handleSubmitFormErrors(
        null,
        apiErrorMessages
      );
      window.scrollTo(0, 0);
      setHandledSubmitFormErrors(handledSubmitFormErrors);
      setLoading(false);
    }
  };

  const FieldJSX = ({ labelText = "", dataText }) => (
    <div className={styles["field-row"]}>
      <label>{labelText}:</label>
      {dataText ? <p>{dataText}</p> : ""}
    </div>
  );

  const renderFormSection = () => {
    if (initialDataLoading)
      return (
        <div className={styles.spinner}>
          <ClipLoader
            color="#001e62"
            size={70}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      );

    if (loggedInUser && !userPersonalDetails) return null;
    if (!programmesListData) return null;

    return (
      <section className={styles["form-group-wrapper"]}>
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className={styles["form-row"]} />
          {FormFields.map((field: any, index) => {
            if (field?.isHidden) return null;

            if (field.propertyName === "homeCountry") {
              return (
                <CountryStateSection
                  key={`${field.labelText}${index}`}
                  isNationalityVariant={true}
                  register={register}
                  errors={formState.errors}
                  countryInputValue={countryInputValue}
                  setCountryInputValue={setCountryInputValue}
                  countries={countriesList}
                  control={control}
                  setValue={setValue}
                  setCountries={setCountriesList}
                  selectedCountry={watch(field?.propertyName) as string}
                  setSelectedCountry={(countryVal) => {
                    setValue(field?.propertyName, countryVal);
                  }}
                  showCountriesList={showNationalCountriesList}
                  setShowCountriesList={setShowNationalCountriesList}
                  countryOfResidenceLabelText={field.labelText}
                  countryValidationMsg={field.validationMsg}
                />
              );
            }

            if (field.propertyName === "programmeSection") {
              return (
                <ProgrammesListSection
                  key={`${field.labelText}${index}`}
                  register={register}
                  programme={"EVENT_PROGRAMS"}
                  programmesListData={programmesListData}
                  programmesHeadingText={field.labelText}
                  programmesHintText={""}
                  programmesSelectedTypes={watch(field?.propertyName) || []}
                  setProgrammesSelectedTypes={(val) =>
                    setValue(field?.propertyName, val)
                  }
                  formState={formState}
                  name={field.propertyName}
                />
              );
            }

            return (
              <Fragment key={`container-${field?.labelText}-${index}`}>
                {loggedInUser &&
                permanentFields.includes(field.propertyName) ? (
                  <FieldJSX
                    key={`${field?.labelText}-${index}`}
                    labelText={field?.labelText}
                    dataText={field?.dataText}
                  />
                ) : (
                  <FormField
                    formType={field?.formType}
                    watch={watch}
                    validation={field?.validation}
                    type={field?.type || "text"}
                    register={register}
                    property={field?.dataText}
                    key={`${field.labelText}${index}`}
                    hintText={field?.hintText}
                    placeholder={field.labelText}
                    options={field?.options}
                    name={field?.propertyName}
                    errors={formState.errors}
                    setSelectValue={(e) => setValue(field?.propertyName, e)}
                    selectValue={watch(field?.propertyName) as string}
                  />
                )}
              </Fragment>
            );
          })}

          {!canContactByEmail && (
            <FormPrivacySection register={register} formState={formState} />
          )}

          <div className="btn-and-text-wrapper">
            <SubmitButton
              loading={loading}
              id="submitButton"
              text="Book Event"
              type={"secondary"}
            />
          </div>
        </form>
      </section>
    );
  };

  if (isFullEvent)
    return (
      <div className={styles["inner-container"]}>
        <p className={styles["offsetTop1"]}>
          We’re sorry, but this event is now full. Please check our website for
          other events that may be of interest.
        </p>
      </div>
    );

  return (
    <div
      className={clsx(
        styles["event-registration-form"],
        "wrapper component-wrapper"
      )}
    >
      <Grid row>
        <Grid column sm={12}>
          <div>
            <h1 className="h2">{eventRegistrationFormTitleText}</h1>
            <h2 className={clsx("h4", styles["sub-title"])}>{subTitleText}</h2>
            <h1 className="h2">{loggedInUser ? loggedInTitleText : ""}</h1>

            {handledSubmitFormErrors &&
              handledSubmitFormErrors.map((error, index) => (
                <FormErrorMessage
                  key={index}
                  text={error}
                  userEmail={submittedUserEmail}
                />
              ))}
          </div>
          <section className={clsx(styles.form, "form", styles.cf)}>
            <div className={styles["fields-floated"]}>
              <Grid row>
                <Grid column sm={12}>
                  <p>{loggedInUser ? introLoggedInText : introText}</p>
                  <br />
                  <br />
                  {!loggedInUser && (
                    <p>
                      Already registered?{" "}
                      <a className="underline" href={LOGIN_URL}>
                        Log in here.
                      </a>
                    </p>
                  )}
                </Grid>
              </Grid>
              {renderFormSection()}
            </div>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default C71_EventRegistrationForm;
