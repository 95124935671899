import C54_ProfilePhotoLinkBox from "@components/C54_ProfilePhotoLinkBox/C54_ProfilePhotoLinkBox";
import Grid from "@components/Grid/Grid";
import RenderComponents from "@utilities/renderComponents";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import Head from "next/head";
import {
  ContributorDetailContextProps,
  ContributorDetailProvider,
} from "../../../context/contributorDetails";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import { ContentfulImage } from "@customTypes/ContentfulImage";
import styles from "./ContributorProfileDetail.module.scss";
import { FacultyProfileData } from "context/facultyDetails";

export interface ContributorProfileDetailProps
  extends ContributorDetailContextProps {
  slug?: string;
  bodyContent?: any;
  detailContent?: any;
  profileBoxContent?: any;
  contentTinyMce?: TinyMceRichText;
  profilePhoto?: ContentfulImage;
  facultyProfileData?: FacultyProfileData;
}

export const ContributorProfileDetail = (props: {
  content: ContributorProfileDetailProps;
}) => {
  const { content } = props;
  const { bodyContent, detailContent, profileBoxContent, ...details } = content;
  const {
    profileFirstname,
    profileSurname,
    profilePositionTitle,
    profileDetailsText,
    profilePhoto,
  } = details;

  const title =
    content?.facultyProfileData?.fields.profilePositionTitle ||
    profilePositionTitle;
  return (
    <ContributorDetailProvider details={details}>
      <Head>
        <meta
          name="profilephotosmallurl"
          content={`${profilePhoto?.fields.file.url}?q=70&w=512&h=386` || ""}
        />

        <meta
          name="profilephotosmallalt"
          content={profilePhoto?.fields.title || ""}
        />

        <meta name="profilepositiontitle" content={title || ""} />

        <meta name="profilesurname" content={profileSurname || ""} />
      </Head>
      <div className="outer-wrap">
        <div className="wrapper">
          <Grid row justify="space-between">
            <Grid column sm={12} md={8}>
              <h1 className={styles.h2}>
                {profileFirstname} {profileSurname}
              </h1>
              <h5 className={styles["contributor-details__p"]}>{title}</h5>
              <h5 className={styles["contributor-details__role"]}>
                {content?.facultyProfileData?.fields.profileQualification}
              </h5>

              <div className="rich-text">
                <RichTextDisplay
                  richText={content?.profileDetailsText}
                  tinyMceRichText={content?.contentTinyMce}
                />
              </div>
              {RenderComponents(detailContent)}

              {RenderComponents(bodyContent)}
            </Grid>
            <Grid column sm={12} md={4} lg={3} customClass="tp4">
              <C54_ProfilePhotoLinkBox
                content={{ ...details, ...details.contributorProfileData }}
              />
              {RenderComponents(profileBoxContent)}
            </Grid>
          </Grid>
        </div>
      </div>
    </ContributorDetailProvider>
  );
};

export default ContributorProfileDetail;
