import styles from "./C66_MyProfileSavedContent.module.scss";
import { getSavedPages, removeSavedPage } from "../../services/forms";
import { useEffect, useState, useContext } from "react";
import Cta from "../Cta/Cta";
import Link from "@components/Link/Link";
import Grid from "@components/Grid/Grid";
import ApiError from "@components/GenericApiError/Error";
import { UserContext } from "../../context/user";
import getCookie from "@utilities/getCookie";
import ClipLoader from "react-spinners/ClipLoader";

interface SavedItems {
  pages: SavedItem[];
  docs: SavedItem[];
}

interface SavedItem {
  url: string;
  title: string;
  description: string;
  id: string;
  type: string;
}

export interface SavedContentProps {
  removeText: string;
  savedPagesText: string;
  savedDocumentsText: string;
  noItemsText: string;
}

export const SavedContent = (props: { content: SavedContentProps }) => {
  const { content } = props;
  const { removeText, savedPagesText, savedDocumentsText, noItemsText } =
    content;
  const [saved, setSaved] = useState<SavedItems | null>(null);
  const [error, setError] = useState<boolean>(false);

  const { user } = useContext(UserContext);

  useEffect(() => {
    const getSaved = async () => {
      if (user) {
        try {
          const savedPages = await getSavedPages(getCookie("access_token"));

          const mapSavedPages = await Promise.all(
            savedPages?.result?.map(async (item) => {
              return {
                ...(await (
                  await fetch(`/api/getEntryOrAssetById?id=${item}`)
                ).json()),
                id: item,
              };
            })
          );

          setSaved({
            pages: mapSavedPages.filter((item) => item.type === "entry"),
            docs: mapSavedPages.filter((item) => item.type === "asset"),
          });
        } catch (e) {
          console.log(e);
          setError(true);
        }
      }
    };

    getSaved();
  }, [user]);

  const removeSavedItem = async (id: string, index: number, type: string) => {
    const result = await removeSavedPage(id, getCookie("access_token"));

    if (saved && result?.status == 200) {
      const updated = type == "entry" ? [...saved.pages] : [...saved.docs];
      updated.splice(index, 1);
      if (type == "entry") {
        setSaved({ ...saved, pages: updated });
      } else {
        setSaved({ ...saved, docs: updated });
      }
    }
  };

  if (!user) return null;

  if (error) {
    return <ApiError />;
  }

  if (!saved) {
    return (
      <div className={styles["spinner"]}>
        <ClipLoader
          color="#001e62"
          size={70}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return (
    <div className="wrapper">
      <Grid row>
        <Grid column md={8} sm={12}>
          <div className={styles["my-profile-saved-content"]}>
            <div className={styles.coont}>
              {!!saved?.pages?.length && (
                <>
                  <label>{savedPagesText}</label>
                  <ul>
                    {saved.pages.map((page, index) => {
                      return (
                        <li key={page?.id}>
                          <div className={styles["copy-cs"]}>
                            <h2>
                              <Link href={`/${page?.url}`}>
                                <a>{page?.title}</a>
                              </Link>
                            </h2>
                            <p className={styles["sub-copy"]}>
                              {page?.description}
                            </p>
                          </div>
                          <div className={styles.remove}>
                            <Cta
                              url="#"
                              linkText={removeText}
                              type="tertiary"
                              onClick={() =>
                                removeSavedItem(page.id, index, page.type)
                              }
                              icon="icon-cross"
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
              {!!saved?.docs?.length && (
                <>
                  <label>{savedDocumentsText}</label>
                  <ul>
                    {saved.docs.map((doc, index) => {
                      return (
                        <li key={doc.id}>
                          <div className={styles["copy-cs"]}>
                            <h2>
                              <a
                                href={`${doc.url}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {doc.title}
                              </a>
                            </h2>
                          </div>
                          <div className={styles.remove}>
                            <Cta
                              url="#"
                              linkText={removeText}
                              type="tertiary"
                              onClick={() =>
                                removeSavedItem(doc.id, index, doc?.type)
                              }
                              icon="icon-cross"
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
              {!saved ||
                (!saved.docs.length && !saved.pages.length && (
                  <label id="lblEmptyData">{noItemsText}</label>
                ))}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SavedContent;
