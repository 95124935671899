import Cta from "@components/Cta/Cta";
import { ctaLink } from "@customTypes/generalLink";
import clsx from "clsx";

import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import styles from "./ArticleSummary.module.scss";

export interface ArticleSummaryProps {
  articleSummary?: string;
  articleSummaryLabel?: string;
  articleSummaryTinyMce?: TinyMceRichText;
  ctaLink?: ctaLink;
}

const ArticleSummary = (props: { content: ArticleSummaryProps }) => {
  const { content } = props;
  const {
    articleSummary,
    articleSummaryLabel,
    articleSummaryTinyMce,
    ctaLink,
  } = content;

  const Heading = !ctaLink ? "h2" : "h3";

  return (
    <div className={clsx("component", styles.summary)}>
      <div className="wrapper">
        {articleSummaryLabel && <Heading>{articleSummaryLabel}</Heading>}
        <RichTextDisplay
          richText={articleSummary}
          tinyMceRichText={articleSummaryTinyMce}
        />
        {ctaLink && <Cta type="secondary" {...ctaLink} />}
      </div>
    </div>
  );
};

export default ArticleSummary;
