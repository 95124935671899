import { useEffect, useRef, useState } from "react";
import styles from "./EEAndOnlineCourseSelector.module.scss";
import clsx from "clsx";
import { EEAndOnlineCourseSelectorProps } from "./interfaces";
import useOnClickOutside from "@utilities/hooks/useOnClickOutside";
import FormField from "@components/Form/FormFields/FormFields";

const EEAndOnlineCourseSelector = (props: EEAndOnlineCourseSelectorProps) => {
  const {
    value,
    register,
    errors,
    name,
    validation,
    placeholder,
    options,
    setValue,
    clearErrors,
    watch,
    selectDescription,
    inputDescription,
  } = props;
  const [filteredOptions, setFilteredOptions] = useState(options);

  const [isFocused, setIsFocused] = useState(false);
  const [isShowOptions, setShowOptions] = useState(false);

  const notFoundItem = { id: "none", value: "Other" };

  const getFilteredOptions = (inputValue) => {
    const result = options.filter((item) =>
      item?.value?.toLowerCase().includes(inputValue.toLowerCase())
    );

    if (result.findIndex((item) => item === notFoundItem) === -1) {
      result.push(notFoundItem);
    }

    setFilteredOptions(result);
  };

  const hasError = errors && errors[name];

  useEffect(() => {
    setIsFocused(!!value);
  }, [value]);

  const handleOptionSelect = (option) => {
    setValue(name, option.value);
    setValue(name + "FreeText", "");
    clearErrors(name);
    setIsFocused(!!value);
    setShowOptions(false);
  };

  const handleOnBlur = () => {
    setIsFocused(!!value);
    setShowOptions(false);
  };

  const handleOnFocus = () => {
    setIsFocused(true);
  };

  const handleInputOnChange = (e) => {
    setValue(name, e.target.value);
    getFilteredOptions(e.target.value);
    setShowOptions(true);
  };

  const fieldRef: any = useRef();

  useOnClickOutside(fieldRef, () => handleOnBlur());

  const renderOptions = () => {
    return (
      <ul className={styles["countries-wrapper"]}>
        {filteredOptions?.map((option, index) => (
          <li
            key={`${option.id}${index}`}
            className={styles["countries-item"]}
            value={option.id}
            onClick={() => handleOptionSelect(option)}
          >
            {option.value}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div>
      <p>{selectDescription}</p>
      <div
        className={clsx(
          "form",
          "form-row",
          styles["form-row"],
          "field-row",
          styles["country-section"],
          isFocused && "focused",
          hasError && "error"
        )}
      >
        <div className="form-label base4">
          <div className="label">
            <label htmlFor="HomeCountry">{placeholder}</label>
          </div>
        </div>
        <div className="form-field base6">
          <div className="field" ref={fieldRef}>
            <>
              <input
                name={name}
                className={clsx("smart-focus", styles["homecountry-box"])}
                type="text"
                value={value}
                autoComplete="off"
                {...register(name, {
                  onChange: handleInputOnChange,
                  onFocus: handleOnFocus,
                  ...validation,
                })}
              />
              {isShowOptions && options?.length > 0 && renderOptions()}
            </>
          </div>

          {hasError && (
            <div className="form-row error">
              <div className="form-field base6">
                <div
                  className={clsx(
                    "field",
                    "message-wrapper",
                    "inline-message",
                    "field_error_msg"
                  )}
                  tabIndex={0}
                >
                  <p className={clsx("message", "active")}>
                    {errors[name]?.message}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EEAndOnlineCourseSelector;
