import { ProgrammeDetails } from "@customTypes/programmeDetails";
import { useSettings } from "@utilities/context/settings";
import styles from "./C272_ProgrammeComparisonTable.module.scss";
import { isUrl } from "@utilities/regex";
import Link from "@utilities/link";
import clsx from "clsx";

interface ProgrammeDEProps {
  count: number;
  programmeDetails: ProgrammeDetails;
}

export const ProgrammeDE = (props: ProgrammeDEProps) => {
  const { count, programmeDetails } = props;

  const {
    programmeSettings: {
      programmeContentLabel,
      entryRequirementsLabel,
      keyDifferentiatorsLabel,
      programmeDurationLabel,
      programmeLocationLabel,
      programmeFeesLabel,
      fallbackProgrammeIterationFee,
      fallbackProgrammeIterationDuration,
      fallbackProgrammeIterationLocation,
      programmeInfoCtaLabel,
    },
  } = useSettings();

  const {
    slug,
    programmeTitle,
    entryRequirements,
    keyDifferentiators,
    programmeContent,
    programmeImportData,
  } = programmeDetails;

  const link = slug.match(isUrl) ? slug : `/${slug}`;

  return (
    <li className={clsx(styles["programme-column"], "programme-column")}>
      <div
        className={clsx(styles["top-heading"], "top-heading")}
        data-reference="10"
      >
        {
          <Link href={link}>
            <h3>{programmeTitle}</h3>
          </Link>
        }
      </div>
      <div className={styles.info}>
        {count === 1 && (
          <div className={styles["labels-wrapper"]}>
            <div className={clsx(styles.labels, "labels")}>
              <span data-reference="1" aria-hidden="true">
                <span>{programmeContentLabel}</span>
              </span>
              <span data-reference="2" aria-hidden="true">
                <span>{entryRequirementsLabel}</span>
              </span>
              <span data-reference="3" aria-hidden="true">
                <span>{keyDifferentiatorsLabel}</span>
              </span>
              <span data-reference="4" aria-hidden="true">
                <span>{programmeFeesLabel}</span>
              </span>
              <span data-reference="5" aria-hidden="true">
                <span>{programmeDurationLabel}</span>
              </span>
              <span data-reference="6" aria-hidden="true">
                <span>{programmeLocationLabel}</span>
              </span>
            </div>
          </div>
        )}
        <div data-reference="1">
          <span className={clsx(styles["inner-wrapper"], "leftside-text")}>
            <span className={styles["d-invis"]}>{programmeContentLabel}</span>
            {programmeContent}
          </span>
        </div>
        <div data-reference="2">
          <span className={clsx(styles["inner-wrapper"], "leftside-text")}>
            <span className={styles["d-invis"]}>{entryRequirementsLabel}</span>
            {entryRequirements}
          </span>
        </div>
        <div data-reference="3">
          <span className={clsx(styles["inner-wrapper"], "leftside-text")}>
            <span className={styles["d-invis"]}>{keyDifferentiatorsLabel}</span>
            {keyDifferentiators}
          </span>
        </div>
        <div data-reference="4">
          <span
            className={clsx(
              styles["inner-wrapper"],
              "rich-text",
              styles["rich-text"]
            )}
          >
            <span className={styles["d-invis"]}>{programmeFeesLabel}</span>
            {programmeImportData && programmeImportData[0]?.programmeFee
              ? programmeImportData[0]?.programmeFee
              : fallbackProgrammeIterationFee}
          </span>
        </div>
        <div data-reference="5">
          <span className={styles["inner-wrapper"]}>
            <span className={styles["d-invis"]}>{programmeDurationLabel}</span>
            {programmeImportData && programmeImportData[0]?.programmeDuration
              ? programmeImportData[0]?.programmeDuration
              : fallbackProgrammeIterationDuration}
          </span>
        </div>
        <div data-reference="6">
          <span className={styles["inner-wrapper"]}>
            <span className={styles["d-invis"]}>{programmeLocationLabel}</span>
            {programmeImportData && programmeImportData[0]?.programmeLocation
              ? programmeImportData[0]?.programmeLocation
              : fallbackProgrammeIterationLocation}
          </span>
        </div>
      </div>
      <div className={styles["bottom-ctas"]}>
        {
          <Link href={link} className="cta">
            {programmeInfoCtaLabel}
          </Link>
        }
      </div>
    </li>
  );
};

export default ProgrammeDE;
