import Cta from "@components/Cta/Cta";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import { ReactNode, useEffect, useState } from "react";
import clsx from "clsx";
import { ItemState } from "./Accordion";
import styles from "./C11_VerticalAccordion.module.scss";

export interface AccordionItemProps {
  content: any;
  contentTinyMce: TinyMceRichText;
  childContent?: ReactNode;
  title: ReactNode;
  accordionState: ItemState;
  item: number;
  toggleAccordion: (event, item) => void;
}

export const AccordionItem = (props: AccordionItemProps) => {
  const {
    content,
    childContent,
    title,
    contentTinyMce,
    accordionState,
    item,
    toggleAccordion,
  } = props;
  const [isVisible, setVisibility] = useState(false);
  const [contentHeight, setContentHeight] = useState(
    accordionState.contentHeight
  );
  const isRichText =
    content?.nodeType === "document" || contentTinyMce?.nodeType === "document";
  const isArray = Array.isArray(content) && content?.length > 0;
  let isLinkList = false;

  if (isArray)
    isLinkList = content.some(
      (link) => (!!link?.url || !!link?.contentReference) && link?.linkText
    );

  useEffect(() => {
    // Set height for animation
    setContentHeight(accordionState.contentHeight);

    // If it's open, set visibility and set content to auto so it can be responsive on resize
    if (accordionState.isOpen) {
      setVisibility(true);

      setTimeout(() => {
        setContentHeight("auto");
      }, 300);
    } else {
      setTimeout(() => {
        setContentHeight("0");
      }, 10);

      setTimeout(() => {
        setVisibility(false);
      }, 300);
    }
  }, [accordionState.isOpen]);

  return (
    <li>
      <a
        href="#"
        className={styles["toggle-link"]}
        aria-expanded={isVisible}
        onClick={(e) => {
          toggleAccordion(e, item);
        }}
      >
        {title}
      </a>
      <div
        className={styles["accordion-drop"]}
        aria-hidden={!isVisible}
        style={{
          visibility: isVisible ? "visible" : "hidden",
          height: contentHeight,
        }}
      >
        <div className={styles["content"]}>
          <section
            className={clsx(
              "component",
              "text-placeholder",
              styles["text-placeholder"]
            )}
          >
            {!!childContent ? (
              childContent
            ) : (
              <>
                {isRichText && (
                  <RichTextDisplay
                    richText={content}
                    tinyMceRichText={contentTinyMce}
                  />
                )}
                {isLinkList &&
                  content
                    .filter(Boolean)
                    .map((link, i) => (
                      <Cta
                        key={`${link.url}${link.linkText}${i}`}
                        aria-expanded="false"
                        type="tertiary"
                        {...link}
                        icon="icon-arrow"
                      />
                    ))}
                {!isRichText && !isLinkList && content}
              </>
            )}
          </section>
        </div>
      </div>
    </li>
  );
};

export default AccordionItem;
