import { useState, useEffect, useRef } from "react";
import { AtomicFacet, AtomicNumericFacet } from "@coveo/atomic-react";

interface CoveoSearchFacetProps {
  content: SearchFacetFields;
}

export interface SearchFacetFieldsData {
  metadata: { tags: string[] };
  fields: SearchFacetFields;
}

interface SearchFacetFields {
  label: string;
  coveoFieldName: string;
  coveoFacetType: string;
}

export const CoveoSearchFacet = ({ content }: CoveoSearchFacetProps) => {
  const { label, coveoFieldName, coveoFacetType } = content;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const facetRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent) => {
    if (facetRef.current && !facetRef.current.contains(event.target as Node)) {
      setIsCollapsed(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  let facet;

  if (coveoFacetType === "atomic-facet") {
    facet = (
      <AtomicFacet
        facet-id={coveoFieldName}
        withSearch={false}
        isCollapsed={isCollapsed}
        field={coveoFieldName}
        label={label}
        numberOfValues={500}
        sortCriteria="occurrences"
      />
    );
  } else if (coveoFacetType === "atomic-numeric-facet") {
    facet = <AtomicNumericFacet field={coveoFieldName} label={label} />;
  } else {
    return null;
  }

  return (
    <div ref={facetRef} onClick={() => setIsCollapsed(false)}>
      {facet}
    </div>
  );
};

export default CoveoSearchFacet;
