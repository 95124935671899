export const CoveoDPOSearchResultsStyles = (isMobile) => `
atomic-result-list::part(result-list) {
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem 3rem;
  margin-bottom: 2.4rem;
}

atomic-result-list::part(result-list-grid-clickable-container outline) {
  background: white;
  padding: 0;
  border-radius: 0;
}

a:hover .tile-title {
  text-decoration: underline;
}

.funding-provider {
  background-color: #fff;
  padding: 2.4rem 2.4rem 2rem;
  height: 100%;
  text-decoration: none;
  display: block;
}

.funding-provider .funding-provider-labels {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1.2rem;
}

.funding-provider-type {
  font-size: 1.4rem;
  letter-spacing: .88px;
  line-height: 2.2rem;
  font-weight: 500;
  text-transform: uppercase;
  color: #6e6259;
  font-family: var(--lbs-base-font-family), sans-serif;
}

.tile-title {
  font-family: var(--lbs-heading-font-family), serif;
  font-size: 3rem;
  line-height: 3.8rem;
  margin-bottom: 1.5rem;
}

.value-label {
  margin-bottom: 2.4rem;
  line-height: 1.5;
  font-size: 1.6rem;
  color: #001e62;
  font-family:var(--lbs-base-font-family), sans-serif;
  font-weight: bold;
}

.open-to-label {
  font-weight: 500;
  margin-bottom: 1rem;
  line-height: 1.5;
  font-size: 1.6rem;
  font-family: var(--lbs-base-font-family), sans-serif;

}

atomic-result-list::part(result-list) {
  grid-template-columns: ${isMobile ? "repeat(1, 1fr)" : "repeat(2, 1fr)"} ;
}
`;
