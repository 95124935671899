import { VideoPlayerType } from "./VideoPlayer.types";

export const getVideoUrl = (
  videoPlayerType: VideoPlayerType,
  videoCode?: string
) => {
  if (!videoCode) {
    return undefined;
  }

  switch (videoPlayerType) {
    case "vimeo":
      return `https://vimeo.com/${videoCode}`;
    case "youtube":
      return `https://www.youtube.com/watch?v=${videoCode}&modestbranding=1`;
  }
};
