import Grid from "@components/Grid/Grid";
import { NextContentfulImage } from "@components/Image";
import { BSRArticleType } from "@customTypes/BSRArticle";
import { EventDetail } from "@customTypes/EventDetail";
import clsx from "clsx";
import { parseISO } from "date-fns";
import { useSettings } from "@utilities/context/settings";

import styles from "./C281_EditorsChoice.module.scss";
import EditorsChoiceMedia, {
  EditorsChoiceMediaType,
} from "./EditorsChoiceMedia";
import EditorsChoiceTile, {
  EditorsChoiceTileType,
  TileSize,
} from "./EditorsChoiceTile";

export interface EditorsChoiceProps {
  headerTitle?: string;
  headerDescription?: string;
  primaryArticle: BSRArticleType | EventDetail;
  secondaryArticle: BSRArticleType | EventDetail;
  tertiaryArticles: (BSRArticleType | EventDetail)[];
  editorsChoicePrimaryArticleImage: any;
  editorsChoiceSecondaryArticleImage: any;
}

const EditorsChoice = (props: { content: EditorsChoiceProps }) => {
  const { content } = props;
  const {
    headerTitle,
    headerDescription,
    primaryArticle,
    secondaryArticle,
    tertiaryArticles,
    editorsChoicePrimaryArticleImage,
    editorsChoiceSecondaryArticleImage,
  } = content;

  const { allTags } = useSettings();

  function getTagData(pageTags, allTags) {
    const tagsCollection = pageTags?.map((tag) => {
      const name = allTags?.find(
        (t) => t.id.toLocaleLowerCase() === tag.sys.id.toLocaleLowerCase()
      )?.name;
      return {
        displayNameAndParamValue: name?.split(":")[1],
        id: tag?.sys?.id,
        queryParameter: name?.split(":")[0].toLocaleLowerCase(),
      };
    });

    return tagsCollection;
  }

  const formatBSRArticle = (
    article: BSRArticleType,
    articleImageObject?: any
  ): EditorsChoiceTileType => {
    const {
      articleTitle,
      articlePublishedDate,
      slug,
      shortDescription,
      articleDescription,
      articleImage,
      authorsList,
      youTubeVideoId,
      vimeoVideoId,
      soundcloudPodcastId,
    } = article.fields;

    const tags = getTagData(article.metadata.tags, allTags);

    return {
      title: articleTitle,
      tags: tags ?? [],
      date: parseISO(articlePublishedDate),
      linkUrl: slug,
      description: shortDescription ?? articleDescription,
      isEvent: false,
      image:
        articleImageObject != undefined ? articleImageObject : articleImage,
      authors: authorsList?.length
        ? authorsList.map(({ fields }) => fields.articleAuthorName)
        : undefined,
      mediaToRender:
        youTubeVideoId || vimeoVideoId
          ? EditorsChoiceMediaType.VIDEO
          : !!soundcloudPodcastId
            ? EditorsChoiceMediaType.PODCAST
            : undefined,
    };
  };

  const formatEventDetail = (event: EventDetail): EditorsChoiceTileType => ({
    title: event.fields.eventTitle,
    date: parseISO(event.fields.eventStarts),
    linkUrl: event.fields.slug,
    description:
      event.fields.eventShortDescription ?? event.fields.eventLongDescription,
    isEvent: true,
    image: !!event.fields.eventTopImage
      ? {
          fields: {
            image: event.fields.eventTopImage,
          },
        }
      : undefined,
    authors: event.fields.eventOrganisedByList,
  });

  const formatData = (
    articleOrEvent: BSRArticleType | EventDetail,
    articleImageObject?: any
  ) => {
    return articleOrEvent.sys.contentType.sys.id === "bsrContentPage"
      ? formatBSRArticle(articleOrEvent as BSRArticleType, articleImageObject)
      : formatEventDetail(articleOrEvent as EventDetail);
  };

  const largeTileViewModel = formatData(
    primaryArticle,
    editorsChoicePrimaryArticleImage
  );
  const smallTileUpperViewModel = formatData(tertiaryArticles[0]);
  const smallTileLowerViewModel = formatData(tertiaryArticles[1]);
  const mediumTileViewModel = formatData(
    secondaryArticle,
    editorsChoiceSecondaryArticleImage
  );

  return (
    <div className="wrapper">
      <div className={clsx("component", styles["editors-choice"])}>
        {headerTitle && (
          <div className={styles["editors-choice--header"]}>
            <h4 className={styles["title-header"]}>{headerTitle}</h4>
            {headerDescription && <p>{headerDescription}</p>}
          </div>
        )}

        <Grid row>
          <Grid column sm={12} md={8} customClass={styles["left-col"]}>
            <div className={styles["editors-choice--image"]}>
              <div className={styles["editor-choice-tile"]}>
                <div className={styles["tile-image"]}>
                  {!!largeTileViewModel.image && (
                    <NextContentfulImage
                      alt={largeTileViewModel.image.fields.altText}
                      src={
                        largeTileViewModel.image.fields.image.fields.file.url
                      }
                      fill
                      sizes="(max-width: 1024px) 100vw, 50vw"
                      loading="eager"
                      priority
                    />
                  )}
                </div>
                <EditorsChoiceMedia
                  mediaToRender={largeTileViewModel.mediaToRender}
                  size={TileSize.LARGE}
                />
              </div>
            </div>
          </Grid>

          <Grid column sm={12} md={4} customClass={styles["right-col"]}>
            <div
              className={clsx(
                styles["editors-choice--small"],
                styles["desktop-only"]
              )}
            >
              <div className={styles["editor-choice-tile"]}>
                <EditorsChoiceTile
                  tile={smallTileUpperViewModel}
                  size={TileSize.SMALL}
                />
              </div>
            </div>
            <div className={styles["editors-choice--small"]}>
              <div className={styles["editor-choice-tile"]}>
                <EditorsChoiceTile
                  tile={smallTileLowerViewModel}
                  size={TileSize.SMALL}
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid row>
          <Grid column sm={12} md={8} customClass={styles["left-col"]}>
            <div
              className={clsx(
                styles["editors-choice--image"],
                styles["mobile-only"]
              )}
            >
              <div className={styles["editor-choice-tile"]}>
                <div className={styles["tile-image"]}>
                  <EditorsChoiceMedia
                    mediaToRender={largeTileViewModel.mediaToRender}
                    size={TileSize.LARGE}
                  />
                  {largeTileViewModel.image && (
                    <NextContentfulImage
                      alt={largeTileViewModel.image.fields.altText}
                      src={
                        largeTileViewModel.image.fields.image.fields.file.url
                      }
                      height={
                        largeTileViewModel.image.fields.image.fields.file
                          .details.image.height
                      }
                      width={
                        largeTileViewModel.image.fields.image.fields.file
                          .details.image.width
                      }
                      loading="eager"
                      priority
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles["editors-choice--large"]}>
              <div className={styles["editor-choice-tile"]}>
                <EditorsChoiceTile
                  tile={largeTileViewModel}
                  size={TileSize.LARGE}
                />
              </div>
            </div>
          </Grid>

          <Grid column sm={12} md={4} customClass={styles["right-col"]}>
            <div
              className={clsx(
                styles["editors-choice--small"],
                styles["mobile-only"]
              )}
            >
              <div className={styles["editor-choice-tile"]}>
                <EditorsChoiceTile
                  tile={smallTileUpperViewModel}
                  size={TileSize.SMALL}
                />
              </div>
            </div>
            <div className={styles["editors-choice--medium"]}>
              <div
                className={clsx(styles["editor-choice-tile"], styles["blue"])}
              >
                <div className={styles["tile-image"]}>
                  <EditorsChoiceMedia
                    mediaToRender={mediumTileViewModel.mediaToRender}
                    size={TileSize.MEDIUM}
                  />
                  {/* TODO: Check Image Rendering */}
                  {mediumTileViewModel.image && (
                    <NextContentfulImage
                      alt={mediumTileViewModel.image.fields.altText}
                      src={
                        mediumTileViewModel.image.fields.image.fields.file.url
                      }
                      width={
                        mediumTileViewModel.image.fields.image.fields.file
                          .details.image.width
                      }
                      height={
                        mediumTileViewModel.image.fields.image.fields.file
                          .details.image.height
                      }
                    />
                  )}
                </div>
                <EditorsChoiceTile
                  tile={mediumTileViewModel}
                  size={TileSize.MEDIUM}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EditorsChoice;
