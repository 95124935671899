import Grid from "@components/Grid/Grid";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import FormField from "@components/Form/FormFields/FormFields";
import { PhdFormFieldsTypes } from "@customTypes/PhdFormFieldsTypes";
import SubmitButton from "@components/SubmitButton/SubmitButton";
import { getCountries, getCountriesIso } from "../../services/forms";
import SelectAutocomplete from "@components/SelectAutocomplete/SelectAutocomplete";
import {
  getPaymentErrors,
  getCountryCode,
  hasCountryMatch,
} from "@utilities/payment";

import styles from "./PhdFees.module.scss";
import FormErrorMessage from "@components/FormErrorMessage/FormErrorMessage";
import { CountriesIsoProps } from "@components/EePayment/EePayment";

export interface PhdFormDetailsProps {
  emailLabelText: string;
  firstNameLabelText: string;
  lastNameLabelText: string;
  addressLabelText: string;
  amountLabelText: number;
  companyNameLabelText: string;
  cityLabelText: string;
  postcodeLabelText: string;
  mobileNumberLabelText: string;
  submitButtonLabelText: string;
  formDescriptionText: string;
  pageHeaderTitle: string;
  amountLabelField: string;
  emailHintText: string;
  amountHintText: string;
  subjectAreaOptions: any;
  subjectAreaLabelText: string;
  homeCountryLabelText: string;
}

export const PhdFees = (props: { content: PhdFormDetailsProps }) => {
  const { content } = props;
  const {
    pageHeaderTitle,
    emailLabelText,
    firstNameLabelText,
    lastNameLabelText,
    addressLabelText,
    companyNameLabelText,
    cityLabelText,
    postcodeLabelText,
    mobileNumberLabelText,
    submitButtonLabelText,
    formDescriptionText,
    emailHintText,
    amountHintText,
    amountLabelText,
    subjectAreaOptions,
    subjectAreaLabelText,
    homeCountryLabelText,
  } = content;

  const [phdFormDetails, setPhdFormDetails] =
    useState<PhdFormFieldsTypes | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [countriesIso, setCountriesIso] = useState<CountriesIsoProps[]>([]);

  const {
    register,
    handleSubmit,
    formState,
    watch,
    setValue,
    clearErrors,
    trigger,
  } = useForm({
    mode: "onBlur",
  });

  const fetchCountryData = async (term: string, fieldName: string) => {
    try {
      if (!term) {
        setCountries([]);
        return;
      }
      const countriesResponse = await getCountries(term);
      setCountries(countriesResponse);
    } catch (e) {
      setErrors(["Fetching countries data is failed"]);
      setCountries([]);
    }
  };

  useEffect(() => {
    const getCountryIsos = async () => {
      const countries = await getCountriesIso();
      setCountriesIso(countries);
    };

    getCountryIsos();
    setValue("payAmount", 75);
  }, []);

  const formFields: any = [
    {
      propertyName: "emailAddress",
      validation: {
        isRequired: true,
        email: true,
        maxLength: 255,
      },
      labelText: emailLabelText,
      dataText: phdFormDetails?.emailAddress,
      hintText: emailHintText,
    },
    {
      propertyName: "payAmount",
      validation: {
        isRequired: true,
        range: { min: 10, max: 120000 },
        integerValidator: true,
      },
      labelText: amountLabelText,
      dataText: phdFormDetails?.amount || 75,
      hintText: amountHintText,
    },
    {
      propertyName: "subjectArea",
      formType: "select",
      options: subjectAreaOptions?.values,
      validation: {
        isRequired: true,
      },
      labelText: subjectAreaLabelText,
      dataText: phdFormDetails?.currency,
    },
    {
      propertyName: "firstName",
      validation: {
        isRequired: true,
        maxLength: 30,
        firstName: true,
        alphaNumeric: true,
      },
      labelText: firstNameLabelText,
      dataText: phdFormDetails?.firstName,
    },
    {
      propertyName: "lastName",
      validation: {
        isRequired: true,
        maxLength: 30,
        lastName: true,
      },
      labelText: lastNameLabelText,
      dataText: phdFormDetails?.lastName,
    },
    {
      propertyName: "companyName",
      validation: {
        maxLength: 30,
      },
      labelText: companyNameLabelText,
      dataText: phdFormDetails?.companyName,
    },
    {
      propertyName: "addressLine1",
      validation: {
        isRequired: true,
        maxLength: 30,
      },
      labelText: addressLabelText,
      dataText: phdFormDetails?.address,
    },
    {
      propertyName: "city",
      validation: {
        isRequired: true,
        maxLength: 30,
        alphaNumeric: true,
      },
      labelText: cityLabelText,
      dataText: phdFormDetails?.city,
    },
    {
      propertyName: "country",
      fieldType: "selectAutocomplete",
      validation: {
        required: "Country of residence is required",
        maxLength: 30,
        validate: (value) =>
          !!hasCountryMatch(value, countries) || "Unknown country entered",
      },
      labelText: homeCountryLabelText,
      dataText: phdFormDetails?.country,
    },
    {
      propertyName: "postCode",
      validation: {
        isRequired: true,
        maxLength: 30,
      },
      labelText: postcodeLabelText,
      dataText: phdFormDetails?.postcode,
    },
    {
      propertyName: "phoneNumber",
      validation: {
        isRequired: true,
        maxLength: 30,
        telephone: true,
      },
      labelText: mobileNumberLabelText,
      dataText: phdFormDetails?.mobileNumber,
    },
  ];

  const onSubmit = async (formData) => {
    setErrors([]);
    setLoading(true);

    const data = {
      ...formData,
      currencyCode: "GBP",
      country: getCountryCode(formData.country, countriesIso),
      internalReference: "PHDFEE",
    };

    localStorage.setItem("paymentFormData", JSON.stringify(data));

    const response = await fetch("/api/postPayment", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    const postPaymentResponseData = await response.json();

    setLoading(false);

    if (!response.ok) {
      setErrors(getPaymentErrors(postPaymentResponseData?.response));
      window.scrollTo(0, 0);
    }

    if (response.ok)
      window.location.href = postPaymentResponseData?.response?.checkOutUrl;
  };

  return (
    <div className={clsx(styles["phd-form"], "wrapper")}>
      <Grid row>
        <Grid column sm={12} md={6}>
          <div className={clsx(styles.component, "component", styles.form)}>
            <h1 className="h2">{pageHeaderTitle}</h1>
            {formDescriptionText && <p>{formDescriptionText}</p>}
          </div>
          <section
            className={clsx(styles.component, styles.form, "form", styles.cf)}
          >
            {errors &&
              errors.map((error, index) => (
                <FormErrorMessage key={index} text={error} />
              ))}

            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <section className={styles["form-group-wrapper"]}>
                <div className={styles["form-row"]} />
                {formFields?.map((field, index) => {
                  if (field.fieldType === "selectAutocomplete") {
                    return (
                      <SelectAutocomplete
                        key={`${field.labelText}${index}`}
                        placeholder={field.labelText}
                        name={field.propertyName}
                        register={register}
                        setValue={setValue}
                        clearErrors={clearErrors}
                        errors={formState.errors}
                        value={watch(field.propertyName) as string}
                        onBlur={() => {
                          trigger("country");
                        }}
                        getOptions={(term) =>
                          fetchCountryData(term, field.propertyName)
                        }
                        options={countries}
                        validation={field.validation}
                      />
                    );
                  }
                  return (
                    <FormField
                      key={`${field.labelText}${index}`}
                      formType={field?.formType}
                      watch={watch}
                      validation={field?.validation}
                      type={field.type === "select" ? "select" : "text"}
                      register={register}
                      property={field.dataText}
                      hintText={field?.hintText}
                      placeholder={field.labelText}
                      options={field.options}
                      optionalText={field?.optionalText}
                      name={field?.propertyName}
                      errors={formState.errors}
                    />
                  );
                })}
              </section>
              <div className="btn-and-text-wrapper">
                <SubmitButton
                  loading={loading}
                  id="submitButton"
                  text="Submit"
                />
              </div>
            </form>
          </section>
        </Grid>
      </Grid>
    </div>
  );
};

export default PhdFees;
