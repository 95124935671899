import { ResultList as HeadlessResultList, Result } from "@coveo/headless";
import { useEffect, useState, FunctionComponent, useContext } from "react";
import { ProgrammeContext } from "../../context/programmeComparison";
import { useSettings } from "@utilities/context/settings";
/* Templates */
import { BsrResultsTemplate } from "./BsrResultsTemplate/BsrResultsTemplate";
import DefaultResultsTemplate from "./DefaultResultsTemplate/DefaultResultsTemplate";
import PublicationDetailResultsTemplate from "./PublicationDetailResultsTemplate/PublicationDetailResultsTemplate";
import EventsDetailsResultsTemplate from "./EventsDetailsResultsTemplate/EventsDetailsResultsTemplate";
import FacultyProfileResultsTemplate from "./FacultyProfileResultsTemplate/FacultyProfileResultsTemplate";
import NewsDetailsResultsTemplate from "./NewsDetailsResultsTemplate/NewsDetailsResultsTemplate";
import ProgrammeDetailResultsTemplate from "./ProgrammeDetailResultsTemplate/ProgrammeDetailResultsTemplate";
/* Template styles */
import { BsrTemplateStyles } from "./BsrResultsTemplate/BsrResultsTemplateStyles";
import { DefaultResultsTemplateStyles } from "./DefaultResultsTemplate/DefaultResultsTemplateStyles";
import { PublicationDetailResultsTemplateStyles } from "./PublicationDetailResultsTemplate/PublicationDetailResultsTemplateStyles";
import { eventsTemplateStyles } from "./EventsDetailsResultsTemplate/EventsDetailsResultsTemplateStyles";
import { FacultyProfileStyles } from "./FacultyProfileResultsTemplate/FacultyProfileResultsTemplateStyles";
import { NewsTemplateStyles } from "./NewsDetailsResultsTemplate/NewsDetailsResultsTemplateStyles";
import { ProgrammeDetailResultsTemplateStyles } from "./ProgrammeDetailResultsTemplate/ProgrammeDetailResultsTemplateStyles";
import { useDevice } from "@utilities/react/get-device/get-device";
import { ContentTypes } from "./CoveoSearchComponent";
import ClipLoader from "react-spinners/ClipLoader";
import { AtomicNoResults } from "@coveo/atomic-react";
import ResearchAndFacultyResultsTemplate from "./ResearchAndFacultyResultsTemplate/ResearchAndFacultyResultsTemplate";
import { ResearchAndFacultyResultsTemplateStyles } from "./ResearchAndFacultyResultsTemplate/ResearchAndFacultyResultsTemplateStyles";

interface ResultListProps {
  controller: HeadlessResultList;
  pageFormat: string;
  display?: string;
  imageSize?: string;
}

const getPropsByContentType = (contentType: ContentTypes, index: number) => {
  const isImageContentType =
    contentType === ContentTypes.FACULTY_PROFILE_DETAIL ||
    contentType === ContentTypes.CONTRIBUTOR_PROFILE_DETAIL ||
    contentType === ContentTypes.STUDENT_PROFILE_DETAIL ||
    contentType === ContentTypes.STORY_PAGE ||
    contentType === ContentTypes.RESEARCH_PAGE;

  return isImageContentType && index < 4 ? { priority: true } : {};
};

export const LBSResultList: FunctionComponent<ResultListProps> = (props) => {
  const { controller, pageFormat, display, imageSize } = props;
  const [state, setState] = useState(controller.state);
  const { addProgramme, removeProgramme } = useContext(ProgrammeContext);
  const { siteSettings } = useSettings();
  useEffect(() => controller.subscribe(() => setState(controller.state)), []);
  const templateType: any =
    pageFormat === ContentTypes.GLOBAL
      ? ContentTypes.GLOBAL
      : state.results.length
        ? (state.results[0].raw["contenttypeid"] as ContentTypes)
        : null;

  const resultsConfig: any = {
    [ContentTypes.GLOBAL]: {
      template: DefaultResultsTemplate,
      styles: DefaultResultsTemplateStyles,
      props: {
        siteSettings,
      },
    },
    [ContentTypes.BSR_CONTENT_PAGE]: {
      template: BsrResultsTemplate,
      styles: BsrTemplateStyles,
      props: {
        siteSettings,
      },
    },
    [ContentTypes.PROGRAMME_DETAILS]: {
      template: ProgrammeDetailResultsTemplate,
      styles: ProgrammeDetailResultsTemplateStyles,
      props: {
        addProgramme,
        removeProgramme,
      },
    },
    [ContentTypes.EVENTS_DETAIL]: {
      template: EventsDetailsResultsTemplate,
      styles: eventsTemplateStyles,
      props: {
        siteSettings,
      },
    },
    [ContentTypes.NEWS_DETAIL]: {
      template: NewsDetailsResultsTemplate,
      styles: NewsTemplateStyles,
      props: {
        siteSettings,
      },
    },
    [ContentTypes.FACULTY_PROFILE_DETAIL]: {
      template: FacultyProfileResultsTemplate,
      styles: FacultyProfileStyles,
    },
    [ContentTypes.CONTRIBUTOR_PROFILE_DETAIL]: {
      template: FacultyProfileResultsTemplate,
      styles: FacultyProfileStyles,
    },
    [ContentTypes.STUDENT_PROFILE_DETAIL]: {
      template: FacultyProfileResultsTemplate,
      styles: FacultyProfileStyles,
    },
    [ContentTypes.STORY_PAGE]: {
      template: FacultyProfileResultsTemplate,
      styles: FacultyProfileStyles,
    },
    [ContentTypes.PUBLICATION_DETAIL]: {
      template: PublicationDetailResultsTemplate,
      styles: PublicationDetailResultsTemplateStyles,
    },
    [ContentTypes.RESEARCH_PAGE]: {
      template: ResearchAndFacultyResultsTemplate,
      styles: ResearchAndFacultyResultsTemplateStyles,
    },
    [ContentTypes.DEFAULT]: {
      template: DefaultResultsTemplate,
      styles: DefaultResultsTemplateStyles,
      props: {
        siteSettings,
      },
    },
  };

  const TemplateResolver: any = (result: Result, index: number) => {
    const Component = resultsConfig[templateType].template;

    return (
      <Component
        result={result}
        {...getPropsByContentType(templateType, index)}
        {...resultsConfig[templateType].props}
      />
    );
  };

  const device = useDevice();
  const isMobile = device === "mobile";
  if (state.isLoading) {
    return (
      <div className="coveo-loading-center">
        <div className="loader">
          <ClipLoader
            color="#001e62"
            size={50}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
        <div className="loader-text">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  const outerStyles = `${
    display ? `display-${display}` : null
  } density-normal ${imageSize ? `image-${imageSize}` : null}`;
  const styles = templateType ? resultsConfig[templateType].styles : null;
  return (
    <>
      {templateType ? (
        <style>{typeof styles !== "string" ? styles(isMobile) : styles}</style>
      ) : null}
      {!state.results.length ? (
        <div className="no-results">
          <AtomicNoResults enableCancelLastAction={true} {...state.results} />
        </div>
      ) : (
        <div className="lbs-result-list-wrapper">
          <div className={`${display ? `display-${display}` : null}`}>
            <div className={`list-wrapper ${outerStyles}`}>
              <div className={`list-root result-list ${outerStyles}`}>
                {templateType
                  ? state.results.map((result, index) => (
                      <div
                        key={result.uniqueId}
                        className="result-list-grid-clickable-container outline"
                      >
                        <div className="hydrated result-component">
                          <div className={`result-root ${outerStyles}`}>
                            {TemplateResolver(result, index)}
                          </div>
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
