import { useEffect, useRef, useState } from "react";
import { useInView } from "react-hook-inview";
import clsx from "clsx";

import styles from "./C252_DynamicPie.module.scss";

export interface standardPie {
  title: string;
  colour: string;
  percentage: number;
  description: string;
}

export const DynamicPieRegular = ({
  title,
  percentage,
  description,
}: standardPie) => {
  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });
  const circle: any = useRef();
  const [isDone, setIsDone] = useState(false);
  const [currentPercentage, setCurrentPercentage] = useState(0);

  useEffect(() => {
    if (isVisible && !isDone && circle.current) {
      setIsDone(true);
      circle.current.style.strokeDasharray = percentage + ",100";
      var number = 0;
      const interval = setInterval(() => {
        if (number >= percentage) {
          clearInterval(interval);
        }
        setCurrentPercentage(number++);
      }, 1000 / percentage);
    }
  }, [isVisible]);

  return (
    <div
      ref={ref}
      className={clsx(styles.chart, isVisible && styles.animate)}
      data-percent={percentage}
    >
      <h4>{title}</h4>
      <div className={styles.graphic}>
        <div className={styles.number}>
          <p>
            <span>{currentPercentage}</span>%
          </p>
        </div>
        <svg viewBox="0 0 36 36" className={styles["circular-chart"]}>
          <path
            className={styles.circle}
            strokeDasharray="0, 100"
            d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            // @ts-ignore
            ref={circle}
            className={styles.two}
            strokeDasharray="0, 100"
            d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </svg>
      </div>
      <p className={styles.large}>{description}</p>
    </div>
  );
};

export default DynamicPieRegular;
