import { useEffect, useRef, useState } from "react";
import { useInView } from "react-hook-inview";
import { useDevice } from "../../utils/react/get-device/get-device";
import clsx from "clsx";
import Cta from "../Cta/Cta";

import {
  NextContentfulImage,
  NextContentfulLoaderProps,
} from "@components/Image";
import { GeneralLink } from "@customTypes/generalLink";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import styles from "./C27_NavigationalQuote.module.scss";

interface QuoteProps {
  quoteId?: number;
  content: QuoteType;
  onReadMoreClick?: (clicked?: number) => void;
}

export interface QuoteType {
  authorName: string;
  quote: string;
  authorJobTitle?: string;
  ctaLink?: GeneralLink;
  label?: string;
  optionalCta?: GeneralLink;
  desktopImage?: ImageWrapperType;
  fullWidth?: boolean;
  longQuote?: string;
}

const loaderConfig: NextContentfulLoaderProps = { fit: "fill" };

export const Quote = ({ quoteId, content, onReadMoreClick }: QuoteProps) => {
  const {
    fullWidth,
    label,
    quote,
    authorName,
    authorJobTitle,
    desktopImage,
    ctaLink,
    optionalCta,
    longQuote,
  } = content;
  const [showText, setShowText] = useState(false);

  const isCarousel = typeof quoteId !== "undefined";

  const handleClick = () => {
    !isMobile &&
      typeof quoteId != undefined &&
      onReadMoreClick &&
      onReadMoreClick(quoteId);
    isMobile && setShowText(!showText);
  };

  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });

  const line: any = useRef(null);
  const blockquote: any = useRef(null);
  const footnote: any = useRef(null);

  const device = useDevice();
  const isMobile = device === "mobile";

  const setLineHeight = () => {
    if (!isMobile) {
      const height =
        footnote.current.offsetTop -
        blockquote.current.offsetTop -
        blockquote.current.offsetHeight +
        40;
      line.current.dataset.height = height + "px";
      line.current.style.height = height + "px";
    } else {
      line.current.removeAttribute("style");
    }
  };

  useEffect(() => {
    setLineHeight();
  }, [isVisible, isMobile]);

  const fullQuote = longQuote ? quote + " " + longQuote : quote;

  const textToRender = !showText ? "Read More" : "Read Less";

  return (
    <div
      className={clsx(
        styles["navigational-quote"],
        isVisible && styles.animate,
        !desktopImage && styles["no-img"],
        !isCarousel && !fullWidth && styles.half
      )}
      ref={ref}
    >
      <div className={styles["block-container"]}>
        <div className={styles["text-content"]}>
          {fullWidth && <p className={styles.label}>{label}</p>}
          {isCarousel && (quote.length > 216 || longQuote) ? (
            <blockquote ref={blockquote}>
              {!showText ? (
                <>
                  <span>
                    {quote.length > 216
                      ? `"${quote.substring(0, 216)}`
                      : `"${quote}"`}
                  </span>
                  <span>{quote.length > 216 ? '..."' : ""}</span>
                </>
              ) : (
                <span>
                  {`"${
                    fullQuote.length > 730
                      ? fullQuote.substring(0, 730)
                      : fullQuote
                  }"`}
                </span>
              )}
            </blockquote>
          ) : (
            <blockquote ref={blockquote}>&quot;{quote}&quot;</blockquote>
          )}
          {isCarousel && (quote.length > 216 || longQuote) && (
            <a onClick={handleClick} className={styles["read-more-btn"]}>
              {isMobile ? textToRender : "Read More"}
            </a>
          )}
          {desktopImage && fullWidth && (
            <div className={styles.image}>
              <NextContentfulImage
                alt={desktopImage?.fields.altText || ""}
                src={desktopImage?.fields.image.fields.file.url}
                width={isMobile ? 768 : 800}
                height={isMobile ? 517 : 704}
                loaderConfig={loaderConfig}
              />
            </div>
          )}
          <div className={styles.line} ref={line}></div>
          <div ref={footnote} className={styles.footnote}>
            <div>
              <p className={styles.name}>{authorName}</p>
              {authorJobTitle ? <p>{authorJobTitle}</p> : null}
            </div>
            <div>
              {ctaLink && (
                <div className={styles.mainCta}>
                  <Cta {...ctaLink.fields} type="secondary" light={true} />
                </div>
              )}
              {optionalCta && (
                <Cta
                  {...optionalCta.fields}
                  type="tertiary"
                  light={true}
                  icon="icon-arrow-small"
                />
              )}
            </div>
          </div>
        </div>
        {fullWidth && desktopImage && (
          <div className={styles.image}>
            <NextContentfulImage
              alt={desktopImage?.fields.altText || ""}
              src={desktopImage?.fields.image.fields.file.url}
              width={isMobile ? 768 : 800}
              height={isMobile ? 517 : 704}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Quote;
