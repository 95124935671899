import Lightbox from "@components/Lightbox/Lightbox";
import { ContentfulImage } from "@customTypes/ContentfulImage";
import { Media } from "@customTypes/media";
import clsx from "clsx";
import Link from "@components/Link/Link";
import { useModal } from "react-modal-hook";

import IconEmail from "../../public/image/svg/email.svg";
import IconExternal from "../../public/image/svg/external.svg";
import IconLinkedIn from "../../public/image/svg/linkedin.svg";
import IconPDF from "../../public/image/svg/pdf.svg";
import IconPhone from "../../public/image/svg/phone.svg";
import IconTwitterX from "../../public/image/svg/twitter-x.svg";

import styles from "./C54_ProfilePhotoLinkBox.module.scss";
import { NextContentfulImage } from "@components/Image";

export interface ProfilePhotoLinkBoxProps {
  profileFirstname: string;
  profilePhoto: ContentfulImage;
  profileSurname: string;
  profileCVFile?: Media;
  profileLinkedInLink?: string;
  profilePersonalWebPage?: string;
  profileTwitterLink?: string;
  profileEmail?: string;
  suppressPersonalWebPage?: boolean;
  suppressEmail?: boolean;
  supressPhone?: boolean;
  supressLinkedIn?: boolean;
  supressCVFile?: boolean;
  supressTwitter?: boolean;
  facultyProfileData: {
    fields: {
      profilePhone?: string;
      profileEmail?: string;
      profileFirstname?: string;
      profileSurname?: string;
    };
  };
}

const ProfilePhotoLinkBox = (props: { content: ProfilePhotoLinkBoxProps }) => {
  const { content } = props;
  const {
    profileCVFile,
    profileLinkedInLink,
    profilePersonalWebPage,
    profilePhoto,
    profileTwitterLink,
    profileEmail,
    suppressPersonalWebPage,
    suppressEmail,
    supressPhone,
    supressLinkedIn,
    supressCVFile,
    supressTwitter,
    facultyProfileData,
  } = content;

  const email = facultyProfileData?.fields?.profileEmail || profileEmail || "";
  const profilePhone = facultyProfileData?.fields?.profilePhone;

  // If it's a faculty member, use fields from the API data
  const profileFirstname = facultyProfileData?.fields?.profileFirstname
    ? facultyProfileData?.fields?.profileFirstname
    : content?.profileFirstname;
  const profileSurname = facultyProfileData?.fields?.profileSurname
    ? facultyProfileData?.fields?.profileSurname
    : content?.profileSurname;

  const [showModal, hideModal] = useModal(
    () => (
      <Lightbox
        onCloseFunction={hideModal}
        customInnerClass={styles["lightbox"]}
      >
        <NextContentfulImage
          alt={`${profileFirstname} ${profileSurname}`}
          src={profilePhoto.fields.file.url}
          height={profilePhoto.fields.file.details.image.height}
          width={profilePhoto.fields.file.details.image.width}
        />
      </Lightbox>
    ),
    []
  );

  const displayPersonalWebPage =
    !suppressPersonalWebPage && !!profilePersonalWebPage;
  const displayEmail = !suppressEmail && !!email;
  const displayPhone = !supressPhone && !!profilePhone;
  const displayLinkedIn = !supressLinkedIn && !!profileLinkedInLink;
  const displayTwitter = !supressTwitter && !!profileTwitterLink;
  const displayCV = !supressCVFile && !!profileCVFile;
  const displayDetails =
    displayEmail ||
    displayPhone ||
    displayLinkedIn ||
    displayTwitter ||
    displayCV;

  if (
    !profilePhoto &&
    !displayPersonalWebPage &&
    !displayEmail &&
    !displayPhone &&
    !displayLinkedIn &&
    !displayTwitter &&
    !displayCV
  )
    return null;

  return (
    <div className={clsx("component", styles["profile-photo-link-box"])}>
      {!!profilePhoto && (
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            showModal();
          }}
        >
          <NextContentfulImage
            alt={`${profileFirstname} ${profileSurname}`}
            src={profilePhoto.fields.file.url}
            height={profilePhoto.fields.file.details.image.height}
            width={profilePhoto.fields.file.details.image.width}
            sizes="(max-width: 768px) 100vw, 40vw"
            loading="eager"
            priority
          />
        </a>
      )}
      {displayDetails && (
        <ul className={styles["link-list"]}>
          {displayPersonalWebPage && (
            <li>
              <i className={styles["icon-external"]}>
                <IconExternal />
              </i>
              <Link href={profilePersonalWebPage}>
                <a target="_blank" rel="noreferrer">
                  Personal Website
                </a>
              </Link>
            </li>
          )}
          {displayEmail && (
            <li>
              <i className={styles["icon-email"]}>
                <IconEmail />
              </i>
              {email}
            </li>
          )}
          {displayPhone && (
            <li>
              <i className={styles["icon-call"]}>
                <IconPhone />
              </i>
              <Link href={`tel:${profilePhone}`}>
                <a target="_blank" rel="noreferrer">
                  {profilePhone}
                </a>
              </Link>
            </li>
          )}
          {displayLinkedIn && (
            <li>
              <i className={styles["icon-linkedin"]}>
                <IconLinkedIn />
              </i>
              <Link href={profileLinkedInLink}>
                <a target="_blank" rel="noreferrer">
                  LinkedIn
                </a>
              </Link>
            </li>
          )}
          {displayTwitter && (
            <li>
              <i className={styles["icon-twitter-x"]}>
                <IconTwitterX />
              </i>
              <Link href={profileTwitterLink}>
                <a target="_blank" rel="noreferrer">
                  Twitter
                </a>
              </Link>
            </li>
          )}
          {displayCV && (
            <li>
              <i className={styles["icon-pdf"]}>
                <IconPDF />
              </i>
              <a
                href={profileCVFile.fields.file.url}
                target="_blank"
                rel="noreferrer"
              >
                {profileFirstname} {profileSurname} CV
              </a>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default ProfilePhotoLinkBox;
