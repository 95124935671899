import styles from "./C200_1_BrandIconTitle.module.scss";
import clsx from "clsx";
import BrandIconDesktop from "../../public/image/svg/brand-icon-desktop.svg";
import BrandIconMobile from "../../public/image/svg/brand-icon-mobile.svg";
import { useSettings } from "@utilities/context/settings";

export const BrandIconTitle = (props: { isH2?: boolean }) => {
  const { isH2 } = props;
  const { siteSettings } = useSettings();

  const classNames = clsx(styles["section-title"], styles["visually-hidden"]);

  return (
    <div
      className={clsx(
        "component",
        styles["brand-title"],
        styles["brand-title--icon"]
      )}
    >
      <div className={clsx("wrapper", styles.wrapper)}>
        {isH2 ? (
          <h2 className={classNames}>{siteSettings?.brandIconTitleText}</h2>
        ) : (
          <h1 className={classNames}>{siteSettings?.brandIconTitleText}</h1>
        )}
        <div className="image-wrapper">
          <span className={styles["icon-think-logo-desktop"]}>
            <BrandIconDesktop />
          </span>
          <span className={styles["icon-think-logo-mobile"]}>
            <BrandIconMobile />
          </span>
        </div>
      </div>
    </div>
  );
};

export default BrandIconTitle;
