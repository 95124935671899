import C15_SocialSharing from "@components/C15_SocialSharing/C15_SocialSharing";
import Lightbox from "@components/Lightbox/Lightbox";
import { SocialLink } from "@customTypes/SocialLink";
import { ImageWrapperType } from "@customTypes/imageWrapper";
import clsx from "clsx";
import { useContext, useEffect, useRef, useState } from "react";
import { useInView } from "react-hook-inview";
import VideoPlayer from "@components/VideoPlayer";
import { getVideoUrl } from "@components/VideoPlayer/utils";
import { StoryContext } from "../C253_StoriesProvider";
import styles from "../C253_StoriesContainer.module.scss";
import { NextContentfulImage } from "@components/Image";

interface StoriesFullWidthData {
  descriptionLarge: string;
  descriptionSimple: string;
  h1Text: string;
  h3Text: string;
  fullWidthImage: ImageWrapperType;
  showChevron: boolean;
  isSocialIconDark: boolean;
  socialLinks: SocialLink[];
  isVimeoCode: boolean;
  displayVideo: boolean;
  videoCode: string;
  enableSaveToProfileLink?: boolean;
}

export interface StoriesFullWidthProps {
  content: StoriesFullWidthData;
  index: number;
}

export const StoriesFullWidth = (props: StoriesFullWidthProps) => {
  const { content, index } = props;
  const {
    fullWidthImage,
    h3Text,
    h1Text,
    isVimeoCode,
    videoCode,
    displayVideo,
    descriptionLarge,
    descriptionSimple,
    isSocialIconDark,
    socialLinks,
    showChevron,
    enableSaveToProfileLink,
  } = content;

  const lineRef: any = useRef();
  const mobileLineRef: any = useRef();

  const isFirst = index === 0;

  const [showLightbox, setShowLightbox] = useState(false);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [hasLineAnimated, setHasLineAnimated] = useState(false);

  const { updateNavigation, lineHeights } = useContext(StoryContext);

  const [ref, isVisible] = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (isVisible) {
      if (!hasAnimated) {
        setHasAnimated(true);
      }

      updateNavigation(index, !fullWidthImage);
    }
  }, [isVisible, hasAnimated]);

  useEffect(() => {
    const getLineHeight = (ref) => {
      const lineY = ref.current.getBoundingClientRect().top;
      return lineHeights[index] - lineY;
    };

    if (lineHeights[index] && !hasLineAnimated) {
      lineRef.current.style.height = getLineHeight(lineRef) + "px";
      mobileLineRef.current.style.height = getLineHeight(mobileLineRef) + "px";
      setHasLineAnimated(true);
    }
  }, [lineHeights]);

  const closeLightbox = () => {
    setTimeout(() => {
      setShowLightbox(false);
    }, 300);
  };

  const videoUrl = getVideoUrl(isVimeoCode ? "vimeo" : "youtube", videoCode);

  return (
    <section
      ref={ref}
      className={clsx(
        styles.quote,
        styles.landing,
        fullWidthImage && styles["with-image"],
        hasAnimated && styles.animate
      )}
      data-bg={fullWidthImage ? "dark" : "light"}
    >
      <div className="wrapper">
        <div className={styles.content}>
          <h3 className={styles.title}>{h3Text}</h3>
          {isFirst && <div className={clsx(styles.initial, styles.line)}></div>}
          {h1Text && h1Text.startsWith("<h3>") ? (
            <div dangerouslySetInnerHTML={{ __html: h1Text }} />
          ) : (
            <h1
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: h1Text }}
            />
          )}
          {displayVideo && videoCode && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowLightbox(true);
              }}
              className="play"
            >
              <div className="play-icon">
                <img src="/assets2019/img/vidicon.png" alt="play icon" />
                <span className={styles["visually-hidden"]}>Play video</span>
              </div>
            </a>
          )}
          {showLightbox && (
            <Lightbox isBanner onCloseFunction={closeLightbox}>
              <VideoPlayer url={videoUrl} />
            </Lightbox>
          )}
          {descriptionLarge && <p className="large">{descriptionLarge}</p>}
          {descriptionSimple && <p>{descriptionSimple}</p>}

          <div ref={lineRef} className={styles.line}></div>

          {socialLinks && socialLinks.length > 0 && (
            <C15_SocialSharing
              socialLinks={socialLinks}
              isSocialIconDark={isSocialIconDark}
              enableSaveToProfileLink={enableSaveToProfileLink}
            />
          )}
        </div>

        {fullWidthImage && (
          <div className={styles.picture}>
            <NextContentfulImage
              alt={fullWidthImage.fields.altText}
              src={fullWidthImage.fields.image.fields.file.url}
              height={
                fullWidthImage.fields.image.fields.file.details.image.height
              }
              width={
                fullWidthImage.fields.image.fields.file.details.image.width
              }
              loading="eager"
              priority
            />
          </div>
        )}
      </div>

      <div
        ref={mobileLineRef}
        className={clsx(styles.line, styles.mobile)}
      ></div>

      {showChevron && (
        <span
          className={styles["slide-down"]}
          onClick={(e) =>
            updateNavigation(index + 1, !fullWidthImage, true, e.target)
          }
        >
          <span className="chevron"></span>
        </span>
      )}
    </section>
  );
};

export default StoriesFullWidth;
