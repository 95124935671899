import styles from "./C283_DonateTool.module.scss";
import clsx from "clsx";
import Grid from "@components/Grid/Grid";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";
import { useEffect, useRef, useState } from "react";
import C11_VerticalAccordion from "@components/C11_VerticalAccordion/C11_VerticalAccordion";
import BlackbaudForm from "./BlackbaudForm";
import DonateDetails from "./DonateDetails";
import { Document as RichTextContent } from "@contentful/rich-text-types";
import { TinyMceRichText } from "@customTypes/TinyMceRichText";
import { DonateToolRelationships } from "@customTypes/DonateToolRelationship";
import DonateSelect from "./DonateSelect";

export interface DonateToolProps {
  label?: string;
  headerTitle: string;
  headerIntro?: RichTextContent;
  headerIntroTinyMce?: TinyMceRichText;
  leftTabLabel: string;
  rightTabLabel: string;
  leftTabIntro?: string;
  taxResidentLabel: string;
  taxResidentCountries: Array<TaxResidentCountryFields | CountryRegion>;
  relationshipsLabel: string;
  relationships: Relationship[];
  ctaText: string;
  rightTabIntro?: RichTextContent;
  rightTabAccordions;
  studentBlackbaudFormsDefault: BlackbaudFormFields;
  alumniBlackbaudFormsDefault: BlackbaudFormFields;
}

interface Relationship {
  fields: {
    title: string;
    value: string;
  };
}

interface TaxResidentCountryFields {
  fields?: TaxResidentCountry;
}
export interface TaxResidentCountry {
  countryName: string;
  value?: string;
  externalUrl?: string;
  displayDonateDetails?: boolean;
  donateDetailsIntro?: RichTextContent;
  donateDetailsIntroTinyMce?: TinyMceRichText;
  donateDetailsAddressTitle?: string;
  donateDetailsAddressLine1?: string;
  donateDetailsAddressLine2?: string;
  donateDetailsTelephone?: string;
  donateDetailsFax?: string;
  donateDetailsEmail?: string;
  donateDetailsWebsite?: string;
  donateDetailsSummary?: string;
  studentBlackbaudForms?: BlackbaudFormFields;
  alumniBlackbaudForms?: BlackbaudFormFields;
  giftAid?: boolean;
}

interface CountryRegion {
  fields?: {
    regionCountries: TaxResidentCountryFields[];
    regionName: string;
  };
}

interface BlackbaudFormFields {
  fields: BlackbaudForms;
}

export interface BlackbaudForms {
  single?: string;
  recurringCard?: string;
  recurringDd?: string;
}

type NumberStringUndefined = Array<number | string | undefined>;

export const DonateTool = (props: { content: DonateToolProps }) => {
  const { content } = props;
  const formAnchor = "#Form";
  const {
    label,
    headerTitle,
    headerIntro,
    headerIntroTinyMce,
    leftTabLabel,
    rightTabLabel,
    leftTabIntro,
    taxResidentLabel,
    taxResidentCountries,
    relationshipsLabel,
    relationships,
    ctaText,
    rightTabIntro,
    rightTabAccordions,
    studentBlackbaudFormsDefault,
    alumniBlackbaudFormsDefault,
  } = content;

  const accordionDrop = useRef<Array<HTMLDivElement | null>>([]);

  const formattedCountries: TaxResidentCountry[] = [];

  taxResidentCountries.map((item: any) => {
    const {
      countryName,
      displayDonateDetails,
      donateDetailsIntro,
      donateDetailsIntroTinyMce,
      donateDetailsAddressTitle,
      donateDetailsAddressLine1,
      donateDetailsAddressLine2,
      donateDetailsTelephone,
      donateDetailsFax,
      donateDetailsEmail,
      donateDetailsWebsite,
      value,
      externalUrl,
      regionCountries,
      studentBlackbaudForms,
      alumniBlackbaudForms,
      giftAid,
    } = item?.fields;

    if (countryName) {
      formattedCountries.push({
        countryName,
        value,
        externalUrl,
        displayDonateDetails,
        donateDetailsIntro,
        donateDetailsIntroTinyMce,
        donateDetailsAddressTitle,
        donateDetailsAddressLine1,
        donateDetailsAddressLine2,
        donateDetailsTelephone,
        donateDetailsFax,
        donateDetailsEmail,
        donateDetailsWebsite,
        studentBlackbaudForms,
        alumniBlackbaudForms,
        giftAid,
      });
    } else {
      regionCountries.map((country) => {
        const {
          countryName,
          displayDonateDetails,
          donateDetailsIntro,
          donateDetailsIntroTinyMce,
          donateDetailsAddressTitle,
          donateDetailsAddressLine1,
          donateDetailsAddressLine2,
          donateDetailsTelephone,
          donateDetailsFax,
          donateDetailsEmail,
          donateDetailsWebsite,
          value,
          externalUrl,
          studentBlackbaudForms,
          alumniBlackbaudForms,
          giftAid,
        } = country?.fields;

        formattedCountries.push({
          countryName,
          value,
          externalUrl,
          displayDonateDetails,
          donateDetailsIntro,
          donateDetailsIntroTinyMce,
          donateDetailsAddressTitle,
          donateDetailsAddressLine1,
          donateDetailsAddressLine2,
          donateDetailsTelephone,
          donateDetailsFax,
          donateDetailsEmail,
          donateDetailsWebsite,
          studentBlackbaudForms,
          alumniBlackbaudForms,
          giftAid,
        });
      });
    }
  });

  // Get country by name
  const getCountry = (countryName) => {
    return formattedCountries.find((x) => x.countryName === countryName);
  };

  // Tab Accordion States
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [previousTab, setPreviousTab] = useState<number | null>(null);
  const [isVisible, setVisibility] = useState([false, false]);
  const [contentHeight, setContentHeight] = useState<
    Array<number | string | undefined>
  >([0, 0]);

  useEffect(() => {
    if (activeTab !== null) {
      const contentHeightState: NumberStringUndefined = contentHeight.map(
        (value, i) => {
          return i === activeTab ? accordionDrop?.current[i]?.scrollHeight : 0;
        }
      );
      setContentHeight(contentHeightState);

      const visibilityState: boolean[] = isVisible.map((value, i) => {
        return i === activeTab;
      });
      setVisibility(visibilityState);

      setTimeout(() => {
        const contentHeightState: NumberStringUndefined = contentHeight.map(
          (value, i) => {
            return i === activeTab ? "auto" : 0;
          }
        );
        setContentHeight(contentHeightState);
      }, 300);
    } else {
      const contentHeightState: NumberStringUndefined = contentHeight.map(
        (value, i) => {
          return i === previousTab
            ? accordionDrop?.current[i]?.scrollHeight
            : 0;
        }
      );
      setContentHeight(contentHeightState);

      setTimeout(() => {
        setContentHeight([0, 0]);
      }, 10);
    }
  }, [activeTab]);

  const isWindow = typeof window === "object";
  const urlParams = isWindow
    ? new URLSearchParams(window.location.search)
    : null;
  const tax = urlParams?.get("t");
  const rel = urlParams?.get("r");

  // Journey States
  const [taxResident, setTaxResident] = useState(tax || "");
  const [relationship, setRelationship] = useState(rel || "");
  const [externalUrl, setExternalUrl] = useState("");
  const [continueActive, setContinue] = useState(false);
  const [journeyStep, setJourneyStep] = useState(1);
  const firstTab = useRef<HTMLAnchorElement | null>(null);
  useEffect(() => {
    if (taxResident && relationship) {
      firstTab.current && firstTab.current.click();
      setJourneyStep(2);
    }
  }, []);

  const handleRelationship = (e) => {
    const relationship = e?.target?.value;
    const selectedValue = document
      .getElementById("relationship")
      ?.querySelector('option[value="' + relationship + '"]')
      ?.getAttribute("data-form-ref");

    setRelationship(selectedValue ? selectedValue : "");
  };

  const handleTaxResidents = (e) => {
    setTaxResident(e?.target?.value);
  };

  // If a tax country and relationship has been set
  useEffect(() => {
    setContinue(!!taxResident && !!relationship);

    if (taxResident && relationship) {
      const country: any = getCountry(taxResident);

      if (country) {
        setExternalUrl(
          country && country.externalUrl ? country.externalUrl : ""
        );

        // If this country has bespoke blackbaud IDs
        if (country?.studentBlackbaudForms && country?.alumniBlackbaudForms) {
          setBlackbaudForms(
            relationship?.toLowerCase() ===
              DonateToolRelationships.ALUMNI.toLowerCase()
              ? { ...country.alumniBlackbaudForms.fields }
              : relationship?.toLowerCase() ===
                  DonateToolRelationships.STUDENT.toLowerCase()
                ? { ...country.studentBlackbaudForms.fields }
                : null
          );
        } else {
          if (studentBlackbaudFormsDefault && alumniBlackbaudFormsDefault) {
            setBlackbaudForms(
              relationship?.toLowerCase() ===
                DonateToolRelationships.ALUMNI.toLowerCase()
                ? { ...alumniBlackbaudFormsDefault.fields }
                : relationship?.toLowerCase() ===
                    DonateToolRelationships.STUDENT.toLowerCase()
                  ? { ...studentBlackbaudFormsDefault.fields }
                  : null
            );
          }
        }
        setGiftAid(!!country.giftAid);
      }
    } else {
      setExternalUrl("");
      setBlackbaudForms(null);
      setGiftAid(false);
    }
  }, [taxResident, relationship]);

  const switchJourney = (e, step) => {
    if (externalUrl) {
      return;
    }

    e.preventDefault();
    setJourneyStep(step);
  };

  const handleTabs = (e, tabIndex) => {
    e.preventDefault();
    setPreviousTab(activeTab);
    setActiveTab(tabIndex !== activeTab ? tabIndex : null);
  };

  // Dropdown options for Relationships
  const relationshipOptions = relationships.map((item) => {
    const title = item?.fields?.title;
    const value = item?.fields?.value;

    return (
      <option key={value} data-form-ref={value} value={title}>
        {title}
      </option>
    );
  });

  // Dropdown options for Tax Resident Countries
  const countryOptions = taxResidentCountries.map((item: any) => {
    const { regionName, regionCountries, countryName, value, externalUrl } =
      item?.fields;
    let markup;

    if (countryName) {
      const dataFormRef = countryName ? { "data-form-ref": value } : {};
      const dataUrl = externalUrl ? { "data-url": externalUrl } : {};

      markup = (
        <option key={`country-${countryName}`} {...dataFormRef} {...dataUrl}>
          {countryName}
        </option>
      );
    } else if (regionCountries) {
      markup = (
        <optgroup label={regionName} key={regionName}>
          {regionCountries.map((country) => (
            <option key={country?.fields?.countryName}>
              {country?.fields?.countryName}
            </option>
          ))}
        </optgroup>
      );
    }

    return markup;
  });

  const btnAttributes = externalUrl
    ? { target: "_blank", rel: "noreferrer" }
    : {};

  // Blackbaud State
  const [switchJourneyUrl, setSwitchJourneyUrl] = useState("#");

  useEffect(() => {
    setSwitchJourneyUrl(
      externalUrl
        ? externalUrl
        : isWindow
          ? `${window.location.origin}${window.location.pathname}?t=${taxResident}&r=${relationship}${formAnchor}`
          : "#"
    );
  }, [taxResident, relationship, externalUrl]);

  const [blackbaudForms, setBlackbaudForms] = useState<BlackbaudForms | null>(
    null
  );
  const [giftAid, setGiftAid] = useState(false);

  return (
    <div
      className={clsx(
        "component",
        styles["donate-tool"],
        styles["vertical-accordion"],
        "single-display",
        journeyStep === 2 && styles["show-journey-step-two"]
      )}
    >
      <div className="wrapper">
        {label && <h2 className={styles["small-page-title"]}>{label}</h2>}
        <h3 className="h2">{headerTitle}</h3>
        <div className={styles.intro}>
          <RichTextDisplay
            richText={headerIntro}
            tinyMceRichText={headerIntroTinyMce}
          />
        </div>
        <ul className={styles.accordion} id="Form">
          <li>
            <a
              href="#"
              className={styles["toggle-link"]}
              onClick={(e) => {
                handleTabs(e, 0);
              }}
              aria-expanded={activeTab === 0}
              ref={firstTab}
            >
              {leftTabLabel}
            </a>
            <div
              className={styles["accordion-drop"]}
              aria-hidden={activeTab !== 0}
              //@ts-ignore
              ref={(el) => (accordionDrop.current[0] = el)}
              style={{
                visibility: isVisible[0] ? "visible" : "hidden",
                height: contentHeight[0],
              }}
            >
              <div className={styles.content}>
                <div className={styles["journey-step"]}>
                  <div className={styles["step-count"]}>
                    <a
                      href="#"
                      className="link-prev-step"
                      tabIndex={-1}
                      onClick={(e) => {
                        switchJourney(e, 1);
                      }}
                    >
                      <span>1</span>
                      About you
                    </a>
                  </div>
                  <div className={styles["step-count"]}>
                    <span>2</span>
                    Donation
                  </div>
                </div>
                <div className={styles["journey-content-step-one"]}>
                  {leftTabIntro && (
                    <p className={styles.intro}>{leftTabIntro}</p>
                  )}
                  <div className={clsx(styles.form, "form", "component")}>
                    <Grid
                      row
                      justify={"center"}
                      customClass={clsx(
                        "form-row",
                        "select-wrapper",
                        styles["select-wrapper"]
                      )}
                    >
                      <Grid
                        column
                        sm={12}
                        md={5}
                        customClass={styles["form-field"]}
                        justify={"flex-end"}
                      >
                        <DonateSelect
                          content={{
                            id: "tax-residence",
                            describeBy: "tax-info",
                            defaultOption: taxResidentLabel,
                            customClass: styles.select,
                            options: countryOptions,
                            onChange: handleTaxResidents,
                          }}
                        />
                      </Grid>
                      <Grid
                        column
                        sm={12}
                        md={5}
                        customClass={styles["form-field"]}
                      >
                        <DonateSelect
                          content={{
                            id: "relationship",
                            describeBy: "relationship-info",
                            defaultOption: relationshipsLabel,
                            customClass: styles.select,
                            options: relationshipOptions,
                            onChange: handleRelationship,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <div className={styles["btn-and-text-wrapper"]}>
                      <div
                        className={clsx(styles["button-wrapper"], "form-row")}
                      >
                        <p>
                          <a
                            href={switchJourneyUrl}
                            {...btnAttributes}
                            className={clsx(
                              "cta",
                              styles["cta-continue"],
                              !continueActive && "disabled"
                            )}
                            aria-disabled={!continueActive}
                          >
                            {ctaText}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles["journey-content-step-two"]}>
                  <DonateDetails
                    content={{ formattedCountries, taxResident, journeyStep }}
                  />
                  {blackbaudForms && journeyStep === 2 && (
                    <BlackbaudForm
                      content={{
                        journeyStep,
                        externalUrl,
                        blackbaudForms,
                        giftAid,
                      }}
                      anchor={formAnchor}
                    />
                  )}
                </div>
              </div>
            </div>
          </li>
          <li>
            <a
              href="#"
              className={styles["toggle-link"]}
              onClick={(e) => {
                handleTabs(e, 1);
              }}
              aria-expanded={activeTab === 1}
            >
              {rightTabLabel}
            </a>
            <div
              className={styles["accordion-drop"]}
              aria-hidden={activeTab !== 1}
              //@ts-ignore
              ref={(el) => (accordionDrop.current[1] = el)}
              style={{
                visibility: isVisible[1] ? "visible" : "hidden",
                height: contentHeight[1],
              }}
            >
              <div className={styles.content}>
                <Grid row>
                  <Grid column sm={12} md={6}>
                    <section className="component text-placeholder">
                      {leftTabIntro}
                    </section>
                  </Grid>
                  <Grid column sm={12} md={6}>
                    <C11_VerticalAccordion
                      content={{
                        ...rightTabAccordions.fields,
                        customClass: clsx(
                          styles["donate-tool-vertical-accordion"],
                          "donate-tool-vertical-accordion"
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DonateTool;
