import Grid from "@components/Grid/Grid";
import styles from "./C261_Contact.module.scss";
import ContactDetails, { ContactDetailsProps } from "./C261_ContactDetails";
import ContactDetailsExtended from "./C261_ContactDetailsExtended";
import LinkedInContactDetails, {
  ContactLinkedInProps,
} from "./C261_LinkedInContactDetails";
import clsx from "clsx";

export interface ContactProps {
  title: string;
  isExtended: boolean;
  description: boolean;
  contactLinkedInDetails: ContactLinkedInProps[];
  contactsExtended: {
    contactDetails: ContactDetailsProps;
    contactLinkedInDetails: ContactLinkedInProps;
  }[];
  contactDetails: ContactDetailsProps[];
}

export const Contact = (props: { content: ContactProps }) => {
  const { content } = props;
  const {
    title,
    isExtended,
    description,
    contactLinkedInDetails,
    contactsExtended,
    contactDetails,
  } = content;

  return (
    <div
      className={clsx(
        "component",
        styles.contact,
        isExtended && styles["contact-details-extended"]
      )}
    >
      <div className="wrapper">
        <div className={styles["header-section"]}>
          <Grid row>
            <Grid column sm={12} lg={5}>
              <h4>{title}</h4>
            </Grid>
            <Grid column sm={12} lg={7}>
              <p>{description}</p>
            </Grid>
          </Grid>
        </div>
        <div className={styles.content}>
          <>
            {isExtended ? (
              <ContactDetailsExtended
                isExtended={isExtended}
                contactsExtended={contactsExtended}
              />
            ) : (
              <Grid row>
                <Grid column sm={12} lg={5}>
                  <div className={styles.base6_tp12}>
                    <Grid row>
                      {contactLinkedInDetails &&
                        contactLinkedInDetails.map((contactDetail, index) => {
                          return (
                            <LinkedInContactDetails
                              key={`${contactDetail.title}${index}`}
                              {...contactDetail}
                            />
                          );
                        })}
                    </Grid>
                  </div>
                </Grid>
                <Grid column sm={12} lg={7}>
                  <div className={styles["contact-details"]}>
                    <Grid row>
                      {contactDetails &&
                        contactDetails.map((contactDetail, index) => {
                          return (
                            <ContactDetails
                              key={`${contactDetail.title}-${index}`}
                              {...contactDetail}
                            />
                          );
                        })}
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            )}
          </>
        </div>
      </div>
    </div>
  );
};

export default Contact;
