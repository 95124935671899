import IconExternal from "../../../public/image/svg/external.svg";
import styles from "../C37.5_EventDetail.module.scss";

const LinksSection = (props: { links: any[] }) => {
  const { links } = props;

  return (
    <ul className={styles["link-list"]}>
      {links.map((link, index) => (
        <li key={`link-${index}`}>
          <i className="icon-external">
            <IconExternal />
          </i>
          <a href={link.eventRelatedUrlValue} target="_blank" rel="noreferrer">
            {link.eventRelatedUrlDescription}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default LinksSection;
