import Cta from "@components/Cta/Cta";
import Grid from "@components/Grid/Grid";
import { Image } from "@customTypes/image";
import { Link as LinkType } from "@customTypes/link";
import Link from "@components/Link/Link";
import { NextContentfulImage } from "@components/Image";
import { useInView } from "react-hook-inview";
import styles from "./C20_PromotionalArticles.module.scss";
import clsx from "clsx";

export interface PromotionalArticlesProps {
  imageFullWide: Image;
  imageWide: Image;
  imageMedium: Image;
  imageSmall: Image;
  imageMobile: Image;
  label: string;
  title: string;
  description: string;
  articleLink: LinkType;
  linkUrlForRender: LinkType;
  reverse: boolean;
  isLight: boolean;
  isSeventyPercentVariation: boolean;
}

export const PromotionalArticles = (props: {
  content: PromotionalArticlesProps;
}) => {
  const { content } = props;
  const {
    imageFullWide,
    imageWide,
    imageMedium,
    imageSmall,
    imageMobile,
    label,
    title,
    description,
    articleLink,
    linkUrlForRender,
    reverse,
    isLight,
    isSeventyPercentVariation,
  } = content;

  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });

  let promotionalType = 0;
  let cssClass = "";

  if (imageMedium) {
    promotionalType = 1;
  } else if (imageFullWide && imageWide) {
    promotionalType = 2;
    cssClass = isSeventyPercentVariation ? "small-width" : "wide";
  } else if (imageSmall) {
    promotionalType = 3;
    cssClass = "alt";
  }

  return (
    <Grid row>
      <Grid column sm={12}>
        <div
          className={clsx(
            "component",
            styles["promotional-articles"],
            styles.solo,
            reverse && styles.reverse,
            isLight ? styles.white : styles.dark,
            styles[cssClass],
            isVisible && styles.animate
          )}
          ref={ref}
        >
          <div className={styles.wrapper}>
            <div className={styles.container}>
              <article>
                {imageMobile && linkUrlForRender && (
                  <Link
                    passHref={true}
                    href={linkUrlForRender.fields.url || "#"}
                  >
                    <a className={styles["link"]}>
                      <NextContentfulImage
                        src={imageMobile.fields.file.url}
                        alt={imageMobile.fields.title}
                        width={imageMobile.fields.file.details.image.width}
                        height={imageMobile.fields.file.details.image.height}
                      />
                    </a>
                  </Link>
                )}
                <div className={styles.text}>
                  {(promotionalType === 1 || promotionalType === 2) && (
                    <div className={styles.line}></div>
                  )}
                  {label && <p className={styles.label}>{label}</p>}
                  {title && linkUrlForRender?.fields?.url && (
                    <h4>
                      <Link href={linkUrlForRender?.fields?.url}>{title}</Link>
                    </h4>
                  )}
                  {description && <p>{description}</p>}
                  {articleLink && (
                    <Cta
                      {...articleLink.fields}
                      type="secondary"
                      light={
                        (promotionalType === 1 || promotionalType === 2) &&
                        !isLight
                      }
                      openInNewTab={articleLink.fields.openInNewTab}
                    />
                  )}
                </div>
              </article>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default PromotionalArticles;
