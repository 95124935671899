import React from "react";
import Link from "@components/Link/Link";
import styles from "./MegaNav.module.scss";
import { NavGroupHeading, NavLinkType } from "@customTypes/megaNav";
import { GeneralLink } from "@customTypes/generalLink";
import { navigationGAClick } from "@utilities/navigationGAClick";

const PanelLinkList = ({
  linkList,
  handleReset,
}: {
  linkList: (NavGroupHeading | GeneralLink)[];
  handleReset: () => {};
}) => {
  return (
    <ul>
      {linkList.map((panelLink, index) => {
        const panelLinkType = panelLink?.sys?.contentType?.sys?.id;

        switch (panelLinkType) {
          case NavLinkType.NavGroupHeading:
            const headingWithType = panelLink as NavGroupHeading;
            return (
              <li key={headingWithType.fields?.label + index}>
                <h4 className={styles["list-head"]}>
                  {headingWithType.fields?.link?.fields?.linkText ? (
                    <Link
                      href={headingWithType.fields?.link?.fields?.url || "#"}
                    >
                      <a
                        onClick={(e) => {
                          if (headingWithType.fields?.link?.fields?.url) {
                            navigationGAClick(e);
                            handleReset();
                          } else {
                            e.stopPropagation();
                          }
                        }}
                      >
                        {headingWithType.fields?.link?.fields?.linkText}
                      </a>
                    </Link>
                  ) : null}
                </h4>
              </li>
            );
          case NavLinkType.GeneralLink:
            const linkWithType = panelLink as GeneralLink;
            return (
              <li key={linkWithType.fields?.linkText + index}>
                {linkWithType.fields?.linkText ? (
                  <Link href={linkWithType.fields?.url || "#"}>
                    <a
                      onClick={(e) => {
                        if (linkWithType.fields?.url) {
                          navigationGAClick(e);
                          handleReset();
                        } else {
                          e.stopPropagation();
                        }
                      }}
                    >
                      {linkWithType.fields?.linkText}
                    </a>
                  </Link>
                ) : null}
              </li>
            );
        }
      })}
    </ul>
  );
};

export default PanelLinkList;
