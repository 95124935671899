import Lightbox from "@components/Lightbox/Lightbox";
import { MQ_AND_BELOW } from "@customTypes/Breakpoints";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import clsx from "clsx";
import styles from "./C27_NavigationalQuote.module.scss";
import { Quote, QuoteType } from "./C27_Quote";

export interface NavigationalQuoteProps {
  content: Quotes | QuoteType;
}

export interface Quotes {
  quotes?: { fields: QuoteType }[];
}

export const NavigationalQuote = ({ content }: NavigationalQuoteProps) => {
  const { quotes } = content as Quotes;
  const [longQuoteId, setLongQuoteId] = useState(0);
  const [showLightbox, setShowLightbox] = useState(false);
  const [pQuotes, setPQuotes] = useState(null);
  useEffect(() => {
    /*
     * Get only published quotes
     * this logic should be moved to the data mappers un utils
     * Only accessible data should reach the frontend component
     * AB
     */
    setPQuotes(quotes?.filter((quote) => quote.fields !== undefined));
  }, []);

  const sliderSettings = {
    dots: true,
    adaptiveHeight: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: MQ_AND_BELOW.MOBILE,
        settings: {
          adaptiveHeight: false,
        },
      },
    ],
    appendDots: (dots) => <ul>{dots}</ul>,
    customPaging: (i) => (
      <div key={`custom-paging${i}`} className={styles["tns-nav"]}>
        <span></span>
      </div>
    ),
  };

  const onReadMoreClick = (clicked?: number) => {
    clicked && setLongQuoteId(clicked);
    setShowLightbox(true);
  };

  const closeLightbox = () => {
    setTimeout(() => {
      setShowLightbox(false);
    }, 300);
  };

  if (!pQuotes) {
    return <Quote content={content as QuoteType} />;
  }
  return (
    <div>
      {pQuotes && pQuotes.length > 1 ? (
        <div className={styles["navigational-quote-carousel"]}>
          <Slider {...sliderSettings}>
            {pQuotes &&
              pQuotes.map((quote, i) => (
                <Quote
                  key={`${quote.fields?.quote}${i}`}
                  quoteId={i}
                  content={{ ...quote.fields }}
                  onReadMoreClick={onReadMoreClick}
                />
              ))}
          </Slider>

          {showLightbox && (
            <Lightbox
              customClass="quote-lightbox"
              onCloseFunction={closeLightbox}
            >
              <div
                className={clsx(
                  styles["overlay-content"],
                  styles["long-quote-overlay"]
                )}
              >
                <div
                  className={clsx(
                    "wrapper",
                    styles["long-quote-content"],
                    styles["popup"]
                  )}
                >
                  <div className={styles["qt-line"]}></div>
                  <p>
                    {pQuotes[longQuoteId].fields.longQuote
                      ? pQuotes[longQuoteId].fields.quote +
                        pQuotes[longQuoteId].fields.longQuote
                      : pQuotes[longQuoteId].fields.quote}
                    <br />
                    <br />
                    <b>{pQuotes[longQuoteId].fields.authorName}</b>,{" "}
                    {pQuotes[longQuoteId].fields.authorJobTitle}
                  </p>

                  <div className={styles["qt-second-line"]}></div>
                </div>
              </div>
            </Lightbox>
          )}
        </div>
      ) : pQuotes[0].fields.fullWidth ? (
        <div className={styles["navigational-quote-carousel"]}>
          <Quote content={{ ...pQuotes[0].fields }} />
        </div>
      ) : (
        <Quote content={{ ...pQuotes[0].fields }} />
      )}
    </div>
  );
};

export default NavigationalQuote;
