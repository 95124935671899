import Lightbox from "@components/Lightbox/Lightbox";
import React, { memo } from "react";
import dynamic from "next/dynamic";
import { HeaderLightBoxVideoPlayerProps } from "./C46_HeaderLightBoxVideoPlayer.interfaces";

const VideoPlayer = dynamic(() => import("@components/VideoPlayer"), {
  ssr: false,
});

function C46_HeaderLightBoxVideoPlayer({
  url,
  controls,
  onVideoClose,
  onVideoEnded,
}: HeaderLightBoxVideoPlayerProps) {
  return (
    <Lightbox isBanner onCloseFunction={onVideoClose}>
      <VideoPlayer url={url} controls={controls} onEnded={onVideoEnded} />
    </Lightbox>
  );
}

export default memo(C46_HeaderLightBoxVideoPlayer);
