import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";

import Grid from "@components/Grid/Grid";
import RichTextFullWidth from "@components/C16_RichTextFullWidth/C16_RichTextFullWidth";

import styles from "./PaymentConfirmation.module.scss";
import setGADataLayer from "@utilities/setGADataLayer";
import { getBusinessUnit } from "@utilities/getBusinessUnit";
import ClipLoader from "react-spinners/ClipLoader";
import RichTextDisplay from "@components/RichTextDisplay/RichTextDisplay";

import {
  PaymentConfirmationProps,
  ResponseData,
  PaymentFormData,
} from "./interfaces";

const PaymentConfirmation = (props: { content: PaymentConfirmationProps }) => {
  const [responseData, setResponseData] = useState<ResponseData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  const MAX_CONNECTION_COUNT = 150;
  let connectionCounter = 0;
  let transaction_id = router?.query?.transaction_id;
  let errorMsg = router?.query?.error;

  useEffect(() => {
    if (
      router?.query?.reference?.includes("?transaction_id") &&
      router?.asPath?.split("?")?.length > 1
    ) {
      let queryparam = new URLSearchParams(
        router?.asPath.replace("?transaction_id", "&transaction_id")
      );
      transaction_id = queryparam?.get("transaction_id") || "";
    }
    if (transaction_id) {
      setLoading(true);
      getTransactionResponse();
    }
  }, [router]);

  const { content } = props;
  const { failErrorMessage, successMessage } = content;

  const getTransactionResponse = async () => {
    connectionCounter++;

    const response = await fetch(
      `/api/getTransactionDetails?transactionId=${transaction_id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    const paymentData = await response.json();

    if (
      !paymentData?.response?.status &&
      connectionCounter < MAX_CONNECTION_COUNT
    ) {
      setTimeout(async () => {
        await getTransactionResponse();
      }, 1000);
    } else {
      setLoading(false);
    }

    setResponseData(paymentData?.response);
  };

  let formData = {} as PaymentFormData;
  if (typeof window !== "undefined") {
    formData = JSON.parse(
      window?.localStorage?.getItem("paymentFormData") || "{}"
    );
  }

  if (
    responseData === null ||
    responseData === undefined ||
    responseData.transactionId === "undefined"
  ) {
    if (!errorMsg) return;
    return (
      <div className={clsx(styles["vertical-padd"], "wrapper")}>
        <Grid row>
          <Grid column sm={12} md={12}>
            <div>
              <div className="rich-text">
                <RichTextDisplay
                  richText={failErrorMessage?.fields?.content}
                  tinyMceRichText={failErrorMessage?.fields?.contentTinyMce}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (
    responseData?.status !== "FAILED" ||
    responseData?.transactionId !== "undefined"
  ) {
    setGADataLayer({
      event: "formComplete",
      formName: "paymentForm",
      businessUnit: getBusinessUnit(),
      programmeCode: "",
      eventType: "",
    });
  }

  if (responseData.status === "FAILED") {
    return (
      <div className={clsx(styles["edit-details"], "wrapper")}>
        <Grid row>
          <Grid column sm={12} md={12}>
            <div className="rich-text">
              <RichTextDisplay
                richText={failErrorMessage?.fields?.content}
                tinyMceRichText={failErrorMessage?.fields?.contentTinyMce}
              />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }

  const renderDataLoader = () => {
    if (loading) {
      return (
        <span>
          ( data is loading...{" "}
          <ClipLoader
            color="#001e62"
            size={15}
            aria-label="Loading Spinner"
            data-testid="loader"
          />{" "}
          )
        </span>
      );
    } else {
      return <span>{responseData.reference}</span>;
    }
  };
  const renderAmountValue = (data: {
    currencyCode?: string;
    amountReceived?: string;
    payAmount: string;
  }) => {
    const { currencyCode, amountReceived, payAmount } = data;
    return (
      <>
        {new Intl.NumberFormat("en-GB", {
          style: "currency",
          currency: currencyCode,
        }).format(parseFloat(amountReceived || payAmount))}
      </>
    );
  };

  const renderEeDeccDetails = () => {
    return (
      <div>
        <div className={styles["c-description"]}>
          <p>Your payment has been made successfully.</p>
          <p>
            The payment confirmation email is sent to&nbsp;
            <strong>{formData.emailAddress}</strong> with the following details.
            Please keep the record as a reference.
          </p>
        </div>

        <h3>Payment details</h3>
        <p>
          <strong>Payment reference number:</strong> {renderDataLoader()}
        </p>
        <p>
          <strong>Email address:</strong> {formData?.emailAddress}
        </p>

        <p>
          <strong>Student ID/Customer Code:</strong> {formData?.PayReference}
        </p>

        <p>
          <strong>Invoice No:</strong> {formData?.invoiceNumber}
        </p>
        <p>
          <strong>Invoice currency:</strong> {formData?.currencyCode}
        </p>
        <p>
          <strong>Amount: </strong>
          {renderAmountValue(formData)}
        </p>
      </div>
    );
  };

  const renderPhdDetails = () => {
    return (
      <div>
        <h3>Application details</h3>
        <div className={styles["c-details"]}>
          <p>
            <strong>First name: </strong> {formData?.firstName}
          </p>
          <p>
            <strong>Surname: </strong> {formData?.lastName}
          </p>
          <p>
            <strong>Contact number: </strong> {formData?.phoneNumber}
          </p>
          <p>
            <strong>Email:</strong> {formData?.emailAddress}
          </p>
          <p>
            <strong>Subject Area:</strong> {formData?.subjectArea}
          </p>
          <p>
            <strong>Amount: </strong>
            {renderAmountValue(formData)}
          </p>
        </div>
        <div className={styles["c-description-phd"]}>
          <p>Your application fee payment has been completed.</p>
          {!!responseData?.reference && (
            <p>
              Your payment reference number is{" "}
              <strong>{renderDataLoader()}</strong>
            </p>
          )}
          <p>
            You can now go to <strong>Interfolio</strong> and complete your
            application.
          </p>
        </div>
      </div>
    );
  };

  const renderInteractPaymentDetails = () => {
    const payData = {
      currencyCode: "GBP",
      amountReceived: (
        parseFloat(responseData.amountReceived) / 100
      )?.toString(),
      payAmount: (parseFloat(responseData.amountReceived) / 100)?.toString(),
    };
    return (
      <div>
        <h3>Payment details</h3>
        <div className={styles["c-details"]}>
          <p>
            <strong>Merchant reference: </strong> {responseData?.reference}
          </p>
          <p>
            <strong>Transaction id: </strong> {responseData?.transactionId}
          </p>
          <p>
            <strong>Email address: </strong>{" "}
            {responseData?.customerEmailAddress}
          </p>
          <p>
            <strong>Invoice currency:</strong> GBP
          </p>
          <p>
            <strong>Amount: </strong>
            {renderAmountValue(payData)}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className={clsx(styles["payment-confirmation"], "wrapper")}>
      <Grid row>
        <Grid column sm={12} md={12}>
          <h1>Thanks for your payment</h1>
          <RichTextFullWidth
            content={successMessage?.fields?.content}
            addBorder={false}
          />
          {responseData &&
          router?.query?.reference?.includes("APPWEB") &&
          responseData.appId
            ? renderInteractPaymentDetails()
            : formData?.internalReference === "PHDFEE"
              ? renderPhdDetails()
              : renderEeDeccDetails()}
          <div className={styles["btn-and-text-wrapper"]}>
            <div className="button-wrapper form-row">
              <button
                type="submit"
                id="submitButton"
                value=""
                className={`${styles["back-button"]} cta`}
                onClick={() => router.push("/")}
              >
                Back to homepage
              </button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PaymentConfirmation;
