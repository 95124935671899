export const ProgrammeDetailResultsTemplateStyles = `

.lbs-result-list-wrapper .course-search-results {
    margin-top: 2rem;
}

.lbs-result-list-wrapper .course-search-results .application-box {
    display: flex;
}

.lbs-result-list-wrapper .application-box > div {
    padding: 3rem;
}

.lbs-result-list-wrapper .application-box .left-section {
    width: 55%;
    background: white;
    position: relative;
    font-family: var(--lbs-base-font-family),sans-serif;

}

.lbs-result-list-wrapper .left-section .title-link {
    text-decoration: none;
}

.lbs-result-list-wrapper .left-section .title-link:focus {
    text-decoration: underline;
}

.lbs-result-list-wrapper .left-section .title-link:hover {
    text-decoration: underline;
}

.lbs-result-list-wrapper .left-section h4 {
    line-height: 4.5rem;
     margin-bottom: 1.8rem;
     font-size: 3.6rem;
     font-family: var(--lbs-heading-font-family),serif;
     font-weight: 400;
}

.lbs-result-list-wrapper .left-section > p {
    margin-bottom: 2rem;
    
}

.lbs-result-list-wrapper .left-section .apply-wrapper {
        position: absolute;
        bottom: 3rem;
        width: 90%;
}

.lbs-result-list-wrapper .cta {
    line-height: 1.5;
    background-color: #001e62;
    color: white;
    width: 100%;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    font-size: 1.6rem;
    padding: 1.2rem 3.2rem;
    border: 2px solid transparent;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0;
    transition: all 0.3s;
}

.lbs-result-list-wrapper .left-section .apply-wrapper .button-with-link {
    display: fleX;
    align-items: center;
    align-content: flex-start;
    justify-content: space-between;
}

.lbs-result-list-wrapper .left-section .apply-wrapper .button-with-link .cta {
    max-width: 22rem;
    margin-right: 1rem;
    // color: white !important;
}

.lbs-result-list-wrapper .left-section .apply-wrapper .button-with-link .cta:first-child:hover {
    background-color: white;
    border: 2px solid #001e62;
}

.lbs-result-list-wrapper .left-section .apply-wrapper .button-with-link .cta:hover {
    color: #001e62 !important;
     text-decoration: none;
}

.lbs-result-list-wrapper .left-section .apply-wrapper .button-with-link .tertiary {
    margin-right: 0;
    // color: #001e62 !important;
}

.lbs-result-list-wrapper .cta.tertiary  .icon-arrow {
    display:inline-block;
    width: 1.6rem;
    height: 1.6rem;
    position: absolute;
    left: 0;
    top: calc(45% - 0.1rem);
    transform: translateY(-50%);
    fill: #001e62;
}

.lbs-result-list-wrapper .section-row-wrapper .section-row .delivery-text  li::before {
    content: "";
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid #c8103e;
    border-radius: 50%;
    left: -1.4rem;
    top: 0.5rem;
}
.lbs-result-list-wrapper .section-row-wrapper .section-row .delivery-text  li {
    padding-left: 1rem;
    list-style-type: none;
    position: relative;
    line-height: 1.5;
    font-size: 1.6rem;
}

.lbs-result-list-wrapper .cta.tertiary {
    background: none;
    border: 0;
    width: auto;
    color: #001e62;
    font-size: 1.8rem;
    padding: 0 1rem 0 3.5rem;
    position: relative;
}

.lbs-result-list-wrapper .tertiary span:last-child {
    margin-left: -0.5rem;
    transition: margin 0.3s;
    display: inline-block;
    color: #001e62;
    font-size: 1.8rem;
}

.lbs-result-list-wrapper .tertiary span:last-child:hover {
    text-decoration: underline;
    
}



.lbs-result-list-wrapper .left-section .button-margin {
    margin-bottom: 9rem;
}

.lbs-result-list-wrapper .left-section > .form {
    margin-bottom: 2.2rem;
}

.lbs-result-list-wrapper .left-section > .form .checkbox {
    color: variables.$projectBlue;
          float: right;
          margin-right: 1rem;
}

.lbs-result-list-wrapper .left-section > .form .checkbox .tick {
    left: 0;
}

.lbs-result-list-wrapper .left-section > .form .checkbox label {
    padding-top: 0.2rem;
            font-weight: 700;
            outline: 0;
            display: inline;
}



.lbs-result-list-wrapper .left-section  .tag-info {
    padding-right: 4rem;
    margin-bottom: 2.5rem;
    font-size: 1.2rem;
    color: #001e62;
}

.lbs-result-list-wrapper .left-section  .tag-info .tag-label {
    font-weight: 700;
          margin-right: 0.5rem;
}

.lbs-result-list-wrapper .application-box .right-section {
    background: #001e62;;
    color: white;
    width: 45%;
    font-family: var(--lbs-base-font-family), sans-serif;
    
}

.lbs-result-list-wrapper .right-section .section-row-wrapper {
    margin: 0;
    flex-flow: column;
}

.lbs-result-list-wrapper .right-section .inner-wrapper {
    height: 100%;
    position: relative;
}

.lbs-result-list-wrapper .section-row-wrapper .section-row {

    display: flex;
    margin-bottom: 1.5rem;
}

.lbs-result-list-wrapper .section-row-wrapper .section-row .label {
    min-width: 7.5rem;
    display: inline-block;
    font-size: 1.2rem;
    padding-top: 0.7rem;
}

.lbs-result-list-wrapper .section-row-wrapper .section-row .info {
    font-size: 1.6rem;
}

.lbs-result-list-wrapper .section-row-wrapper .section-row .info .sub-text {
        display: block;
        font-size: 1.2rem;
        color: #7f8eb0;
}

.lbs-result-list-wrapper .section-row-wrapper .section-row .delivery-info {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
}

.lbs-result-list-wrapper .section-row-wrapper .section-row .delivery-text ul {
    margin-bottom: 0;
}

.lbs-result-list-wrapper .section-row-wrapper .section-row .delivery-text li {
    margin-bottom: 0.6rem;
    margin-top: 0.35rem;
}



.lbs-result-list-wrapper .section-row-wrapper .section-row .info {
    padding-right: 0.5rem;
}

.lbs-result-list-wrapper .section-row-wrapper .section-row .info:last-child {
    padding-right: 0;
    flex-direction: column;
    line-height: 1.5;
    flex-basis: 100%;
}

  


@media all and (min-width:128px) and (max-width:767px){

  .lbs-result-list-wrapper .application-box {
        display: block;
        box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.41);
    }

    .lbs-result-list-wrapper .application-box > div {
        padding: 2rem;
    }

    .lbs-result-list-wrapper .application-box .left-section, .application-box .right-section {
        width: auto;
    }

    .lbs-result-list-wrapper .application-box .left-section .button-margin {
        margin-bottom: 6rem;
    }

    .lbs-result-list-wrapper .application-box .left-section h4 {
        line-height: 1.2;
          margin-bottom: 2rem;
       
    }

    .lbs-result-list-wrapper .application-box .left-section > p {
        font-size: 1.2rem;
    }

    
    .lbs-result-list-wrapper .application-box .left-section .button-with-link {
        display: none;
    }

    .lbs-result-list-wrapper .application-box .left-section > .form {
        margin-bottom: 1rem;
    }

    .lbs-result-list-wrapper .application-box .left-section > .form .checkbox {
        color: variables.$projectBlue;
            float: none;
            margin-right: 1rem;
    }


    .lbs-result-list-wrapper .application-box .right-section .section-row .delivery-info {
        -webkit-line-clamp: 8;
    }

    .lbs-result-list-wrapper .application-box .right-section .section-row .info {
        font-size: 1.2rem;
        padding-top: 0.3rem;
    }

    .lbs-result-list-wrapper .application-box .right-section .section-row .sub-text {
        margin-top: 0.5rem;
    }

    .lbs-result-list-wrapper .application-box .right-section .inner-wrapper {
        padding-bottom: 0;
    }

    .lbs-result-list-wrapper .application-box .tag-info {
        font-size: 1.2rem;
        margin-bottom: 2rem;
        color: #001e62;
    }

    .lbs-result-list-wrapper .application-box .tag-info .tag-label {
        font-weight: 700;
          color: #001e62;
    }

    .lbs-result-list-wrapper .application-box .m-hide {
        display: block;
    }

    .lbs-result-list-wrapper .application-box .m-hide > span {
        display: none;
    }

    .course-search-results .application-box {
        display: inline-block;
    }

}


`;
