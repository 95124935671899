import { Speaker } from "@components/__templates__/EventsDetail/EventsDetail";
import { Fragment } from "react";

const SpeakersSection = (props: { speakers: Speaker[] }) => {
  const { speakers } = props;

  if (!speakers?.length) return null;

  return (
    <dl>
      {speakers.map((speaker, index) => (
        <Fragment key={`speaker-${index}`}>
          <dt>{speaker?.fields?.name}</dt>
          <dd>{speaker?.fields?.link}</dd>
        </Fragment>
      ))}
    </dl>
  );
};

export default SpeakersSection;
