import { useSettings } from "@utilities/context/settings";
import clsx from "clsx";
import Link from "@components/Link/Link";
import { NextContentfulImage } from "@components/Image";
import styles from "./EMBAGGlobalHeader.module.scss";

export const EMBAGGlobalHeader = () => {
  const { siteSettings } = useSettings();

  return (
    <header id="main-header">
      <div className={styles["header-wrap"]}>
        <h1 className={clsx(styles.branding, styles.embag)}>
          {siteSettings?.embagGlobalHeaderCollection?.items.map(
            (item, index) => {
              return (
                <Link href={item.link} key={`${item.logo.title}${index}`}>
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <NextContentfulImage
                      width={item.logo.width}
                      height={item.logo.height}
                      src={item.logo.url}
                      alt={item.logo.title || ""}
                      loading="eager"
                      priority
                    />
                  </a>
                </Link>
              );
            }
          )}
        </h1>
      </div>
    </header>
  );
};

export default EMBAGGlobalHeader;
