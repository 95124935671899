import React, { useRef, useState } from "react";
import clsx from "clsx";

import styles from "./EmailSignup.module.scss";

export const EmailSignup = ({ text, ctaText, description }) => {
  return (
    <div className={clsx(styles["cta-container"], styles.flush)}>
      <div className={styles["cta-section"]}>
        <div className="wrapper">
          <h3>{text}</h3>
          {description && <p>{description}</p>}
          <div className={styles["button-wrapper"]}>
            <button type="button" className="cta {{cta-type}}">
              {ctaText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSignup;
