import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./C03_SubNavigation.module.scss";
import Cta from "@components/Cta/Cta";
import { useRouter } from "next/router";
import Arrow from "../../public/assets2019/img/svg/arrow-carousel.svg";
import Link from "@components/Link/Link";
import { useDevice } from "@utilities/react/get-device/get-device";
import clsx from "clsx";
import { flushSync } from "react-dom";
import { SubNavLinkContext } from "../../context/subNavLinks";
import { GeneralLink } from "@customTypes/generalLink";
import C72_ProgrammeDetailsBar from "@components/C72_ProgrammeDetailsBar/C72_ProgrammeDetailsBar";
import { useSettings } from "@utilities/context/settings";
import { useProgramme } from "@components/__templates__/ProgrammeDetailsPage/ProgrammeDetailsProvider";
import { ProgrammeCTAType } from "@customTypes/ProgrammeCTAType";
import { useProgrammeDetailsPage } from "../../context/programmeDetailsPage";
import { UserContext } from "../../context/user";
import DownloadTypes from "../../types/DownloadTypes";

export interface SubNavigationProps {
  content: {
    ctaLink1: GeneralLink;
    ctaLink2: GeneralLink;
    ctaLink2HelpText?: string;
    showApplyNowCta?: boolean;
    showDownloadCta?: boolean;
    showEnquiryCta?: boolean;
    programmeDetailsBar?: any;
  };
}

const SubNavigation: React.FC<SubNavigationProps> = (props) => {
  const stickyRef: any = useRef();
  const navigationRef: any = useRef();
  const stickComboRef: any = useRef();
  const stickComboWrapRef: any = useRef();
  const [isSticky, setIsSticky] = useState(false);
  const [activeAnchorId, setActiveAnchorId] = useState("");
  const [isExpanded, setIsExpanded] = useState(false);
  const [initialHeight, setInitialHeight] = useState(0);
  const [subNavOpen, setSubNavOpen] = useState(false);

  const router = useRouter();

  const [referPage, setReferPage] = useState("");
  useEffect(() => {
    setReferPage(router.asPath);
  }, [router]);

  const { content } = props;
  const {
    ctaLink1,
    ctaLink2,
    ctaLink2HelpText,
    showDownloadCta,
    showApplyNowCta,
    showEnquiryCta,
    programmeDetailsBar,
  } = content;

  const { user } = useContext(UserContext);

  const navigationLinks = useContext(SubNavLinkContext);

  const updateSubNavHeight = (subNav, height) => {
    const isOffScreen = subNav.current.getBoundingClientRect().top < 1;

    flushSync(() => setIsSticky(isOffScreen));

    subNav.current.style.paddingBottom = isOffScreen ? height + "px" : "0px";
  };

  const stickNav = () => {
    const height = stickComboRef.current.getBoundingClientRect().height;
    updateSubNavHeight(stickComboRef, height);
  };

  const scrollTo = (el, anchorId) => {
    const sectionHead = document.querySelector(anchorId);
    const container = stickyRef.current;
    if (sectionHead) {
      const focusTimeout =
        Math.abs(sectionHead.getBoundingClientRect().top / 2) +
        (document.body.clientWidth > 1023 ? 200 : 500);

      setTimeout(
        () => {
          window.scroll({
            top:
              sectionHead.getBoundingClientRect().top +
              (window.scrollY || document.documentElement.scrollTop) -
              container.getBoundingClientRect().height,
            left: 0,
            behavior: "smooth",
          });
        },
        document.body.clientWidth > 1023 ? 0 : 500
      );

      setTimeout(() => {
        sectionHead.focus();
      }, Math.abs(focusTimeout));

      history.replaceState({}, "", el.getAttribute("href"));
    }
  };

  const device = useDevice();

  const onNavClick = (e, hrefValue) => {
    e.stopPropagation();

    if (device === "mobile") {
      setIsExpanded(false);
    }

    let anchorId = hrefValue.substring(hrefValue.lastIndexOf("#"));

    setActiveAnchorId(hrefValue.split("#")[1]);

    if (anchorId.charAt(0) === "#") {
      e.preventDefault();
      scrollTo(e.target, anchorId);
    }
  };

  const LiJSX = ({
    linkText,
    openInNewTab,
    url,
    contentReference,
  }: {
    url?: string;
    linkText: string;
    openInNewTab?: boolean;
    contentReference?: { fields: { slug: string } };
  }) => {
    const linkUrl = contentReference?.fields?.slug
      ? `/${contentReference.fields.slug}`
      : url;

    const linkTarget = openInNewTab ? { target: "_blank" } : null;
    const activeUrl = activeAnchorId
      ? activeAnchorId
      : router["asPath"].split("#")[1];
    return (
      <li className={clsx(activeUrl === url?.split("#")[1] && styles.active)}>
        <Link href={linkUrl || "#"} passHref>
          <a {...linkTarget} onClick={(e) => onNavClick(e, linkUrl)}>
            {linkText}
          </a>
        </Link>
      </li>
    );
  };

  useEffect(() => {
    const wrapper = stickyRef.current.querySelector(`.wrapper`);
    const condensedNavHeight = wrapper.getBoundingClientRect().height;

    setInitialHeight(condensedNavHeight);

    window.addEventListener("scroll", stickNav);
    return () => {
      window.removeEventListener("scroll", stickNav);
    };
  }, []);

  useEffect(() => {
    const wrapper = stickyRef.current.querySelector(".wrapper");
    const primaryList = stickyRef.current.querySelector(".subnav-links");
    const buttons = stickyRef.current.querySelector(".button-wrappers");
    const listHeight = primaryList.getBoundingClientRect().height;
    const buttonsHeight = buttons.getBoundingClientRect().height;
    if (device === "tablet" || device === "mobile") {
      if (isExpanded) {
        // wrapper.getBoundingClientRect().height =
        //   wrapper.getBoundingClientRect().height;
        wrapper.style.height = listHeight + buttonsHeight + "px";
      } else {
        wrapper.style.height = initialHeight + "px";
      }
    } else {
      wrapper.style.height = "auto";
    }
  }, [isExpanded, device]);

  const onNavLabelKeydown = (e) => {
    if (e.which === 13 || e.which === 32) {
      setSubNavOpen(true);
    }
  };
  const { programmeTypeCode, programmeTypeGroup } = useProgramme();

  const { siteSettings } = useSettings();
  const downloadBrochureSlug =
    siteSettings?.programmeCtaPagesCollection?.items.find(
      (item) =>
        item.type.toLowerCase() ===
        DownloadTypes.DOWNLOAD_BROCHURE.toLowerCase()
    );

  const { programmeApplyOnlineParameterValue, type } =
    useProgrammeDetailsPage();

  const apply = siteSettings?.programmeCtaPagesCollection?.items.filter(
    ({ type }) => type === ProgrammeCTAType.Apply
  )[0];

  return (
    <div
      className={clsx(
        styles.component,
        styles["subnav-combo"],
        "blue",
        isSticky && styles["stick-combo"]
      )}
      ref={stickComboRef}
    >
      <div
        ref={stickComboWrapRef}
        className={clsx(styles["sticky-combo-wrap"])}
      >
        {programmeDetailsBar && (
          <C72_ProgrammeDetailsBar
            isSticky={isSticky}
            content={programmeDetailsBar}
          />
        )}
        <div
          ref={navigationRef}
          className={clsx(
            styles.component,
            styles["sub-navigation-two-level"],
            "blue",
            isExpanded && styles.open,
            subNavOpen && styles["subnav-open"]
          )}
        >
          <div ref={stickyRef} className={styles["sticky-wrap"]}>
            <div
              className={clsx("wrapper", styles.wrapper, styles["nav-wrapper"])}
            >
              <ul className={clsx(styles["subnav-links"], "subnav-links")}>
                <li
                  onKeyDown={onNavLabelKeydown}
                  onClick={() => setIsExpanded(!isExpanded)}
                  className={clsx(styles["nav-label"], styles["d-hide"])}
                  aria-expanded={!isExpanded}
                >
                  Go to...
                  <div className={clsx(styles.tick, styles["icon-chevron"])}>
                    <i className={clsx(styles["icon-chevron"])}>
                      <Arrow></Arrow>
                    </i>
                  </div>
                </li>
                {navigationLinks.map((link, i) => {
                  if (link && (link.menuTitle || link.pageTitle)) {
                    return (
                      <React.Fragment
                        key={`${link.menuTitle || link.pageTitle}-${i}}`}
                      >
                        <LiJSX
                          linkText={link.menuTitle || link.pageTitle}
                          url={`#${link.anchorKey}`}
                        />
                      </React.Fragment>
                    );
                  }
                })}
              </ul>
              <div
                className={clsx(styles["button-wrappers"], "button-wrappers")}
              >
                {showApplyNowCta && (
                  <Cta
                    id="apply-now"
                    linkText="Apply now"
                    type="primary"
                    light
                    url={
                      user
                        ? `/interact?applyID=${programmeApplyOnlineParameterValue}`
                        : `${apply?.url.slug}?&applyID=${programmeApplyOnlineParameterValue}`
                    }
                  />
                )}
                {!showApplyNowCta && ctaLink1 && (
                  <Cta
                    id="cta-link1"
                    {...ctaLink1.fields}
                    type="primary"
                    light
                  />
                )}
                {showDownloadCta && programmeTypeCode && (
                  <div className={styles.subNavTertiary}>
                    <Cta
                      id="download-brochure"
                      url={`${downloadBrochureSlug?.url.slug}?programmeTypeCode=${programmeTypeCode}&programmeTypeGroup=${programmeTypeGroup}&programType=${type}&brochureType=Download a brochure&referPage=${referPage}`}
                      linkText="Download brochure"
                      type="tertiary"
                      light
                      icon="icon-arrow"
                    />
                  </div>
                )}
                {showDownloadCta && !programmeTypeCode && ctaLink2 && (
                  <div className={styles.subNavTertiary}>
                    <Cta
                      id="cta-link2"
                      url={`${downloadBrochureSlug?.url.slug}${ctaLink2.fields?.url}`}
                      type="tertiary"
                      light
                      icon="icon-arrow"
                      linkText="Download brochure"
                    />
                  </div>
                )}
                {!showDownloadCta && ctaLink2 && (
                  <div className={styles.subNavTertiary}>
                    <Cta
                      id="cta-link3"
                      {...ctaLink2.fields}
                      type="tertiary"
                      light
                      icon="icon-arrow"
                      helpText={ctaLink2HelpText}
                    />
                  </div>
                )}
                {showEnquiryCta && (
                  <div className={styles.subNavTertiary}>
                    <Cta
                      id="cta-enquiry"
                      url="#Model.EnquiryLink"
                      linkText="EnquiryLabel"
                      type="tertiary"
                      light
                      icon="icon-arrow"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubNavigation;
