import RichTextFullWidth from "@components/C16_RichTextFullWidth/C16_RichTextFullWidth";
import Cta from "@components/Cta/Cta";
import { useCallback, useRef, useState } from "react";
import { useInView } from "react-hook-inview";
import clsx from "clsx";
import { getVideoUrl } from "@components/VideoPlayer/utils";
import C46_HeaderVideoPlayer from "./C46_HeaderVideoPlayer";
import { HeaderBannerProps } from "./C46_HeaderBanner.interfaces";
import C46_HeaderLightBoxVideoPlayer from "./C46_HeaderLightBoxVideoPlayer";
import {
  getImagePanelStyle,
  getImageSize,
  getImageSizes,
  getImageStyle,
} from "./C46_HeaderBanner.functions";
import NextContentfulImage from "@components/Image/NextContentfulImage";
import styles from "./C46_HeaderBanner.module.scss";

const HeaderBanner = (props: { content: HeaderBannerProps }) => {
  const { content } = props;

  const {
    title,
    isLight,
    localVideo,
    description,
    showChevron,
    ctaLink,
    ctaLinkAppearance,
    desktopImage,
    bannerType,
    videoLinkText,
    secondaryTitle,
    isVimeoCode,
    videoCode,
    hideLine,
    subDescription,
  } = content;

  const bannerContent = useRef<HTMLDivElement | null>(null);
  const [hideImage, setHideImage] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);

  const [ref, isVisible] = useInView({
    threshold: 0,
    unobserveOnEnter: true,
  });

  const handleVideoPlay = useCallback(() => {
    setHideImage(true);
  }, []);

  const handleVideoEnded = useCallback(() => {
    setHideImage(false);
  }, []);

  const scrollDown = (event) => {
    event.preventDefault();
    let rect = bannerContent?.current?.getBoundingClientRect();

    window.scroll({
      top:
        rect.top +
        rect.height +
        (window.scrollY || document.documentElement.scrollTop),
      left: 0,
      behavior: "smooth",
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      scrollDown(event);
    }
  };

  const handleOpenLightbox = () => {
    setShowLightbox(true);
  };

  const handleCloseLightbox = () => {
    setTimeout(() => {
      setShowLightbox(false);
    }, 300);
  };

  const videoUrl = getVideoUrl(isVimeoCode ? "vimeo" : "youtube", videoCode);
  const bannerVideoUrl = localVideo?.fields.file.url || videoUrl;
  const lightboxVideoUrl = videoUrl || localVideo?.fields.file.url;

  const isVideoBackground = bannerType === "Video Background";
  const isVideoOverlay = bannerType === "Video Overlay";
  const isImage = bannerType === "Image";

  const imagePanelStyle = getImagePanelStyle(bannerType);

  return (
    <div
      ref={ref}
      className={clsx(
        styles["header-banner-image"],
        styles["flush"],
        !desktopImage && styles["no-image"],
        !desktopImage && styles["full-width"],
        desktopImage && isVideoBackground && styles["page-video"],
        desktopImage && isVideoOverlay && styles["type-one"],
        desktopImage && isImage && styles["type-three"],
        hideImage && styles["played"],
        isVisible && styles["animate"],
        !isLight &&
          (typeof window === "object" && window?.location?.pathname === "/"
            ? styles["blue-main-page"]
            : styles["blue"]),
        hideLine && styles["hide-line"]
      )}
    >
      <div
        ref={bannerContent}
        className={clsx(styles["main-content"], { wrapper: isImage })}
      >
        <div className={clsx(styles["text-with-line"], { wrapper: !isImage })}>
          <div className={styles["content-wrapper"]}>
            <div className={styles.content}>
              {isVideoBackground ? (
                <>
                  {title && title.trim().startsWith("<h1") ? (
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  ) : (
                    <h1
                      className={secondaryTitle && styles["small-page-title"]}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  )}
                  {secondaryTitle && <h2>{secondaryTitle}</h2>}
                </>
              ) : (
                <>
                  <span className={styles["section-label"]}>
                    {secondaryTitle}
                  </span>
                  {title && title.startsWith("<h1") ? (
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  ) : (
                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                  )}
                </>
              )}

              {description && <p>{description}</p>}
              {subDescription && (
                <RichTextFullWidth
                  content={subDescription?.content}
                  contentTinyMce={subDescription?.contentTinyMce}
                  addBorder={false}
                />
              )}
              {videoLinkText && isVideoOverlay && (
                <Cta
                  onClick={handleOpenLightbox}
                  type="tertiary"
                  url={""}
                  linkText={videoLinkText}
                  openInNewTab={false}
                  icon="icon-play"
                  btnTheme="play"
                  customClass="icon-play"
                />
              )}
              {ctaLink && ctaLink.linkText && ctaLink.url && (
                <p>
                  {ctaLinkAppearance &&
                  ctaLinkAppearance.includes("primary") ? (
                    <Cta
                      type={ctaLinkAppearance}
                      light={!isLight}
                      {...ctaLink}
                    />
                  ) : (
                    <Cta type="tertiary" {...ctaLink} icon="icon-arrow" />
                  )}
                </p>
              )}
            </div>
          </div>
          {!isImage && !hideLine && (
            <span className={styles.line}>
              <span className={styles["inner-line"]} />
            </span>
          )}
        </div>
        {desktopImage && (
          <div
            className={clsx(styles["image-wrapper"], "chromatic-ignore", {
              "flush-no-padding": !isImage,
            })}
          >
            <div className={styles["inner-wrapper"]}>
              {isVideoBackground && bannerVideoUrl && (
                <div
                  className={styles["video-player-wrapper"]}
                  style={imagePanelStyle}
                >
                  <C46_HeaderVideoPlayer
                    url={bannerVideoUrl}
                    onVideoReady={handleVideoPlay}
                    onVideoPlay={handleVideoPlay}
                    onVideoEnded={handleVideoEnded}
                  />
                </div>
              )}
              <div
                className={styles["picture-wrapper"]}
                style={imagePanelStyle}
              >
                <NextContentfulImage
                  src={desktopImage.url}
                  alt={desktopImage.altText || ""}
                  {...getImageSize(bannerType)}
                  style={getImageStyle(bannerType)}
                  sizes={getImageSizes(bannerType)}
                  loading="eager"
                  priority
                />
              </div>
            </div>
            {!hideLine && (
              <span className={clsx(styles.line, styles.image)}>
                <span className={styles["inner-line"]} />
              </span>
            )}
          </div>
        )}
        {isVideoBackground && showChevron && (
          <span
            className={styles["slide-down"]}
            tabIndex={0}
            onClick={scrollDown}
            onKeyDown={handleKeyPress}
          >
            <span className={styles.chevron} />
          </span>
        )}
      </div>
      {showLightbox && lightboxVideoUrl && (
        <C46_HeaderLightBoxVideoPlayer
          url={lightboxVideoUrl}
          controls={isVimeoCode}
          onVideoClose={handleCloseLightbox}
          onVideoEnded={handleVideoEnded}
        />
      )}
    </div>
  );
};

export default HeaderBanner;
