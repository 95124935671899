import { Image as ImageProps } from "@customTypes/image";
import styles from "./C256_ProofPointWrapper.module.scss";
import { NextContentfulImage } from "@components/Image";
import { Link as LinkType } from "@customTypes/link";
import Link from "@components/Link/Link";
import clsx from "clsx";

export interface ProofPointData {
  description: string;
  title: string;
  titleNumber?: string;
  bigTitle?: boolean;
  largeImage?: ImageProps;
  smallImage?: ImageProps;
  exploreCourseLink?: LinkType;
}

export const ProofPoint = (props: ProofPointData) => {
  const {
    description,
    title,
    titleNumber,
    bigTitle,
    largeImage,
    smallImage,
    exploreCourseLink,
  } = props;
  const image = largeImage || smallImage;
  return (
    <div className={styles.point}>
      {image && (
        <div className={styles.icon}>
          <NextContentfulImage
            src={image.fields.file.url}
            alt={image.fields.title}
            width={768}
            height={432}
          />
        </div>
      )}
      <div className={styles.content}>
        <div>
          {title && (
            <h6 className={clsx(bigTitle && styles.h4)}>
              {titleNumber && <span>{titleNumber}</span>}
              {title}
            </h6>
          )}
          <p>{description}</p>
        </div>
        {exploreCourseLink?.fields?.url && (
          <Link href={exploreCourseLink?.fields?.url}>
            {exploreCourseLink?.fields?.linkText}
          </Link>
        )}
      </div>
    </div>
  );
};

export default ProofPoint;
