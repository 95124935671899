import { useEffect, useState } from "react";
import clsx from "clsx";
import { FormErrors } from "@customTypes/FormErrors";
import styles from "./AvailabilitySelector.module.scss";

export interface AvailabilitySelectorItemType {
  id: string;
  name: string;
  value: string;
}

export interface AvailabilitySelectorList {
  [key: string]: AvailabilitySelectorItemType[];
}

export interface AvailabilitySelectorProps {
  register: any;
  availability: string;
  availabilityHeadingText?: string;
  availabilityHintText: string;
  availabilitySelectedTypes: Array<string>;
  setAvailabilitySelectedTypes: Function;
  availabilityListData: AvailabilitySelectorList;
  formState: {
    errors: FormErrors;
  };
  name: string;
}

const AvailabilitySelector = (props: AvailabilitySelectorProps) => {
  const {
    register,
    formState,
    availability,
    availabilityListData,
    availabilityHeadingText,
    availabilityHintText,
    availabilitySelectedTypes,
    setAvailabilitySelectedTypes,
    name,
  } = props;

  const [showHint, setShowHint] = useState(true);
  useEffect(() => {
    setAvailabilitySelectedTypes(availabilitySelectedTypes);
  }, []);

  const handleAvailabilityChange = (
    event: Event & { target: HTMLInputElement }
  ) => {
    const { value, checked } = event.target;

    if (checked) {
      setAvailabilitySelectedTypes([...availabilitySelectedTypes, value]);
    } else {
      setAvailabilitySelectedTypes(
        availabilitySelectedTypes.filter(
          (chosenAvailability) => chosenAvailability !== value
        )
      );
    }
  };

  useEffect(() => {
    availabilitySelectedTypes.length > 0
      ? setShowHint(false)
      : setShowHint(true);
  }, [availabilitySelectedTypes]);

  const getValidationError = (value) => {
    if (value?.length === 0) {
      return availabilityHintText !== ""
        ? availabilityHintText
        : "At least one availability option must be selected";
    } else return null;
  };

  const availabilityKeyList = Object.keys(availabilityListData);
  const getMarkup = () => {
    let resultData: React.JSX.Element[] = [];

    // Header row with AM and PM
    resultData.push(
      <div key="header" className={styles["day-time"]}>
        <span></span> {/* Placeholder for empty cell */}
        <label className={styles["time-slot-label"]}>AM</label>
        <label className={styles["time-slot-label"]}>PM</label>
      </div>
    );

    // Rows with days and checkboxes
    availabilityKeyList.forEach((key) => {
      resultData.push(
        <div key={key} className={styles["day-time"]}>
          <span className={styles["day-name"]}>
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </span>
          <div className="form-field base6">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  value={availabilityListData[key][0].value}
                  checked={availabilitySelectedTypes.includes(
                    availabilityListData[key][0].value
                  )}
                  {...register(name, {
                    required: true,
                    validate: getValidationError,
                    onChange: handleAvailabilityChange,
                  })}
                />
                <span className="tick"></span>
              </label>
            </div>
          </div>
          <div className="form-field base6">
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  value={availabilityListData[key][1].value}
                  checked={availabilitySelectedTypes.includes(
                    availabilityListData[key][1].value
                  )}
                  {...register(name, {
                    required: true,
                    validate: getValidationError,
                    onChange: handleAvailabilityChange,
                  })}
                />
                <span className="tick"></span>
              </label>
            </div>
          </div>
        </div>
      );
    });
    return resultData;
  };

  return (
    <div className={styles["availability-selector"]}>
      <p className={styles["availability-question"]}>
        {availabilityHeadingText}
      </p>

      <div
        className={clsx(
          "form-row",
          styles["form-row"],
          styles["availabilityList"]
        )}
      >
        {availabilityListData && (
          <div className={styles["days-times"]}>{getMarkup()}</div>
        )}

        {showHint && availabilityHintText !== "" && (
          <div className="form-field base6">
            <div className="field">
              <input
                id="hiddenPOIChecked"
                name="hiddenPOIChecked"
                type="hidden"
                value=""
              />
            </div>

            <div className="field base6 message-wrapper inline-message field_error_msg">
              <p className="message">
                {"Please select at least one time slot"}
              </p>
            </div>
          </div>
        )}

        {formState.errors && formState.errors[name] && (
          <div className="form-row error">
            <div className="form-field base6">
              <div
                className={clsx(
                  "field",
                  "message-wrapper",
                  "inline-message",
                  formState.errors[name]?.message && "field_error_msg"
                )}
                tabIndex={0}
              >
                <p
                  className={clsx(
                    "message",
                    formState.errors[name].type === "required" && "active"
                  )}
                >
                  {formState.errors[name]?.message}
                  {formState.errors[name] &&
                    formState.errors[name].type === "required" && (
                      <span>
                        At least one availability option must be selected
                      </span>
                    )}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AvailabilitySelector;
